import makeStyles from '@mui/styles/makeStyles'

export const feedbackStyle = makeStyles((theme) => ({
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  parentBox: {
    width: '100%',
    display: 'flex',
  },
  title: {
    textAlign: 'center',
  },
  formControl: {
    minWidth: '250px',
    width: '100%',
  },
  selected: {
    borderColor: '#AC0000',
  },
  typeItalic: {
    fontSize: '15px',
    fontStyle: 'italic',
  },
}))
