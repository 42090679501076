import { useState, useEffect } from 'react'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { USA } from '../../components/common/constants'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Container from '@mui/material/Container'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useAuth } from '@praxis/component-auth'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Typography } from '@mui/material'
import { FloorMapCanvas } from './floorMapCanvas'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import { DeskStyles } from '../../styles/deskSelectionStyle'
import moment from 'moment'

const GroupReservationForm = () => {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const classes = DeskStyles()
  const activeFloor = env.floorActivation
  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [desks, setDesks] = useState([])
  const [formData, setFormData] = useState({
    reservingFor: [],
    building: '',
    floor: '',
    reservationDate: moment(),
    desk: [],
  })
  const [selectedDesks, setSelectedDesks] = useState([])
  const [deskCoordinates, setDeskCoordinates] = useState([])
  const [isFloorMapVisible, setIsFloorMapVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [businessUnits, setBusinessUnits] = useState([])
  const [loading, setLoading] = useState(false)
  const [floorOpeningDateMessage, setFloorOpeningDateMessage] = useState('')

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // viewport height
  }

  const selectedBuilding = buildings.find(
    (building) => building.building_id === formData.building,
  )

  const selectedBuildingName = selectedBuilding?.organisation_name

  // Fetch available buildings.
  useEffect(() => {
    async function fetchBuildings() {
      try {
        const url = env.apiUrl[USA].getReservableBuildings
        const response = await axios.get(url)
        const targetLocations = ['US', 'INDIA']
        const usAndIndiaBuildings = response.data.filter((building) =>
          targetLocations.includes(building.primary_location),
        )
        setBuildings(usAndIndiaBuildings)
      } catch (error) {
        console.error('Error fetching buildings:', error)
      }
    }

    fetchBuildings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch floors based on selected building and business unit.
  useEffect(() => {
    async function fetchFloors() {
      if (formData.building && businessUnits.length > 0) {
        try {
          const url = env.apiUrl[USA].getFloorsForGroupBooking
          // Convert null values to the string "null"
          const businessUnitsStr = businessUnits
            .map((bu) => (bu === null ? 'null' : bu))
            .join(',')

          const response = await axios.get(url, {
            params: {
              building_id: formData.building,
              business_unit: businessUnitsStr,
              name: formData.reservingFor
                .map((item) => item.employee_name)
                .join(', '),
            },
          })
          setFloors(response.data)
        } catch (error) {
          console.error('Error fetching floors:', error)
        }
      }
    }

    fetchFloors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.building, businessUnits])

  const fetchAutocompleteOptions = (input) => {
    if (input.length >= 3) {
      let url = env.apiUrl[USA].userNameSearch + input
      axios
        .get(url)
        .then((response) => {
          setAutocompleteOptions(response.data)
        })
        .catch((error) => {
          console.error('Error fetching autocomplete options:', error)
        })
    }
  }

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
    const updatedFormData = {
      ...formData,
      [field]: value,
    }
    if (
      updatedFormData.building &&
      updatedFormData.floor &&
      updatedFormData.reservationDate
    ) {
      // Now fetch desks if any of those fields were changed
      if (
        field === 'building' ||
        field === 'floor' ||
        field === 'reservationDate'
      ) {
        fetchDesks(updatedFormData) // pass the updated form data
      }
    }
    if (field === 'floor' || field === 'building') {
      setSelectedDesks([])
    }

    setFormData(updatedFormData)
    setErrorMessage('')
  }

  async function fetchBusinessUnit(lanId) {
    try {
      const config = {
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      const url = env.apiUrl[USA].getUserCompensation + lanId
      const response = await axios.get(url, config)
      return response.data[0]?.worker?.organization?.cost_center_id || null
    } catch (error) {
      console.error('Error fetching business unit:', error)
      return null
    }
  }

  useEffect(() => {
    async function updateBusinessUnits() {
      const businessUnitsList = []
      for (const employee of formData.reservingFor) {
        const businessUnit = await fetchBusinessUnit(employee.lan_id)
        businessUnitsList.push(businessUnit)
      }
      setBusinessUnits(businessUnitsList)
    }

    updateBusinessUnits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.reservingFor])

  const handleSubmit = async () => {
    setLoading(true)
    if (!formData.reservingFor.length) {
      toast.error('Please fill in the Reserving For field', toastConfigure)
      return
    }

    // Ensure that the desks and people counts match
    if (formData.reservingFor.length !== selectedDesks.length) {
      toast.error(
        'Mismatch between number of desks and reserving names.',
        toastConfigure,
      )
      return
    }

    const location =
      selectedBuildingName === 'TII-NXT' || selectedBuildingName === 'TII-C2'
        ? 'India'
        : 'USA'

    let url = env.apiUrl[USA].reserveFullDay + `?location=${location}`

    // This function wraps setTimeout inside a Promise for async/await support
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const selectedFloor = floors.find(
      (floor) => floor.floor_id === formData.floor,
    )
    for (let i = 0; i < formData.reservingFor.length; i++) {
      let payloadforusa = {
        space_id: selectedDesks[i].desk_id,
        floor_name: selectedFloor.floor_name,
        floor_id: formData.floor,
        building_id: formData.building,
        booking_date: moment(formData.reservationDate).format('MM/DD/Y'),
        building_name: selectedBuildingName,
        reserved_for: formData.reservingFor[i].employee_name,
        reserved_for_id: formData.reservingFor[i].lan_id,
        reserved_by: `${session.userInfo.email.split('@')[0]}`,
        meeting_id: '',
        workspace_type: 'workstation',
        reserved_for_mail: `${formData.reservingFor[i].employee_name}@target.com`,
      }

      let payloadforindia = {
        space_id: selectedDesks[i].desk_id,
        floor_name: selectedFloor.floor_name,
        floor_id: formData.floor,
        building_id: formData.building,
        booking_date: moment(formData.reservationDate).format('MM/DD/Y'),
        building_name: selectedBuildingName,
        reserved_for: formData.reservingFor[i].employee_name,
        reserved_for_id: formData.reservingFor[i].lan_id,
        reserved_by: `${session.userInfo.email.split('@')[0]}`,
        meeting_id: '',
        workspace_type: 'workstation',
        reserved_for_mail: `${formData.reservingFor[i].employee_name}@target.com`,
        start_time: '09:30',
        end_time: '18:15',
        reservation_dates: [moment(formData.reservationDate).format('MM/DD/Y')],
      }

      const payload =
        selectedBuildingName === 'TII-NXT' || selectedBuildingName === 'TII-C2'
          ? payloadforindia
          : payloadforusa

      try {
        await axios.post(url, payload)

        // If it's not the last iteration, then delay for 2 seconds
        if (i !== formData.reservingFor.length - 1) {
          await delay(2000) // 2000ms = 2s
        }
      } catch (error) {
        toast.error('Failed to make a reservation.', toastConfigure)
        console.error('Error with reservation:', error)
        return // Exit the loop if there's an error
      }
    }
    setLoading(false)
    setOpen(true)
    // Reset form data
    setFormData({
      reservingFor: [],
      building: '',
      floor: '',
      reservationDate: moment(),
      desk: [],
    })

    // Reset selected desks
    setSelectedDesks([])
    setIsFloorMapVisible(false)
  }

  const fetchDesks = (updatedFormData) => {
    const payload = {
      building_id: updatedFormData.building,
      reservation_date: updatedFormData.reservationDate
        ? moment(updatedFormData.reservationDate).format('MM/DD/Y')
        : null,
      workspace_type: 'workstation',
      floor_id: updatedFormData.floor,
    }
    let url = env.apiUrl[USA].groupBooking

    axios
      .post(url, payload)
      .then((response) => {
        setDesks(response.data)
        // Assuming each desk item in response.data has a `desk_id` field
        const deskIds = response.data.map((desk) => desk.desk_id).join(',')

        // Fetch desk coordinates for the floor

        let endpoint =
          env.apiUrl['USA'].getDeskCoordinatesTM +
          `?building_id=${updatedFormData.building}&floor_id=${updatedFormData.floor}&desk_id=${deskIds}`
        const config = {
          headers: {
            Authorization: localStorage.access_token,
          },
        }

        axios
          .get(endpoint, config)
          .then((response) => {
            setDeskCoordinates(response.data)
          })
          .catch((error) => {
            console.error('Error fetching desk coordinates:', error)
          })
      })
      .catch((error) => {
        console.error('Error fetching desks:', error)
      })
  }
  const handleFloorMapToggle = () => {
    setIsFloorMapVisible(!isFloorMapVisible)
  }

  // useEffect(() => {
  //   // Reset floor if building or reservingFor changes
  //   handleInputChange('floor', '')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formData.building, formData.reservingFor])

  useEffect(() => {
    // Reset floor if building or reservingFor changes
    handleInputChange('reservationDate', moment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.building, formData.floor])

  const getStartDate = () => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // Set to start of the day

    if (
      formData.building &&
      activeFloor[formData.building] &&
      activeFloor[formData.building][formData.floor]
    ) {
      const activationDate = new Date(
        activeFloor[formData.building][formData.floor],
      )

      if (activationDate > currentDate) {
        return activationDate
      }
    }

    return currentDate // Return the current date if no specific date is found or if the activation date is in the past
  }

  useEffect(() => {
    const startDate = getStartDate()
    if (startDate > new Date()) {
      const formattedDate = `${
        startDate.getMonth() + 1
      }/${startDate.getDate()}/${startDate.getFullYear()}` // format it as MM/DD/YYYY
      setFloorOpeningDateMessage(`Note: This floor opens from ${formattedDate}`)
    } else {
      setFloorOpeningDateMessage('') // Reset message if it's not a future date
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.floor, formData.building]) // re-run this effect when the floor changes

  const shouldDisableDate = (date) => {
    const startDate = getStartDate()
    startDate.setHours(0, 0, 0, 0) // Set to start of day
    const endRangeDate = new Date(startDate)
    endRangeDate.setDate(endRangeDate.getDate() + 14)

    const isWeekend = date.day() === 6 || date.day() === 0

    return date < startDate || date > endRangeDate || isWeekend
  }

  return (
    <Container maxWidth="sm" style={containerStyle}>
      <Card elevation={3} style={{ borderRadius: '5px' }}>
        <CardContent>
          <Typography
            style={{
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.5rem',
            }}
          >
            Group Booking
          </Typography>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1rem',
              color: '#757575', // A subtle grey color
              fontStyle: 'italic',
              marginTop: '8px', // Some margin for spacing
            }}
          >
            Note: This form supports only full-day reservations for group
            bookings.
          </Typography>
          <br />
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={autocompleteOptions}
                  value={formData.reservingFor}
                  onChange={(_, newValue) =>
                    handleInputChange('reservingFor', newValue)
                  }
                  onInputChange={(_, newInputValue) =>
                    fetchAutocompleteOptions(newInputValue)
                  }
                  getOptionLabel={(option) => option.employee_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reserving For"
                      variant="outlined"
                      placeholder={'Search by firstname.lastname'}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Building"
                  value={formData.building}
                  onChange={(e) =>
                    handleInputChange('building', e.target.value)
                  }
                  variant="outlined"
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="" disabled></option>

                  {buildings.map((building) => (
                    <option
                      key={building.building_id}
                      value={building.building_id}
                    >
                      {building.organisation_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Floor"
                  value={formData.floor}
                  onChange={(e) => handleInputChange('floor', e.target.value)}
                  variant="outlined"
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  disabled={!formData.building}
                >
                  <option value="" disabled></option>
                  {floors.map((floor) => (
                    <option key={floor.floor_id} value={floor.floor_id}>
                      {floor.floor_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {floorOpeningDateMessage && (
                  <Typography
                    variant="body2"
                    style={{
                      color: 'red',
                      fontStyle: 'italic',
                      float: 'right',
                    }}
                  >
                    {floorOpeningDateMessage}
                  </Typography>
                )}
                <DatePicker
                  variant="outlined"
                  format="MM/D/Y"
                  // margin="normal"
                  label="Reservation Date"
                  name="reservation_date"
                  value={formData.reservationDate}
                  onChange={(date) =>
                    handleInputChange('reservationDate', date)
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  shouldDisableDate={shouldDisableDate}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={desks}
                  onOpen={() => {
                    if (desks.length < formData.reservingFor.length) {
                      setErrorMessage(
                        'There are not enough desks available for the selected number of people.',
                      )
                    } else {
                      setErrorMessage('')
                    }
                  }}
                  value={selectedDesks}
                  onChange={(_, newValue) => {
                    if (newValue.length > formData.reservingFor.length) {
                      toast.error(
                        'You can only select as many desks as people reserving.',
                        toastConfigure,
                      )
                    } else {
                      setSelectedDesks(newValue)

                      handleInputChange('desks', newValue)
                    }
                  }}
                  getOptionLabel={(option) => option.desk_id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Desks"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  disableCloseOnSelect
                  limitTags={formData.reservingFor.length}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={handleFloorMapToggle}
                  style={{ color: '#ac0000' }}
                  disabled={
                    !formData.building ||
                    !formData.floor ||
                    !formData.reservationDate ||
                    !formData.reservingFor.length
                  }
                >
                  View Floor Map
                </Button>
              </Grid>
              <br />
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Home" arrow>
                  <IconButton
                    component={Link}
                    to="/tm/home"
                    variant="outlined"
                    // className={classes.homeIcon}
                    color="primary"
                    size="large"
                  >
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    formData.reservingFor.length !== selectedDesks.length ||
                    errorMessage ||
                    !formData.reservingFor.length ||
                    !formData.building ||
                    !formData.floor ||
                    !formData.reservationDate
                  }
                >
                  {loading ? 'Confirming...' : 'Confirm'}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Dialog
        fullScreen
        open={isFloorMapVisible}
        onClose={handleFloorMapToggle}
        fullWidth={true}
        maxWidth="md" // You can adjust this if you need a bigger or smaller dialog
        aria-labelledby="floor-map-dialog-title"
      >
        <DialogTitle id="floor-map-dialog-title">
          Floor Map
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleFloorMapToggle}
            aria-label="close"
            style={{ color: '#AC0000' }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              formData.reservingFor.length !== selectedDesks.length ||
              errorMessage
            }
            style={{ float: 'right' }}
          >
            {loading ? 'Confirming...' : 'Confirm'}
          </Button>
          <Typography variant="h6">
            Selected Space(s):{' '}
            <b>
              <font color="#AC0000">
                {Object.values(selectedDesks)
                  .map((desk) => desk.desk_id)
                  .join(', ')}
              </font>
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FloorMapCanvas
            allPoints={deskCoordinates}
            pngImage={
              env.apiUrl['USA'].getFloorImage +
              `?building_id=${formData.building}&floor_id=${formData.floor}`
            }
            setSelectedDesk={setSelectedDesks}
            selectedDesk={selectedDesks}
            reservingForLength={formData.reservingFor.length}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className={classes.dialogRoot}>
          <div className={classes.successBanner}>
            <CheckCircleIcon style={{ fontSize: 56, color: 'green' }} />
          </div>

          <DialogContentText className={classes.contentDialog}>
            All reservations are made successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            component={Link}
            to="/tm/home"
            color="primary"
            style={{ margin: '0 auto', display: 'flex' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default GroupReservationForm
