import { useState, useEffect } from 'react'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { USA } from '../../components/common/constants'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import toast from 'react-hot-toast'
import MenuItem from '@mui/material/MenuItem'

export default function AddManualSpaces({ open, onClose }) {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const [buildings, setBuildings] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedFloor, setSelectedFloor] = useState('')
  const [spaceId, setSpaceId] = useState('')

  useEffect(() => {
    const url = env.apiUrl[USA].getAllBuildingsUrl
    axios
      .get(url)
      .then((response) => {
        setBuildings(response.data)
      })
      .catch((error) => console.error(`Error: ${error}`))
  }, [env.apiUrl])

  const floors = selectedBuilding
    ? buildings.find(
        (building) =>
          building.building_details.building_id ===
          selectedBuilding.building_details.building_id,
      ).floor_details
    : []

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!selectedBuilding) return // Ensure a building is selected
    const floorData = selectedFloor.split(':')

    const payload = {
      building_id: selectedBuilding.building_details.building_id,
      floor_id: floorData[0],
      space_id: spaceId,
      created_by: session.userInfo.email.split('@')[0],
    }
    const url = env.apiUrl[USA].createManualSpace
    axios
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Manual Space Created Successfully', toastConfigure)
        }
      })
      .catch((error) => console.error(`Error: ${error}`))
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Manual Space</DialogTitle>
        <DialogContent>
          <br />
          <form onSubmit={handleSubmit}>
            <Autocomplete
              id="building-select-label"
              options={buildings}
              getOptionLabel={(option) => option.building_details.building_id}
              value={selectedBuilding}
              onChange={(event, newValue) => {
                setSelectedBuilding(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Building ID"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br />
            <TextField
              select
              fullWidth
              label="Floor ID"
              value={selectedFloor?.split(':')[1]}
              onChange={(event) => setSelectedFloor(event.target.value)}
              variant="outlined"
            >
              {floors.map((floor) => (
                <MenuItem
                  value={`${floor.floor_id}:${floor.floor_name}`}
                  key={floor.floor_id}
                >
                  {floor.floor_name}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <br />
            <TextField
              fullWidth
              label="Space ID"
              value={spaceId}
              onChange={(event) => setSpaceId(event.target.value)}
              variant="outlined"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(event) => {
              handleSubmit(event)
              onClose()
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
