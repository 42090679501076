import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import 'date-fns'
// import DateFnsUtils from '@date-io/date-fns'
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { FormStyles } from '../../styles/reservationFormStyle'
import CustomTimePicker from '../../components/timePicker'
import momentNormal from 'moment'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import EventIcon from '@mui/icons-material/Event'
import toast from 'react-hot-toast'
import {
  toastConfigure,
  addDays,
  getBusinessUnit,
  isSameDay,
} from '../../components/common/commonFunctionalities'
import Card from '@mui/material/Card'
import { CardContent, FormHelperText } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import {
  convertAMPMFromHours,
  getCompensation,
} from '../../components/common/commonFunctionalities'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import { useAuth } from '@praxis/component-auth'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import {
  convertInHoursFormat,
  convertInHoursFormatWithoutZeroPrefix,
  isTodayEnabled,
} from '../../components/common/commonFunctionalities'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import '../../style.css'
import moment from 'moment-timezone'
import WarningIcon from '@mui/icons-material/Warning'
import {
  eaganBuildingId,
  workstation,
  office,
  Desk,
  Office,
  LT,
  timezoneIndia,
  India,
  USA,
  INDIA,
  US,
  cabin,
} from '../../components/common/constants'
import DatePickerMultiple from '../../components/common/datePickerMultipleDays'
import { timezoneUS } from '../../components/common/constants'
import { format } from 'date-fns'
import _ from 'lodash'
import 'react-day-picker/dist/style.css'
import '../../styles/datePickerStyle.css'

export const ReservationForm = () => {
  const env = useEnv()
  const config = env.constants
  const [buildings, setBuildings] = React.useState(null)
  const [endTimeOptions, setEndTimeOptions] = React.useState([])
  const [startTimeOptions, setStartTimeOptions] = React.useState([
    config[US].startHour,
    config[US].endHour - config[US].minBookingTime,
  ])
  const [disableFullDay, setDisableFullDay] = React.useState(false)
  const [showExistingReservation, setShowExistingReservation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reservingForData, setReservingForData] = useState(null)
  const [showOffice, setShowOffice] = useState(false)
  const [showSmallOffice, setShowSmallOffice] = useState(false)
  const [showCabin, setShowCabin] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const [finalDisabledDays, setFinalDisabledDays] = useState([])
  const [appliedBusinessUnit, setAppliedBusinessUnit] = useState('')
  const teamMemberDetails = useSelector(
    (state) => state.teamMember.reservationFields,
  )
  const adminReservation = useSelector((state) => state.teamMember.reservingFor)
  const [reservationFormFields, setReservationFormFields] =
    React.useState(teamMemberDetails)
  const [formattedDays, setFormattedDays] = useState([
    ...teamMemberDetails.reservation_date,
  ])

  const location = useSelector((state) => state.common.location)
  const userCompensation = useSelector(
    (state) => state.common.userCompensationUS,
  )
  const reservingForCompensation = useSelector(
    (state) => state.common.reservingForCompensationUS,
  )
  const businessUnit = useSelector((state) => state.common.userBusinessUnit)
  const reservingForBusinessUnit = useSelector(
    (state) => state.common.reservingForBusinessUnit,
  )

  const [errorText, setErrorText] = useState('')
  const [cabinSelectionText, setCabinSelectionText] = useState('')

  const classes = FormStyles()
  const history = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const { session } = auth
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = useState(false)

  const findNearestInterval = (currentTime) => {
    let splitTime = currentTime.split(':')
    let temp = 60 / config.interval //4
    let i = 1
    for (i; i <= temp; i++) {
      let minutes = i * config.interval
      if (minutes > Number(splitTime[1])) {
        break
      }
    }
    let min = i * config.interval
    let finalMinutes = min.toString().length === 1 ? '0' + min : min
    let value =
      finalMinutes === 60
        ? Number(splitTime[0]) + 1
        : splitTime[0] + ':' + finalMinutes
    return value
  }

  const isToday = (dates) => {
    let today =
      reservationFormFields.location_timezone !== ''
        ? moment()
            .tz(reservationFormFields.location_timezone)
            .format('MM/DD/YYYY')
        : momentNormal().format('MM/DD/YYYY')
    if (dates.includes(today)) return true
    else return false
  }

  useEffect(() => {
    let buildingUrl = env.apiUrl['USA'].getReservableBuildings
    // const header = {
    //   headers: {
    //     Authorization: localStorage.access_token,
    //   },
    // }
    axios
      .get(buildingUrl)
      .then((response) => {
        setBuildings(response.data)
      })
      .catch((err) => console.log(err))
    if (
      reservationFormFields &&
      reservationFormFields.reservation_date &&
      reservationFormFields.reservation_date.length > 0
    ) {
      let selectedDaysCopy = reservationFormFields.reservation_date.map(
        (day) => new Date(day),
      )
      setSelectedDays(selectedDaysCopy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let timezone =
      reservationFormFields.location_timezone !== ''
        ? reservationFormFields.location_timezone
        : timezoneUS
    let today = moment().tz(timezone).format('MM/DD/YYYY')
    if (
      (reservationFormFields.primary_location !== INDIA &&
        reservationFormFields.reservation_date.length > 1) ||
      (reservationFormFields.reservation_date &&
        reservationFormFields.reservation_date.includes(today))
    ) {
      //To not allow multiple day selection for locations other than India
      setSelectedDays([])
      setFormattedDays([])
      setDisableFullDay(false)
      setReservationFormFields({
        ...reservationFormFields,
        reservation_date: [],
        start_time: '',
      })
    } else if (reservationFormFields.start_time !== '') {
      setStartTime('')
      //this is being overwritten
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationFormFields.primary_location])

  React.useEffect(() => {
    let chosenLocation =
      reservationFormFields.primary_location === INDIA
        ? India
        : reservationFormFields.primary_location
    let holidaysList
    if (reservationFormFields.location_timezone === 'Asia/Shanghai') {
      holidaysList = config['China'].disabledDays
    } else {
      holidaysList = config[chosenLocation].disabledDays
    }
    let weekDays = config[chosenLocation].disabledDaysOfWeek
    let filteredDays = holidaysList.map((date) => new Date(date))
    filteredDays.push({ dayOfWeek: weekDays })

    let timezone =
      reservationFormFields.location_timezone !== ''
        ? reservationFormFields.location_timezone
        : timezoneUS
    if (isTodayEnabled(timezone, config[chosenLocation].cutoffTime)) {
      filteredDays.push(
        {
          after: new Date(addDays(config[chosenLocation].maxBookingDays)),
        },
        { before: new Date() },
      )
    } else {
      filteredDays.push(
        {
          after: new Date(addDays(config[chosenLocation].maxBookingDays)),
        },
        { before: new Date(addDays(1)) },
      )
      // let today = moment().tz(timezone).format('MM/DD/YYYY')

      // if (
      //   reservationFormFields.reservation_date &&
      //   reservationFormFields.reservation_date.includes(today)
      // ) {
      //   setSelectedDays([])
      //   setFormattedDays([])
      //   setDisableFullDay(false)
      //   setReservationFormFields({
      //     ...reservationFormFields,
      //     reservation_date: [],
      //   })
      // }
    }
    setFinalDisabledDays(filteredDays)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    config,
    reservationFormFields.location_timezone,
    reservationFormFields.primary_location,
  ])

  useEffect(() => {
    let appliedCompensation = adminReservation
      ? reservingForCompensation
      : userCompensation
    if (reservationFormFields.primary_location === INDIA) {
      setShowOffice(false)
      // setStartTime('')
      if (
        appliedCompensation?.is_manager === 'Y' &&
        appliedCompensation?.level &&
        (appliedCompensation?.level >= 7 || appliedCompensation?.level === LT)
      ) {
        setShowCabin(true)
      } else {
        setShowCabin(false)
        setReservationFormFields({
          ...reservationFormFields,
          workspace_type: workstation,
        })
      }
    } else {
      setShowCabin(false)
      if (reservationFormFields.workspace_type !== workstation) {
        setReservationFormFields({
          ...reservationFormFields,
          workspace_type: workstation,
        })
      }
      if (
        appliedCompensation &&
        (appliedCompensation?.level >= 8 || appliedCompensation?.level === LT)
      ) {
        setShowOffice(true)
      } else {
        setShowOffice(false)
        if (reservationFormFields.workspace_type !== workstation) {
          setReservationFormFields({
            ...reservationFormFields,
            workspace_type: workstation,
          })
        }
      }
      if (
        appliedCompensation &&
        (appliedCompensation?.job_code === '40306' ||
          appliedCompensation?.job_code === '29459')
      ) {
        setShowSmallOffice(true)
      } else {
        setShowSmallOffice(false)
        if (reservationFormFields.workspace_type !== workstation) {
          setReservationFormFields({
            ...reservationFormFields,
            workspace_type: workstation,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminReservation,
    reservingForCompensation,
    userCompensation,
    reservationFormFields.primary_location,
  ])

  useEffect(() => {
    const updatedAppliedBusinessUnit = adminReservation
      ? reservingForBusinessUnit
      : businessUnit

    setAppliedBusinessUnit(updatedAppliedBusinessUnit)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminReservation, reservingForBusinessUnit, businessUnit])

  useEffect(() => {
    setReservingForData({
      reserved_for_id: reservationFormFields.lan_id,
      reserved_for: reservationFormFields.reserved_for,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationFormFields.reserved_for])

  useEffect(() => {
    if (showExistingReservation && isLoading) setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingReservation])

  useEffect(() => {
    let today = isToday(reservationFormFields.reservation_date)
    let chosenLocation =
      reservationFormFields.primary_location === INDIA
        ? India
        : reservationFormFields.primary_location

    if (!today) {
      setStartTimeOptions([
        config[chosenLocation].startHour,
        config[chosenLocation].endHour - config[chosenLocation].minBookingTime,
      ])
    } else {
      resetCheckboxAndTimePickers(reservationFormFields.reservation_date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reservationFormFields.reservation_date,
    teamMemberDetails.reservation_date,
    reservationFormFields.building_id,
    reservationFormFields.primary_location,
  ])

  useEffect(() => {
    if (endTimeOptions.length !== 0 && reservationFormFields.end_time === '') {
      let convertedEndTime = convertAMPMFromHours(endTimeOptions[0])
      setReservationFormFields({
        ...reservationFormFields,
        end_time: convertedEndTime,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeOptions])

  useEffect(() => {
    if (
      reservationFormFields.start_time &&
      reservationFormFields.start_time !== '' &&
      typeof reservationFormFields.start_time !== 'object' &&
      !reservationFormFields.fullDay
    ) {
      let selectedEnd = convertInHoursFormatWithoutZeroPrefix(
        reservationFormFields.start_time,
      )
      let chosenLocation =
        reservationFormFields.primary_location === INDIA
          ? India
          : reservationFormFields.primary_location
      let colonSplit = selectedEnd.split(':') //['6:00']

      let end =
        Number(colonSplit[0]) +
        config[chosenLocation].minBookingTime +
        ':' +
        colonSplit[1]
      setEndTimeOptions([end, config[chosenLocation].endHour])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationFormFields.start_time])

  const checkPartialForMultipleDay = () => {
    if (
      reservationFormFields.primary_location === INDIA &&
      reservationFormFields.reservation_date.length > 1 &&
      reservationFormFields.start_time !== ''
    ) {
      return true
    }
    return false
  }

  const resetCheckboxAndTimePickers = (date) => {
    if (
      date.includes(momentNormal().format('MM/DD/YYYY')) ||
      typeof date === 'undefined'
    ) {
      let tempEvent = { target: { checked: false } }
      let currentTime =
        reservationFormFields.location_timezone !== ''
          ? moment().tz(reservationFormFields.location_timezone).format('HH:mm')
          : momentNormal().format('HH:mm')
      let chosenLocation =
        reservationFormFields.primary_location === INDIA
          ? India
          : reservationFormFields.primary_location
      if (parseInt(currentTime) < config[chosenLocation].startHour) {
        setDisableFullDay(false) //enable full day booking if today's time is falling before reservation start time

        let checkboxValue = { target: { checked: teamMemberDetails.fullDay } }
        resetFullday(checkboxValue, date, currentTime)

        setStartTimeOptions([
          config[chosenLocation].startHour,
          config[chosenLocation].endHour -
            config[chosenLocation].minBookingTime,
        ])
      } else if (
        parseInt(currentTime) <
        config[chosenLocation].endHour - config[chosenLocation].minBookingTime
      ) {
        setDisableFullDay(true)
        let option = findNearestInterval(currentTime)

        resetFullday(tempEvent, date, currentTime)

        setStartTimeOptions([
          option,
          config[chosenLocation].endHour -
            config[chosenLocation].minBookingTime,
        ])
      } else {
        setDisableFullDay(true)
        // to clear selected dropdown option
        setStartTimeOptions([])
        setEndTimeOptions([])
        // setStartTime('')
        // setEndTime('')
        let payload = {
          ...reservationFormFields,
          start_time: '',
          end_time: '',
          fullDay: false,
          reservation_date: date,
        }
        setReservationFormFields(payload)
        dispatch({
          type: 'SAVE_RESERVATION_DETAILS',
          payload: payload,
        })
      }
      return true
    }
    return false
  }

  const handleReservedForTyping = (event, newValue) => {
    // setReservedFor(newValue)

    if (newValue.length >= 3) {
      const payloadOptions = {
        method: 'GET',
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      setLoading(true)
      ;(async () => {
        try {
          let url = env.apiUrl['USA'].userNameSearch + newValue
          const response = await fetch(url, payloadOptions)
          const data = await response.json()
          setLoading(false)

          let filterData = data.map((item) => {
            return {
              reserved_for_id: item.lan_id,
              reserved_for: item.employee_name,
            }
          })
          setOptions(filterData)
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }

  const setStartTime = (value) => {
    setReservationFormFields({
      ...reservationFormFields,
      start_time: value,
      end_time: '',
    })
  }

  const setEndTime = (value) => {
    setReservationFormFields({
      ...reservationFormFields,
      end_time: value,
    })
  }

  const resetFullday = (event, date, currentTime) => {
    let newStartTime = reservationFormFields.start_time

    if (parseInt(convertInHoursFormat(newStartTime)) < parseInt(currentTime)) {
      setStartTime('')
      setReservationFormFields({
        ...reservationFormFields,
        fullDay: event.target.checked,
        reservation_date: date,
        start_time: '',
      })
    } else {
      setReservationFormFields({
        ...reservationFormFields,
        fullDay: event.target.checked,
        reservation_date: date,
      })
    }
  }

  const handleFullDayBooking = (event) => {
    setReservationFormFields({
      ...reservationFormFields,
      start_time: '',
      end_time: '',
      fullDay: event.target.checked,
    })

    let payload = { ...reservationFormFields, fullDay: event.target.checked }
    dispatch({
      type: 'SAVE_RESERVATION_DETAILS',
      payload: payload,
    })
  }

  const checkForTimeDifference = () => {
    if (
      reservationFormFields.primary_location === INDIA &&
      reservationFormFields.start_time !== '' &&
      reservationFormFields.end_time !== ''
    ) {
      let selectedStart = convertInHoursFormatWithoutZeroPrefix(
        reservationFormFields.start_time,
      )
      let selectedEnd = convertInHoursFormatWithoutZeroPrefix(
        reservationFormFields.end_time,
      )

      let chosenLocation =
        reservationFormFields.primary_location === INDIA
          ? India
          : reservationFormFields.primary_location
      let colonSplit = selectedStart.split(':') //['6:00']
      let end = Number(colonSplit[0]) + config[chosenLocation].minBookingTime
      if (selectedEnd.split(':')[0] < end) {
        return true
      }
    }
    return false
  }

  const checkIfReservationAlreadyExists = () => {
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    let buildingLocation =
      reservationFormFields.location_timezone === timezoneIndia ? India : USA
    let url
    if (adminReservation) {
      url =
        env.apiUrl['USA'].checkReservation +
        `reserved_for=${reservationFormFields.reserved_for}&location=${buildingLocation}`
    } else {
      let name = session.userInfo.email.split('@')
      url =
        env.apiUrl['USA'].checkReservation +
        `reserved_for=${name[0]}&location=${buildingLocation}`
    }
    let payload = reservationFormFields.reservation_date
    axios
      .post(url, payload, config)
      .then((response) => {
        if (response.status === 200) {
          response.data.length === 0
            ? submitRequest()
            : setShowExistingReservation(true)
          // setIsLoading(false)
        } else {
          setIsLoading(false)

          throw new Error('Unable to check if reservation already exists')
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const validateForm = (e) => {
    setIsLoading(true)
    e.preventDefault()
    if (
      reservationFormFields.start_time === '6:00 AM' &&
      reservationFormFields.end_time === '6:00 PM'
    ) {
      setIsLoading(false)

      toast(
        'You are trying to book full day reservation using partial day option. Please use full day reservation functionality.',
        {
          icon: <WarningIcon style={{ color: 'yellow' }} />,
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        },
      )
    } else if (
      reservationFormFields.building_id === eaganBuildingId &&
      reservationFormFields.workspace_type === office &&
      adminReservation
    ) {
      let userId = adminReservation
        ? reservingForData.reserved_for_id
        : session.userInfo.lanId
      getCompensation(env, location, userId)
        .then((data) => {
          if (!data || (data['level'] < 8 && data['level'] !== LT)) {
            toast.error(
              'TM is not eligible for reserving Office. Please select another workspace type',
              toastConfigure,
            )
            setIsLoading(false)

            e.stopPropagation()
            return
          } else {
            checkIfReservationAlreadyExists()
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(
            'There was some error. Please try again later',
            toastConfigure,
          )
          setIsLoading(false)
          e.stopPropagation()
          return
        })
    } else if (
      reservationFormFields.workspace_type === cabin &&
      reservationFormFields.reservation_date.length > 1
    ) {
      setCabinSelectionText('Choose single day for Cabin reservation')
      setSelectedDays([])
      setFormattedDays([])
      toast.error(
        'Please choose single day for Cabin reservation',
        toastConfigure,
      )

      setReservationFormFields({
        ...reservationFormFields,
        reservation_date: [],
      })
      setIsLoading(false)
      return
    } else if (reservationFormFields.reservation_date.length === 0) {
      toast.error('Please select date(s)', toastConfigure)
      setIsLoading(false)
      return
    } else if (
      reservationFormFields.primary_location === INDIA &&
      reservationFormFields.workspace_type === cabin &&
      reservationFormFields.start_time !== ''
    ) {
      toast.error('Cabins can only be booked for Full day', toastConfigure)

      setIsLoading(false)
      return
    } else if (checkPartialForMultipleDay()) {
      toast.error(
        'Workspace can only be booked for Full day for multiple days',
        toastConfigure,
      )
      setIsLoading(false)
      return
    } else if (checkForTimeDifference()) {
      toast.error('Selected End Time is not correct', toastConfigure)
      setIsLoading(false)
      return
    } else {
      if (reservationFormFields.reservation_id) {
        submitRequest()
        return
      }
      checkIfReservationAlreadyExists()
    }
  }

  const retainReservedSpaceInModify = (spacesList) => {
    let reservedSpace = _.remove(
      spacesList.reserved,
      (item) => item.reserved_for === reservationFormFields.reserved_for,
    )
    let availableSpace = reservedSpace.map((item) => {
      item['reserved_for'] = null
      return item
    })
    let mergedAvailable = [...spacesList.available, ...availableSpace]
    spacesList.available = mergedAvailable.sort((a, b) => {
      return a.desk_id - b.desk_id
    })
  }

  const retainReservedSpaceInModifyPartial = (spacesList) => {
    //find all reservations for current user and desk
    let reservedSpace = _.remove(spacesList.reserved, (item) => {
      let peopleList = item.reserved_for.split(', ')
      return (
        peopleList.includes(reservationFormFields.reserved_for) &&
        item.desk_id === reservationFormFields.desk_id
      )
    })

    let availableSpace = []
    let removedCurrentPerson = reservedSpace.map((item) => {
      let itemDuplicate = JSON.parse(JSON.stringify(item))

      let startTime = itemDuplicate.start_time.split(',')
      let endTime = itemDuplicate.end_time.split(', ')
      let reservedFor = itemDuplicate.reserved_for.split(', ')

      if (reservedFor.length > 1) {
        let occurenceIndex = []
        //loop to handle single desk reservation by multiple people
        reservedFor.forEach((element, index) => {
          if (element === reservationFormFields.reserved_for) {
            occurenceIndex.push(index)
          }
        })

        // eslint-disable-next-line array-callback-return
        occurenceIndex.map((ind) => {
          startTime.splice(ind, 1)
          endTime.splice(ind, 1)
          reservedFor.splice(ind, 1)
        })
        //removing entry for user trying to modify for himself  but keeping other entries
        itemDuplicate.start_time = startTime.join(',')
        itemDuplicate.end_time = endTime.join(',')
        itemDuplicate.reserved_for = reservedFor.join(',')

        //logic to check if requested timing is not clashing with other reservations
        let isDeskAvailable = false

        for (let i = 0; i < startTime.length; i++) {
          if (
            (convertInHoursFormat(reservationFormFields.start_time) <=
              moment(new Date(startTime[i].trim())).format('HH:mm') &&
              convertInHoursFormat(reservationFormFields.end_time) <=
                moment(new Date(startTime[i].trim())).format('HH:mm')) ||
            (convertInHoursFormat(reservationFormFields.start_time) >=
              moment(new Date(endTime[i].trim())).format('HH:mm') &&
              convertInHoursFormat(reservationFormFields.end_time) >=
                moment(new Date(startTime[i].trim())).format('HH:mm'))
          ) {
            isDeskAvailable = true
          }
        }

        if (isDeskAvailable) {
          let itemCopy = JSON.parse(JSON.stringify(item))

          itemCopy['reservedFor'] = null
          itemCopy['reserved_for'] = null
          itemCopy['start_time'] = null
          itemCopy['end_time'] = null

          availableSpace.push(itemCopy)
        }
        return itemDuplicate
      } else {
        let itemCopy = JSON.parse(JSON.stringify(item))

        itemCopy['reservedFor'] = null
        itemCopy['reserved_for'] = null
        itemCopy['start_time'] = null
        itemCopy['end_time'] = null

        availableSpace.push(itemCopy)
        return null
      }
    })

    removedCurrentPerson = removedCurrentPerson.filter((n) => n)
    let mergedReserved = [...spacesList.reserved, ...removedCurrentPerson]
    spacesList.reserved = mergedReserved.sort((a, b) => {
      return a.desk_id - b.desk_id
    })

    let mergedAvailable = [...spacesList.available, ...availableSpace]
    spacesList.available = mergedAvailable.sort((a, b) => {
      return a.desk_id - b.desk_id
    })
  }

  const submitRequest = () => {
    // e.preventDefault()
    dispatch({
      type: 'SAVE_RESERVATION_DETAILS',
      payload: reservationFormFields,
    })

    // if (reservationFormFields.reservation_date.length === 0) {
    //   setCabinSelectionText('')
    //   setErrorText('This field is required')
    //   return
    // }

    let payload = JSON.parse(JSON.stringify(reservationFormFields))
    let buildingLocation =
      reservationFormFields.location_timezone === timezoneIndia ? India : USA
    let url
    if (payload.fullDay) {
      url =
        env.apiUrl['USA'].checkAvailabilityFull +
        `?business_unit=${encodeURIComponent(appliedBusinessUnit)}&name=${
          adminReservation
            ? reservationFormFields.reserved_for
            : session.userInfo.email.split('@')[0]
        }&location=${buildingLocation}`
      payload['reservation_dates'] = payload['reservation_date']

      delete payload['reservation_date']
      delete payload['start_time']
      delete payload['end_time']
    } else {
      url =
        env.apiUrl['USA'].checkAvailabilityPartial +
        `?business_unit=${encodeURIComponent(appliedBusinessUnit)}&name=${
          adminReservation
            ? reservationFormFields.reserved_for
            : session.userInfo.email.split('@')[0]
        }&location=${buildingLocation}`
      // delete payload['workSpaceType']
      payload['reservation_date'] = payload['reservation_date'][0]
      payload['start_time'] = convertInHoursFormat(payload.start_time)
      payload['end_time'] = convertInHoursFormat(payload.end_time)
    }
    delete payload['organisationName']
    axios
      .post(url, payload)
      .then((response) => {
        setIsLoading(false)
        let allData = response.data

        let refinedData = {}
        if (Object.entries(allData).length !== 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(allData).map((floorId) => {
            refinedData[floorId] = { available: [], reserved: [] }
            refinedData[floorId].available = allData[floorId].filter(
              (item) => !item.reserved_for,
            )
            refinedData[floorId].reserved = allData[floorId].filter(
              (item) => item.reserved_for != null,
            )
            if (reservationFormFields.reservation_id) {
              payload.fullDay
                ? retainReservedSpaceInModify(refinedData[floorId])
                : retainReservedSpaceInModifyPartial(refinedData[floorId])
            }
            if (refinedData[floorId].available?.length === 0) {
              //hide floors with zero space availability
              delete refinedData[floorId]
            }
          })
        }
        dispatch({ type: 'AVAILABLE_DESKS', payload: refinedData })
        if (Object.entries(response.data).length !== 0) {
          history('/tm/step2')
        } else {
          toast.error(
            'Sorry!Workspaces are not available in the ' +
              reservationFormFields.organisationName +
              ' building for the provided date and time.',
            toastConfigure,
          )
          setShowExistingReservation(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
        toast.error(
          'Sorry!Workspaces are not available for the provided date and time',
          toastConfigure,
        )
      })
  }

  const handleBookingForChange = (event, newValue) => {
    setReservingForData(newValue)
    if (newValue) {
      getCompensation(env, location, newValue.reserved_for_id)
        .then((data) => {
          if (data && 'level' in data) {
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: data,
            })
          } else {
            //This else condition handles {compensation:null} object from worker dev api
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: null,
            })
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error! Unable to fetch user profile', toastConfigure)
          dispatch({
            type: 'RESERVING_FOR_USER_COMPENSATION_US',
            payload: null,
          })
        })
      getBusinessUnit(env, location, newValue.reserved_for_id)
        .then((data) => {
          if (data && 'cost_center_id' in data) {
            dispatch({
              type: 'RESERVING_FOR_BUSINESS_UNIT',
              payload: data['cost_center_id'],
            })
          } else {
            //This else condition handles {organization:null} object from worker dev api
            dispatch({
              type: 'RESERVING_FOR_BUSINESS_UNIT',
              payload: null,
            })
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error! Unable to fetch user profile', toastConfigure)
          dispatch({
            type: 'RESERVING_FOR_BUSINESS_UNIT',
            payload: null,
          })
        })
      setReservationFormFields({
        ...reservationFormFields,
        reserved_for: newValue.reserved_for,
        lan_id: newValue.reserved_for_id,
      })
    } else {
      dispatch({
        type: 'RESERVING_FOR_USER_COMPENSATION_US',
        payload: null,
      })
      dispatch({
        type: 'RESERVING_FOR_BUSINESS_UNIT',
        payload: null,
      })
    }
  }

  const handleBuildingChange = (event) => {
    let building = event.target.value
    let selectedBuildingData = buildings.find(
      (bldng) => bldng.building_id === building,
    )
    let timezone = selectedBuildingData.location_timezone
    let orgName = selectedBuildingData.organisation_name

    if (
      reservationFormFields.workspace_type === office &&
      building !== eaganBuildingId
    ) {
      setReservationFormFields({
        ...reservationFormFields,
        workspace_type: workstation,
        building_id: building,
        location_timezone: timezone,
        organisationName: orgName,
        primary_location: selectedBuildingData.primary_location,
      })
    } else {
      setReservationFormFields({
        ...reservationFormFields,
        building_id: building,
        location_timezone: timezone,
        organisationName: orgName,
        primary_location: selectedBuildingData.primary_location,
      })
    }
  }

  const handleWorkspaceTypeChange = (evt) => {
    evt.preventDefault()
    setReservationFormFields({
      ...reservationFormFields,
      workspace_type: evt.target.value,
    })
    setCabinSelectionText('')
  }

  //This function should be removed after go-live
  // const prodReleaseDate = () => {
  //   if (momentNormal().format('MM/DD/YYYY') < '10/18/2021') {
  //     return new Date('10/18/2021').toLocaleDateString('en-US', dateOption)
  //   }

  //   return new Date().toLocaleDateString('en-US', dateOption)
  // }
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closePopper = (e) => {
    setAnchorEl(null)
  }

  const handleDayClick = (day, modifiers) => {
    setErrorText('')
    setCabinSelectionText('')
    setDisableFullDay(false)
    const { selected, disabled } = modifiers

    const days = [...selectedDays]
    const formattedDaysCopy = [...formattedDays]

    if (disabled) {
      return
    }
    if (selected && days.length > 0) {
      const selectedIndex = days.findIndex((selectedDay) =>
        isSameDay(selectedDay, day),
      )
      days.splice(selectedIndex, 1) // to remove day on double click
      formattedDaysCopy.splice(selectedIndex, 1)
      // commenting office timing update according to current time

      // if (isSameDay(day, new Date())) {
      //   setOfficeTimings(config.officeTimings)
      // }
    } else {
      // days.push(formatDate( day,'L'))
      days.push(day) //to add day
      formattedDaysCopy.push(format(day, 'MM/dd/y'))

      //   let today =
      //   reservationFormFields.location_timezone !== ''
      //     ? moment()
      //         .tz(reservationFormFields.location_timezone)
      //         .format('MM/DD/YYYY')
      //     : momentNormal().format('MM/DD/YYYY')

      // if (formattedDaysCopy.includes(today)) {
      //   resetCheckboxAndTimePickers(
      //     moment(formattedDaysCopy).format('MM/DD/YYYY')
      //   )
      // } else setDisableFullDay(false)
      // commenting office timing update according to current time

      // if (isSameDay(day, new Date())) setOfficeTimingsOptions()
    }

    setSelectedDays(days)
    setFormattedDays(formattedDaysCopy)

    setReservationFormFields({
      ...reservationFormFields,
      reservation_date: formattedDaysCopy,
    })
    // dispatch({
    //   type: 'SAVE_INDIA_RESERVATION_DETAILS',
    //   payload: { reservation_date: formattedDaysCopy },
    // })
  }

  const handleSingleDayClick = (day, modifiers) => {
    const { disabled } = modifiers
    if (disabled) {
      return
    }
    let formattedDaysCopy = format(day, 'MM/dd/y')
    setSelectedDays([day])
    setFormattedDays([formattedDaysCopy])

    let payload = {
      ...reservationFormFields,
      reservation_date: [formattedDaysCopy],
    }
    setReservationFormFields(payload)

    let today =
      reservationFormFields.location_timezone !== ''
        ? moment()
            .tz(reservationFormFields.location_timezone)
            .format('MM/DD/YYYY')
        : momentNormal().format('MM/DD/YYYY')

    if (formattedDaysCopy === today) {
      resetCheckboxAndTimePickers([
        moment(formattedDaysCopy).format('MM/DD/YYYY'),
      ])
    } else setDisableFullDay(false)

    // }
  }

  const handleCabinSelection = () => {
    if (reservationFormFields.reservation_date.length > 1) {
      setCabinSelectionText('Choose single day for Cabin reservation')
      setSelectedDays([])
      setFormattedDays([])

      setReservationFormFields({
        ...reservationFormFields,
        reservation_date: [],
      })

      // dispatch({
      //   type: 'SAVE_INDIA_RESERVATION_DETAILS',
      //   payload: { reservation_date: [] },
      // })
    }
  }

  return (
    <div className={classes.root}>
      {/* <Container maxWidth="md"> */}
      {/* <Paper className={classes.paper} elevation={3}> */}
      {/* <Grid
        container
        // spacing={0}
        direction="column"
        alignItems="center"
        justify="space-around"
      >
        <Grid item  > */}
      <Card className={classes.cardRoot} elevation={6}>
        <CardContent>
          <form
            name="reservation-form"
            // validate
            autoComplete="off"
            onSubmit={validateForm}
          >
            <Grid
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
              // spacing={2}
              lg={6}
            >
              <Grid item>
                {adminReservation ? (
                  <>
                    <br />
                    <Autocomplete
                      id="reserve-others"
                      className={classes.formControl}
                      options={options}
                      loading={loading}
                      // value={
                      //   reservationFormFields.reserved_for ||
                      //   teamMemberDetails.reserved_for
                      // }
                      value={reservingForData}
                      getOptionLabel={(option) => option.reserved_for}
                      onInputChange={handleReservedForTyping}
                      onChange={handleBookingForChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Reserving For"
                          variant="outlined"
                          placeholder={'Search by firstname.lastname'}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <br />
                  </>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required
                >
                  <InputLabel id="building-select-label">
                    Select Building
                  </InputLabel>
                  <Select
                    labelId="building-select-label"
                    id="building-select"
                    onChange={handleBuildingChange}
                    value={
                      reservationFormFields.building_id ||
                      teamMemberDetails.building_id
                    }
                    label="Select Building"
                    // displayEmpty
                    className={classes.selected}
                    classes={{ icon: classes.icon, select: classes.selected }}
                  >
                    <MenuItem value="" disabled>
                      Select Building
                    </MenuItem>
                    {buildings &&
                      buildings.map((building) => {
                        return (
                          <MenuItem
                            key={building.building_id}
                            value={building.building_id}
                          >
                            {building.organisation_name}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <br />
              <Grid item>
                {reservationFormFields.primary_location === US ? (
                  <DatePickerMultiple
                    id={id}
                    // formattedDays={reservationFormFields.reservation_date}
                    formattedDays={formattedDays.join(',')}
                    classes={classes}
                    handleClick={handleClick}
                    open={open}
                    anchorEl={anchorEl}
                    closePopper={closePopper}
                    selectedDays={selectedDays}
                    handleDayClick={handleSingleDayClick}
                    finalDisabledDays={finalDisabledDays}
                    mode="single"
                    label="Select Appointment Date*"
                  />
                ) : (
                  <>
                    <DatePickerMultiple
                      id={id}
                      formattedDays={formattedDays.join(',')}
                      classes={classes}
                      handleClick={handleClick}
                      open={open}
                      anchorEl={anchorEl}
                      closePopper={closePopper}
                      selectedDays={selectedDays}
                      handleDayClick={handleDayClick}
                      finalDisabledDays={finalDisabledDays}
                      label="Select Reservation Date(s)*"
                    />
                    <FormHelperText className={classes.errorColor}>
                      {errorText}
                      {cabinSelectionText}
                    </FormHelperText>
                  </>
                )}
              </Grid>
              <br />
              <Grid item>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required={true}
                >
                  <InputLabel id="space-type-select-label">
                    Workspace Type
                  </InputLabel>
                  <Select
                    labelId="space-type-select-label"
                    id="space-type-select"
                    onChange={handleWorkspaceTypeChange}
                    value={reservationFormFields.workspace_type}
                    //   onChange={handleChange}
                    label="Workspace Type"
                    // defaultValue="Desk"
                    classes={{ icon: classes.icon }}
                  >
                    <MenuItem value={workstation}>{Desk}</MenuItem>
                    {reservationFormFields.building_id &&
                      //  reservationFormFields.building_id === eaganBuildingId &&
                      showOffice && (
                        <MenuItem value={office}>{Office}</MenuItem>
                      )}
                    {reservationFormFields.building_id &&
                      (showSmallOffice ||
                        (showOffice &&
                          reservationFormFields.building_id ===
                            '0549 TPS')) && (
                        <MenuItem value={'small office'}>
                          {'Small Office'}
                        </MenuItem>
                      )}
                    {reservationFormFields.primary_location === INDIA &&
                      showCabin && (
                        <MenuItem value={cabin} onClick={handleCabinSelection}>
                          Cabin
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <br />
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={teamMemberDetails.fullDay}
                      onChange={handleFullDayBooking}
                      name="fulldayBooking"
                      color="primary"
                      disabled={disableFullDay}
                    />
                  }
                  // label={`Reserve for Full Day (${
                  //   config.startHour >= 12
                  //     ? config.startHour - 12 + ':00 PM'
                  //     : config.startHour + ':00 AM'
                  // }-${
                  //   config.endHour >= 12
                  //     ? config.endHour - 12 + ':00 PM'
                  //     : config.endHour + ':00 AM'
                  // })`}
                  label="Reserve for Full Day"
                />
              </Grid>
              <br />
              <Grid item>
                <CustomTimePicker
                  id="start-time"
                  value={
                    reservationFormFields.start_time ||
                    teamMemberDetails.start_time
                  }
                  setValue={setStartTime}
                  // inputValue={inputValue}
                  // setInputValue={setInputValue}
                  options={startTimeOptions}
                  interval={config.interval}
                  label="Start Time"
                  disabled={teamMemberDetails.fullDay}
                  classes={classes}
                />
              </Grid>
              <br />
              <Grid item>
                <CustomTimePicker
                  id="end-time"
                  value={
                    reservationFormFields.end_time || teamMemberDetails.end_time
                  }
                  setValue={setEndTime}
                  // inputValue={inputValue}
                  // setInputValue={setInputValue}
                  options={endTimeOptions}
                  interval={config.interval}
                  label="End Time"
                  disabled={teamMemberDetails.fullDay}
                  classes={classes}
                />
              </Grid>
              <br />
              <Grid container spacing={4} justifyContent="center">
                <Grid item>
                  {teamMemberDetails.reservation_id ? (
                    <Button
                      component={Link}
                      to="/tm/viewreservations"
                      variant="outlined"
                      color="primary"
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Back
                    </Button>
                  ) : (
                    <Tooltip title="Home" arrow>
                      <IconButton
                        component={Link}
                        to="/tm/home"
                        variant="outlined"
                        color="primary"
                        className={classes.homeIcon}
                        size="large"
                      >
                        <HomeIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      // moment(reservationFormFields.reservation_date).format(
                      //   'YYYY-MM-DD'
                      // ) >
                      //   moment()
                      //     .add(config.maxBookingDays, 'days')
                      //     .format('YYYY-MM-DD') ||
                      // moment(reservationFormFields.reservation_date).format(
                      //   'YYYY-MM-DD'
                      // ) <
                      //   moment()
                      //     .add(config.minBookingDate, 'days')
                      //     .format('YYYY-MM-DD') ||
                      isLoading
                    }
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="primary" />
                    ) : (
                      'Check availability'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <br />
          </form>
        </CardContent>
      </Card>
      <Dialog
        open={showExistingReservation}
        onClose={() => setShowExistingReservation(false)}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent className={classes.dialogRoot}>
          <DialogContentText className={classes.contentDialog}>
            There is already a reservation for this team member on the selected
            date. If this reservation is for another team member, please go back
            and book reservation through Reserve for Others.
            <br />
            <br />
            Do you wish to continue with duplicate booking?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: '0 auto', display: 'flex' }}>
          <Button
            onClick={() => setShowExistingReservation(false)}
            color="primary"
          >
            No
          </Button>
          <Button onClick={submitRequest} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
