import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import 'date-fns'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import Paper from '@mui/material/Paper'
import { Typography, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import { convertInHoursFormat } from '../../components/common/commonFunctionalities'
import { DeskStyles } from '../../styles/deskSelectionStyle'
import { useAuth } from '@praxis/component-auth'
import { useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Autocomplete from '@mui/material/Autocomplete'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { useAnalytics } from '@praxis/component-analytics'
import { ResponsiveDeskLayout } from './responsiveFloorPlan'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import {
  workstation,
  Office,
  Desk,
  timezoneIndia,
  India,
  USA,
  TII_SHIFT,
} from '../../components/common/constants'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const WarningToastConfigure = {
  duration: 10000,
  style: {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  },
}

const DeskSelection = () => {
  const env = useEnv()
  const config = env.constants
  const classes = DeskStyles()
  const auth = useAuth()
  const { session } = auth
  const dispatch = useDispatch()
  const filledForm = useSelector((state) => state.teamMember)
  // const location = useSelector((state) => state.common.location)
  const { trackCustomEvent } = useAnalytics()
  const [selectedDesk, setSelectedDesk] = React.useState('')
  const [autocompleteValue, setAutocompleteValue] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [deskPoints, setDeskPoints] = useState([])
  const [reservedDeskPoints, setReservedDeskPoints] = useState([])
  const [showReserved, setShowReserved] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [showPartialDeskReserved, setShowPartialDeskReserved] = useState([])

  const changeDesk = (newValue) => {
    newValue && newValue !== ''
      ? setSelectedDesk(newValue.desk_id)
      : setSelectedDesk('')
  }
  const handleDeskSelection = (event, newValue) => {
    event.preventDefault()
    setAutocompleteValue(newValue)
    changeDesk(newValue)
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setSelectedDesk('')
    let value = { building_id: '', desk_id: '', floor_id: '' }
    setAutocompleteValue(value)
    setOpenDialog(false)
    setShowPartialDeskReserved([])
  }

  useEffect(() => {
    getDeskCoordinates()
    getDeskCoordinates(
      filledForm.availableDesks,
      setReservedDeskPoints,
      'reserved',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshDesksList = () => {
    // e.preventDefault()

    let payload = JSON.parse(JSON.stringify(filledForm.reservationFields))
    let url
    let buildingLocation =
      filledForm.reservationFields.location_timezone === timezoneIndia
        ? India
        : USA

    if (payload.fullDay) {
      url =
        env.apiUrl['USA'].checkAvailabilityFull +
        `?location=${buildingLocation}`
      payload['reservation_dates'] = payload['reservation_date']
      // delete payload['workSpaceType']
      delete payload['fullDay']
      delete payload['reservation_date']
      delete payload['start_time']
      delete payload['end_time']
    } else {
      url =
        env.apiUrl['USA'].checkAvailabilityPartial +
        `?location=${buildingLocation}`
      // delete payload['workSpaceType']
      delete payload['fullDay']
      payload['reservation_date'] = payload['reservation_date'][0]
      payload['start_time'] = convertInHoursFormat(payload.start_time)
      payload['end_time'] = convertInHoursFormat(payload.end_time)
    }
    delete payload['organisationName']

    axios
      .post(url, payload)
      .then((response) => {
        let value = { building_id: '', desk_id: '', floor_id: '' }
        setAutocompleteValue(value)
        let allData = response.data

        let refinedData = {}

        if (Object.entries(allData).length !== 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(allData).map((floorId) => {
            refinedData[floorId] = { available: [], reserved: [] }
            refinedData[floorId].available = allData[floorId].filter(
              (item) => !item.reserved_for,
            )
            refinedData[floorId].reserved = allData[floorId].filter(
              (item) => item.reserved_for != null,
            )
          })
        }
        dispatch({ type: 'AVAILABLE_DESKS', payload: refinedData })
        setSelectedDesk('')
        // handleDeskSelection(new Event(),'')
        getDeskCoordinates(refinedData)
        getDeskCoordinates(refinedData, setReservedDeskPoints, 'reserved')
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          'Error!Unable to retrieve available workspaces',
          toastConfigure,
        )
      })
  }

  const submitRequest = (e) => {
    e.preventDefault()
    setButtonLoading(true)
    let payload = {}
    let payloadForm = JSON.parse(JSON.stringify(filledForm.reservationFields))
    if (
      filledForm.availableDesks[filledForm.selectedFloor]['available'][0]
        ?.floor_name !== null
    ) {
      payload['floor_name'] =
        filledForm.availableDesks[filledForm.selectedFloor][
          'available'
        ][0]?.floor_name
    } else {
      payload['floor_name'] =
        filledForm.availableDesks[filledForm.selectedFloor][
          'available'
        ][0]?.floor_id
    }
    payload['space_id'] = selectedDesk
    payload['floor_id'] = filledForm.selectedFloor
    payload['building_id'] = payloadForm.building_id
    payload['building_name'] = payloadForm.organisationName
    payload['reserved_for'] =
      payloadForm.reserved_for === undefined || payloadForm.reserved_for === ''
        ? session.userInfo.email.split('@')[0]
        : payloadForm.reserved_for
    payload['reserved_for_id'] =
      payloadForm.lan_id === null || payloadForm.lan_id === ''
        ? session.userInfo.lanId
        : payloadForm.lan_id
    payload['reserved_by'] = session.userInfo.email.split('@')[0]
    payload['meeting_id'] = payloadForm.meeting_id
    payload['workspace_type'] = payloadForm.workspace_type
    let buildingLocation =
      filledForm.reservationFields.location_timezone === timezoneIndia
        ? India
        : USA

    let url
    if (payloadForm.fullDay) {
      url = env.apiUrl['USA'].reserveFullDay + `?location=${buildingLocation}`
      payload['reserved_for_mail'] = session.userInfo.email
      payload['booking_date'] = payloadForm.reservation_date[0]
      if (buildingLocation === India) {
        const timings = TII_SHIFT.split('-')

        payload['start_time'] = convertInHoursFormat(timings[0])
        payload['end_time'] = convertInHoursFormat(timings[1])
        payload['end_time'] = convertInHoursFormat(timings[1])
        payload['reservation_dates'] = payloadForm.reservation_date
        payload['reservation_method'] = 'FullDay'
        delete payload['booking_date']
      }

      // payload['declaration_form_data'] = filledForm.declaration_form_data
    } else {
      url =
        env.apiUrl['USA'].reservePartialDay + `?location=${buildingLocation}`

      // payload['reservation_date'] = payloadForm.reservation_date

      payload['start_time'] = convertInHoursFormat(payloadForm.start_time)
      payload['end_time'] = convertInHoursFormat(payloadForm.end_time)
      payload['reserved_for_mail'] = session.userInfo.email
      payload['booking_date'] = payloadForm.reservation_date[0]
      payload['reservation_method'] = 'PartialDay'

      // payload['declaration_form_data'] = filledForm.declaration_form_data
    }

    if (filledForm.reservationFields.reservation_id) {
      url =
        env.apiUrl['USA'].modifyReservation +
        `?location=${buildingLocation}&reservation_id=${filledForm.reservationFields.reservation_id}&transaction_id=${filledForm.reservationFields.transaction_id}`

      axios
        .put(url, payload)
        .then((response) => {
          setOpen(true)
          dispatch({ type: 'CLEAR_RESERVATION_DETAILS', payload: {} })
          dispatch({
            type: 'RESERVING_FOR_USER_COMPENSATION_US',
            payload: null,
          })
          setButtonLoading(false)

          trackCustomEvent(
            'Modify Button',
            url,
            `Success. Building: ${payloadForm.building_id}, FloorNo:${
              filledForm.selectedFloor
            }, DeskNumber:${selectedDesk}, BookingDate:${
              payloadForm.reservation_date
            }, ModifiedBy:${session.userInfo.email.split('@')[0]}`,
            'Modify',
          )
        })
        .catch((err) => {
          setButtonLoading(false)

          if (err.response.status === 409) {
            refreshDesksList()

            toast(
              'Selected workspace has been reserved by someone else recently. Please select another workspace.',
              {
                icon: <WarningIcon style={{ color: 'yellow' }} />,
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              },
            )
          } else {
            toast.error(`Error - ${err}`, WarningToastConfigure)
          }
          trackCustomEvent(
            'Modify Button',
            url,
            `${err.statusCode} : ${err.statusMsg}`,
            'Modify',
          )
        })
    } else {
      axios
        .post(url, payload)
        .then((response) => {
          setOpen(true)
          dispatch({ type: 'CLEAR_RESERVATION_DETAILS', payload: {} })
          dispatch({
            type: 'RESERVING_FOR_USER_COMPENSATION_US',
            payload: null,
          })
          setButtonLoading(false)

          trackCustomEvent(
            'Reserve Button',
            url,
            `Success. Building: ${payloadForm.building_id}, FloorNo:${
              filledForm.selectedFloor
            }, DeskNumber:${selectedDesk}, BookingDate:${
              payloadForm.reservation_date
            }, BookedBy:${session.userInfo.email.split('@')[0]}`,
            'New Reservation',
          )
        })
        .catch((err) => {
          setButtonLoading(false)

          if (err.response.status === 409) {
            refreshDesksList()

            toast(
              'Selected workspace has been reserved by someone else recently. Please select another workspace.',
              {
                icon: <WarningIcon style={{ color: 'yellow' }} />,
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              },
            )
          } else {
            toast.error(`Error - ${err}`, WarningToastConfigure)
          }
          trackCustomEvent(
            'Reserve Button',
            url,
            `${err.statusCode} : ${err.statusMsg}`,
            'New Reservation',
          )
        })
    }
  }

  const getDeskCoordinates = (
    allDesks = filledForm.availableDesks,
    setData = setDeskPoints,
    deskType = 'available',
  ) => {
    if (Object.keys(allDesks).length > 0) {
      const filteredDesk = allDesks[filledForm.selectedFloor][deskType].map(
        (desk) => desk.desk_id,
      )

      let url =
        env.apiUrl['USA'].getDeskCoordinatesTM +
        `?building_id=${filledForm.reservationFields.building_id}&floor_id=${
          filledForm.selectedFloor
        }&desk_id=${filteredDesk.join(',')}`

      const config = {
        headers: {
          Authorization: localStorage.access_token,
        },
      }

      axios
        .get(url, config)
        .then((response) => {
          if (response.status === 200) {
            if (deskType !== 'available') {
              let mapDeskId = {}
              // eslint-disable-next-line array-callback-return
              allDesks[filledForm.selectedFloor][deskType].map((desk) => {
                mapDeskId[desk.desk_id] = desk.reserved_for
              })

              let finalData = response.data.map((item) => {
                return {
                  ...item,
                  reserved_for: mapDeskId[item.desk_id],
                }
              })
              setData(finalData)
            } else setData(response.data)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <form
          name="reservation-form"
          validate
          autoComplete="off"
          onSubmit={submitRequest}
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            // spacing={6}
            className={classes.formStyle}
          >
            <Grid item>
              <Typography>
                Building: <b>{filledForm.reservationFields.organisationName}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <b>
                  Floor:{' '}
                  {filledForm.availableDesks[filledForm.selectedFloor][
                    'available'
                  ][0]?.floor_name
                    ? filledForm.availableDesks[filledForm.selectedFloor][
                        'available'
                      ][0]?.floor_name
                    : filledForm.availableDesks[filledForm.selectedFloor][
                        'available'
                      ][0]?.floor_id}
                </b>
              </Typography>
            </Grid>
            <br />
            <Grid container>
              {/* <Grid item xs={6} lg={6} align="center">
                <Typography style={{ fontWeight: 'bold' }}>
                  Building: {filledForm.reservationFields.building_id}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6} align="center">
                <Typography style={{ fontWeight: 'bold' }}>
                  Floor: {filledForm.selectedFloor}
                </Typography>
              </Grid> */}
              <br />
              <br />
              <Grid item xs={6} lg={6} align="center">
                <TextField
                  id="reservation-date"
                  label="Reservation Date"
                  defaultValue={filledForm.reservationFields.reservation_date}
                  className={classes.textFieldRead}
                  inputProps={{ readOnly: true }}
                  variant="standard"
                />
              </Grid>

              <Grid item xs={6} lg={6} align="center">
                <TextField
                  id="workspace-type-confirm"
                  label="Workspace Type"
                  variant="standard"
                  defaultValue={
                    filledForm.reservationFields.workspace_type === workstation
                      ? Desk
                      : Office
                  }
                  className={classes.textFieldRead}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container>
              {filledForm && filledForm.reservationFields.fullDay ? (
                // <Grid
                //   item
                //   xs={6}
                //   lg={6}
                //   md={6}
                //   align="center"
                //   // className={classes.fullRes}
                // >

                //   <TextField
                //     id="fulldayBookingConfirm"
                //     label="Reservation Type"
                //     defaultValue={'Full Day'}
                //     className={classes.textFieldRead}
                //     inputProps={{ readOnly: true }}
                //   />

                // </Grid>
                <>
                  <Grid item xs={6} lg={6} align="center">
                    <TextField
                      id="start_time"
                      label="Start Time"
                      defaultValue={
                        config.startHour >= 12
                          ? config.startHour - 12 + ':00 PM'
                          : config.startHour + ':00 AM'
                      }
                      className={classes.textFieldRead}
                      inputProps={{ readOnly: true }}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={6} lg={6} align="center">
                    <TextField
                      id="end_time"
                      label="End Time"
                      defaultValue={
                        config.endHour >= 12
                          ? config.endHour - 12 + ':00 PM'
                          : config.endHour + ':00 AM'
                      }
                      className={classes.textFieldRead}
                      inputProps={{ readOnly: true }}
                      variant="standard"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6} lg={6} align="center">
                    <TextField
                      id="start_time"
                      label="Start Time"
                      defaultValue={filledForm.reservationFields.start_time}
                      className={classes.textFieldRead}
                      inputProps={{ readOnly: true }}
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={6} lg={6} align="center">
                    <TextField
                      id="end_time"
                      label="End Time"
                      defaultValue={filledForm.reservationFields.end_time}
                      className={classes.textFieldRead}
                      inputProps={{ readOnly: true }}
                      variant="standard"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <br />
            {filledForm.reservingFor && (
              <Grid container>
                <Grid item xs={6} lg={6} align="center">
                  {/* <br />
                  <br /> */}
                  <TextField
                    id="reserved_for_confirm"
                    label="Reserving For"
                    defaultValue={filledForm.reservationFields.reserved_for}
                    className={classes.textFieldRead}
                    inputProps={{ readOnly: true }}
                    variant="standard"
                  />
                </Grid>
              </Grid>
            )}

            <br />
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} lg={6} md={6} align="center">
                <Autocomplete
                  id="desk-select"
                  value={autocompleteValue}
                  onChange={handleDeskSelection}
                  getOptionLabel={(option) => option.desk_id}
                  options={
                    filledForm.availableDesks[filledForm.selectedFloor]
                      ?.available
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Available Spaces"
                      autoFocus={true}
                      required
                      variant="standard"
                    />
                  )}
                  //   hidden={disabled}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} md={6} align="center">
                <div>
                  <Typography
                    className={classes.floorPlan}
                    onClick={handleClickOpen}
                  >
                    {/* <RoomOutlinedIcon />View Floorplan */}
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className={classes.mapBtn}
                      startIcon={<RoomOutlinedIcon />}
                      disableRipple={true}
                    >
                      View Floorplan
                    </Button>
                  </Typography>
                  {/* <img src={'/TPS18.jpg'} alt="logo" width={130} height={100} /> */}
                  <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleClose}
                    // fullWidth={true}
                    // maxWidth="xl"
                    TransitionComponent={Transition}
                  >
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          Floor Plan :{' '}
                          {filledForm.reservationFields.organisationName}/
                          {filledForm.availableDesks[filledForm.selectedFloor][
                            'available'
                          ][0]?.floor_name ||
                            filledForm.availableDesks[filledForm.selectedFloor][
                              'available'
                            ][0]?.floor_id ||
                            ''}
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    {/* <CssBaseline /> */}

                    <div className={classes.deskPanel}>
                      <div className={classes.flexBox}>
                        <Typography variant="h6">
                          Selected Space:{' '}
                          <b>
                            <font color="#AC0000">
                              {selectedDesk}{' '}
                              {selectedDesk !== '' &&
                                (filledForm.availableDesks[
                                  filledForm.selectedFloor
                                ]['available'].filter(
                                  (desk) => desk.desk_id === selectedDesk,
                                ).length > 0 ? (
                                  <Typography>
                                    (Available from{' '}
                                    {filledForm.reservationFields.start_time !==
                                    ''
                                      ? filledForm.reservationFields.start_time
                                      : '6 AM'}{' '}
                                    to{' '}
                                    {filledForm.reservationFields.end_time !==
                                    ''
                                      ? filledForm.reservationFields.end_time
                                      : '6 PM'}
                                    )
                                  </Typography>
                                ) : (
                                  <Typography>
                                    (Not Available from{' '}
                                    {filledForm.reservationFields.start_time !==
                                    ''
                                      ? filledForm.reservationFields.start_time
                                      : '6 AM'}{' '}
                                    to{' '}
                                    {filledForm.reservationFields.end_time !==
                                    ''
                                      ? filledForm.reservationFields.end_time
                                      : '6 PM'}
                                    )
                                  </Typography>
                                ))}
                            </font>
                          </b>
                        </Typography>
                        <br />
                        {showPartialDeskReserved.length !== 0 &&
                          (showPartialDeskReserved.length === 1 ? (
                            <Typography>
                              <Typography variant="h6">
                                {' '}
                                Reserved By:
                              </Typography>
                              {showPartialDeskReserved[0].reservedFor} (
                              {showPartialDeskReserved[0].startTime} to{' '}
                              {showPartialDeskReserved[0].endTime})
                            </Typography>
                          ) : (
                            <Typography variant="h6">
                              <Accordion elevation={0}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className={classes.sx}
                                >
                                  Reserved By: ({showPartialDeskReserved.length}{' '}
                                  Reservations)
                                </AccordionSummary>
                                <AccordionDetails className={classes.sx}>
                                  <Typography>
                                    {showPartialDeskReserved.map((item) => (
                                      <div>
                                        {item.reservedFor} ({item.startTime} to{' '}
                                        {item.endTime})
                                        <br />
                                      </div>
                                    ))}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </Typography>
                          ))}

                        {/* Show who reserved on full day */}
                        {showReserved !== '' &&
                          filledForm.reservationFields.fullDay && (
                            <Typography variant="h6">
                              {' '}
                              Reserved By: <b>{showReserved}</b>
                            </Typography>
                          )}

                        {/* old logic that was used for full day, remove after considerations for showing partial reservations in full day */}
                        {/* {showReserved !== '' &&
                        filledForm.reservationFields.fullDay ? (
                          <Typography variant="h6">
                            {' '}
                            Reserved By: <b>{showReserved}</b>
                          </Typography>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={submitRequest}
                            disabled={
                              selectedDesk === '' ||
                              buttonLoading ||
                              !filledForm.availableDesks[
                                filledForm.selectedFloor
                              ]['available'].filter(
                                (desk) => desk.desk_id === selectedDesk
                              ).length > 0
                            }
                            hidden={filledForm.reservationFields.fullDay}
                          >
                            {buttonLoading ? (
                              <CircularProgress size={24} color="primary" />
                            ) : filledForm &&
                              filledForm.reservationFields.reservation_id ? (
                              'Modify'
                            ) : (
                              'Reserve'
                            )}
                          </Button>
                        )} */}

                        <br />
                        <div className={classes.button}>
                          <Button
                            //  fullWidth={true}
                            variant="contained"
                            color="primary"
                            onClick={submitRequest}
                            disabled={
                              selectedDesk === '' ||
                              buttonLoading ||
                              !filledForm.availableDesks[
                                filledForm.selectedFloor
                              ]['available'].filter(
                                (desk) => desk.desk_id === selectedDesk,
                              ).length > 0
                            }
                          >
                            {buttonLoading ? (
                              <CircularProgress size={24} color="primary" />
                            ) : filledForm &&
                              filledForm.reservationFields.reservation_id ? (
                              'Modify'
                            ) : (
                              'Reserve'
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <ResponsiveDeskLayout
                      allPoints={deskPoints}
                      reservedPoints={reservedDeskPoints}
                      setShowReserved={setShowReserved}
                      pngImage={
                        env.apiUrl['USA'].getFloorImage +
                        `?building_id=${filledForm.reservationFields.building_id}&floor_id=${filledForm.selectedFloor}`
                      }
                      setSelectedDesk={setSelectedDesk}
                      changeDesk={changeDesk}
                      selectedDesk={selectedDesk}
                      setShowPartialDeskReserved={setShowPartialDeskReserved}
                    />
                  </Dialog>
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <Button
                  component={Link}
                  to="/tm/step2"
                  variant="outlined"
                  color="primary"
                  //   size="small"
                  startIcon={<ArrowBackIosIcon />}
                  // style={{marginRight:'auto'}}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth={true}
                  style={{ width: '100%', margin: '0 auto', display: 'flex' }}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? (
                    <CircularProgress size={24} color="primary" />
                  ) : filledForm &&
                    filledForm.reservationFields.reservation_id ? (
                    'Modify'
                  ) : (
                    'Reserve'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className={classes.dialogRoot}>
          <div className={classes.successBanner}>
            <CheckCircleIcon style={{ fontSize: 56, color: 'green' }} />
          </div>

          <DialogContentText className={classes.contentDialog}>
            Workspace No.{selectedDesk} has been reserved successfully.
          </DialogContentText>
          {/* <DialogContentText className={classes.contentDialog}>
         
            Please wear a mask.{' '}
            <span
              role="img"
              aria-label={'medical mask icon'}
            >
              {String.fromCodePoint('0x1F637')}
            </span>
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            component={Link}
            to="/tm/home"
            color="primary"
            style={{ margin: '0 auto', display: 'flex' }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeskSelection
