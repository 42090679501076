import Carousel from './carousel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LoginStyle from '../../styles/india/loginStyles'

const LoginPage = ({ login }) => {
  const classes = LoginStyle()
  return (
    <div data-testid="loginLayout" className={classes.loginRoot}>
      <Grid container alignItems="center" justifyItems="center" spacing={10}>
        <Grid item xs={12}>
          <Carousel />
        </Grid>
        <Grid item xs={12} className={classes.buttonPosition}>
          <Button
            className={classes.loginButton}
            variant="contained"
            color="primary"
            onClick={login}
            fullWidth={true}
          >
            {'Login'}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoginPage
