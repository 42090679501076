import makeStyles from '@mui/styles/makeStyles'

export const ContractorVaccinationStyle = makeStyles((theme) => ({
  parentBox: {
    padding: '10px',
  },

  linkText: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  document: { margin: 'auto', padding: 50, alignItems: 'center' },
  view: { display: 'flex' },
}))
