import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

export const UploadStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    uploadSection: {},
    info: {
      backgroundColor: theme.palette.secondary.main,
      border: `0.5px solid ${theme.palette.secondary.dark}`,
      minHeight: '2rem',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',
      padding: '4px 0px 0px 4px',
    },
    paperEffect: {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',
    },
    infoIcon: {
      color: theme.palette.warning.main,
      transform: 'translate(0px, 4px)',
    },
    formControl: {
      margin: `${theme.spacing(1)} !important`,
      minWidth: '157px !important',
    },
    iconHome: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    iconButtonLabel: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    button: {
      height: 56,
      margin: theme.spacing(1),
      display: 'inline-block',
      width: 150,
      color: 'white',
      backgroundColor: '#AC0000',
      '&:hover': {
        backgroundColor: '#AC0000',
      },
      cursor: 'pointer',
    },
    dropzone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      height: 180,
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
  }),
)
