let initialState = {
  allLocations: [],
  selectedLocation: {
    location_no: '',
    location_name: '',
    buildingData: [],
    selectedBuilding: [
      {
        area: null,
        building_address: '',
        building_id: '',
        capacity: null,
        city: '',
        created_by: null,
        location_type: '',
        modified_by: null,
        number_of_floors: null,
        organization_name: '',
        primary_location: '',
        state: '',
        status: null,
      },
    ],
    tableData: [],
  },
}

export function SpaceHierarchyReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_ALL_LOCATIONS':
      return Object.assign({}, state, {
        allLocations: payload,
      })

    case 'SAVE_SELECTED_LOCATION':
      return Object.assign({}, state, {
        selectedLocation: payload,
      })

    case 'SAVE_LOCATION_BUILDING_DATA':
      return Object.assign({}, state, {
        buildingData: payload,
      })

    case 'SAVE_LOCATION_TABLE_DATA':
      return Object.assign({}, state, {
        tableData: payload,
      })

    case 'SAVE_LOCATION_SELECTED_BUILDING':
      return Object.assign({}, state, {
        selectedBuilding: payload,
      })

    default:
      return state
  }
}
