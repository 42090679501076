let initialState = {
  buildingId: '',
  selectedFloor: '',
  selectedBuilding: '',
  allFloors: null,
  allBuildings: null,
  floorMetadata: [],
}

export function CadProcessingReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_CAD_FLOOR':
      return Object.assign({}, state, {
        selectedFloor: payload,
      })

    case 'SAVE_CAD_BUILDING':
      return Object.assign({}, state, {
        selectedBuilding: payload,
      })

    case 'SAVE_CAD_BUILDINGID':
      return Object.assign({}, state, {
        buildingId: payload,
      })

    case 'SAVE_CAD_ALL_FLOORS':
      return Object.assign({}, state, {
        allFloors: payload,
      })

    case 'SAVE_CAD_ALL_BUILDINGS':
      return Object.assign({}, state, {
        allBuildings: payload,
      })

    case 'SAVE_CAD_FLOOR_METADATA':
      return Object.assign({}, state, {
        floorMetadata: payload,
      })

    default:
      return state
  }
}
