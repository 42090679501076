import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from '@praxis/component-auth'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { praxisTheme as theme, darkTheme } from './styles/theme'
import { Main } from './components/main'
import CssBaseline from '@mui/material/CssBaseline'
import { store } from './store/store'
import { Provider } from 'react-redux'
import { AnalyticsProvider } from '@praxis/component-analytics'
// import ScrollToTop from './components/common/scrollTop'
import { Toaster } from 'react-hot-toast'
import { EnvProvider } from '@praxis/component-runtime-env'
import LoadingIndicator from './components/splash/loadingIndicator'
import SorryPage from './components/common/sorryPage'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const App = () => {
  let [darkMode, setDarkMode] = React.useState(
    JSON.parse(localStorage.getItem('dark-mode')),
  )

  const chosenTheme = darkMode ? darkTheme : theme
  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {({ env }) => {
        if (env.constants.makeUnavailable) {
          return <SorryPage message={env.constants.unavailableMsg} />
        }
        return (
          <>
            <Toaster position="bottom-right" reverseOrder={false} />
            <Provider store={store}>
              <ThemeProvider theme={chosenTheme}>
                <AuthProvider
                  clientId={env.auth.clientId}
                  authorizationUrl={env.auth.authorizationUrl}
                  loginRedirect={`${window.location.origin}/auth/login`}
                  logoutUrl={env.auth.logoutUrl}
                  logoutRedirect={`${window.location.origin}/`}
                  // onLogout={() => {
                  //   window.location = `${window.location.origin}/`
                  //   localStorage.clear()
                  // }}
                  onLogin={(err, session) => {
                    if (err) {
                      console.error(err)
                    }
                  }}
                  loadingIndicator={LoadingIndicator}
                >
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <CssBaseline />

                      <Router>
                        <AnalyticsProvider
                          eventManagerConfiguration={{
                            appName: env.firefly.fireflyAppName,
                            apiKey: env.firefly.fireflyApiKey,
                            url: env.firefly.fireflyUrl,
                          }}
                          trackLocations
                        >
                          <Main setDarkMode={setDarkMode} />
                          {/* <ScrollToTop /> */}
                        </AnalyticsProvider>
                      </Router>
                    </LocalizationProvider>
                  </StyledEngineProvider>
                </AuthProvider>
              </ThemeProvider>
            </Provider>
          </>
        )
      }}
    </EnvProvider>
  )
}

export default App
