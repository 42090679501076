import { Typography, Paper, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { useNavigate } from 'react-router-dom'
import { FloorStyles } from '../../styles/floorSelectionStyle'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { useEnv } from '@praxis/component-runtime-env'

const FloorSelection = () => {
  const env = useEnv()
  const activeFloor = env.floorActivation
  const reservationFloor = useSelector(
    (state) => state.teamMember.reservationFields.building_id,
  )
  const organisationName = useSelector(
    (state) => state.teamMember.reservationFields.organisationName,
  )
  const reservationDate = useSelector(
    (state) => state.teamMember.reservationFields.reservation_date,
  )
  const floorsAvailable = useSelector(
    (state) => state.teamMember.availableDesks,
  )
  const dispatch = useDispatch()
  const history = useNavigate()
  const classes = FloorStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <Typography style={{ textAlign: 'center', fontSize: '1.5rem' }}>
          <b>{organisationName}</b>
        </Typography>
        {Object.entries(floorsAvailable).length === 0 ? (
          <>
            <br />
            <Typography> No reservable floors available</Typography>
          </>
        ) : (
          <Typography style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
            {' '}
            Select a floor to proceed further
          </Typography>
        )}
        <List className={classes.list}>
          {floorsAvailable &&
            Object.keys(floorsAvailable).map((floor) => {
              let floorName
              if (floorsAvailable[floor]['available'][0]?.floor_name !== null) {
                floorName = floorsAvailable[floor]['available'][0]?.floor_name
              } else {
                floorName = floorsAvailable[floor]['available'][0]?.floor_id
              }

              const floorType =
                floorsAvailable[floor]['available'][0].floor_type
              return (
                <>
                  <ListItem
                    className={classes.listItem}
                    button
                    onClick={(evt) => {
                      dispatch({ type: 'SELECTED_FLOOR', payload: floor })
                      history('/tm/step3')
                    }}
                    disabled={
                      activeFloor &&
                      activeFloor[reservationFloor] &&
                      reservationDate < activeFloor[reservationFloor][floor]
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.container}
                    >
                      <Grid item>
                        <ListItemAvatar>
                          <Avatar
                            variant="rounded"
                            className={classes.avatarStyle}
                          >
                            <ApartmentIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </Grid>
                      <Grid item style={{ width: '60%' }}>
                        <ListItemText
                          style={{ color: '#AC0000' }}
                          primary={`Floor Number: ${floorName}`}
                          secondary={
                            <>
                              <b>
                                <Typography variant="body2" component="span">
                                  Available Workspaces:{' '}
                                  {floorsAvailable[floor]?.available?.length}
                                </Typography>
                                <br />
                                <Typography variant="body2" component="span">
                                  Floor Type: {floorType}
                                </Typography>
                              </b>
                            </>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={12} className={classes.center}>
                        {activeFloor &&
                          activeFloor[reservationFloor] &&
                          reservationDate <
                            activeFloor[reservationFloor][floor] && (
                            <Typography variant="subtitle2">
                              <b>
                                Open for reservation from{' '}
                                {activeFloor[reservationFloor][floor]}
                              </b>
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )
            })}
        </List>
        <Button
          component={Link}
          to="/tm/step1"
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<ArrowBackIosIcon />}
          className={classes.btn}
        >
          Back
        </Button>{' '}
      </Paper>
    </div>
  )
}

export default FloorSelection
