import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import { UploadInteractiveFloorStyles } from '../../styles/uploadInteractiveFloorStyles'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { Button, TextField } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import fileDownload from 'js-file-download'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import HomeIcon from '@mui/icons-material/Home'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const CADFileProcessing = () => {
  const env = useEnv()
  const classes = UploadInteractiveFloorStyles()
  const dispatch = useDispatch()
  // const [newFloor, setNewFloor] = useState()
  const interactiveState = useSelector((state) => state.cadProcess)
  const allBuildings = useSelector((state) => state.cadProcess.allBuildings)
  const floorMetadata = useSelector((state) => state.cadProcess.floorMetadata)
  const [bldng, setBldng] = useState('')
  const [, setFloors] = useState([])
  const [, setSelectedFloor] = useState('')
  const [building, setBuilding] = useState('')
  const floorMetadataPNG = useSelector(
    (state) => state.interactive.floorMetadata,
  )
  const [usFloorNames, setUsFloorNames] = useState([])

  useEffect(() => {
    refreshBuilding()
    fetchUSFloors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchUSFloors = () => {
    const url = env.apiUrl['USA'].listHomebaseFlexFloors
    axios
      .get(url)
      .then((response) => {
        const data = response.data

        const filteredFloorNames = data
          .filter((building) => building.building_details.location === 'US')
          .reduce((acc, building) => {
            acc.push(...building.floor_details)
            return acc
          }, [])
          .map((item) => item.floor_id)
        setUsFloorNames(filteredFloorNames)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }
  const refreshBuilding = () => {
    const url = env.apiUrl['USA'].getAllBuildingsUrl
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SAVE_CAD_ALL_BUILDINGS',
          payload: res.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // const buildingChange = (event) => {
  //   console.log(event.target.value)
  //   setBuilding(event.target.value)
  const buildingChange = (event, value) => {
    if (value) {
      setBldng(value)
      setBuilding(value.building_details.building_name)
    } else {
      setBuilding('')
      setBldng('')
    }
  }

  useEffect(() => {
    handleBuildingChange(building)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, allBuildings])

  const handleBuildingChange = (building) => {
    dispatch({ type: 'SAVE_CAD_FLOOR_METADATA', payload: [] })
    dispatch({ type: 'SAVE_INTERACTIVE_FLOOR_METADATA', payload: [] })
    if (building !== '') {
      dispatch({
        type: 'SAVE_CAD_BUILDING',
        payload: building,
      })
      dispatch({
        type: 'SAVE_CAD_BUILDINGID',
        payload: allBuildings.find(
          (bldng) => bldng.building_details.building_name === building,
        ).building_details.building_id,
      })
      let floors = interactiveState.allBuildings
        .find((bldng) => bldng.building_details.building_name === building)
        .floor_details.sort()
      setFloors(floors)
      setSelectedFloor('')
      dispatch({ type: 'SAVE_CAD_ALL_FLOORS', payload: floors })
      dispatch({ type: 'SAVE_CAD_FLOOR', payload: '' })
    } else {
      setFloors([])
      setSelectedFloor('')
      dispatch({ type: 'SAVE_CAD_ALL_FLOORS', payload: '' })
      dispatch({ type: 'SAVE_CAD_FLOOR', payload: '' })
    }
  }

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value)
    dispatch({ type: 'SAVE_CAD_FLOOR', payload: event.target.value })
  }

  useEffect(() => {
    const floorData = interactiveState.selectedFloor.split(':')
    if (floorData[0] !== '' && floorData[0] !== 'newfloor') {
      getFloorMetadata()
      getFloorMetadataPNG()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactiveState.selectedFloor])

  const getFloorMetadata = () => {
    const floorData = interactiveState.selectedFloor.split(':')

    const url =
      env.apiUrl['USA'].getCadFloorMetadata +
      `building_id=${interactiveState.buildingId}&floor_id=${floorData[0]}`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        setFloors(res.data)
        dispatch({
          type: 'SAVE_CAD_FLOOR_METADATA',
          payload: res.data,
        })
      })
      .catch((error) => {
        toast.error(`Error - ${error}`, toastConfigure)
      })
  }
  const getFloorMetadataPNG = () => {
    const floorData = interactiveState.selectedFloor.split(':')

    const url =
      env.apiUrl['USA'].getFloorMetadata +
      `building_id=${interactiveState.buildingId}&floor_id=${floorData[0]}`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios.get(url, config).then((res) => {
      res.data
        .filter((item) => item.file_type.includes('PNG'))
        // eslint-disable-next-line array-callback-return
        .map((value) => {
          dispatch({
            type: 'SAVE_INTERACTIVE_FLOOR_METADATA',
            payload: res.data,
          })
        })
    })
  }

  const handleDownload = (filepath, filename) => {
    const url = env.apiUrl['USA'].downloadCadFile + filepath
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
      responseType: 'arraybuffer',
    }
    axios.get(url, config).then((res) => {
      fileDownload(res.data, filename)
    })
  }

  const handleDownloadPNG = (filepath, filename) => {
    const url = env.apiUrl['USA'].downloadUrl + filepath
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
      responseType: 'arraybuffer',
    }
    axios.get(url, config).then((res) => {
      fileDownload(res.data, filename)
    })
  }

  return (
    <div style={{ padding: '1.5%', paddingTop: '1%' }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item></Grid>
        <Grid item>
          <FormControl classes={{ root: classes.formControl }}>
            <Autocomplete
              id="building-autocomplete"
              className={classes.formControl}
              options={allBuildings || []}
              value={bldng}
              onChange={buildingChange}
              getOptionLabel={(option) =>
                option.building_details
                  ? option.building_details.building_name
                  : ''
              }
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Building Name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    style: { minWidth: 300 },
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          &nbsp;&nbsp;
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Floor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={interactiveState.selectedFloor}
              onChange={handleFloorChange}
              label="Floor Name"
            >
              <MenuItem disabled>--Choose Floor--</MenuItem>

              {interactiveState.allFloors &&
                interactiveState.allFloors.map((floor, key) => {
                  return (
                    <MenuItem
                      value={`${floor.floor_id}:${floor.floor_name}`}
                      key={floor.floor_id}
                    >
                      {floor.floor_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/cre/dashboard"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <List className={classes.list}>
        {floorMetadata &&
          floorMetadata.map((floor) => {
            return (
              <ListItem>
                <Grid
                  container
                  direction="row"
                  // justify="center"
                  alignItems="center"
                  style={{ fontWeight: '520' }}
                >
                  <Grid item>
                    <ListItemAvatar>
                      <Avatar variant="rounded" className={classes.avatarStyle}>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs>
                    <ListItemText
                      primary={<b>{floor.file_name}</b>}
                      secondary={`Last updated on ${moment(
                        floor.created_date,
                      ).format('MMMM Do YYYY')} by ${floor.created_user_name}`}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.btn}
                      onClick={() => {
                        handleDownload(floor.toss_file_path, floor.file_name)
                      }}
                    >
                      Download
                    </Button>
                    &nbsp;&nbsp;
                    <hr />
                  </Grid>
                </Grid>
              </ListItem>
            )
          })}

        {usFloorNames.includes(interactiveState.selectedFloor?.split(':')[0]) &&
          floorMetadataPNG &&
          floorMetadataPNG
            .filter((item) => item.file_type === 'PNG')
            .map((floor) => {
              return (
                <ListItem>
                  <Grid
                    container
                    direction="row"
                    // justify="center"
                    alignItems="center"
                    style={{ fontWeight: '520' }}
                  >
                    <Grid item>
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          className={classes.avatarStyle}
                        >
                          <DescriptionIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs>
                      <ListItemText
                        primary={<b>{floor.file_name}</b>}
                        secondary={`Last updated on ${moment(
                          floor.created_date,
                        ).format('MMMM Do YYYY')} by ${
                          floor.created_user_name
                        }`}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.btn}
                        onClick={() => {
                          handleDownloadPNG(
                            floor.toss_file_path,
                            floor.file_name,
                          )
                        }}
                      >
                        Download
                      </Button>
                      &nbsp;&nbsp;
                      <hr />
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
      </List>
      {interactiveState.selectedFloor?.split(':')[0] !== '' &&
        interactiveState.selectedFloor?.split(':')[0] !== 'newfloor' && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ fontWeight: '520' }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                component={Link}
                to="/cre/saveCadFile"
              >
                {floorMetadata.length === 0 ? 'Add File(s)' : 'Update file(s)'}
              </Button>
            </Grid>
          </Grid>
        )}
      <br />
    </div>
  )
}

export default CADFileProcessing
