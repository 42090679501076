import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { SpaceEnablementStyles } from '../../styles/spaceEnablementStyle'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  tableIcons,
  LoadedTabPanel as TabPanel,
} from '../../components/common/commonFunctionalities'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import Switch from '@mui/material/Switch'
import { Typography, Paper, FormControlLabel } from '@mui/material'
import Button from '@mui/material/Button'
import toast from 'react-hot-toast'
import {
  toastConfigure,
  getTableData,
} from '../../components/common/commonFunctionalities'
// import TNC01 from '../../images/PS18.pdf'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import withStyles from '@mui/styles/withStyles'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import { useAnalytics } from '@praxis/component-analytics'
import { DeskLayout } from './deskLayout'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { CsvBuilder } from 'filefy'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { India, INDIA, USA } from '../../components/common/constants'

const AccordionSummary = withStyles({
  content: {
    flexGrow: 0,
  },
})(MuiAccordionSummary)

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: '#fafafa',
    '&$checked': {
      color: theme.palette.success.dark,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  checked: {},
  track: {},
}))(Switch)

const SpaceEnablement = () => {
  const env = useEnv()
  const classes = SpaceEnablementStyles()
  const { trackCustomEvent } = useAnalytics()
  // const location = useSelector((state) => state.common.location)

  const [buildingFloor, setBuildingFloor] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState('')
  const [selectedFloor, setSelectedFloor] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [buildingLocation, setBuildingLocation] = useState(USA)
  const [floor, setFloor] = useState([])

  const [allWorkspacesList, setAllWorkspacesList] = useState([]) // all spaces in list
  const [allSpacesMap, setAllSpacesMap] = useState({}) // all spaces in map format
  const [workspaceCountMap, setWorkspaceCountMap] = useState({})

  const [workspaceTypesAvailable, setWorkspaceTypesAvailable] = useState([])
  const [spaceRefresh, setSpaceRefresh] = useState(false)
  const [clearMapSelection, setClearMapSelection] = useState(false)
  const [loadTable, setLoadTable] = useState()
  const [loading, setLoading] = useState(true)
  const [enableAllButton, setEnableAllButton] = useState(false)

  const [disableAllButton, setDisableAllButton] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    const url = env.apiUrl[USA].listHomebaseFlexFloors
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        let sortedData = _.sortBy(res.data, 'building_details.building_name')
        setBuildingFloor(sortedData)
      })
      .catch((error) => {
        console.error(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleBuildingChange = (event) => {
    setSelectedBuilding(event.target.value)

    let buildingDetails = buildingFloor.find(
      (bldng) => bldng.building_details.building_name === event.target.value,
    ).building_details
    setBuildingId(buildingDetails.building_id)
    let location = buildingDetails.location === INDIA ? India : USA
    setBuildingLocation(location)

    let floorData = buildingFloor
      .find(
        (bldng) => bldng.building_details.building_name === event.target.value,
      )
      .floor_details.sort()
    setFloor(floorData)
    setSelectedFloor('')
    setLoadTable(false)
  }

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value)
    setLoadTable(true)
    setLoading(true)
  }

  useEffect(() => {
    if (selectedFloor !== '') {
      getSpaceDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor])

  useEffect(() => {
    if (spaceRefresh === true) {
      getSpaceDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceRefresh])

  const filterResponse = (res) => {
    let filteredWorkstations = res.data.space_details
    let spacesMap = {}
    let spacesCountMap = {}
    let availableSpaceType = []
    let allSpacesList = []
    // eslint-disable-next-line array-callback-return
    filteredWorkstations.map((item) => {
      spacesMap[item.space_type] = item.space_status
      availableSpaceType.push(item.space_type)
      let countEnabled = item.space_status.filter(
        (eachSpace) => eachSpace.space_enabled === true,
      )
      spacesCountMap[item.space_type] = countEnabled.length
      allSpacesList = [...allSpacesList, ...item.space_status]
    })

    setWorkspaceTypesAvailable(availableSpaceType)
    setAllWorkspacesList(allSpacesList)
    setAllSpacesMap(spacesMap)
    setWorkspaceCountMap(spacesCountMap)

    let isDisableAll = Object.values(spacesCountMap).every((item) => item === 0)
    setDisableAllButton(isDisableAll)

    let isEnableAll = Object.keys(spacesCountMap).every(
      (item) => spacesCountMap[item] === spacesMap[item].length,
    )

    setEnableAllButton(isEnableAll)
    setSpaceRefresh(false)
    setLoading(false)
  }

  const getSpaceDetails = () => {
    const url =
      env.apiUrl[buildingLocation].getSpaceDetails +
      `?building_name=${selectedBuilding}&building_id=${buildingId}&floor_name=${
        selectedFloor?.split(':')[0]
      }`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        // setSpaces(res.data.space_details)
        filterResponse(res)
      })

      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const enableAllDesks = (e, workspaceList = workspaceTypesAvailable) => {
    setLoading(true)
    const url =
      env.apiUrl[buildingLocation].enableDisableAllUrl +
      `?building_id=${buildingId}&floor_name=${
        selectedFloor?.split(':')[0]
      }&space_active=true`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
        'Content-Type': 'application/json',
      },
    }
    let payload = JSON.stringify(workspaceList)

    axios
      .put(url, payload, config)
      .then((res) => {
        // if (res.status === 201) {
        //   setSpaceRefresh(true)
        //   toast.success('All Workstations Enabled Successfully', toastConfigure)
        //   trackCustomEvent('Enable All Button', url, res.data, 'Enable All')
        // }

        if (res.data) {
          let errorSpaceType = Object.keys(res.data).filter(
            (item) => res.data[item] !== true,
          )
          if (errorSpaceType.length === 0) {
            setSpaceRefresh(true)
            let successMessage =
              workspaceList.length === 1
                ? `All ${workspaceList.toString()}s enabled successfully`
                : 'All Workspaces Enabled Successfully'
            toast.success(successMessage, toastConfigure)
            trackCustomEvent('Enable All Button', url, res.data, 'Enable All')
          } else {
            setSpaceRefresh(true)

            toast.error(
              `Error in enabling Workspace type: ${errorSpaceType.join(',')} `,
              toastConfigure,
            )
            trackCustomEvent(
              'Enable All Button',
              url,
              `${res.status} : ${res.data}`,
              'Enable All',
            )
          }
        }
      })
      .catch((err) => {
        toast.error(`Error - ${err}`, toastConfigure)
        setLoading(false)
        trackCustomEvent(
          'Enable All Button',
          url,
          `${err.statusCode} : ${err.statusMsg}`,
          'Enable All',
        )
      })
  }

  const disableAllDesks = (e, workspaceList = workspaceTypesAvailable) => {
    let spacestoBeDisabled =
      workspaceList.length === 1
        ? allSpacesMap[workspaceList]
        : allWorkspacesList
    let isSpaceReserved = spacestoBeDisabled.every((item, index) => {
      return item.reservation_dates[0] === null
    })

    if (!isSpaceReserved) {
      toast.error(
        'Error! Cannot disable workspace with active reservations',
        toastConfigure,
      )
    } else {
      setLoading(true)
      const url =
        env.apiUrl[buildingLocation].enableDisableAllUrl +
        `?building_id=${buildingId}&floor_name=${
          selectedFloor?.split(':')[0]
        }&space_active=false`
      const config = {
        headers: {
          Authorization: localStorage.access_token,
          'Content-Type': 'application/json',
        },
      }
      let payload = JSON.stringify(workspaceList)
      axios
        .put(url, payload, config)
        .then((res) => {
          if (res.data) {
            let errorSpaceType = Object.keys(res.data).filter(
              (item) => res.data[item] !== true,
            )
            if (errorSpaceType.length === 0) {
              setSpaceRefresh(true)
              let successMessage =
                workspaceList.length === 1
                  ? `All ${workspaceList.toString()}s disabled successfully`
                  : 'All Workspaces Disabled Successfully'
              toast.success(successMessage, toastConfigure)
              trackCustomEvent(
                'Disable All Button',
                url,
                res.data,
                'Disable All',
              )
            } else {
              setSpaceRefresh(true)

              toast.error(
                `Error in disabling Workspace type: ${errorSpaceType.join(
                  ',',
                )} `,
                toastConfigure,
              )
              trackCustomEvent(
                'Disable All Button',
                url,
                `${res.status} : ${res.data}`,
                'Disable All',
              )
            }
          }

          // if (res.status === 201) {
          //   setSpaceRefresh(true)
          //   toast.success(
          //     'All Workstations Disabled Successfully',
          //     toastConfigure
          //   )
          //   trackCustomEvent('Disable All Button', url, res.data, 'Disable All')
          // }
        })
        .catch((err) => {
          toast.error(`Error - ${err}`, toastConfigure)
          setLoading(false)
          trackCustomEvent(
            'Disable All Button',
            url,
            `${err.statusCode} : ${err.statusMsg}`,
            'Disable All',
          )
        })
    }
  }

  // const allEnableEqual = (arr) => arr.every((val) => val === true)
  // const allDisableEqual = (arr) => arr.every((val) => val === false)

  const spaceColumns = [
    {
      field: 'space_no',
      title: 'SpaceNumber',
      defaultSort: 'asc',
    },
    {
      field: 'space_enabled',
      title: 'SpaceEnabled?',
      type: 'boolean',
      render: (rowData) => (
        // <Switch
        //   // style={{ color: theme.palette.success.main }}
        //   color="primary"
        //   checked={rowData.space_enabled}
        // />
        <CustomSwitch
          checked={rowData.space_enabled}
          value={rowData.space_enabled ? 'Enabled' : 'Disabled'}
        />
      ),
    },
    {
      field: 'reservation_dates',
      title: 'Reservation Dates',
      hidden: true,
    },
    {
      field: 'date1',
      title: moment().format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] === moment().format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] === moment().format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date2',
      title: moment().add(1, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(1, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(1, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(1, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(1, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date3',
      title: moment().add(2, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(2, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(2, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(2, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(2, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(2, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(2, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date4',
      title: moment().add(3, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(3, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(3, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(3, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date5',
      title: moment().add(4, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(4, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(4, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(4, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date6',
      title: moment().add(5, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(5, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(5, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(5, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date7',
      title: moment().add(6, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(6, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(6, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(6, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },

    {
      field: 'date8',
      title: moment().add(7, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(7, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(7, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(7, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date9',
      title: moment().add(8, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(8, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(8, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(8, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date10',
      title: moment().add(9, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(9, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(9, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(9, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date11',
      title: moment().add(10, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(10, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(10, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(10, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date12',
      title: moment().add(11, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(11, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(11, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(11, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date13',
      title: moment().add(12, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[12] ===
                    moment().add(12, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(12, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[12] ===
                    moment().add(12, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
    {
      field: 'date14',
      title: moment().add(13, 'days').format('DD MMM'),
      export: false,
      render: (rowData) =>
        rowData.space_enabled ? (
          <Chip
            variant="outlined"
            label={
              rowData.reservation_dates[0] === null
                ? 'A'
                : rowData.reservation_dates[0] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[12] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[13] ===
                    moment().add(13, 'days').format('MM/DD/YYYY')
                ? 'R'
                : 'A'
            }
            className={
              rowData.reservation_dates[0] === null
                ? classes.success
                : rowData.reservation_dates[0] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[1] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[2] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[3] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[4] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[5] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[6] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[7] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[8] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[9] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[10] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[11] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[12] ===
                    moment().add(13, 'days').format('MM/DD/YYYY') ||
                  rowData.reservation_dates[13] ===
                    moment().add(13, 'days').format('MM/DD/YYYY')
                ? classes.booked
                : classes.success
            }
          />
        ) : null,
    },
  ]

  const handleTableEnableSwitch = (event) => {
    event.preventDefault()
    const { checked } = event.target
    if (checked) enableAllDesks({}, [workspaceTypesAvailable[tabValue]])
  }

  const handleTableDisableSwitch = (event) => {
    event.preventDefault()
    const { checked } = event.target
    if (checked) disableAllDesks({}, [workspaceTypesAvailable[tabValue]])
  }

  const enableDisableSpace = (
    space_no,
    status,
    selectedSpaceType = 'workstation',
  ) => {
    // let chosenSpaces = space_no
    let payload = {}
    let url =
      env.apiUrl[buildingLocation].enableDisableNewUrl +
      `?building_name=${selectedBuilding}&building_id=${buildingId}&floor_name=${
        selectedFloor?.split(':')[0]
      }&space_type=${selectedSpaceType}&space_no=${space_no}&space_active=${status}`

    if (typeof space_no === 'object') {
      payload = [...space_no].map((space) => {
        return { space_type: selectedSpaceType, space_id: space }
      })

      url =
        env.apiUrl[buildingLocation].enableDisableMultipleUrl +
        `?building_name=${selectedBuilding}&building_id=${buildingId}&floor_name=${
          selectedFloor?.split(':')[0]
        }&space_active=${status}`
    }

    // let payload = {
    //   space_type: selectedSpaceType,
    //   space_no: chosenSpaces,
    // }

    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .put(url, payload, config)

      .then((res) => {
        setLoading(true)
        // setSpaceRefresh(true)
        if (res.status !== 201) {
          toast.error(
            'Error!Issue in enabling/disabling workspace',
            toastConfigure,
          )
        } else {
          filterResponse(res)
          setClearMapSelection(!clearMapSelection)
          toast.success(
            'Workstation(s) status updated successfully',
            toastConfigure,
          )
        }
        if (typeof space_no === 'object') {
          trackCustomEvent(
            'Enable/Disable Toggle',
            url,
            `${space_no} is set to ${status}`,
            'Enable/Disable',
          )
        } else {
          trackCustomEvent(
            'Enable/Disable Toggle',
            url,
            `${space_no} is set to ${status}`,
            'Enable/Disable',
          )
        }
      })
      .catch((err) => {
        toast.error(`Error - ${err}`, toastConfigure)
        setLoading(false)
        trackCustomEvent(
          'Enable/Disable Toggle',
          url,
          `${err.statusCode} : ${err.statusMsg}`,
          'Enable/Disable',
        )
      })
  }

  const exportCustomCSV = (allColumns, allData) => {
    const [columns, data] = getTableData(allColumns, allData)

    let fileName = `${selectedBuilding} - ${selectedFloor.split(':')[1]}`

    const builder = new CsvBuilder(fileName + '.csv')
    builder
      // .setDelimeter(this.props.exportDelimiter)
      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile()
  }

  return (
    <div style={{ padding: '1%', paddingTop: '1%' }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item></Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Building Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedBuilding}
              onChange={handleBuildingChange}
              label="Building Name"
            >
              <MenuItem disabled>--Choose Building--</MenuItem>
              {buildingFloor &&
                buildingFloor.map((e, key) => {
                  return (
                    <MenuItem
                      value={e.building_details.building_name}
                      key={key}
                    >
                      {e.building_details.building_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          &nbsp;&nbsp;
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Floor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedFloor}
              onChange={handleFloorChange}
              label="Floor Name"
            >
              <MenuItem disabled>--Choose Floor--</MenuItem>
              {floor &&
                floor.map((val, key) => {
                  return (
                    <MenuItem
                      // value={{
                      //   floor_id: `${val.floor_id}`,
                      //   floor_name: `${val.floor_name}`,
                      // }}
                      value={`${val.floor_id}:${val.floor_name}`}
                      key={val.floor_id}
                    >
                      {val.floor_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/cre/dashboard"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {loadTable ? (
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Accordion className={classes.accordianBorder}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  Interactive Floor Map
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <div
                    // className={classes.container}
                    key={selectedFloor}
                  > */}
                <DeskLayout
                  allPoints={allSpacesMap}
                  pngImage={
                    env.apiUrl[buildingLocation].getFloorImage +
                    `?building_id=${buildingId}&floor_id=${
                      selectedFloor?.split(':')[0]
                    }`
                  }
                  stageWidth={1250}
                  stageHeight={800}
                  enableDisableSpace={enableDisableSpace}
                  clearMapSelection={clearMapSelection}
                  allSpaceType={workspaceTypesAvailable}
                />
                &nbsp;
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            <Typography>
              {' '}
              <Chip
                variant="outlined"
                label={'A'}
                className={classes.success}
              />{' '}
              <b>- Available</b> &nbsp;&nbsp;&nbsp;
              <Chip
                variant="outlined"
                label={'R'}
                className={classes.booked}
              />{' '}
              <b>- Reserved</b>{' '}
            </Typography>
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>
                <b> Enabled: </b>
              </Typography>

              {/* <Chip
                variant="outlined"
                label={`${enabledCount}/${workstation.length}`}
                className={classes.enabledCount}
              />{' '} */}

              {workspaceCountMap &&
                allSpacesMap &&
                Object.keys(allSpacesMap).length > 0 &&
                Object.keys(workspaceCountMap).length > 0 &&
                Object.keys(allSpacesMap).map((spaceCount) => (
                  <Grid container>
                    <Grid item style={{ textAlign: 'center', width: 120 }}>
                      <Chip
                        // variant="outlined"
                        color="primary"
                        label={`${workspaceCountMap[spaceCount]}/${allSpacesMap[spaceCount].length}`}
                        className={classes.enabledCount}
                      />
                      <Typography variant="body2">
                        {spaceCount.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </div>
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={enableAllDesks}
              disabled={enableAllButton}
              // {allEnableEqual(enableDisable)}
            >
              Enable All
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              color="primary"
              variant="outlined"
              onClick={disableAllDesks}
              disabled={disableAllButton}
            >
              Disable All
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <br />
      {loadTable ? (
        <Paper>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="workspace type tabs"
          >
            {workspaceTypesAvailable.map((eachSpaceType, index) => (
              <Tab
                style={{ fontWeight: 'bold' }}
                label={eachSpaceType}
                key={index}
              />
            ))}
          </Tabs>

          {workspaceTypesAvailable.map((eachSpaceType, index) => (
            <TabPanel value={tabValue} index={index} key={'tabpanel' + index}>
              <MaterialTable
                title={''}
                options={{
                  thirdSortClick: false,
                  showTitle: true,
                  search: true,
                  toolbar: true,
                  pageSizeOptions: [10, 25, 50, 100],
                  loadingType: 'overlay',
                  pageSize: 100,
                  headerStyle: {
                    position: 'sticky',
                    top: 0,
                    height: '50px',
                    fontWeight: 'bold',
                    // backgroundColor: '#aba7a8',
                  },
                  maxBodyHeight: '53vh',
                  emptyRowsWhenPaging: false,
                  // padding: 'dense',
                }}
                columns={spaceColumns}
                data={allSpacesMap[eachSpaceType]}
                icons={tableIcons}
                isLoading={loading}
                onRowClick={(event, rowData) => {
                  let space = rowData.space_enabled === true ? false : true
                  if (!space && rowData.reservation_dates[0] !== null) {
                    toast.error(
                      'Error! Cannot disable workspace with active reservations',
                      toastConfigure,
                    )
                  } else {
                    enableDisableSpace(
                      rowData.space_no,
                      space,
                      workspaceTypesAvailable[tabValue],
                    )
                  }
                }}
                components={{
                  Toolbar: (props) => (
                    <div className={classes.toolbarStyle}>
                      <div>
                        {allSpacesMap &&
                          Object.keys(allSpacesMap).length > 0 &&
                          allSpacesMap[eachSpaceType] && (
                            <FormControlLabel
                              control={
                                <CustomSwitch
                                  checked={
                                    workspaceCountMap[eachSpaceType] ===
                                    allSpacesMap[eachSpaceType].length
                                  }
                                  // onChange={handleChange}
                                  onChange={handleTableEnableSwitch}
                                  name="enableCheckbox"
                                  color="primary"
                                />
                              }
                              label="Enable All"
                            />
                          )}
                        <FormControlLabel
                          control={
                            <CustomSwitch
                              checked={workspaceCountMap[eachSpaceType] === 0}
                              // onChange={handleChange}
                              onChange={handleTableDisableSwitch}
                              name="disableCheckbox"
                              color="primary"
                            />
                          }
                          label="Disable All"
                        />
                      </div>
                      <MTableToolbar {...props} />

                      <Tooltip title="Export to CSV">
                        <IconButton
                          onClick={() =>
                            exportCustomCSV(props.columns, props.originalData)
                          }
                          size="large"
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ),
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: '',
                  },
                }}
              />
            </TabPanel>
          ))}
        </Paper>
      ) : null}
    </div>
  )
}

export default SpaceEnablement
