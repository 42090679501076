import ReactDOM from 'react-dom/client'
import { onError, reportWebVitals } from '@praxis/component-logging'
import App from './App'
// import * as serviceWorker from './serviceWorker'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError

// If you want to start measuring performance in your app, enable the reportWebVitals()
// function below. Learn more: https://praxis.prod.target.com/components/logging#quick-start-metrics
reportWebVitals()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

//Uncomment this before pushing to production
// serviceWorker.register()
// Register service worker to control making site work offline

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/pwa-examples/a2hs/sw.js').then(() => {
//     console.log('Service Worker Registered')
//   })
// }

// Code to handle install prompt on desktop
var deferredPrompt
let installButton = document.querySelector('.install-button')

window.addEventListener('beforeinstallprompt', function (e) {
  console.log('beforeinstallprompt Event fired')
  e.preventDefault()

  // Stash the event so it can be triggered later.
  deferredPrompt = e

  return false
})

installButton.addEventListener('click', function () {
  console.log('install clicked')
  console.log(deferredPrompt)
  if (deferredPrompt !== undefined) {
    // The user has had a postive interaction with our app and Chrome
    // has tried to prompt previously, so let's show the prompt.
    deferredPrompt.prompt()

    // Follow what the user has done with the prompt.
    deferredPrompt.userChoice.then(function (choiceResult) {
      console.log(choiceResult.outcome)

      if (choiceResult.outcome === 'dismissed') {
        console.log('User cancelled home screen install')
      } else {
        console.log('User added to home screen')
      }

      // We no longer need the prompt.  Clear it up.
      deferredPrompt = null
    })
  }
})
