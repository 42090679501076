import { forwardRef } from 'react'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import FirstPage from '@mui/icons-material/FirstPage'
import Pageview from '@mui/icons-material/Pageview'
import LastPage from '@mui/icons-material/LastPage'
import Clear from '@mui/icons-material/Clear'
import Edit from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import ViewColumn from '@mui/icons-material/ViewColumn'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import momentTz from 'moment-timezone'
import moment from 'moment'
import axios from 'axios'
import { India, timezoneIndia } from './constants'
import { utcToZonedTime } from 'date-fns-tz'
import Search from '@mui/icons-material/Search'
import { CsvBuilder } from 'filefy'
export let dateOption = { year: 'numeric', month: '2-digit', day: '2-digit' }

export const LinkMap = {
  work: '/tii/work',
  'hi-tech': '/tii/appointment',
  permanent: '/tii/permanentDesk',
  common: '/tii/common',
}

export const DeclarationBackButton = {
  work: '/tii/food&transport',
  'hi-tech': '/tii/appointment',
  permanent: '/tii/food&transport',
  common: '/tii/food&transport',
}

export const FoodTransportBackButton = {
  work: '/tii/desk',
  permanent: '/tii/permanentDesk',
  common: '/tii/common',
}

export const isSameDay = (d1, d2) => {
  if (!d1 || !d2) {
    return false
  }
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  )
}

const clone = (d) => {
  return new Date(d.getTime())
}

export function isDayAfter(d1, d2) {
  const day1 = clone(d1).setHours(0, 0, 0, 0)
  const day2 = clone(d2).setHours(0, 0, 0, 0)
  return day1 > day2
}

export const addDays = (days) => {
  // let date = new Date()
  // date.setDate(date.getDate() + days)
  // return date
  return moment().add(days, 'days')
}

export const subtractDays = (days) => {
  // let date = new Date()
  // date.setDate(date.getDate() - days)
  // return date
  return moment().subtract(days, 'days')
}

export const getDates = (startDate, endDate) => {
  let dateArray = []
  let currentDate = moment(startDate)
  let stopDate = moment(endDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('MM/DD/YYYY'))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export const isTodayEnabled = (locationTimezone, cutoffTime) => {
  let currentTime =
    locationTimezone !== ''
      ? momentTz().tz(locationTimezone).format('HH:mm')
      : moment().format('HH:mm')

  return currentTime < cutoffTime
}

export const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Pageview: forwardRef((props, ref) => <Pageview {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  // Search: forwardRef((props, ref) => null),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  DeletePrimary: forwardRef((props, ref) => (
    <DeleteIcon {...props} ref={ref} color="primary" />
  )),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <CloudDownloadIcon {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
}

export const TimeConfig = {
  minBookingTime: 4,
  startHour: 6,
  endHour: 18,
  minBookingDate: 0,
  maxBookingDays: 14,
  interval: 30,
  dayEndTime:
    localStorage.getItem('base_location') === India ? '20:00' : '18:00',
}
export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export function LoadedTabPanel(props) {
  const { children, index, value, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      // eslint-disable-next-line eqeqeq
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
      {/* }  */}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const CreateTable = ({ row }) => (
  <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>building_id</TableCell>
          <TableCell align="right">floor_id</TableCell>
          <TableCell align="right">space_id</TableCell>
          <TableCell align="right">space_name</TableCell>
          <TableCell align="right">allocated_name</TableCell>
          <TableCell align="right">employee_id</TableCell>
          <TableCell align="right">manager_name</TableCell>
          <TableCell align="right">pyramid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={row.floor_id}>
          <TableCell component="th" scope="row">
            {row.building_id}
          </TableCell>
          <TableCell align="right">{row.floor_id}</TableCell>
          <TableCell align="right">{row.space_id}</TableCell>
          <TableCell align="right">{row.space_name}</TableCell>
          <TableCell align="right">{row.allocated_name}</TableCell>
          <TableCell align="right">{row.employee_id}</TableCell>
          <TableCell align="right">{row.manager_name}</TableCell>
          <TableCell align="right">{row.pyramid}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)

export async function checkIfReservationAlreadyExists(
  url,
  reservedFor,
  payload,
  location,
) {
  const config = {
    headers: {
      Authorization: localStorage.access_token,
    },
  }

  let finalURL = url + `reserved_for=${reservedFor}&location=${location}`

  return axios
    .post(finalURL, payload, config)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data)
      } else {
        throw new Error('Unable to check if reservation already exists')
      }
    })
    .catch((err) => console.log(err))
}

export const convertInHoursFormat = (value) => {
  let spaceSplit = value.split(' ') //['6:00','AM']
  let colonSplit = spaceSplit[0].split(':') //['6','00']
  let finalValue

  if (spaceSplit[1] === 'PM') {
    finalValue =
      Number(colonSplit[0]) === 12
        ? 12 + ':' + colonSplit[1]
        : Number(colonSplit[0]) + 12 + ':' + colonSplit[1]
  } else {
    finalValue =
      colonSplit[0].length === 1 ? '0' + spaceSplit[0] : spaceSplit[0]
  }
  return finalValue
}

export const convertInHoursFormatWithoutZeroPrefix = (value) => {
  let spaceSplit = value.split(' ') //['6:00','AM']
  let colonSplit = spaceSplit[0].split(':') //['6','00']
  let finalValue

  if (spaceSplit[1] === 'PM') {
    finalValue =
      Number(colonSplit[0]) === 12
        ? 12 + ':' + colonSplit[1]
        : Number(colonSplit[0]) + 12 + ':' + colonSplit[1]
  } else {
    finalValue = spaceSplit[0]
  }
  return finalValue
}

export const convertAMPMFromHours = (time) => {
  //time= "13:00"
  let finalValue = ''
  if (time) {
    let colonSplit = time.split(':') //['13','00']
    let timeInNumber = Number(colonSplit[0])
    finalValue =
      timeInNumber > 12
        ? timeInNumber - 12 + `:${colonSplit[1]} PM`
        : timeInNumber === 12
        ? timeInNumber + `:${colonSplit[1]} PM`
        : timeInNumber + `:${colonSplit[1]} AM`
  }
  return finalValue
}

export const findNearestInterval = (currentTime, config) => {
  let splitTime = currentTime.split(':')
  let temp = 60 / config.interval //4
  let i = 1
  for (i; i <= temp; i++) {
    let minutes = i * config.interval
    if (minutes > Number(splitTime[1])) {
      break
    }
  }
  let min = i * config.interval
  let finalMinutes = min.toString().length === 1 ? '0' + min : min
  let value =
    finalMinutes === 60
      ? Number(splitTime[0]) + 1
      : splitTime[0] + ':' + finalMinutes
  return value
}

export const findNearestOfficeTimings = (currentTime, config) => {
  let timings = config.officeTimings
  let officeTimingsAvailable = timings.filter(
    (timing) => currentTime < convertInHoursFormat(timing.split('-')[0]),
  )
  return officeTimingsAvailable
}

export const toastConfigure = {
  duration: 5000,
  style: {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  },
}

const byString = (o, s) => {
  if (!s) {
    return
  }

  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  var a = s.split('.')
  for (var i = 0, n = a.length; i < n; ++i) {
    var x = a[i]
    if (o && x in o) {
      o = o[x]
    } else {
      return
    }
  }
  return o
}

const getFieldValue = (rowData, columnDef, lookup = true) => {
  let value
  if (columnDef.field === 'space_enabled') {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field] === true
          ? 'Enabled'
          : 'Disabled'
        : byString(rowData, columnDef.field)
  } else if (columnDef.field === 'reservation_dates') {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field].join(',')
        : byString(rowData, columnDef.field)
  } else {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  }
  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }
  return value
}

export const getTableData = (allColumns, allData) => {
  const columns = allColumns
    .filter(
      (columnDef) =>
        // (!columnDef.hidden || columnDef.export === true) &&
        columnDef.export !== false && columnDef.field,
    )
    .sort((a, b) =>
      a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1,
    )
  const data = allData.map((rowData) =>
    columns.map((columnDef) => getFieldValue(rowData, columnDef)),
  )

  return [columns, data]
}

const getDashboardFieldValue = (rowData, columnDef, lookup = true) => {
  let value
  if (columnDef.field === 'timing') {
    value =
      typeof rowData['start_time'] !== 'object'
        ? convertAMPMFromHours(rowData['start_time']) +
          '-' +
          convertAMPMFromHours(rowData['end_time'])
        : byString(rowData, columnDef.field)
  } else if (columnDef.field === 'type') {
    value = typeof rowData['start_time'] !== 'object' ? 'Partial' : 'Full'
  } else if (columnDef.field === 'status') {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field] === 'Inactive'
          ? 'Cancelled'
          : rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  } else if (columnDef.field === 'reservation_date') {
    value = moment(rowData[columnDef.field]).format('MM-DD-YYYY')
  } else {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  }
  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }
  return value
}

const getDashboardIndiaFieldValue = (rowData, columnDef, lookup = true) => {
  let value
  if (columnDef.field === 'start_time' || columnDef.field === 'end_time') {
    value =
      typeof rowData[columnDef.field] !== 'object'
        ? convertAMPMFromHours(rowData[columnDef.field].split(' ')[1])
        : byString(rowData, columnDef.field)
  } else if (columnDef.field === 'reservation_date') {
    value = columnDef.emptyValue
  } else {
    value =
      typeof rowData[columnDef.field] !== 'undefined'
        ? rowData[columnDef.field]
        : byString(rowData, columnDef.field)
  }
  if (columnDef.lookup && lookup) {
    value = columnDef.lookup[value]
  }
  return value
}

export const getDashboardTableData = (allColumns, allData) => {
  const columns = allColumns
    .filter(
      (columnDef) =>
        // (!columnDef.hidden || columnDef.export === true) &&
        columnDef.export !== false && columnDef.field,
    )
    .sort((a, b) =>
      a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1,
    )
  const data = allData.map((rowData) =>
    columns.map((columnDef) => getDashboardFieldValue(rowData, columnDef)),
  )

  return [columns, data]
}

export const getDashboardIndiaTableData = (allColumns, allData) => {
  const columns = allColumns
    .filter(
      (columnDef) =>
        // (!columnDef.hidden || columnDef.export === true) &&
        columnDef.export !== false && columnDef.field,
    )
    .sort((a, b) =>
      a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1,
    )
  const data = allData.map((rowData) =>
    columns.map((columnDef) => getDashboardIndiaFieldValue(rowData, columnDef)),
  )

  return [columns, data]
}

export const getPermanentUserPayload = (data) => {
  let payload = {}
  payload['space_id'] = data.space_id
  payload['building_name'] = data.building_name
  payload['building_id'] = data.building_id
  payload['floor_id'] = data.floor_id
  payload['workspace_type'] = data.space_type
  return payload
}

export async function getCompensation(env, location, userId) {
  const url = env.apiUrl[location].getUserCompensation + userId

  return axios
    .get(url, {
      headers: { Authorization: localStorage.access_token },
    })
    .then((res) => {
      const data = res.data[0].worker
      let returnData = {
        level: data.compensation?.level,
        is_manager: data.identity?.is_manager,
        job_code: data.job?.job_code,
      }
      return Promise.resolve(returnData)
    })
    .catch((error) => {
      console.error(error)
    })
}

export async function getBusinessUnit(env, location, userId) {
  const url = env.apiUrl[location].getUserCompensation + userId

  return axios
    .get(url, {
      headers: { Authorization: localStorage.access_token },
    })
    .then((res) => {
      const data = res.data[0].worker
      return Promise.resolve(data.organization)
    })
    .catch((error) => {
      console.error(error)
    })
}

export const findNextWorkingDay = (daysOfWeek, holidaysList) => {
  let nextDay = new Date()
  nextDay.setDate(nextDay.getDate() + 1)

  while (
    daysOfWeek.includes(nextDay.getDay()) ||
    holidaysList.includes(moment(nextDay).format('YYYY-MM-DD'))
  ) {
    nextDay.setDate(nextDay.getDate() + 1)
  }
  return nextDay
}

export const findLastWorkingDay = (daysOfWeek, holidaysList) => {
  let today = new Date()
  const lastDay = utcToZonedTime(today, timezoneIndia)

  while (
    daysOfWeek.includes(lastDay.getDay()) ||
    holidaysList.includes(moment(lastDay).format('YYYY-MM-DD'))
  ) {
    lastDay.setDate(lastDay.getDate() - 1)
  }
  return lastDay
}

export const todayInIndia = () => {
  let today = new Date()
  const todayZoned = utcToZonedTime(today, timezoneIndia)
  return todayZoned
}
export const exportCustomCSV = (allColumns, allData, fileName) => {
  const [columns, data] = getDashboardIndiaTableData(allColumns, allData)

  const builder = new CsvBuilder(fileName + '.csv')
  builder
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile()
}
