import makeStyles from '@mui/styles/makeStyles'

export const navBarStyle = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
  },
  bar: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  feedbackButton: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  envBadge: {
    color: 'white !important',
    backgroundColor: '#660000 !important',
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
  },
  offset: theme.mixins.toolbar,
}))
