import makeStyles from '@mui/styles/makeStyles'

export const FloorStyles = makeStyles((theme) => ({
  avatarStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    minWidth: 275,
    // border: '2px solid #AC0000',
    borderRadius: '15px',
    textAlign: 'center',
    padding: '2%',
  },
  root: {
    paddingTop: '5%',
    height: '100vh',
    // backgroundColor: theme.palette.secondary.main,
    padding: '1%',
  },
  btn: {
    // margin: '2%',
  },
  listItem: {
    border: '1px solid #BFBFBF',
    borderRadius: '10px',
    marginTop: '5px',
    backgroundColor: '#f7f8fa',
  },
  inline: {
    display: 'inline',
  },
  center: {
    textAlign: 'center',
  },
  list: {
    marginTop: '-10px',
  },
  container: {
    fontWeight: '520',
    marginLeft: '30%',
    justifyContent: 'center',

    [`${theme.breakpoints.up('sm')} and (orientation: portrait)`]: {
      justifyContent: 'center',
      marginLeft: '25%',
    },

    [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
      justifyContent: 'center',
      marginLeft: '5%',
    },
  },
}))
