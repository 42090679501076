import makeStyles from '@mui/styles/makeStyles'

export const SideDrawerStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '330',
  },
  outerGrid: {
    height: '50px',
    marginBottom: '10px',
    marginTop: '30px',
  },
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
  imageLogo: { height: '50px' },
  innerGrid: {
    marginTop: '35px',
    marginLeft: '10px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  installBanner: {
    backgroundColor: theme.palette.background.paper,
  },
}))
