import { useState, useEffect } from 'react'
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@mui/material'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { India, USA } from '../../components/common/constants'
import moment from 'moment'
import WarningIcon from '@mui/icons-material/Warning'
import { toast } from 'react-hot-toast'
import MaterialTable from '@material-table/core'
import {
  exportCustomCSV,
  tableIcons,
} from '../../components/common/commonFunctionalities'
import { Checkbox } from '@mui/material'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { useAuth } from '@praxis/component-auth'
import { CircularProgress } from '@mui/material'

const HomeBaseSpaceStatus = () => {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const [buildings, setBuildings] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState('')
  const [selectedFloor, setSelectedFloor] = useState('')
  const [tableData, setTableData] = useState([])
  const [selectedSpaces, setSelectedSpaces] = useState([])
  const [loadingPermanent, setLoadingPermanent] = useState(false)
  const [loadingReservable, setLoadingReservable] = useState(false)

  const [reservableButtonDisabled, setReservableButtonDisabled] =
    useState(false)
  const [permanentButtonDisabled, setPermanentButtonDisabled] = useState(false)
  const [spaceCounts, setSpaceCounts] = useState({
    reservable: 0,
    permanent: 0,
  })

  const fetchBuildingAndFloorData = () => {
    const url = env.apiUrl[USA].getBuildingsandFloorsForHB
    axios
      .get(url)
      .then((response) => {
        setBuildings(response.data)
      })
      .catch((error) => {
        console.error('Error fetching building and floor data:', error)
      })
  }

  useEffect(() => {
    fetchBuildingAndFloorData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedFloor) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloor])

  useEffect(() => {
    updateSpaceCounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  useEffect(() => {
    updateButtonState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpaces])

  const handleBuildingChange = (event) => {
    setSelectedBuilding(event.target.value)
    setSelectedFloor('')
  }

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value)
  }

  const getFloorNames = () => {
    const building = buildings.find(
      (building) => building.building_details.building_id === selectedBuilding,
    )
    return building ? building.floor_details : []
  }

  const loadData = () => {
    const url = env.apiUrl[India].downloadExcelJSON
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    const payload = {
      building_id: selectedBuilding,
      floor_id: selectedFloor?.split(':')[0],
    }
    axios.post(url, payload, config).then((res) => {
      if (res.data.length === 0) {
        toast(
          'No data to download. Please enable desks before downloading it.',
          {
            icon: <WarningIcon style={{ color: 'yellow' }} />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          },
        )
      } else {
        const filteredData = res.data.map((item) => {
          return {
            building_id: item.building_id,
            floor_id: item.floor_id,
            space_id: item.space_id,
            space_name: item.space_name,
            space_status: item.space_status,
            floor_name: item.floor_name,
          }
        })
        setTableData(filteredData)
      }
    })
  }

  const handleSpaceSelection = (event, rowData) => {
    if (event.target.checked) {
      // Check if there are any selected spaces with a different status
      if (
        selectedSpaces.length > 0 &&
        selectedSpaces[0].space_status !== rowData.space_status
      ) {
        toast.error(
          'You can only select spaces with the same status.',
          toastConfigure,
        )
        return
      }
      setSelectedSpaces((prevState) => [...prevState, rowData])
    } else {
      setSelectedSpaces((prevState) =>
        prevState.filter(
          (space) =>
            space.building_id !== rowData.building_id ||
            space.floor_id !== rowData.floor_id ||
            space.space_id !== rowData.space_id,
        ),
      )
    }
    updateButtonState()
  }

  const updateButtonState = () => {
    let hasReservable = false
    let hasPermanent = false

    selectedSpaces.forEach((space) => {
      if (space.space_status === 'Reservable') {
        hasReservable = true
      } else if (space.space_status === 'Permanent') {
        hasPermanent = true
      }
    })

    setReservableButtonDisabled(!hasPermanent)
    setPermanentButtonDisabled(!hasReservable)
  }

  const changeSpaceStatus = (spaceStatus) => {
    if (spaceStatus === 'Permanent') {
      setLoadingPermanent(true)
    } else {
      setLoadingReservable(true)
    }
    const url =
      env.apiUrl[USA].updateHomebaseSpaceStatus +
      `space_status=${spaceStatus}&update_username=${
        session.userInfo.email.split('@')[0]
      }`

    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }

    const payload = selectedSpaces.map((space) => ({
      building_id: space.building_id,
      floor_id: space.floor_id,
      space_id: space.space_id,
      space_name: space.space_name,
    }))

    axios
      .put(url, payload, config)
      .then((response) => {
        if (response.data === 'Space status updated successfully.') {
          toast.success('Space status updated successfully', toastConfigure)
        } else if (
          response.data ===
          'There is an issue in changing the space status. Please check your mail for the details.'
        ) {
          toast.error(
            'There is an issue in changing the space status. Please check your mail for the details.',
            toastConfigure,
          )
        } else if (
          response.data ===
          'Space status updated successfully except for some records. Please check your mail for the records having an issue.'
        ) {
          toast.success(
            'Space status updated successfully except for some records. Please check your mail for the records having an issue.',
            toastConfigure,
          )
        }
        setSelectedSpaces([])
        loadData()
        setLoadingPermanent(false)
        setLoadingReservable(false)
      })
      .catch((error) => {
        console.error('Error updating space status:', error)
        setLoadingPermanent(false)
        setLoadingReservable(false)
      })
  }

  const updateSpaceCounts = () => {
    const counts = tableData.reduce(
      (acc, space) => {
        if (space.space_status === 'Reservable') {
          acc.reservable += 1
        } else if (space.space_status === 'Permanent') {
          acc.permanent += 1
        }
        return acc
      },
      { reservable: 0, permanent: 0 },
    )

    setSpaceCounts(counts)
  }

  return (
    <Container maxWidth={false} style={{ paddingTop: '2rem' }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Building ID</InputLabel>
                  <Select
                    value={selectedBuilding}
                    onChange={handleBuildingChange}
                    label="Building ID"
                  >
                    {buildings.map((building) => (
                      <MenuItem
                        key={building.building_details.building_id}
                        value={building.building_details.building_id}
                      >
                        {building.building_details.building_id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Floor Name</InputLabel>
                  <Select
                    value={selectedFloor}
                    onChange={handleFloorChange}
                    label="Floor Name"
                    disabled={!selectedBuilding}
                  >
                    {getFloorNames().map((floor) => (
                      <MenuItem
                        key={floor.floor_id}
                        value={`${floor.floor_id}:${floor.floor_name}`}
                      >
                        {floor.floor_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {tableData.length > 0 && (
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <div>
                <strong>Reservable Spaces:</strong> {spaceCounts.reservable}{' '}
                <strong>Permanent Spaces:</strong> {spaceCounts.permanent}
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => changeSpaceStatus('Permanent')}
                  style={{ marginRight: '1rem' }}
                  disabled={permanentButtonDisabled || loadingPermanent}
                >
                  {loadingPermanent ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Make it Permanent'
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => changeSpaceStatus('Reservable')}
                  disabled={reservableButtonDisabled || loadingReservable}
                >
                  {loadingReservable ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Make it Reservable'
                  )}
                </Button>
              </div>
            </div>
            <MaterialTable
              title="Space Data"
              columns={[
                {
                  title: '',
                  render: (rowData) => (
                    <Checkbox
                      checked={selectedSpaces.some(
                        (space) =>
                          space.building_id === rowData.building_id &&
                          space.floor_id === rowData.floor_id &&
                          space.space_id === rowData.space_id,
                      )}
                      onChange={(event) => handleSpaceSelection(event, rowData)}
                      style={{
                        color: selectedSpaces.some(
                          (space) =>
                            space.building_id === rowData.building_id &&
                            space.floor_id === rowData.floor_id &&
                            space.space_id === rowData.space_id,
                        )
                          ? 'maroon'
                          : 'inherit',
                      }}
                    />
                  ),
                  sorting: false,
                  grouping: false,
                  filtering: false,
                },
                { title: 'Building ID', field: 'building_id' },
                { title: 'Floor ID', field: 'floor_id', hidden: true },
                { title: 'Floor Name', field: 'floor_name' },
                { title: 'Space ID', field: 'space_id' },
                { title: 'Space Name', field: 'space_name' },
                { title: 'Space Status', field: 'space_status' },
              ]}
              data={tableData}
              icons={tableIcons}
              options={{
                exportButton: true,
                exportAllData: true,
                search: true,
                pageSizeOptions: [10, 25, 50, 100, 200],
                loadingType: 'overlay',
                pageSize: 200,
                headerStyle: {
                  position: 'sticky',
                  top: 0,
                  // backgroundColor: 'rgb(231, 231, 231)',
                  backgroundColor: 'lightgrey',
                  height: '50px',
                },
                maxBodyHeight: '60vh',
                emptyRowsWhenPaging: false,
                exportMenu: [
                  {
                    label: 'Export CSV',
                    exportFunc: (cols, datas) =>
                      exportCustomCSV(
                        cols,
                        datas,
                        `${selectedBuilding}-${
                          selectedFloor?.split(':')[1]
                        }-${moment().format('MMMM Do YYYY')}`,
                      ),
                  },
                ],
              }}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default HomeBaseSpaceStatus
