import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

export const SpaceEnablementStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: `${theme.spacing(1)} !important`,
      minWidth: '157px !important',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 650,
      border: '4px double #c2185b',
    },
    typography: {
      fontSize: 18,
    },
    enableAllButton: {
      backgroundColor: '#008000',
      '&:hover': {
        backgroundColor: '#008000',
      },
    },
    disableAllButton: {
      backgroundColor: '#FF0000',
      '&:hover': {
        backgroundColor: '#FF0000',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: 'bold',
    },
    responsiveIframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      border: 'none',
    },
    container: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      paddingTop: '56.25%' /* 16:9 Aspect Ratio */,
    },
    content: {
      flexGrow: 0,
    },
    accordianBorder: {
      border: `1px solid rgba(0, 0, 0, 0.23)`,
    },
    success: {
      color: `theme.palette.success.light !important`,
      // color: 'white',
      fontWeight: 600,
      border: `1px solid ${theme.palette.success.light} !important`,
    },
    booked: {
      color: `theme.palette.error.light !important`,
      // color: 'white',
      fontWeight: 600,
      border: `1px solid ${theme.palette.error.light} !important`,
    },
    actionSuccess: {
      backgroundColor: theme.palette.success.main,
      color: 'white',
      fontWeight: 600,
    },
    actionBooked: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
      fontWeight: 600,
    },
    enabledCount: {
      // color: '#AC0000',
      // fontWeight: 600,
      // border: '1px solid #AC0000',
    },
    disabledCount: {
      color: 'grey',
      fontWeight: 'bolder',
      border: '1px solid grey',
    },
    iconHome: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    toolbarStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
)
