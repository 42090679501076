import Grid from '@mui/material/Grid'
import { useAuth } from '@praxis/component-auth'
import { Typography, Paper } from '@mui/material'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import Avatar from '@mui/material/Avatar'
import { useDispatch, useSelector } from 'react-redux'
// import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import { LandingPageStyles } from '../../styles/landingPageStyle'
import {
  getCompensation,
  getBusinessUnit,
} from '../../components/common/commonFunctionalities'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import DevicesIcon from '@mui/icons-material/Devices'

export const LandingPage = () => {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const constants = env.constants['US']
  const classes = LandingPageStyles()
  //const [thumbnail, setThumbnail] = React.useState('')
  const dispatch = useDispatch()

  const userCompensation = useSelector(
    (state) => state.common.userCompensationUS,
  )
  const userProfileInfo = useSelector((state) => state.common.userProfile)
  const businessUnit = useSelector((state) => state.common.userBusinessUnit)
  const location = useSelector((state) => state.common.location)

  useEffect(() => {
    if (!userProfileInfo) {
      const profileUrl =
        env.apiUrl[location].userProfile +
        session.userInfo.lanId +
        `&emp_id=${session.identity.employeeid}&location=${location}`

      axios
        .get(profileUrl, {
          headers: { Authorization: localStorage.access_token },
        })
        .then((res) => {
          setTimeout(() => {
            //setProfile(false)
          }, 2000)
          dispatch({ type: 'SAVE_USER_PROFILE', payload: res.data })
        })
        .catch((error) => {
          console.error(error)
          //setProfile(false)
        })
    }
    if (!userCompensation) {
      getCompensation(env, location, session.userInfo.lanId)
        .then((data) => {
          if (data && 'level' in data) {
            dispatch({
              type: 'SAVE_USER_COMPENSATION_US',
              payload: data,
            })
          }
        })
        .catch((err) => console.log(err))
    }
    if (!businessUnit) {
      getBusinessUnit(env, location, session.userInfo.lanId)
        .then((data) => {
          if (data && 'cost_center_id' in data) {
            dispatch({
              type: 'SAVE_USER_BUSINESS_UNIT',
              payload: data['cost_center_id'],
            })
          }
        })
        .catch((err) => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* <Grid container justify="flex-end">
        <Button
          variant="outlined"
          // size="large"
          startIcon={<RoomOutlinedIcon />}
          component={Link}
          to="/location"
          className={classes.top}
        >
          {location}
        </Button>
      </Grid> */}
      <br />
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={6}
        xs={12}
        className={classes.grid}
      >
        <Grid item>
          <Avatar
            // alt={session.userInfo.fullName.charAt(0)}
            //src={thumbnail}
            className={classes.large}
          />
        </Grid>
        <Grid item>
          <Typography>
            {' '}
            Welcome{' '}
            <b>
              <i>{session.userInfo.fullName}</i>
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            style={{ width: 300 }}
            variant="contained"
            color="primary"
            component={Link}
            to="/tm/step1"
            onClick={() => {
              dispatch({ type: 'RESERVING_FOR', payload: false })
              dispatch({ type: 'CLEAR_RESERVATION_DETAILS', payload: {} })
            }}
          >
            Reserve Workspace
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ width: 300 }}
            variant="contained"
            color="primary"
            component={Link}
            to="/tm/viewreservations"
          >
            View Reservations
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ width: 300 }}
            variant="contained"
            color="primary"
            component={Link}
            to="/tm/step1"
            onClick={() => {
              dispatch({ type: 'RESERVING_FOR', payload: true })
              dispatch({ type: 'CLEAR_RESERVATION_DETAILS', payload: {} })
            }}
          >
            Reserve for others
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ width: 300 }}
            variant="contained"
            color="primary"
            component={Link}
            to="/tm/groupbooking"
          >
            Group Booking
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{ width: 300 }}
            variant="outlined"
            color="primary"
            component={Link}
            to="/tm/servicerequest"
          >
            Submit a Building Issue?
          </Button>
        </Grid>
        {constants.showMaintenanceMsg && (
          <Grid item className={classes.noteRoot}>
            <Paper className={classes.noteParent}>
              <Typography variant="body1" className={classes.noteChild}>
                <b>
                  <u> Note</u>: {constants.maintenanceMsg}
                </b>
              </Typography>
            </Paper>
          </Grid>
        )}

        <Grid container className={classes.anchorBody}>
          <Grid item className={classes.otherApps}>
            <Typography variant="body1" style={{ marginTop: 10 }}>
              <b>Explore other apps:</b>
            </Typography>

            <a
              rel="noreferrer"
              href="https://target.cafebonappetit.com/"
              target="_blank"
              className={classes.anchorLink}
            >
              <div className="" padding="dense" style={{ padding: 10 }}>
                Cafe Bon App
              </div>
              <div>
                <FastfoodIcon fontSize="medium" style={{ marginTop: 5 }} />
              </div>
            </a>
            <a
              rel="noreferrer"
              href="https://target.hungerbox.com"
              target="_blank"
              className={classes.anchorLink}
            >
              <div className="" padding="dense" style={{ padding: 10 }}>
                HungerBox
              </div>
              <div>
                <FastfoodIcon fontSize="medium" style={{ marginTop: 5 }} />
              </div>
            </a>

            <a
              rel="noreferrer"
              href="https://target.safetrax.in"
              target="_blank"
              className={classes.anchorLink}
            >
              <div className="" padding="dense" style={{ padding: 10 }}>
                TII Transport
              </div>
              <div>
                <AirportShuttleIcon
                  fontSize="medium"
                  style={{ marginTop: 10 }}
                />
              </div>
            </a>
            <a
              rel="noreferrer"
              href="https://targetonline.sharepoint.com/sites/HiTech"
              target="_blank"
              className={classes.anchorLink}
            >
              <div className="" padding="dense" style={{ padding: 10 }}>
                Missing Hardware?
              </div>
              <div>
                <DevicesIcon fontSize="medium" style={{ marginTop: 10 }} />
              </div>
            </a>
          </Grid>
        </Grid>

        {/* <Grid item className={classes.noteRoot}>
          <Paper className={classes.noteParent}>
            <div
              role="img"
              aria-label={'medical mask icon'}
              className={clsx(classes.noteChild, classes.emoji)}
            >
              {String.fromCodePoint('0x1F637')}
            </div>
            &nbsp;
            <Typography variant="body1" className={classes.noteChild}>
              <b>
                <i>
                  Masks are required in all common areas including Reservable
                  Workstations.
                </i>
              </b>
            </Typography>
          </Paper>
        </Grid> */}
      </Grid>
    </>
  )
}
