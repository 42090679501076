const extractDesks = (
  dxfData,
  spaceLayerList,
  width = 1500,
  height = 800,
  scalingWidth = 1,
  scalingHeight = 1,
) => {
  var yMax = Number.MIN_SAFE_INTEGER
  var xMax = Number.MIN_SAFE_INTEGER
  var yMin = Number.MAX_SAFE_INTEGER
  var xMin = Number.MAX_SAFE_INTEGER
  var polylines = dxfData.entities.filter(function (el) {
    return el.type === 'LWPOLYLINE'
  })
  // capture the boundary endpoints from the tri measured gross area layer
  polylines.forEach((ele) => {
    if (ele.layer === 'triMeasuredGrossAreaLayer') {
      ele.vertices.forEach((vertex) => {
        if (vertex.x > xMax) {
          xMax = vertex.x
        }
        if (vertex.y > yMax) {
          yMax = vertex.y
        }
        if (vertex.x < xMin) {
          xMin = vertex.x
        }
        if (vertex.y < yMin) {
          yMin = vertex.y
        }
      })
    }
  })
  // filter out only text layers corresponding to 01_Dedicated Open Workstation layer name
  var layerList = spaceLayerList
  var allWorkStations = dxfData.entities.filter(function (el) {
    return layerList.includes(el.layer) && el.type === 'TEXT'
  })

  // transform the coordinates to fit in the png image coordinates and invert y axis for conva
  var allPoints = allWorkStations.map(function (ele) {
    return {
      x: (ele.startPoint.x - xMin) * (width / (xMax - xMin)) * scalingWidth,
      y:
        (height - (ele.startPoint.y - yMin) * (height / (yMax - yMin))) *
        scalingHeight,
      space_id: ele.text,
      current_use_space_class: ele.layer,
      color: 'red',
    }
  })
  return allPoints
}
export default extractDesks
