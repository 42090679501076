import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

export const ViewReservationStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      padding: '0px !important',
      textAlign: 'center',
    },
    contentDialog: {
      // padding: 4,
      // paddingTop: 8,
      fontWeight: '540',
      color: 'black',
    },
    cancelBanner: {
      height: '5rem',
      // backgroundColor: '#f08080',
      textAlign: 'center',
      alignItems: 'center',
      paddingTop: '3%',
    },
  }),
)
