import makeStyles from '@mui/styles/makeStyles'

export const SuccessDialogStyle = makeStyles((theme) => ({
  dialogRoot: {
    padding: '0px !important',
    textAlign: 'center',
  },
  contentDialog: {
    // padding: 4,
    // paddingTop: 8,
    fontWeight: '540',
    color: 'black',
  },
  successBanner: {
    height: '5rem',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '3%',
  },
  okBtn: {
    margin: '0 auto',
    display: 'flex',
  },
}))
