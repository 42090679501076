import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { OverviewStyle } from '../../styles/overviewStyle'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

const COLORS_desk = ['#EC6B56', '#FFC154']
const COLORS_room = ['#03a9f4', '#66e2ca']

const Overview = ({ cardInfo, chartData, handleCardClick, selectedFloor }) => {
  const classes = OverviewStyle()
  return (
    <>
      <div className={classes.dashBoardCard}>
        {cardInfo &&
          cardInfo.length > 0 &&
          cardInfo.map((singleCard) => {
            return (
              <Card
                elevation={4}
                className={classes.root}
                onClick={(e) =>
                  handleCardClick(
                    e,
                    singleCard?.floor_id
                      ? `${singleCard?.floor_id}:${singleCard?.floor_name}`
                      : '',
                    {
                      name: singleCard.building_name,
                      id: singleCard.building_id,
                    },
                  )
                }
              >
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {singleCard.floor_name || singleCard.building_name}
                    {singleCard.floor_name
                      ? singleCard.floor_status !== ''
                        ? // eslint-disable-next-line no-useless-concat
                          ` ` + `-` + ` ` + singleCard.floor_status
                        : ' - Flex'
                      : ''}{' '}
                    {/* NC01 */}
                  </Typography>
                  <hr />
                  &nbsp;&nbsp;
                  <div style={{ display: 'flex' }}>
                    {singleCard && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '250px',
                            alignItems: 'center',
                          }}
                        >
                          <PieChart width={220} height={120}>
                            <Pie
                              dataKey="value"
                              data={singleCard.desk}
                              cx="50%"
                              cy="65%"
                              startAngle={180}
                              endAngle={0}
                              innerRadius={30}
                              outerRadius={60}
                              fill="#82ca9d"
                            >
                              {singleCard.desk.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS_desk[index % COLORS_desk.length]}
                                />
                              ))}
                            </Pie>

                            <Tooltip />
                          </PieChart>
                          <Typography color="textSecondary">
                            <div style={{ textAlign: 'center' }}>
                              {' '}
                              {singleCard.total_desks !== 0
                                ? `${(
                                    (singleCard.desk[0].value /
                                      singleCard.total_desks) *
                                    100
                                  ).toFixed(2)}`
                                : `0.00`}
                              % reserved
                            </div>
                            <br />
                            <div
                              style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                              }}
                            >
                              {/* <div
                                style={{
                                  height: 14,
                                  width: 14,
                                  margin: 4,
                                  border: '1px solid black',
                                }}
                              ></div> */}
                              Total Desk Capacity:{' '}
                              {singleCard.floor_status === 'Homebase'
                                ? singleCard.total_desks +
                                  singleCard.homebase_permanent_desks
                                : singleCard.total_desks}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  fontWeight: 'normal',
                                }}
                              >
                                <div
                                  style={{
                                    height: 14,
                                    width: 14,
                                    backgroundColor: COLORS_desk[1],
                                    margin: 4,
                                  }}
                                ></div>
                                Available Desks: {singleCard.desk[1].value}
                              </div>
                            </div>
                            <br />
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  height: 14,
                                  width: 14,
                                  backgroundColor: COLORS_desk[0],
                                  margin: 4,
                                }}
                              ></div>
                              Reserved: {singleCard.desk[0].value}
                              &nbsp;&nbsp;&nbsp;
                              {singleCard.floor_status === 'Homebase' && (
                                <div style={{ display: 'flex' }}>
                                  Permanent:{' '}
                                  {singleCard.homebase_permanent_desks}
                                </div>
                              )}
                            </div>
                          </Typography>
                        </div>
                        {singleCard['total_offices'] &&
                        singleCard.total_offices > 0 ? (
                          <>
                            &nbsp;&nbsp;
                            <hr />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                //marginLeft: '2%',
                                justifyItems: 'center',
                                width: '250px',
                                alignItems: 'center',
                              }}
                            >
                              <PieChart width={220} height={120}>
                                <Pie
                                  dataKey="value"
                                  data={singleCard.office}
                                  cx="50%"
                                  cy="65%"
                                  startAngle={180}
                                  endAngle={0}
                                  innerRadius={30}
                                  outerRadius={60}
                                  fill={COLORS_room[0]}
                                >
                                  {singleCard.office.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={
                                        COLORS_room[index % COLORS_room.length]
                                      }
                                    />
                                  ))}
                                </Pie>

                                <Tooltip />
                              </PieChart>
                              <Typography color="textSecondary">
                                <div style={{ textAlign: 'center' }}>
                                  {' '}
                                  {singleCard.total_offices !== 0
                                    ? `${(
                                        (singleCard.office[0].value /
                                          singleCard.total_offices) *
                                        100
                                      ).toFixed(2)}`
                                    : `0.00`}
                                  % reserved
                                </div>
                                <br />
                                <div
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                    }}
                                  ></div>
                                  Total Office Capacity:{' '}
                                  {singleCard.floor_status === 'Homebase'
                                    ? singleCard.total_offices +
                                      singleCard.homebase_permanent_offices
                                    : singleCard.total_offices}
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 14,
                                        width: 14,
                                        backgroundColor: COLORS_room[1],
                                        margin: 4,
                                      }}
                                    ></div>
                                    Available Offices:{' '}
                                    {singleCard.office[1].value}
                                  </div>
                                </div>
                                <br />

                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      height: 14,
                                      width: 14,
                                      backgroundColor: COLORS_room[0],
                                      margin: 4,
                                    }}
                                  ></div>
                                  Reserved: {singleCard.office[0].value}
                                  &nbsp;&nbsp;&nbsp;
                                  {singleCard.floor_status === 'Homebase' && (
                                    <div style={{ display: 'flex' }}>
                                      Permanent:{' '}
                                      {singleCard.homebase_permanent_offices}
                                    </div>
                                  )}
                                </div>
                              </Typography>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>
            )
          })}
      </div>
    </>
  )
}

export default Overview
