import { LandingPage } from '../pages/teamMember/teamMemberLandingPage'
import { LandingPage as InsightsLandingPage } from '../pages/insights/landingPage'
import UploadLandingPage from '../pages/cre/InteractiveFloorUpload'
import SpaceEnablement from '../pages/cre/spaceEnablement'
import { ReservationForm } from '../pages/teamMember/reservationForm'
import ViewReservations from '../pages/teamMember/viewReservationLandingPage'
import FloorSelection from '../pages/teamMember/floorSelection'
import DeskSelection from '../pages/teamMember/deskSelection'
// import { Questionnaire } from '../components/common/questionnaireIndia'
import { HouseKeepingLandingPage } from '../pages/housekeeping/housekeepingLandingPage'
import CleaningList from '../pages/housekeeping/housekeepingTabView'
import Dashboard from '../pages/cre/dashboard'
import SavePngDxfFile from '../pages/cre/savePNGDXFFile'
import SaveDxfFile from '../pages/cre/saveCADFile'
import Feedback from '../pages/cre/feedback'
// import { TIILandingPage } from '../pages/teamMember/india/tiiLandingPage'
import TIIExcelUpload from '../pages/cre/india/excelUpload'
// import ViewMultipleReservations from '../pages/teamMember/india/viewReservation'
// import AdminDashboard from '../pages/cre/india/adminDashboard'
import ViewCertificate from '../pages/contractor/viewCertificate'
import AdminDashboard from '../pages/cre/india/adminDashboardNew'
import Capacity from '../pages/cre/india/capacityMgmt'
import VaccinationDashboard from '../pages/contractor/vaccinationDashboard'
// import ViewContractorCertificate from '../pages/contractor/viewContractorCertificate'
import CADFileProcessing from '../pages/cre/fileProcessingCAD'
import SpaceAllocation from '../pages/cre/spaceAllocation'
import ModifySingleSpace from '../pages/cre/modifySingleSpace'
import SpaceHierarchy from '../pages/cre/spaceHierarchy'
import FloorHierarchy from '../pages/cre/floorHierarchy'
import RequestForm from '../pages/teamMember/requestForm'
import HomeBaseSpaceStatus from '../pages/cre/homebaseSpaceStatus'
import HomebaseMapping from '../pages/cre/homebaseMapping'
import GroupReservationForm from '../pages/teamMember/groupBooking'

const routesNew = [
  {
    path: '/tm/home',
    component: <LandingPage />,
  },
  {
    path: '/tm/groupbooking',
    component: <GroupReservationForm />,
  },
  {
    path: '/tm/step1',
    component: <ReservationForm />,
  },
  {
    path: '/tm/step2',
    component: <FloorSelection />,
  },
  {
    path: '/tm/step3',
    component: <DeskSelection />,
  },
  {
    path: '/tm/viewreservations',
    component: <ViewReservations />,
  },
  {
    path: '/cre/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/cre/spaceallocation',
    component: <SpaceAllocation />,
  },
  {
    path: '/cre/spacemaster',
    component: <SpaceHierarchy />,
  },
  {
    path: '/viewfloors',
    component: <FloorHierarchy />,
  },
  {
    path: '/update/mailstop',
    component: <ModifySingleSpace />,
  },
  {
    path: '/cre/homebasemapping',
    component: <HomebaseMapping />,
  },
  {
    path: '/cre/changespacestatus',
    component: <HomeBaseSpaceStatus />,
  },
  {
    path: '/insights',
    component: <InsightsLandingPage />,
  },
  {
    path: '/housekeeping/landing',
    component: <HouseKeepingLandingPage />,
  },
  {
    path: '/housekeeping/cleaninglist',
    component: <CleaningList />,
  },
  {
    path: '/cre/feedback',
    component: <Feedback />,
  },
  {
    path: '/cre/upload',
    component: <UploadLandingPage />,
  },
  {
    path: '/cre/cadProcess',
    component: <CADFileProcessing />,
  },
  {
    path: '/cre/savefiles',
    component: <SavePngDxfFile />,
  },
  {
    path: '/cre/saveCadFile',
    component: <SaveDxfFile />,
  },
  {
    path: '/cre/spaceenablement',
    component: <SpaceEnablement />,
  },

  {
    path: '/cre/excelupload',
    component: <TIIExcelUpload />,
  },
  {
    path: '/tm/servicerequest',
    component: <RequestForm />,
  },
  {
    path: '/cre/tii/dashboard',
    component: <AdminDashboard />,
  },
  {
    path: '/contractor/vaccination',
    component: <ViewCertificate />,
  },
  {
    path: '/cre/capacity',
    component: <Capacity />,
  },
  {
    path: '/cre/vaccinationDashboard',
    component: <VaccinationDashboard />,
  },
  // {
  //   path: '/cre/contractorCertificate/:userId',
  //   component: <ViewContractorCertificate />,
  // },
]

// const routes = {
//   USA: [
//     {
//       path: '/tm/home',
//       component: <LandingPage />,
//     },
//     {
//       path: '/tm/groupbooking',
//       component: <GroupReservationForm />,
//     },
//     {
//       path: '/tm/step1',
//       component: <ReservationForm />,
//     },
//     {
//       path: '/tm/step2',
//       component: <FloorSelection />,
//     },
//     {
//       path: '/tm/step3',
//       component: <DeskSelection />,
//     },
//     {
//       path: '/tm/viewreservations',
//       component: <ViewReservations />,
//     },
//     {
//       path: '/cre/dashboard',
//       component: <Dashboard />,
//     },
//     {
//       path: '/cre/spaceallocation',
//       component: <SpaceAllocation />,
//     },
//     {
//       path: '/cre/spacemaster',
//       component: <SpaceHierarchy />,
//     },
//     {
//       path: '/viewfloors',
//       component: <FloorHierarchy />,
//     },
//     {
//       path: '/update/mailstop',
//       component: <ModifySingleSpace />,
//     },
//     {
//       path: '/cre/homebasemapping',
//       component: <HomebaseMapping />,
//     },
//     {
//       path: '/cre/changespacestatus',
//       component: <HomeBaseSpaceStatus />,
//     },
//     {
//       path: '/insights',
//       component: <InsightsLandingPage />,
//     },
//   ],
//   India: [
//     // {
//     //   path: '/tii/purpose',
//     //   component: PurposeOfVisit,
//     // },
//     // {
//     //   path: '/tm/home',
//     //   component: LandingPage,
//     // },

//     {
//       path: '/contractor/vaccination',
//       component: <ViewCertificate />,
//     },
//     {
//       path: '/cre/capacity',
//       component: <Capacity />,
//     },
//     {
//       path: '/cre/vaccinationDashboard',
//       component: <VaccinationDashboard />,
//     },
//     {
//       path: '/cre/contractorCertificate/:userId',
//       component: <ViewContractorCertificate />,
//     },
//   ],
//   common: [
//     {
//       path: '/housekeeping/landing',
//       component: <HouseKeepingLandingPage />,
//     },
//     {
//       path: '/housekeeping/cleaninglist',
//       component: <CleaningList />,
//     },
//     {
//       path: '/cre/feedback',
//       component: <Feedback />,
//     },
//     {
//       path: '/cre/upload',
//       component: <UploadLandingPage />,
//     },
//     {
//       path: '/cre/cadProcess',
//       component: <CADFileProcessing />,
//     },
//     {
//       path: '/cre/savefiles',
//       component: <SavePngDxfFile />,
//     },
//     {
//       path: '/cre/saveCadFile',
//       component: <SaveDxfFile />,
//     },
//     {
//       path: '/cre/spaceenablement',
//       component: <SpaceEnablement />,
//     },

//     {
//       path: '/cre/excelupload',
//       component: <TIIExcelUpload />,
//     },
//     {
//       path: '/tm/servicerequest',
//       component: <RequestForm />,
//     },
//     {
//       path: '/cre/tii/dashboard',
//       component: <AdminDashboard />,
//     },
//     {
//       path: '/contractor/vaccination',
//       component: <ViewCertificate />,
//     },
//     {
//       path: '/cre/capacity',
//       component: <Capacity />,
//     },
//     {
//       path: '/cre/vaccinationDashboard',
//       component: <VaccinationDashboard />,
//     },
//     {
//       path: '/cre/contractorCertificate/:userId',
//       component: <ViewContractorCertificate />,
//     },
//   ],
// }

export default routesNew
// export const DetermineUserAccess = () => {
//   const auth = useAuth()
//   const { session, isAuthorized } = auth
//   const dispatch = useDispatch()
//   const commonDetails = useSelector((state) => state.common)
// console.log(auth)
//   // useEffect(() => {
//   //   console.log(isAuthorized())
//   //   if (isAuthorized()) {
//   //     let sessionLocation = USA

//   //     // if (savedLocation) {
//   //     //   sessionLocation = savedLocation
//   //     // } else {
//   //     //   sessionLocation = config.locationIndia.includes(
//   //     //     session.identity.locationnumber
//   //     //   )
//   //     //     ? 'India'
//   //     //     : 'USA'

//   //     //   localStorage.setItem('base_location', sessionLocation)
//   //     // }
//   //     dispatch({
//   //       type: 'SAVE_USER_LOCATION',
//   //       payload: sessionLocation,
//   //     })
//   //     dispatch({
//   //       type: 'SAVE_ROUTES',
//   //       payload: [...routes[sessionLocation], ...routes['common']],
//   //     })
//   //   }
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps
//   // }, [session])
// console.log([...routes[USA], ...routes['common']])
//   return (
//     <>
//     <Routes>
//     <Route element={<ProtectedElement />}>
//     {isAuthorized() && [...routes[USA], ...routes['common']].map((route) => (
//           // <ProtectedElement
//           //   exact
//           //   key={route.path}
//           //   path={route.path}
//           //   component={route.component}
//           // />
// <Route
// key={route.path}
//   path={route.path}
//   element={route.component}
// />
//         ))}
//         </Route>
//         </Routes>
//     </>
//   )
// }
