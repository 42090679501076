import makeStyles from '@mui/styles/makeStyles'

export const PurposeStyle = makeStyles((theme) => ({
  root: {
    marginTop: '4%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 310,
    maxWidth: 310,
  },
  cardRoot: {
    borderRadius: 16,
    padding: '1%',
    // minHeight: '40vh',
    overflow: 'visible',

    [theme.breakpoints.between('md', 'xl')]: {
      width: '70%',
    },
  },
  selectPurpose: {
    marginTop: 8,
  },
  loader: {
    marginRight: 16,
  },
  normalFields: {
    margin: theme.spacing(2),
    minWidth: 310,
    maxWidth: 380,
  },
  spaceHierarchySelect: {
    width: 380,
    margin: '4px 0px 4px 8px',
  },
  emailText: {
    padding: 8,
  },
}))
