import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useAuth } from '@praxis/component-auth'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import axios from 'axios'
import Avatar from '@mui/material/Avatar'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { HousekeepingStyles } from '../../styles/housekeepingStyle'
import { Link } from 'react-router-dom'
import EventIcon from '@mui/icons-material/Event'
import 'date-fns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import {
  addDays,
  subtractDays,
} from '../../components/common/commonFunctionalities'
import { useEnv } from '@praxis/component-runtime-env'

export const HouseKeepingLandingPage = () => {
  const config = {
    minBookingDate: -14,
    maxBookingDays: 14,
  }
  const env = useEnv()
  const dateConfig = env.constants

  const auth = useAuth()
  const { session } = auth
  const classes = HousekeepingStyles()
  const [thumbnail] = React.useState('')
  const dispatch = useDispatch()
  const housekeepingState = useSelector((state) => state.housekeeping)
  const allBuildings = useSelector((state) => state.housekeeping.allBuildings)

  useEffect(() => {
    const url = env.apiUrl['USA'].listHomebaseFlexFloors
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }

    axios
      .get(url, config)
      .then((res) => {
        const data = res.data.map((building) => ({
          building_details: building.building_details,
          floor_names: building.floor_details,
        }))

        // if (localStorage.getItem('base_location') === 'India') {
        //   dispatch({
        //     type: 'SAVE_HOUSEKEEPING_ALL_BUILDINGS',
        //     payload: data.filter(
        //       (item) => item.building_details.location === 'INDIA',
        //     ),
        //   })
        // } else {
        dispatch({
          type: 'SAVE_HOUSEKEEPING_ALL_BUILDINGS',
          payload: data.filter(
            (item) => item.building_details.location === 'US',
          ),
        })
        // }
      })
      .catch((error) => {
        console.error(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBuildingChange = (event) => {
    dispatch({
      type: 'SAVE_HOUSEKEEPING_BUILDING',
      payload: event.target.value,
    })

    const selectedBuilding = allBuildings.find(
      (bldng) => bldng.building_details.building_name === event.target.value,
    )

    dispatch({
      type: 'SAVE_HOUSEKEEPING_BUILDINGID',
      payload: selectedBuilding.building_details.building_id,
    })

    let floors = selectedBuilding.floor_names.sort()

    dispatch({ type: 'SAVE_HOUSEKEEPING_ALL_FLOORS', payload: floors })
    dispatch({ type: 'SAVE_HOUSEKEEPING_FLOOR', payload: '' })
  }

  const handleFloorChange = (event) => {
    const selectedValue = event.target.value
    const selectedFloorId = selectedValue.split(':')
    //const selectedFloorName = selectedValue.split(":")[1]
    dispatch({ type: 'SAVE_HOUSEKEEPING_FLOOR', payload: selectedFloorId[0] })
    dispatch({
      type: 'SAVE_HOUSEKEEPING_FLOOR_NAME',
      payload: selectedFloorId[1],
    })
  }

  const handleDateChange = (date) => {
    dispatch({
      type: 'SAVE_HOUSEKEEPING_CLEANING_DATE',
      payload: date,
    })
  }

  return (
    <>
      <br />
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={6}
        xs={12}
        className={classes.grid}
      >
        <Grid item>
          <Avatar src={thumbnail} className={classes.large} />
        </Grid>
        <Grid item>
          <Typography>
            {' '}
            Welcome{' '}
            <b>
              <i>{session.userInfo.fullName}</i>
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Building Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={housekeepingState.selectedBuilding}
              onChange={handleBuildingChange}
              label="Building Name"
            >
              <MenuItem disabled>--Choose Building--</MenuItem>
              {housekeepingState.allBuildings &&
                housekeepingState.allBuildings.map((building, key) => {
                  return (
                    <MenuItem
                      value={building.building_details.building_name}
                      key={key}
                    >
                      {building.building_details.building_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Floor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={`${housekeepingState.selectedFloor}:${housekeepingState.selectedFloorName}`}
              onChange={handleFloorChange}
              label="Floor Name"
            >
              <MenuItem disabled>--Choose Floor--</MenuItem>
              {housekeepingState.allFloors &&
                housekeepingState.allFloors.map((e, key) => {
                  return (
                    <MenuItem
                      value={`${e.floor_id}:${e.floor_name}`}
                      key={e.floor_id}
                    >
                      {e.floor_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <DatePicker
            // classes={{ root: classes.formControl }}
            className={classes.formControl}
            // disableToolbar
            // variant="inline"
            // inputVariant="outlined"
            format="MM/D/Y"
            // margin="normal"
            // id="date-picker-inline"
            label="Cleaning Date"
            name="cleaningdate"
            value={housekeepingState.cleaningDate}
            onChange={handleDateChange}
            minDate={subtractDays(dateConfig.creMinDate)}
            maxDate={addDays(dateConfig.maxBookingDays)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
            // className={classes.textField}
            // vari
            // autoOk={true}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            component={Link}
            to="/housekeeping/cleaninglist"
            disabled={
              housekeepingState.selectedBuilding === '' ||
              housekeepingState.selectedFloor === '' ||
              moment(housekeepingState.cleaningDate).format('YYYY-MM-DD') >
                moment()
                  .add(config.maxBookingDays, 'days')
                  .format('YYYY-MM-DD') ||
              moment(housekeepingState.cleaningDate).format('YYYY-MM-DD') <
                moment().add(config.minBookingDate, 'days').format('YYYY-MM-DD')
            }
          >
            Get Cleaning list
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
