import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  DialogActions,
  Button,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'

const AddBuildingDialog = ({
  locationData,
  loading,
  handleClose,
  open,
  newBuilding,
  setNewBuilding,
  classes,
  addNewBuilding,
  selectedLocation,
  dialogTitle,
  editBuilding,
}) => {
  const handleSRFCheckBox = (event, newValue) => {
    setNewBuilding({
      ...newBuilding,
      is_serviceable: newValue,
    })
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <form
        onSubmit={dialogTitle === 'Edit' ? editBuilding : addNewBuilding}
        name="building-form"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle} Building</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <Autocomplete
                id="location-autocomplete-building"
                className={classes.normalFields}
                options={locationData}
                //   loading={loading}
                // defaultValue={selectedLocation}
                value={
                  newBuilding.loc_no
                    ? {
                        location_no: newBuilding.loc_no,
                        location_name: newBuilding.loc_name,
                      }
                    : selectedLocation
                }
                required
                // onChange={(event, newValue) => {
                //     console.log(newValue)
                //   if (newValue) {
                //     setNewBuilding({
                //       ...newBuilding,
                //       loc_no: newValue.location_no,
                //       loc_name: newValue.location_name,
                //     })
                //   }
                // }}
                disabled
                getOptionLabel={(option) => option.location_name}
                clearOnBlur
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Location"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Building Name"
                variant="outlined"
                required
                className={classes.normalFields}
                value={newBuilding.organization_name}
                onChange={(event) =>
                  setNewBuilding({
                    ...newBuilding,
                    organization_name: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="Building Address"
                variant="outlined"
                required
                className={classes.normalFields}
                value={newBuilding.building_address}
                onChange={(event) =>
                  setNewBuilding({
                    ...newBuilding,
                    building_address: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.spaceHierarchySelect}
              >
                <InputLabel id="building-status-label">Status</InputLabel>
                <Select
                  labelId="building-status-label"
                  label="Status"
                  id="building-status"
                  name="building-status"
                  value={newBuilding.status}
                  onChange={(event) =>
                    setNewBuilding({
                      ...newBuilding,
                      status: event.target.value,
                    })
                  }
                  // autoWidth={true}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                label="No of floors"
                variant="outlined"
                className={classes.normalFields}
                value={newBuilding.number_of_floors}
                type="number"
                onChange={(event) =>
                  setNewBuilding({
                    ...newBuilding,
                    number_of_floors: event.target.value,
                  })
                }
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    // checked={srfIndicator}
                    checked={newBuilding?.is_serviceable}
                    onChange={handleSRFCheckBox}
                    name="Visible to CRESRF Form"
                    color="primary"
                  />
                }
                label={'Visible to CRESRF Form'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          {dialogTitle === 'Edit' ? (
            <Button variant="contained" type="submit" color="primary">
              Save
            </Button>
          ) : (
            <Button variant="contained" type="submit" color="primary">
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddBuildingDialog
