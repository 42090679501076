import React, { useState, useEffect } from 'react'
import {
  Grid,
  FormControl,
  TextField,
  CircularProgress,
  Button,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import { USA } from '../../components/common/constants'
import { PurposeStyle } from '../../styles/india/purposeOfVisitStyle'
import { tableIcons } from '../../components/common/commonFunctionalities'
import MaterialTable from '@material-table/core'
import { useAuth } from '@praxis/component-auth'
import AddBuildingDialog from './addBuilding'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const SpaceHierarchy = () => {
  const env = useEnv()
  const classes = PurposeStyle()
  const auth = useAuth()
  const { session } = auth
  const dispatch = useDispatch()
  const locationDetails = useSelector((state) => state.spaceHierarchy)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Add New')
  const [autocompleteBuildingValue, setAutocompleteBuildingValue] =
    React.useState(null)

  const initialBuildingData = {
    organization_name: '',
    building_id: '',
    loc_no: null,
    loc_name: '',
    city: '',
    state: '',
    created_by: session.userInfo.email.split('@')[0],
    area: '',
    status: 'Active',
    primary_location: '',
    building_address: '',
    number_of_floors: null,
    modified_by: '',
    is_serviceable: '',
    old_is_serviceable: '',
  }
  const [newBuilding, setNewBuilding] = useState(initialBuildingData)

  const tableColumns = [
    {
      field: 'location_id',
      title: 'Location Id',
      defaultSort: 'asc',
    },
    {
      field: 'location_name',
      title: 'Location Name',
      defaultSort: 'asc',
    },
    {
      field: 'location_address',
      title: 'Location Address',
      defaultSort: 'asc',
    },

    {
      field: 'location_city',
      title: 'Location City',
      defaultSort: 'asc',
    },

    {
      field: 'location_state',
      title: 'Location State',
      defaultSort: 'asc',
    },

    {
      field: 'organization_name',
      title: 'Building Name',
      defaultSort: 'asc',
      render: (rowData) => (
        <Link to="/viewfloors" state={{ building_id: rowData.building_id }}>
          {rowData.organization_name}
        </Link>
      ),
    },
    {
      field: 'building_address',
      title: 'Building Address',
      defaultSort: 'asc',
    },
    {
      field: 'city',
      title: 'Building City',
      defaultSort: 'asc',
    },
    {
      field: 'state',
      title: 'Building State',
      defaultSort: 'asc',
    },

    {
      field: 'status',
      title: 'Status',
      defaultSort: 'asc',
    },

    {
      field: 'area',
      title: 'Area(sq ft)',
      defaultSort: 'asc',
    },
    {
      field: 'number_of_floors',
      title: 'Total Floors',
      defaultSort: 'asc',
    },
    {
      field: 'building_id',
      title: 'building_id',
      hidden: true,
    },
    {
      field: 'capacity',
      title: 'capacity',
      hidden: true,
    },
    {
      field: 'created_by',
      title: 'created_by',
      hidden: true,
    },
    {
      field: 'modified_by',
      title: 'modified_by',
      hidden: true,
    },
    {
      field: 'primary_location',
      title: 'primary_location',
      hidden: true,
    },
    {
      field: 'is_serviceable',
      title: 'Visible to CRESRF Form',
      type: 'boolean',
      render: (rowData) =>
        rowData.is_serviceable ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <CancelIcon style={{ color: 'red' }} />
        ),
    },
  ]

  useEffect(() => {
    let url = env.apiUrl[USA].getAllLocations
    if (
      locationDetails.allLocations &&
      locationDetails.allLocations.length === 0
    ) {
      axios
        .get(url, {
          headers: { Authorization: localStorage.access_token },
        })
        .then((response) => {
          const locations = response.data.map((val) => {
            return {
              location_name: `${val.location_no + '-' + val.location_name}`,
              location_no: val.location_no,
            }
          })
          dispatch({ type: 'SAVE_ALL_LOCATIONS', payload: locations })
        })
        .catch((err) => console.log(err))
    } else {
      //to set location number on screen refresh
      setNewBuilding({
        ...newBuilding,
        loc_no: locationDetails.selectedLocation.location_no,
        loc_name: locationDetails.selectedLocation.location_name,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (locationDetails.selectedBuilding?.length > 0) {
      setAutocompleteBuildingValue(locationDetails.selectedBuilding[0])

      let modifiedTableData = locationDetails.selectedBuilding.map((item) => {
        let [, loc_name] =
          locationDetails.selectedLocation.location_name.split('-')
        return {
          ...item,
          location_id: locationDetails.selectedLocation.location_no,
          location_name: loc_name,
        }
      })
      dispatch({ type: 'SAVE_LOCATION_TABLE_DATA', payload: modifiedTableData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails.selectedBuilding])

  const handleLocationSelection = (event, newValue) => {
    if (newValue) {
      setLoading(true)
      dispatch({ type: 'SAVE_SELECTED_LOCATION', payload: newValue })

      let url = env.apiUrl[USA].getBuildingForLocation + newValue.location_no
      axios
        .get(url, {
          headers: { Authorization: localStorage.access_token },
        })
        .then((response) => {
          setLoading(false)
          dispatch({
            type: 'SAVE_LOCATION_BUILDING_DATA',
            payload: response.data,
          })

          let modifiedTableData = response.data.map((item) => {
            let [, loc_name] = newValue.location_name.split('-')

            return {
              ...item,
              location_id: newValue.location_no,
              location_name: loc_name,
            }
          })
          setNewBuilding({
            ...newBuilding,
            loc_no: newValue.location_no,
            loc_name: newValue.location_name,
          })
          dispatch({
            type: 'SAVE_LOCATION_TABLE_DATA',
            payload: modifiedTableData,
          })
        })
        .catch((err) => console.log(err))
    } else {
      dispatch({
        type: 'SAVE_SELECTED_LOCATION',
        payload: {
          location_no: '',
          location_name: '',
        },
      })

      dispatch({ type: 'SAVE_LOCATION_BUILDING_DATA', payload: [] })

      dispatch({
        type: 'SAVE_LOCATION_SELECTED_BUILDING',
        payload: [],
      })
      setAutocompleteBuildingValue({})
    }
  }

  const handleBuildingChange = (event, newValue) => {
    if (newValue) {
      dispatch({
        type: 'SAVE_LOCATION_SELECTED_BUILDING',
        payload: [newValue],
      })
      setAutocompleteBuildingValue(newValue)
    } else {
      dispatch({
        type: 'SAVE_LOCATION_SELECTED_BUILDING',
        payload: [],
      })
      setAutocompleteBuildingValue(newValue)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setNewBuilding(initialBuildingData)
    setDialogTitle('Add New')
  }

  const addNewBuilding = (e) => {
    e.preventDefault()
    let payload = {
      ...newBuilding,
      area: locationDetails.buildingData[0].area,
      city: locationDetails.buildingData[0].city,
      primary_location: locationDetails.buildingData[0].primary_location,
      state: locationDetails.buildingData[0].state,
    }

    payload['building_id'] =
      payload.loc_no && payload.loc_no.toString().length > 3
        ? payload.loc_no + ' ' + payload.organization_name
        : '0' + payload.loc_no + ' ' + payload.organization_name

    let url = env.apiUrl[USA].addNewBuilding
    axios
      .post(url, payload, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        handleLocationSelection({}, locationDetails.selectedLocation)
        handleClose()
      })
      .catch((err) => {
        console.log(err)

        if (err.response.status === 409) {
          toast.error(
            'Error! Building already exists for selected location',
            toastConfigure,
          )
        }
      })
  }

  const handleEditBuilding = (evt, rowData) => {
    let payload = {
      organization_name: rowData.organization_name,
      building_id: rowData.building_id,
      loc_no: rowData.location_id,
      loc_name: rowData.location_name,
      city: rowData.city,
      state: rowData.state,
      created_by: rowData.created_by,
      area: rowData.area,
      status: rowData.status,
      primary_location: rowData.primary_location,
      building_address: rowData.building_address,
      number_of_floors: rowData.number_of_floors,
      is_serviceable: rowData.is_serviceable,
      old_is_serviceable: rowData.is_serviceable,
      modified_by: session.userInfo.email.split('@')[0],
    }
    setNewBuilding(payload)
    setDialogTitle('Edit')
    setOpen(true)
  }

  const editBuilding = (e) => {
    e.preventDefault()
    let payload = {
      ...newBuilding,
    }

    let url = env.apiUrl[USA].modifyBuilding
    axios
      .put(url, payload, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        handleLocationSelection({}, locationDetails.selectedLocation)
        handleClose()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={classes.selectPurpose}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <FormControl variant="outlined">
            <Autocomplete
              id="location-autocomplete"
              className={classes.formControl}
              options={locationDetails.allLocations}
              value={locationDetails.selectedLocation}
              onChange={handleLocationSelection}
              getOptionLabel={(option) => option.location_name}
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Location"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          &nbsp;&nbsp;
          <FormControl variant="outlined">
            <Autocomplete
              id="building-autocomplete"
              className={classes.formControl}
              options={locationDetails.buildingData}
              loading={loading}
              value={autocompleteBuildingValue}
              onChange={handleBuildingChange}
              getOptionLabel={(option) => option.organization_name}
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Building"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>

      {locationDetails.selectedLocation.location_no !== '' && (
        <>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(true)}
              >
                Add Building
              </Button>
            </Grid>
          </Grid>
          <br />
          <MaterialTable
            title={''}
            options={{
              thirdSortClick: false,
              showTitle: true,
              search: true,
              toolbar: true,
              pageSizeOptions: [10, 25, 50, 100],
              loadingType: 'overlay',
              pageSize: 100,
              headerStyle: {
                position: 'sticky',
                top: 0,
                height: '50px',
                fontWeight: 'bold',
                // backgroundColor: '#aba7a8',
              },
              maxBodyHeight: '53vh',
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: tableIcons.Edit,
                tooltip: 'Edit Building',
                onClick: (event, rowData) => handleEditBuilding(event, rowData),
              },
            ]}
            columns={tableColumns}
            data={locationDetails.tableData}
            icons={tableIcons}
            isLoading={loading}
            localization={{
              body: {
                emptyDataSourceMessage: '',
              },
            }}
          />
        </>
      )}
      <AddBuildingDialog
        newBuilding={newBuilding}
        setNewBuilding={setNewBuilding}
        handleClose={handleClose}
        open={open}
        locationData={locationDetails.allLocations}
        classes={classes}
        addNewBuilding={addNewBuilding}
        selectedLocation={locationDetails.selectedLocation}
        dialogTitle={dialogTitle}
        editBuilding={editBuilding}
      />
    </div>
  )
}

export default SpaceHierarchy
