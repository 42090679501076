import React, { useEffect } from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import MaterialTable from '@material-table/core'
import { tableIcons } from '../../../components/common/commonFunctionalities'
import toast from 'react-hot-toast'
import { India } from '../../../components/common/constants'

export const toastConfigure = {
  duration: 5000,
  style: {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  },
}
export const columns = [
  {
    title: 'Building Name',
    field: 'organization_name',
    editable: 'never',
    defaultSort: 'asc',
  },
  { title: 'Capacity', field: 'capacity' },
]
export default function CapacityManagement() {
  const env = useEnv()
  const [capacityList, setCapacityList] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const getCapacity = () => {
    const url = env.apiUrl[India].getCapacity

    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        setCapacityList(res.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getCapacity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateRecord = (buildingId, capacity) => {
    setIsLoading(true)
    let url =
      env.apiUrl[India].updateCapacity +
      `building_id=${buildingId}&capacity=${capacity}`

    axios
      .put(url)
      .then((response) => {
        if (response.status === 200) {
          getCapacity()
          setIsLoading(false)
          toast.success(
            'Building capacity updated successfully',
            toastConfigure,
          )
        } else throw new Error('Unable to update building capacity')
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const validateCapacityCount = (data) => {
    if (data.length === 0) return false
    return true
  }

  return (
    <div>
      <MaterialTable
        title="Set Building Capacity"
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              let validateCapacity = validateCapacityCount(newData.capacity)
              if (!validateCapacity) {
                toast.error(
                  'Please fill in value for building capacity',
                  toastConfigure,
                )
                reject()
              } else {
                updateRecord(newData.building_id, newData.capacity)
                resolve()
              }
            }),
        }}
        columns={columns}
        data={capacityList}
        icons={tableIcons}
        options={{
          exportButton: true,
          pageSize: 10,
          search: true,
          pageSizeOptions: [10, 25, 50],
        }}
        isLoading={isLoading}
      />
    </div>
  )
}
