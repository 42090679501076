import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import Grid from '@mui/material/Grid'
// import moment from 'moment'
import { useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography } from '@mui/material'
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  Type as ListType,
} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import { ActionContent, ItemColumnCentered } from './styledComponents'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import Blink from 'react-blink-text'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { CSVLink } from 'react-csv'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import { useAuth } from '@praxis/component-auth'
import { TimeConfig } from '../../components/common/commonFunctionalities'
import { useAnalytics } from '@praxis/component-analytics'
import RefreshIcon from '@mui/icons-material/Refresh'
import moment from 'moment-timezone'
import { css } from '@emotion/react'
import BeatLoader from 'react-spinners/BeatLoader'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    marginTop: '10px',
    backgroundColor: '#f7f8fa',
    border: '1px solid #BFBFBF',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    width: '22ch',
  },
  media: {
    height: 170,
  },
  formControl: {
    minWidth: '157px !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#6b41cb !important',
      borderWidth: '2px',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 210,
    height: 35,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconHome: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  moveRight: {
    marginLeft: 'auto',
  },
  typographyHeading: {
    fontSize: '13px',
  },
  typographyValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  typographyMediumValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#ffa505',
  },
  typographyHighValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#FF0000',
  },
}))

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

export default function CleaningList() {
  const env = useEnv()
  const { trackCustomEvent } = useAnalytics()
  const classes = useStyles()
  const auth = useAuth()
  const { session } = auth
  const [cleaningList, setCleaningList] = React.useState([])
  const [threshold, setThreshold] = React.useState(0.5)
  const [refresh, setRefresh] = React.useState(false)
  const [searchField, setSearchField] = React.useState('')
  const floorId = useSelector((state) => state.housekeeping.selectedFloor)
  const floorName = useSelector((state) => state.housekeeping.selectedFloorName)
  const buildingId = useSelector((state) => state.housekeeping.buildingId)
  const [loading, setLoading] = React.useState(true)
  const buildingName = useSelector(
    (state) => state.housekeeping.selectedBuilding,
  )
  const cleaningDate = moment(
    useSelector((state) => state.housekeeping.cleaningDate),
  ).format('MM/DD/YYYY')

  React.useEffect(() => {
    setThreshold(0.3)
  }, [setThreshold])

  const getCleaningList = () => {
    const usa_url =
      env.apiUrl['USA'].getCleaningList +
      `date=${cleaningDate}&building_id=${buildingId}&floor_id=${floorId}`
    const india_url =
      env.apiUrl['India'].getCleaningList +
      `cleaning_date=${cleaningDate}&building_id=${buildingId}&floor_id=${floorId}`
    const final_url =
      localStorage.getItem('base_location') === 'India' ? india_url : usa_url
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(final_url, config)
      .then((res) => {
        setCleaningList(
          res.data.cleaning_space_details.filter(
            (item) => item.cleaning_status === 'To be cleaned',
          ),
        )
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCleaningList()
    const interval = setInterval(() => {
      setLoading(true)
      getCleaningList()
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (refresh === true) {
      getCleaningList()
      setRefresh(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const leadingActions = ({ reservation_id }) => (
    <LeadingActions>
      <SwipeAction onClick={updateCleaningStatus(reservation_id)}>
        <ActionContent style={{ backgroundColor: '#4a7b2b' }}>
          <ItemColumnCentered>
            <span className="icon">
              <CheckCircleIcon />
            </span>
            Confirm Cleaning
          </ItemColumnCentered>
        </ActionContent>
      </SwipeAction>
    </LeadingActions>
  )

  const updateCleaningStatus = (reservation_id) => () => {
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    const usa_url =
      env.apiUrl['USA'].updateCleaningStatus +
      `building_id=${buildingId}` +
      `&reservation_id=${reservation_id}` +
      `&cleaned_by=${session.userInfo.email.split('@')[0]}`

    const india_url =
      env.apiUrl['India'].updateCleaningStatus +
      `&reservation_id=${reservation_id}` +
      `&cleaned_by=${session.userInfo.email.split('@')[0]}`

    const final_url =
      localStorage.getItem('base_location') === 'India' ? india_url : usa_url

    axios
      .put(final_url, config)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Desk moved to cleaned status', toastConfigure)
        }
        setRefresh(true)
        trackCustomEvent(
          'Swipe Action',
          final_url,
          `Success. ReservationId:${reservation_id}, CleanedBy:${
            session.userInfo.email.split('@')[0]
          }`,
          'Cleaning',
        )
      })
      .catch((err) => {
        toast.error(`Error - ${err}`, toastConfigure)
        trackCustomEvent(
          'Swipe Action',
          final_url,
          `${err.statusCode} : ${err.statusMsg}`,
          'Cleaning',
        )
      })
  }

  const handleChange = (e) => {
    setSearchField(e.target.value)
  }

  const filteredResults = cleaningList.filter((res) => {
    return (
      res.desk_id.toLowerCase().includes(searchField.toLowerCase()) ||
      res.priority.toLowerCase().includes(searchField.toLowerCase()) ||
      res.start_time.toLowerCase().includes(searchField.toLowerCase()) ||
      res.end_time.toLowerCase().includes(searchField.toLowerCase())
    )
  })

  const convertAMPMFromHours = (time) => {
    let colonSplit = time.split(':')
    let timeInNumber = Number(colonSplit[0])
    let finalValue =
      timeInNumber > 12
        ? timeInNumber - 12 + `:${colonSplit[1]} PM`
        : timeInNumber === 12
        ? timeInNumber + `:${colonSplit[1]} PM`
        : timeInNumber + `:${colonSplit[1]} AM`
    return finalValue
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Tooltip title="Download" enterTouchDelay="0" arrow>
            <IconButton size="small">
              <CSVLink
                filename="NotCleanedData.csv"
                data={cleaningList}
                style={{ color: '#AC0000' }}
              >
                <CloudDownloadIcon />
              </CSVLink>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Paper component="form" className={classes.search}>
            <IconButton
              disabled
              className={classes.iconButton}
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              disabled
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item>
          <Tooltip title="Refresh" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              className={classes.iconHome}
              onClick={() => {
                setLoading(true)
                getCleaningList()
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/housekeeping/landing"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={3}>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Building:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>{buildingName}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Floor:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>{floorName}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Date:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {moment(cleaningDate).format('MMM Do')}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Total Tasks:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {filteredResults.length}
          </Typography>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={3} justifyContent="flex-start">
        <Grid item>
          <Typography style={{ fontSize: '14px' }}>
            Note: Swipe the card towards right and confirm when desk is cleaned.
          </Typography>
        </Grid>
      </Grid>

      {loading === true ? (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '50vh' }}
        >
          <BeatLoader color="#AC0007" css={override} size={15} />
        </Grid>
      ) : (
        <>
          {filteredResults.length !== 0 ? (
            <>
              {moment(cleaningDate).isSameOrBefore(moment()) === true ? (
                <>
                  <SwipeableList
                    threshold={threshold}
                    fullSwipe={false}
                    type={ListType.IOS}
                  >
                    {filteredResults.map(
                      ({
                        reservation_id,
                        desk_id,
                        start_time,
                        end_time,
                        cleaning_status,
                        priority,
                        location_timezone,
                      }) => (
                        <SwipeableListItem
                          key={reservation_id}
                          leadingActions={leadingActions({
                            reservation_id,
                          })}
                          blockSwipe={
                            moment()
                              .tz(location_timezone)
                              .format('MM/DD/YYYY HH:mm') < start_time
                          }
                        >
                          <Card className={classes.root} elevation={4}>
                            <CardContent className={classes.content}>
                              <div>
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                >
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      className={classes.typographyHeading}
                                    >
                                      Desk:
                                    </Typography>{' '}
                                    <Typography
                                      className={classes.typographyValue}
                                    >
                                      {desk_id}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      className={classes.typographyHeading}
                                    >
                                      Priority:
                                    </Typography>
                                    {priority === 'High' ? (
                                      <Blink
                                        color="red"
                                        text="High"
                                        fontSize="0.9rem"
                                        fontWeight="bold"
                                      />
                                    ) : priority === 'Past Due - Very High' ? (
                                      <Blink
                                        color="red"
                                        text="Past Due - Very High"
                                        fontSize="0.9rem"
                                        fontWeight="bold"
                                      />
                                    ) : priority === 'Past Due' ? (
                                      <Typography
                                        className={classes.typographyHighValue}
                                      >
                                        Past Due
                                      </Typography>
                                    ) : priority === 'Medium' ? (
                                      <Typography
                                        className={
                                          classes.typographyMediumValue
                                        }
                                      >
                                        Medium
                                      </Typography>
                                    ) : (
                                      <Typography
                                        className={classes.typographyValue}
                                      >
                                        {' '}
                                        {priority}{' '}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      className={classes.typographyHeading}
                                    >
                                      Cleaning Start Time:
                                    </Typography>
                                    <Typography
                                      className={classes.typographyValue}
                                    >
                                      {convertAMPMFromHours(
                                        moment(start_time).format('HH:mm'),
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Typography
                                      className={classes.typographyHeading}
                                    >
                                      Next Reservation Time:
                                    </Typography>
                                    <Typography
                                      className={classes.typographyValue}
                                    >
                                      {end_time &&
                                      moment(end_time).format('HH:mm') !==
                                        TimeConfig.dayEndTime
                                        ? convertAMPMFromHours(
                                            moment(end_time).format('HH:mm'),
                                          )
                                        : 'None'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                            </CardContent>
                          </Card>{' '}
                          <br /> <br />
                          <br />
                        </SwipeableListItem>
                      ),
                    )}
                  </SwipeableList>
                </>
              ) : (
                <SwipeableList>
                  {filteredResults.map(
                    ({
                      reservation_id,
                      desk_id,
                      start_time,
                      end_time,
                      cleaning_status,
                      priority,
                    }) => (
                      <SwipeableListItem blockSwipe={true}>
                        <Card className={classes.root} elevation={6}>
                          <CardContent className={classes.content}>
                            <div>
                              <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                                style={{ fontWeight: '520' }}
                              >
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    className={classes.typographyHeading}
                                  >
                                    Desk Number:{' '}
                                  </Typography>
                                  <Typography
                                    className={classes.typographyValue}
                                  >
                                    {desk_id}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    className={classes.typographyHeading}
                                  >
                                    Priority:
                                  </Typography>
                                  {priority === 'High' ? (
                                    <Blink
                                      color="red"
                                      text="High"
                                      fontSize="o.9rem"
                                      fontWeight="bold"
                                    />
                                  ) : priority === 'Past Due - Very High' ? (
                                    <Blink
                                      color="red"
                                      text="Past Due - Very High"
                                      fontSize="0.9rem"
                                      fontWeight="bold"
                                    />
                                  ) : priority === 'Past Due' ? (
                                    <Typography
                                      className={classes.typographyHighValue}
                                    >
                                      Past Due
                                    </Typography>
                                  ) : priority === 'Medium' ? (
                                    <Typography
                                      className={classes.typographyMediumValue}
                                    >
                                      Medium
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className={classes.typographyValue}
                                    >
                                      {' '}
                                      {priority}{' '}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    className={classes.typographyHeading}
                                  >
                                    Cleaning Start Time:
                                  </Typography>
                                  <Typography
                                    className={classes.typographyValue}
                                  >
                                    {convertAMPMFromHours(
                                      moment(start_time).format('HH:mm'),
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    className={classes.typographyHeading}
                                  >
                                    Next Reservation Time:
                                  </Typography>
                                  <Typography
                                    className={classes.typographyValue}
                                  >
                                    {end_time &&
                                    moment(end_time).format('HH:mm') !==
                                      TimeConfig.dayEndTime
                                      ? convertAMPMFromHours(
                                          moment(end_time).format('HH:mm'),
                                        )
                                      : 'None'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </CardContent>
                        </Card>{' '}
                        <br />{' '}
                      </SwipeableListItem>
                    ),
                  )}
                </SwipeableList>
              )}
            </>
          ) : (
            <Typography
              align="center"
              style={{
                marginTop: '50px',
              }}
            >
              No records to display
            </Typography>
          )}
        </>
      )}
    </>
  )
}
