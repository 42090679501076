import makeStyles from '@mui/styles/makeStyles'

export const HousekeepingStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '5%',
    height: '100vh',
    // backgroundColor: theme.palette.secondary.main,
    padding: '1%',
  },
  formControl: {
    margin: `${theme.spacing(1)} !important`,
    minWidth: `280px !important`,
    //     margin: theme.spacing(2),
    // minWidth: 330,
    // maxwidth: 330,
  },
  large: {
    width: 120,
    height: 150,
  },
  btn: {
    width: 200,
  },
  grid: {
    margin: 0,
  },
}))
