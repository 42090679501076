import Grid from '@mui/material/Grid'
import {
  CardContent,
  Button,
  Typography,
  Card,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  ListItemText,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import moment from 'moment'
import { InsightsPageStyles } from '../../styles/insightsPageStyle'
import Paper from '@mui/material/Paper'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import EventIcon from '@mui/icons-material/Event'

export const LandingPage = () => {
  const env = useEnv()
  const classes = InsightsPageStyles()

  const DEFAULT_DATE_RANGE = 7
  const DEFAULT_START_DATE = moment()
  const DEFAULT_END_DATE = DEFAULT_START_DATE.clone().add(
    DEFAULT_DATE_RANGE,
    'd',
  )
  const DEFAULT_CONSOLIDATED_DATA = new Array(DEFAULT_DATE_RANGE + 1)
    .fill({})
    .map((v, i, a) => {
      return {
        date: formatDateObject(DEFAULT_START_DATE.clone().add(i, 'd')),
        total: 0,
      }
    })

  // Insight data variables
  const [upstreamDataList, setUpstreamDataList] = useState([])
  const [isUpstreamDataListInitialized, setIsUpstreamDataListInitialized] =
    useState(false)
  const [consolidatedData, setConsolidatedData] = useState(
    DEFAULT_CONSOLIDATED_DATA,
  )
  const [popupData, setPopupData] = useState([])

  const [startDate, setStartDate] = useState(DEFAULT_START_DATE)
  const [endDate, setEndDate] = useState(DEFAULT_END_DATE)

  const [buildingFloorMap, setBuildingFloorMap] = useState({})
  const [buildingListData, setBuildingListData] = useState([])
  const [buildingList, setBuildingList] = useState([])
  const [floorListData, setFloorListData] = useState([])
  const [floorList, setFloorList] = useState([])

  // Source configs
  const [sourceListMap] = useState({
    'Workspace Reservations': {
      style: {
        stack: 'main',
        fill: '#8884d8',
        opacity: 1,
      },
    },
    'Conference Rooms': {
      style: {
        stack: 'main',
        fill: '000000', //TODO change when Hi-res is migrated '#DC6274',
        opacity: 0.6,
      },
    },
    Events: {
      style: {
        stack: 'main',
        fill: '#000000',
        opacity: 0.6,
      },
    },
    Appointments: {
      style: {
        stack: 'main',
        fill: '#82ca9d',
        opacity: 1,
      },
    },
    'Badge Scans': {
      style: {
        stack: 'main',
        fill: '#E376CD',
        opacity: 1,
      },
    },
    Visits: {
      style: {
        stack: 'main',
        fill: '#FCA503',
        opacity: 1,
      },
    },
  })
  const [sourceListData] = useState(Object.keys(sourceListMap))
  const [sourceList, setSourceList] = useState(sourceListData || [])

  // UI change handlers

  useEffect(() => {
    processBuildingsFloors()
    // eslint-disable-next-line
  }, [])
  // this linting error needs to be ignored so the 'processBuildingsFloors' runs once upon page load

  const handleDateChange = (d, isStart) => {
    let sd = startDate.clone()
    let ed = endDate.clone()
    if (isStart) {
      if (d.isAfter(ed)) {
        ed = d.clone().add(1, 'd')
        setEndDate(ed)
      }
      sd = d
      setStartDate(sd)
    } else {
      if (d.isBefore(sd)) {
        sd = d.clone().subtract(1, 'd')
        setStartDate(sd)
      }
      ed = d
      setEndDate(ed)
    }
    processUpstream({
      startDate: sd,
      endDate: ed,
      buildingList: buildingList,
      floorList: floorList,
      buildingFloorMap: buildingFloorMap,
    })
  }

  const handleBuildingChange = (b) => {
    let updatedBuildingList
    if (b.indexOf('All') > -1) {
      if (buildingList.length === buildingListData.length) {
        updatedBuildingList = []
      } else {
        updatedBuildingList = buildingListData
      }
    } else {
      updatedBuildingList = b
    }
    let updatedFloorList = []
    for (const building of updatedBuildingList) {
      for (const floor of floorListData) {
        if (
          buildingFloorMap[building].indexOf(floor) > -1 &&
          updatedFloorList.indexOf(floor) < 0
        ) {
          updatedFloorList.push(floor)
        }
      }
    }
    setBuildingList(updatedBuildingList)
    setFloorList(updatedFloorList)
    processUpstream({
      startDate: startDate,
      endDate: endDate,
      buildingList: updatedBuildingList,
      floorList: updatedFloorList,
      buildingFloorMap: buildingFloorMap,
    })
  }

  const handleFloorChange = (f) => {
    let updatedFloorList
    if (f.indexOf('All') > -1) {
      if (floorList.length === floorListData.length) {
        updatedFloorList = []
      } else {
        updatedFloorList = floorListData
      }
    } else {
      updatedFloorList = f
    }
    let updatedBuildingList = []
    for (const floor of updatedFloorList) {
      for (const building of buildingListData) {
        if (
          buildingFloorMap[building].indexOf(floor) > -1 &&
          updatedBuildingList.indexOf(building) < 0
        ) {
          updatedBuildingList.push(building)
        }
      }
    }
    setFloorList(updatedFloorList)
    setBuildingList(updatedBuildingList)
    processUpstream({
      startDate: startDate,
      endDate: endDate,
      buildingList: updatedBuildingList,
      floorList: updatedFloorList,
      buildingFloorMap: buildingFloorMap,
    })
  }

  const handleSourceChange = (s) => {
    let updatedSourceList
    if (s.indexOf('All') > -1) {
      if (sourceList.length === sourceListData.length) {
        updatedSourceList = []
      } else {
        updatedSourceList = sourceListData
      }
    } else {
      updatedSourceList = s
    }
    setSourceList(updatedSourceList)
  }

  const handleClickBarChart = (bar) => {
    if (bar) {
      setPopupData(
        upstreamDataList.filter((v, i, a) => {
          return formatDateObject(moment(v.date)) === bar.activeLabel
        }),
      )
    }
  }

  // Common functions

  async function callApi(config, payload) {
    const instance = axios.create(config)
    let data
    try {
      data = await instance.request(payload)
    } catch (e) {
      console.error(e)
    }
    return data
  }

  async function getDeskReservationBuildingFloorData() {
    const res = await callApi({
      method: 'GET',
      baseURL: env.apiUrl['USA'].getBuildingFloorUrl,
      headers: {
        Authorization: localStorage.access_token,
      },
      timeout: 1000,
    })
    return res?.data || []
  }

  async function getHomebaseBuildingFloorsData() {
    const res = await callApi({
      method: 'GET',
      baseURL: env.apiUrl['USA'].getBuildingsandFloorsForHB,
      headers: {
        Authorization: localStorage.access_token,
      },
      timeout: 1000,
    })
    let data = res?.data
    for (const i in data) {
      data[i]['floor_names'] =
        data[i].floor_details.map((f) => {
          delete f.floor_details
          return f.floor_name
        }) || []
    }
    return data || []
  }

  function renderDropDown(selected, list, listData) {
    const value = selected.join(', ')
    return list.length === listData.length
      ? 'All'
      : value.length > 37
      ? value.substring(0, 35).concat('...')
      : value
  }

  function formatDateObject(d) {
    return d.format('MM/DD/YYYY')
  }

  function buildDateObject(config) {
    const data = {}
    let sd = config.startDate.clone()
    let ed = config.endDate.clone()
    while (
      sd.isSameOrBefore(ed) ||
      formatDateObject(sd) === formatDateObject(ed)
    ) {
      data[formatDateObject(sd)] = {
        date: formatDateObject(sd),
        total: 0,
      }
      for (const s of Object.keys(sourceListMap || {})) {
        data[formatDateObject(sd)][s] = 0
      }
      sd = sd.clone().add(1, 'd')
    }
    return data
  }

  async function processBuildingsFloors() {
    const drData = await getDeskReservationBuildingFloorData()
    const hbData = await getHomebaseBuildingFloorsData()
    const data = drData.concat(hbData)
    const bd = []
    const fd = []
    let bfm = {}
    for (const d of data) {
      if (!bd.includes(d?.building_details?.building_id)) {
        bd.push(d?.building_details?.building_id)
      }
      for (const f of d?.floor_names || []) {
        if (!fd.includes(f)) {
          fd.push(f)
        }
      }
      if (bfm[d?.building_details?.building_id || '']) {
        bfm[d?.building_details?.building_id || ''] = bfm[
          d?.building_details?.building_id || ''
        ].concat(d?.floor_names || [])
      } else {
        bfm[d?.building_details?.building_id || ''] = d?.floor_names || []
      }
    }
    bd.sort((a, b) => {
      return a > b
    })
    fd.sort((a, b) => {
      return a > b
    })
    setBuildingListData(bd)
    setBuildingList(bd)
    setFloorListData(fd)
    setFloorList(fd)
    setBuildingFloorMap(bfm)
    processUpstream({
      startDate: startDate,
      endDate: endDate,
      buildingList: bd,
      floorList: fd,
      buildingFloorMap: bfm,
    })
  }

  async function processUpstream(config) {
    const data = buildDateObject(config)

    const queryBuildingList = []
    const queryBuildingFloorList = []
    for (const b of config.buildingList) {
      for (const f of config.floorList) {
        if (config.buildingFloorMap[b].indexOf(f) > -1) {
          if (!queryBuildingList.includes(b)) {
            queryBuildingList.push(b)
          }
          if (!queryBuildingFloorList.includes(f)) {
            queryBuildingFloorList.push(f)
          }
        }
      }
    }

    const upstreamData = await callApi({
      method: 'GET',
      baseURL: env.apiUrl.USA.insights,
      headers: {
        Authorization: localStorage.access_token,
      },
      params: {
        building:
          queryBuildingList.length > 0 &&
          queryBuildingList.length < buildingListData.length
            ? queryBuildingList.join(',')
            : null,
        building_floor:
          queryBuildingFloorList.length > 0 &&
          queryBuildingFloorList.length < floorListData.length
            ? queryBuildingFloorList.join(',')
            : null,
        start_date: config.startDate.format('YYYY-MM-DD'),
        end_date: config.endDate.format('YYYY-MM-DD'),
      },
      timeout: 5000,
    })

    upstreamData?.data?.forEach((v) => {
      const dataDate = formatDateObject(moment(v?.date))
      data[dataDate][v.source_id] += v.count
      data[dataDate].total += v.count
    })

    setConsolidatedData(Object.values(data))

    setUpstreamDataList(upstreamData?.data || [])
    setIsUpstreamDataListInitialized(true)
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Dialog
          open={popupData && popupData.length > 0}
          onClose={() => setPopupData([])}
          onBackdropClick={() => setPopupData([])}
          fullWidth={true}
          maxWidth="sm"
        >
          <Card>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              className={classes.searchBar}
            >
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Source</TableCell>
                      <TableCell align="center">Building</TableCell>
                      <TableCell align="center">Floor</TableCell>
                      <TableCell align="center">Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {popupData.map((v, i, a) => (
                      <TableRow>
                        <TableCell>{v.source_id || 'Unknown'}</TableCell>
                        <TableCell>{v.building_id || 'N/A'}</TableCell>
                        <TableCell>{v.floor_id || 'N/A'}</TableCell>
                        <TableCell>{v.count || 0}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button onClick={() => setPopupData([])} color="primary">
                Close
              </Button>
            </Grid>
          </Card>
        </Dialog>

        <Paper>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className={classes.searchBar}
          >
            <Grid item lg={3} md={6} xs={12}>
              <DatePicker
                // required
                // disableToolbar
                // variant="inline"
                // inputVariant="outlined"
                format="MM/DD/Y"
                margin="normal"
                // id="date-picker-inline"
                label="Start Date"
                value={startDate}
                onChange={(date) => {
                  handleDateChange(moment(date), true)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                autoOk={true}
              />
              <DatePicker
                required
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/Y"
                margin="normal"
                id="date-picker-inline"
                label="End Date"
                value={endDate}
                onChange={(date) => {
                  handleDateChange(moment(date), false)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                autoOk={true}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-building">Buildings</InputLabel>
                <Select
                  labelId="dashboard-building"
                  id="dashboard-building"
                  value={buildingList}
                  onChange={(l) => {
                    handleBuildingChange(l.target.value)
                  }}
                  label="Buildings"
                  renderValue={(selected) =>
                    renderDropDown(selected, buildingList, buildingListData)
                  }
                  multiple
                >
                  <MenuItem disabled>--Select Buildings--</MenuItem>
                  <MenuItem value={'All'} key="All">
                    <Checkbox
                      color="primary"
                      checked={buildingList.length === buildingListData.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {buildingListData.map((v, i) => {
                    return (
                      <MenuItem value={v} key={i}>
                        <Checkbox
                          color="primary"
                          checked={buildingList.indexOf(v) > -1}
                        />
                        <ListItemText primary={v} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-floor">Floors</InputLabel>
                <Select
                  labelId="dashboard-floor"
                  id="dashboard-floor"
                  value={floorList}
                  onChange={(l) => {
                    handleFloorChange(l.target.value)
                  }}
                  label="Floors"
                  renderValue={(selected) =>
                    renderDropDown(selected, floorList, floorListData)
                  }
                  multiple
                >
                  <MenuItem disabled>--Select Floors--</MenuItem>
                  <MenuItem value={'All'} key="All">
                    <Checkbox
                      color="primary"
                      checked={floorList.length === floorListData.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {floorListData.map((v, i) => {
                    return (
                      <MenuItem value={v} key={i}>
                        <Checkbox
                          color="primary"
                          checked={floorList.indexOf(v) > -1}
                        />
                        <ListItemText primary={v} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-source">Sources</InputLabel>
                <Select
                  labelId="dashboard-source"
                  id="dashboard-source"
                  value={sourceList}
                  onChange={(s) => {
                    handleSourceChange(s.target.value)
                  }}
                  label="Sources"
                  renderValue={(selected) =>
                    renderDropDown(selected, sourceList, sourceListData)
                  }
                  multiple
                >
                  <MenuItem disabled>--Select Sources--</MenuItem>
                  <MenuItem value={'All'} key="All">
                    <Checkbox
                      color="primary"
                      checked={sourceList.length === sourceListData.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {sourceListData.map((v, i) => {
                    return (
                      <MenuItem value={v} key={i}>
                        <Checkbox
                          color="primary"
                          checked={sourceList.indexOf(v) > -1}
                        />
                        <ListItemText primary={v} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <br />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper square>
              <CardContent>
                <Typography variant="h5" component="h2">
                  On-Site Population Insights
                </Typography>
                <hr />
                &nbsp;&nbsp;
                <BarChart
                  onClick={handleClickBarChart}
                  width={window.innerWidth * 0.95}
                  height={window.innerHeight * 0.5}
                  data={
                    isUpstreamDataListInitialized
                      ? consolidatedData
                      : DEFAULT_CONSOLIDATED_DATA
                  }
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis dataKey="total" />
                  <Tooltip />
                  <Legend />
                  {sourceListData.map((v) => {
                    return (
                      <Bar
                        dataKey={v}
                        stackId={sourceListMap[v]?.style?.stack || 'main'}
                        fill={sourceListMap[v]?.style?.fill || '#000000'}
                        fillOpacity={sourceListMap[v]?.style?.opacity || 1}
                        hide={sourceList.indexOf(v) < 0}
                      >
                        {isUpstreamDataListInitialized &&
                          sourceList.indexOf(v) === sourceList.length - 1 && (
                            <LabelList
                              dataKey="total"
                              position="top"
                              valueAccessor={(f) => 'asdf'}
                            />
                          )}
                      </Bar>
                    )
                  })}
                </BarChart>
              </CardContent>
            </Paper>
          </Grid>
        </Grid>

        <br />
      </main>
    </div>
  )
}
