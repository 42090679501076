import { Typography, Grid } from '@mui/material'
import LoginStyle from '../../styles/india/loginStyles'

const SorryPage = ({ message }) => {
  const classes = LoginStyle()

  return (
    <Grid
      container
      direction="column"
      // spacing={2}
      className={classes.loginBg}
    >
      <Grid item className={classes.logoStyle}>
        {' '}
        <img src="/wsrWhiteLogo.png" height="300px" alt="WSR logo" />
      </Grid>
      <Grid item className={classes.welcomeMsg}>
        <h2>Under Maintenance</h2>
        <Typography>{message}</Typography>
      </Grid>
    </Grid>
  )
}

export default SorryPage
