import { useState, useEffect } from 'react'
import { Stage, Layer, Image, Circle } from 'react-konva'
// import useImage from 'use-image'
import { IFPPanelStyle } from '../../styles/ifpPanelStyle'

export const DeskLayoutPreview = ({ allPoints, pngImage }) => {
  // const [text, setText] = useState('')
  // const [showButton, setShowButton] = useState(false)
  const [, setScalingWidth] = useState(1)
  const [, setScalingHeight] = useState(1)

  const [stageWidth] = useState(1500)
  const [stageHeight] = useState(800)
  const [, setImageHeight] = useState(null)
  const [, setImageWidth] = useState(null)

  const [image, setImage] = useState(null)
  const classes = IFPPanelStyle()

  useEffect(() => {
    const img = new window.Image()
    const fileReader = new FileReader()
    fileReader.onload = function () {
      img.src = fileReader.result
      setImage(img)
      img.onload = () => {
        setImageHeight(img.height)
        setImageWidth(img.width)
        let scaledHeight = stageHeight / img.height
        let scaledWidth = stageWidth / img.width

        setScalingHeight(scaledHeight)
        setScalingWidth(scaledWidth)
      }
    }
    fileReader.readAsDataURL(pngImage[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Stage
        // width={2844}
        // height={1942}
        // width={1132}
        // height={780}
        width={stageWidth}
        height={stageHeight}
        className={classes.stage}
      >
        <Layer>
          <Image
            image={image}
            // width={2844} height={1942}
            width={stageWidth}
            height={stageHeight}
          />
        </Layer>
        <Layer>
          {allPoints &&
            allPoints.map((circlePoint) => (
              <Circle
                key={circlePoint.space_id}
                id={circlePoint.space_id}
                x={circlePoint.x}
                y={circlePoint.y}
                // numPoints={5}
                radius={7}
                // outerRadius={40}
                // fill="#89b717"
                fill={circlePoint.color}
                opacity={1}
                // draggable
                // rotation={circlePoint.rotation}
                // shadowColor="black"
                // shadowBlur={10}
                // shadowOpacity={0.6}

                reservedBy={circlePoint.reservedBy}
                assignedTo={circlePoint.assignedTo}
                stroke="black"
                strokeWidth={1}
              />
            ))}
        </Layer>
      </Stage>
    </div>
  )
}
