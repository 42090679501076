import makeStyles from '@mui/styles/makeStyles'

export const LandingPageStyles = makeStyles((theme) => ({
  large: {
    width: 150,
    height: 200,
  },
  grid: {
    margin: 0,
  },
  top: {
    marginTop: 4,
    marginRight: 4,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  // infoIcon: {
  //   color: theme.palette.warning.main,
  // },
  noteParent: {
    display: 'flex',
    padding: 4,
    alignContent: 'center',
  },
  noteChild: {
    alignSelf: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  emoji: {
    fontSize: '2em',
  },
  noteRoot: {
    marginTop: '4%',
    justifyContent: 'center',
  },
  anchorBody: {
    '@media (max-width: 375px)': {
      top: 'calc(100% - 120px)',
      left: 'auto',
      bottom: 'auto',
      overflow: 'scroll',
      position: 'static',
      minWidth: '300px',
    },

    justifyContent: 'center',
    backgroundColor: 'rgb(51, 51, 51,0.9)',
    color: 'white',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },

  anchorLink: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginLeft: 50,
      color: 'white',
      textDecoration: 'none',
    },
    display: 'flex',
    marginLeft: 50,
    color: 'white',
    textDecoration: 'none',
  },
  otherApps: {
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      justifyItems: 'baseline',
    },
    display: 'flex',
  },
}))
