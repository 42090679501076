import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { UploadInteractiveFloorStyles } from '../../styles/uploadInteractiveFloorStyles'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { Button, Chip } from '@mui/material'
// import { DropzoneAreaBase } from 'material-ui-dropzone'
import { useDropzone } from 'react-dropzone'
import { useAuth } from '@praxis/component-auth'
import withStyles from '@mui/styles/withStyles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { parsedObject } from './parseDXF'
import Card from '@mui/material/Card'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import { DeskLayoutPreview } from './deskLayoutPreview'
import extractDesks from './extractDesksFromDXF'
import StepConnector from '@mui/material/StepConnector'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CircularProgress from '@mui/material/CircularProgress'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 18,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: 'green',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const SavePngDxfFile = () => {
  const env = useEnv()
  const config = env.constants
  const classes = UploadInteractiveFloorStyles()
  const auth = useAuth()
  const { session } = auth
  const [fileObjectsPNG, setFileObjectsPNG] = useState([])
  const [fileObjectsDXF, setFileObjectsDXF] = useState([])
  const [deskPoints, setDeskPoints] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const history = useNavigate()
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/x-dxf': ['.dxf'],
    },
    onDrop: (files) => handleDXFFile(files),
  })
  // eslint-disable-next-line no-unused-vars
  const dropzoneImage = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDrop: (files) => setFileObjectsPNG(files),
  })
  const selectedFloor = useSelector((state) => state.interactive.selectedFloor)
  const buildingId = useSelector((state) => state.interactive.buildingId)
  const buildingName = useSelector(
    (state) => state.interactive.selectedBuilding,
  )
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const [pngFiles, setPngFiles] = useState([])
  // const [dxfFiles, setDxfFiles] = useState([])

  async function handlePNGUpload() {
    let formData = new FormData()
    formData.append('file', fileObjectsPNG[0])
    formData.append('created_user_id', session.userInfo.lanId)
    formData.append('created_user_name', session.userInfo.fullName)
    formData.append('building_id', buildingId)
    formData.append('floor_id', selectedFloor.split(':')[0])
    formData.append('file_type', 'PNG')

    return axios
      .put(env.apiUrl['USA'].interactiveFloorUpload, formData)
      .then((response) => {
        setFileObjectsPNG([])
        return Promise.resolve(response.data)
        // toast.success('PNG file successfully uploaded', toastConfigure)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)

        toast.error('Error! Image file could not be uploaded', toastConfigure)
      })
  }

  async function handleDXFUpload() {
    let formData = new FormData()
    formData.append('file', fileObjectsDXF[0])
    formData.append('created_user_id', session.userInfo.lanId)
    formData.append('created_user_name', session.userInfo.fullName)
    formData.append('building_id', buildingId)
    formData.append('floor_id', selectedFloor?.split(':')[0])
    formData.append('file_type', 'DXF')

    return axios
      .put(env.apiUrl['USA'].interactiveFloorUpload, formData)
      .then((response) => {
        setFileObjectsDXF([])
        // toast.success('DXF file successfully uploaded', toastConfigure)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        setLoading(false)

        toast.error('Error! AutoCAD file could not be uploaded', toastConfigure)
      })
  }

  // const onDropPng = (acceptedFiles) => {
  //   setPngFiles(acceptedFiles)
  // }

  // const onDropDxf = (acceptedFiles) => {
  //   setDxfFiles(acceptedFiles)
  // }

  // const handlePNGDeleteFiles = (fileObject) => {
  //   setFileObjectsPNG([])
  // }

  // const handleDXFDeleteFiles = (fileObject) => {
  //   setFileObjectsDXF([])
  //   setActiveStep(0)
  // }

  // const handlePNGFile = (files) => {
  //   setFileObjectsPNG(files)
  //   setActiveStep(1)
  // }
  const handleDXFFile = (files) => {
    setFileObjectsDXF(files)
    ;(async function callItself() {
      let dataDxf = await parsedObject(files[0])
      let points = await extractDesks(
        JSON.parse(dataDxf),
        config.dxfSpaceUseClassList,
      )
      setDeskPoints(points)
    })()
  }

  const generatePreview = () => {
    handleClickOpen()
  }

  const submitFiles = (e) => {
    e.preventDefault()
    setLoading(true)
    handlePNGUpload()
      .then((pngUploaded) => {
        if (pngUploaded) return handleDXFUpload()
      })
      .then((dxfData) => {
        if (dxfData) {
          let payload = {}
          payload['building_id'] = buildingId
          payload['floor_id'] = selectedFloor.split(':')[0]
          payload['uploaded_by'] = session.userInfo.email.split('@')[0]
          payload['space_coordinates_details'] = deskPoints

          axios
            .put(env.apiUrl['USA'].addDeskFromFloorPlan, payload)
            .then((response) => {
              // setFileObjectsDXF([])
              toast.success('Desk data saved Successfully', toastConfigure)
              setActiveStep(0)
              setLoading(false)
              setTimeout(history('/cre/upload'), 6000)
            })
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error('Error! Desk data could not be saved', toastConfigure)
      })

    // handleDXFUpload()
  }

  return (
    <div style={{ padding: '1%', paddingTop: '2%' }}>
      <Card className={classes.cardRoot} elevation={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Typography variant={'h4'}>
              Building: {buildingName} &nbsp;&nbsp;Floor:{' '}
              {selectedFloor?.split(':')[1]}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
            <Stepper
              alternativeLabel
              connector={<ColorlibConnector />}
              activeStep={activeStep}
            >
              <Step>
                {fileObjectsPNG.length === 0 ? (
                  <StepLabel
                    icon={
                      <ImageOutlinedIcon color="primary" fontSize="large" />
                    }
                  >
                    PNG File
                  </StepLabel>
                ) : (
                  <StepLabel
                    icon={
                      <CheckCircleOutlinedIcon
                        style={{ color: 'green' }}
                        fontSize="large"
                      />
                    }
                  >
                    PNG File
                  </StepLabel>
                )}
              </Step>
              <Step>
                {fileObjectsDXF.length === 0 ? (
                  <StepLabel
                    icon={
                      <DescriptionOutlinedIcon
                        color="primary"
                        fontSize="large"
                      />
                    }
                  >
                    DXF File
                  </StepLabel>
                ) : (
                  <StepLabel
                    icon={
                      <CheckCircleOutlinedIcon
                        style={{ color: 'green' }}
                        fontSize="large"
                      />
                    }
                  >
                    DXF File
                  </StepLabel>
                )}
              </Step>
            </Stepper>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={5}>
            {/* <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}> */}
            {/* <DropzoneAreaBase
                  dropzoneText={'Drag and drop a PNG file here or click'}
                  onAdd={handlePNGFile}
                  // onDrop={onDropPng}
                  acceptedFiles={['image/png']}
                  maxFileSize={20000000} //20MB
                  filesLimit={1}
                  showFileNames={true}
                  useChipsForPreview={true}
                  fileObjects={fileObjectsPNG}
                  onDelete={handlePNGDeleteFiles}
                /> */}
            {/* </ThemeProvider>
            </StyledEngineProvider> */}
            <section className="container">
              <div
                {...dropzoneImage.getRootProps({ className: 'dropzone' })}
                className={classes.dropzone}
              >
                <input {...dropzoneImage.getInputProps()} />
                <p>Drag & drop PNG file here or click</p>
                {fileObjectsPNG.map((file) => (
                  <Chip key={file.path} variant="outlined" label={file.path} />
                ))}
              </div>
            </section>
          </Grid>
          <Grid item xs={12} lg={5}>
            {/* <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}> */}
            {/* <DropzoneAreaBase
                  dropzoneText={'Drag and drop a DXF file here or click'}
                  onAdd={handleDXFFile}
                  // onDrop={onDropDxf}
                  acceptedFiles={['.dxf']}
                  filesLimit={1}
                  maxFileSize={100000000} //100MB
                  showFileNames={true}
                  useChipsForPreview={true}
                  fileObjects={fileObjectsDXF}
                  onDelete={handleDXFDeleteFiles}
                /> */}
            {/* </ThemeProvider>
            </StyledEngineProvider> */}
            <section className="container">
              <div
                {...getRootProps({ className: 'dropzone' })}
                className={classes.dropzone}
              >
                <input {...getInputProps()} />
                <p>Drag & drop DXF file here or click</p>
                {fileObjectsDXF.map((file) => (
                  <Chip key={file.path} variant="outlined" label={file.path} />
                ))}
              </div>
            </section>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/cre/upload"
              className={classes.iconHome}
            >
              {' '}
              Cancel{' '}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              className={classes.iconHome}
              disabled={
                fileObjectsPNG.length === 0 || fileObjectsDXF.length === 0
              }
              onClick={generatePreview}
            >
              Preview
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={
                fileObjectsPNG.length === 0 ||
                fileObjectsDXF.length === 0 ||
                loading
              }
              onClick={submitFiles}
            >
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          // fullScreen
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xl"
          // TransitionComponent={Transition}
        >
          {/* <AppBar className={classes.appBar}>
          <Toolbar> */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <DeskLayoutPreview allPoints={deskPoints} pngImage={fileObjectsPNG} />
        </Dialog>
      </Card>
    </div>
  )
}

export default SavePngDxfFile
