import { green } from '@mui/material/colors'
// import { createMuiTheme } from '@mui/material/styles'
import { createTheme as createMuiTheme } from '@mui/material/styles'
// import green from '@material-ui/core/colors/green'
import { red } from '@mui/material/colors'
// export const primary = {
//   light: '#fa5788',
//   main: '#c2185b',
//   // dark: '#8c0032',
//   contrastText: '#fff',
// }

// export const primary = {
//   light: {
//     lightBackground: '#D3D3D3',
//     lightText: '#2C383F',
//     dialogHeaderBackground: '#00838F',
//     dialogHeaderTextColor: 'white',
//     lightGrayBackground: '#f7f7f7',
//     errorTextColor: '#e53935',
//     addButtonText: '#F06C01',
//     addButtonTextColor: '#00838F',
//     disabled: '#808080',
//   },
//   dark: {
//     darkHeaderBackground: '#212E35',
//     darkHeaderTextColor: 'white',
//     darkLabelText: '#989898',
//     labelFontSize: '14px',
//     darkContentTextColor: 'black',
//   },
//   status: {
//     success: '#597a0d',
//     failed: '#ce3e1a',
//   },
//   contrastText: '#fff',
//   main: '#AC0000',
//   hover: '#01a3b2',
//   //Deedee's suggestion
//   // main: '#3E72DB',
//   // hover: '#2151b1',
// }

export const primary = {
  light: '#e6482c',
  main: '#ac0000',
  dark: '#750000',
  contrastText: '#ffffff',
}

const sec = {
  light: '#efefef',
  main: '#bdbdbd',
  dark: '#8d8d8d',
  contrastText: '#000000',
}

// const sec = {
//   light: '#f05545',
//   main: '#263238',
//   dark: '#7f0000',
//   contrastText: '#fff',
// }

export const size = {
  small: '12px',
  medium: '15px',
  large: '16px',
  x_large: '18px',
  xx_large: '24px',
}
const warning = {
  main: '#ff9800',
}
const success = {
  light: green[400],
  main: green[500],
  dark: green[600],
}

const error = {
  main: red[500],
  light: red[400],
}

// const error = {
//   light: '#e57373',
//   main: '#f44336',
//   dark: '#d32f2f',
//   contrastText: '#fff',
// }

const text = {
  primary: 'rgba(0, 0, 0, 0.68)',
  secondary: 'rgba(0, 0, 0, 0.54)',
  disabled: 'rgba(0, 0, 0, 0.38)',
  hint: 'rgba(0, 0, 0, 0.38)',
}
export const praxisTheme = createMuiTheme({
  palette: {
    primary: primary,
    secondary: sec,
    size: size,
    // type: 'dark',
    warning: warning,
    success: success,
    text: text,
    error: error,
  },
  typography: {
    fontFamily: ['calibri', 'sans-serif'].join(','),
    fontSize: 14,
    color: '#A9A9A9',
  },
  spacing: 4,
})
// primary: {
//   main: '#f48fb1',
// },
// secondary: {
//   main: '#f06292',
// },
export const darkTheme = createMuiTheme({
  ...praxisTheme,
  palette: {
    type: 'dark',

    primary: {
      main: '#212E35',
    },
    secondary: {
      main: '#c8b7b5',
    },
  },
})
