import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import LoginStyle from '../../styles/india/loginStyles'

const UnavailablePage = ({ message }) => {
  const classes = LoginStyle()

  return (
    <Grid container direction="column" style={{ textAlign: 'center' }}>
      <Grid
        item
        className={classes.unavailableMsg}
        style={{ textAlign: 'center' }}
      >
        <h2>Sorry, this page is not available</h2>
        <Typography>{message}</Typography>
      </Grid>
    </Grid>
  )
}

export default UnavailablePage
