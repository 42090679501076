import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import { UploadInteractiveFloorStyles } from '../../styles/uploadInteractiveFloorStyles'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { Button } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import fileDownload from 'js-file-download'
import TextField from '@mui/material/TextField'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import HomeIcon from '@mui/icons-material/Home'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const UploadLandingPage = () => {
  const env = useEnv()
  const classes = UploadInteractiveFloorStyles()
  const dispatch = useDispatch()
  // const [newFloor, setNewFloor] = useState()
  const interactiveState = useSelector((state) => state.interactive)
  const allBuildings = useSelector((state) => state.interactive.allBuildings)
  const floorMetadata = useSelector((state) => state.interactive.floorMetadata)
  const [building, setBuilding] = useState('')
  const [bldng, setBldng] = useState('')

  useEffect(() => {
    refreshBuilding()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshBuilding = () => {
    const url = env.apiUrl['USA'].getAllBuildingsUrl
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SAVE_INTERACTIVE_ALL_BUILDINGS',
          payload: res.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const buildingChange = (event, value) => {
    if (value) {
      setBldng(value)
      setBuilding(value.building_details.building_name)
    } else {
      setBuilding('')
      setBldng('')
    }
  }

  useEffect(() => {
    handleBuildingChange(building)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, allBuildings])

  const handleBuildingChange = (building) => {
    dispatch({ type: 'SAVE_INTERACTIVE_FLOOR_METADATA', payload: [] })
    if (building !== '') {
      dispatch({
        type: 'SAVE_INTERACTIVE_BUILDING',
        payload: building,
      })
      dispatch({
        type: 'SAVE_INTERACTIVE_BUILDINGID',
        payload: allBuildings.find(
          (bldng) => bldng.building_details.building_name === building,
        ).building_details.building_id,
      })
      let floors = interactiveState.allBuildings
        .find((bldng) => bldng.building_details.building_name === building)
        .floor_details.sort()
      dispatch({ type: 'SAVE_INTERACTIVE_ALL_FLOORS', payload: floors })
      dispatch({ type: 'SAVE_INTERACTIVE_FLOOR', payload: '' })
    } else {
      dispatch({ type: 'SAVE_INTERACTIVE_ALL_FLOORS', payload: [] })
      dispatch({ type: 'SAVE_INTERACTIVE_FLOOR', payload: '' })
    }
  }

  const handleFloorChange = (event) => {
    dispatch({ type: 'SAVE_INTERACTIVE_FLOOR', payload: event.target.value })
  }

  useEffect(() => {
    const floorData = interactiveState.selectedFloor.split(':')
    if (floorData[0] !== '' && floorData[0] !== 'newfloor') {
      getFloorMetadata()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactiveState.selectedFloor])

  const getFloorMetadata = () => {
    const floorData = interactiveState.selectedFloor.split(':')

    const url =
      env.apiUrl['USA'].getFloorMetadata +
      `building_id=${interactiveState.buildingId}&floor_id=${floorData[0]}`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SAVE_INTERACTIVE_FLOOR_METADATA',
          payload: res.data,
        })
      })
      .catch((error) => {
        toast.error(`Error - ${error}`, toastConfigure)
      })
  }

  const handleDownload = (filepath, filename) => {
    const url = env.apiUrl['USA'].downloadUrl + filepath
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
      responseType: 'arraybuffer',
    }
    axios.get(url, config).then((res) => {
      fileDownload(res.data, filename)
    })
  }

  return (
    <div style={{ padding: '1%', paddingTop: '1%' }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item></Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <Autocomplete
              id="building-autocomplete"
              className={classes.formControl}
              options={allBuildings || []}
              value={bldng}
              onChange={buildingChange}
              getOptionLabel={(option) =>
                option.building_details
                  ? option.building_details.building_name
                  : ''
              }
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Building Name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    style: { minWidth: 300 },
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </FormControl>
          &nbsp;&nbsp;
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Floor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={interactiveState.selectedFloor}
              onChange={handleFloorChange}
              label="Floor Name"
            >
              <MenuItem disabled>--Choose Floor--</MenuItem>

              {interactiveState.allFloors &&
                interactiveState.allFloors.map((floor, key) => {
                  return (
                    <MenuItem
                      value={`${floor.floor_id}:${floor.floor_name}`}
                      key={floor.floor_id}
                    >
                      {floor.floor_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/cre/dashboard"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <List className={classes.list}>
        {floorMetadata.map((floor) => {
          return (
            <ListItem>
              <Grid
                container
                direction="row"
                // justify="center"
                alignItems="center"
                style={{ fontWeight: '520' }}
              >
                <Grid item>
                  <ListItemAvatar>
                    <Avatar variant="rounded" className={classes.avatarStyle}>
                      <DescriptionIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={<b>{floor.file_name}</b>}
                    secondary={`Last updated on ${moment(
                      floor.created_date,
                    ).format('MMMM Do YYYY')} by ${floor.created_user_name}`}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.btn}
                    onClick={() => {
                      handleDownload(floor.toss_file_path, floor.file_name)
                    }}
                  >
                    Download
                  </Button>
                  &nbsp;&nbsp;
                  <hr />
                </Grid>
              </Grid>
            </ListItem>
          )
        })}
      </List>
      {interactiveState.selectedFloor?.split(':')[0] !== '' &&
        interactiveState.selectedFloor?.split(':')[0] !== 'newfloor' && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ fontWeight: '520' }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                component={Link}
                to="/cre/savefiles"
              >
                {floorMetadata.length === 0
                  ? 'Add PNG & DXF File'
                  : 'Update files'}
              </Button>
            </Grid>
          </Grid>
        )}
      <br />
    </div>
  )
}

export default UploadLandingPage
