import { useEffect } from 'react'
import MaterialTable from '@material-table/core'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import {
  exportCustomCSV,
  tableIcons,
} from '../../components/common/commonFunctionalities'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'
import { MenuItem, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import moment from 'moment'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  typographyValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  typographyMediumValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#ffa505',
  },
  typographyHighValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  parentBox: {
    padding: '10px',
  },
  selectPadding: {
    padding: '10px',
  },
}))

export default function Feedback() {
  const env = useEnv()
  const classes = useStyles()
  const [feedbackList, setFeedbackList] = useState([])
  const [lastDays, setLastDays] = useState(500)
  const location = useSelector((state) => state.common.location)
  const handleChange = (event) => {
    setLastDays(event.target.value)
  }

  const getFeedbackList = () => {
    const url =
      env.apiUrl['USA'].feedbackList +
      `?last_days=${lastDays}&location=${location}`
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        res.data.forEach((element) => {
          element['created_time'] = moment(element['created_time']).format(
            'MM/DD/YYYY',
          )
        })
        setFeedbackList(res.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getFeedbackList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastDays])

  return (
    <>
      <Box className={classes.parentBox}>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <Typography className={classes.typographyHighValue}>
              Feedback for the last days
            </Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <Select
                className={classes.Select}
                value={lastDays}
                variant="outlined"
                onChange={handleChange}
                inputProps={{
                  name: 'Last Days',
                  id: 'uncontrolled-native',
                }}
                classes={{ select: classes.selectPadding }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Box>
          <MaterialTable
            title="Feedback Response List"
            columns={[
              { title: 'User', field: 'created_user_id' },
              { title: 'Feedback type', field: 'feedback_type' },
              { title: 'Comments', field: 'comments' },
              { title: 'Created Date', field: 'created_time' },
            ]}
            data={feedbackList}
            icons={tableIcons}
            options={{
              exportButton: true,
              exportAllData: true,
              pageSize: 10,
              search: true,
              pageSizeOptions: [10, 25, 50],
              exportMenu: [
                {
                  label: 'Export CSV',
                  exportFunc: (cols, datas) =>
                    exportCustomCSV(cols, datas, 'feedback'),
                },
              ],
            }}
          />
        </Box>
      </Box>
    </>
  )
}
