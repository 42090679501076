import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  DialogActions,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Typography,
  FormControlLabel,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React from 'react'
import { useEnv } from '@praxis/component-runtime-env'
import Checkbox from '@mui/material/Checkbox'

const AddFloorDialog = ({
  loading,
  handleClose,
  open,
  newFloor,
  setNewFloor,
  classes,
  addNewFloor,
  dialogTitle,
  editFloor,
  buildingId,
  openMailSection,
  setOpenMailSection,
  options,
  setOptions,
  sendMail,
  showCancelReservationMessage,
  setShowCancelReservationMessage,
}) => {
  const env = useEnv()
  const handleReservedForTyping = (event, newValue) => {
    if (newValue.length >= 3) {
      const payloadOptions = {
        method: 'GET',
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      //  setLoading(true)
      ;(async () => {
        try {
          let url = env.apiUrl['USA'].userNameSearch + newValue
          const response = await fetch(url, payloadOptions)
          const data = await response.json()
          // setLoading(false)

          let filterData = data.map((item) => {
            return {
              reserved_for_id: item.lan_id,
              reserved_for: item.employee_name,
              email: item.email_id,
            }
          })
          setOptions(filterData)
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }
  const handleBookingForChange = (event, newValue) => {
    if (newValue !== null) {
      setNewFloor({
        ...newFloor,
        to: newValue.email,
      })
    }
  }

  const handleSRFCheckBox = (event, newValue) => {
    setNewFloor({
      ...newFloor,
      is_serviceable: newValue,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <form
        onSubmit={
          dialogTitle === 'Edit'
            ? !openMailSection
              ? editFloor
              : sendMail
            : addNewFloor
        }
        name="building-form"
      >
        <DialogTitle id="form-dialog-title">{dialogTitle} Floor</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <TextField
                label="Building ID"
                variant="outlined"
                disabled
                className={classes.normalFields}
                value={buildingId}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="Floor Name"
                variant="outlined"
                required
                disabled={dialogTitle === 'Edit' ? true : false}
                className={classes.normalFields}
                value={newFloor.floor_id}
                onChange={(event) =>
                  setNewFloor({
                    ...newFloor,
                    floor_id: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="Maximo Floor Code"
                variant="outlined"
                className={classes.normalFields}
                value={newFloor.maximo_floor_code}
                onChange={(event) =>
                  setNewFloor({
                    ...newFloor,
                    maximo_floor_code: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                classes={{ root: classes.normalFields }}
                className={classes.spaceHierarchySelect}
              >
                <InputLabel id="floor-status-label">Floor Status</InputLabel>
                <Select
                  labelId="floor-status-label"
                  label="Floor Status"
                  id="floor-status"
                  name="floor-status"
                  value={newFloor.floor_status}
                  onChange={(event) => {
                    setNewFloor({
                      ...newFloor,
                      floor_status: event.target.value,
                    })
                    setOpenMailSection(false)
                  }}
                  // autoWidth={true}
                >
                  <MenuItem value="Reservable">Reservable</MenuItem>
                  <MenuItem value="Permanent">Permanent</MenuItem>
                  <MenuItem value="Homebase">Homebase</MenuItem>
                  <MenuItem value="Draft">Draft</MenuItem>
                </Select>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={srfIndicator}
                      checked={newFloor?.is_serviceable}
                      onChange={handleSRFCheckBox}
                      name="Visible to CRESRF form"
                      color="primary"
                    />
                  }
                  label={'Visible to CRESRF form'}
                />
              </FormControl>
            </Grid>
            {showCancelReservationMessage && (
              <Grid item>
                <Typography variant="body2" className={classes.normalFields}>
                  <b>
                    Please note that changing floor status to{' '}
                    {newFloor.floor_status} will cancel all active reservations.
                    Do you want to proceed ?
                  </b>
                </Typography>
              </Grid>
            )}

            {openMailSection && (
              <Grid item>
                <Typography variant="body2" className={classes.emailText}>
                  <b>
                    This floor has assigned spaces. Please enter details below
                    to notify CRE
                  </b>
                </Typography>
                <Autocomplete
                  required
                  id="reserve-others"
                  className={classes.normalFields}
                  options={options}
                  //  loading={loading}
                  // value={
                  //   reservationFormFields.reserved_for ||
                  //   teamMemberDetails.reserved_for
                  // }
                  value={newFloor.email}
                  getOptionLabel={(option) => option.reserved_for}
                  onInputChange={handleReservedForTyping}
                  onChange={handleBookingForChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Send To"
                      variant="outlined"
                      placeholder={'Search by firstname.lastname'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <Grid item>
                  <TextField
                    label="Additional Notes"
                    variant="outlined"
                    required
                    className={classes.normalFields}
                    value={newFloor.notes}
                    onChange={(event) =>
                      setNewFloor({
                        ...newFloor,
                        additional_notes: event.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          {dialogTitle === 'Edit' ? (
            !openMailSection ? (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={
                  newFloor.floor_status === newFloor.old_floor_status &&
                  newFloor.is_serviceable === newFloor?.old_is_serviceable &&
                  newFloor.maximo_floor_code === newFloor?.old_maximo_floor_code
                }
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={newFloor.floor_status === newFloor.old_floor_status}
              >
                Send Mail
              </Button>
            )
          ) : (
            <Button variant="contained" type="submit" color="primary">
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddFloorDialog
