import { USA } from '../components/common/constants'

let initialState = {
  userAccess: null,
  location: USA,
  allowedRoutes: null,
  userBusinessUnit: null,
  reservingForBusinessUnit: null,
  //specific to India
  manager: '',
  userProfile: null,
  bookedForUserProfile: null,
  userCompensation: null,
  bookedForUserCompensation: null,
  userFlow: '',
  weekendBooking: false,
  //specific to US
  userCompensationUS: null,
  reservingForCompensationUS: null,
  srfForUserProfile: null,
}

export function CommonReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_ACCESS_DETAILS':
      return Object.assign({}, state, {
        userAccess: payload,
      })

    case 'SAVE_USER_LOCATION':
      return Object.assign({}, state, {
        location: payload,
      })

    case 'SAVE_ROUTES':
      return Object.assign({}, state, {
        allowedRoutes: payload,
      })

    case 'SAVE_MANAGER_ID':
      return Object.assign({}, state, {
        manager: payload,
      })

    case 'SAVE_USER_PROFILE':
      return Object.assign({}, state, {
        userProfile: payload,
      })

    case 'SAVE_BOOKED_FOR_USER_PROFILE':
      return Object.assign({}, state, {
        bookedForUserProfile: payload,
      })

    case 'CLEAR_BOOKED_FOR_USER_PROFILE':
      return Object.assign({}, state, {
        bookedForUserProfile: null,
        bookedForUserCompensation: null,
      })

    case 'SAVE_INDIA_FLOW':
      return Object.assign({}, state, {
        userFlow: payload,
      })

    case 'SET_WEEKEND_BOOKING':
      return Object.assign({}, state, {
        weekendBooking: payload,
      })

    case 'SAVE_USER_COMPENSATION':
      return Object.assign({}, state, {
        userCompensation: payload,
      })

    case 'SAVE_BOOKED_FOR_USER_COMPENSATION':
      return Object.assign({}, state, {
        bookedForUserCompensation: payload,
      })

    case 'SAVE_USER_COMPENSATION_US':
      return Object.assign({}, state, {
        userCompensationUS: payload,
      })

    case 'SAVE_USER_BUSINESS_UNIT':
      return Object.assign({}, state, {
        userBusinessUnit: payload,
      })

    case 'RESERVING_FOR_BUSINESS_UNIT':
      return Object.assign({}, state, {
        reservingForBusinessUnit: payload,
      })

    case 'RESERVING_FOR_USER_COMPENSATION_US':
      return Object.assign({}, state, {
        reservingForCompensationUS: payload,
      })

    case 'SAVE_SRF_FOR_USER_PROFILE':
      return Object.assign({}, state, {
        srfForUserProfile: payload,
      })

    case 'CLEAR_SRF_FOR_USER_PROFILE':
      return Object.assign({}, state, {
        srfForUserProfile: null,
      })

    default:
      return state
  }
}
