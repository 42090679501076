import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { css } from '@emotion/react'
import BeatLoader from 'react-spinners/BeatLoader'

const reservationMap = {
  collab: 'Collaboration',
  hitech: 'Hi-Tech',
  permanent: 'Permanent',
  reservable: 'Reservable',
  transport: 'Transport',
  lunch_food_count: 'Lunch',
  total: 'Total',
  food_count: 'Food count',
}

const tableMap = {
  collab: 'collab',
  hitech: 'Hi-Tech',
  permanent: 'Permanent',
  reservable: 'Reservable',
  transport: 'Transport',
  lunch_food_count: 'Lunch',
  total: 'Total',
  food_count: 'Food count',
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const TIIOverview = ({ isLoading, cardInfo, classes, selectCard }) => {
  return (
    <div className={classes.container}>
      {isLoading === true ? (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '50vh' }}
        >
          <BeatLoader color="#AC0007" css={override} size={15} />
        </Grid>
      ) : (
        <Grid
          container
          // cols={2} rowHeight={100} cellHeight={150}
          spacing={8}
        >
          {cardInfo &&
            Object.keys(cardInfo).map((item) => (
              <Grid item>
                <Card
                  className={classes.card}
                  raised={true}
                  onClick={(e) => selectCard(e, tableMap[item])}
                  // variant="outlined"
                  elevation={4}
                  spacing={4}
                >
                  <CardContent className={classes.cardContentOverview}>
                    <Typography variant="h6" color="textSecondary">
                      {reservationMap[item]}
                    </Typography>
                    <hr />
                    <Typography variant="h4" className={classes.number}>
                      {cardInfo[item].total}
                    </Typography>
                    <Typography variant="h8" color="textSecondary" gutterBottom>
                      {'Total'}
                    </Typography>
                    <Grid
                      container
                      spacing={10}
                      justifyContent="center"
                      className={classes.containerItem}
                    >
                      <Grid className={classes.subCard}>
                        <Typography
                          variant="h6"
                          className={classes.numberActive}
                        >
                          {item === 'transport'
                            ? cardInfo[item].transport_end_time_1
                            : item === 'lunch_food_count'
                            ? cardInfo[item].veg
                            : item === 'food_count'
                            ? cardInfo[item].breakfast
                            : cardInfo[item].active}
                        </Typography>
                        <Typography
                          variant="h8"
                          color="textSecondary"
                          gutterBottom
                        >
                          {item === 'transport'
                            ? '4:15 PM'
                            : item === 'lunch_food_count'
                            ? 'Veg'
                            : item === 'food_count'
                            ? 'Breakfast'
                            : 'Active'}
                        </Typography>
                      </Grid>
                      <Grid className={classes.subCard}>
                        <Typography
                          variant="h6"
                          className={classes.numberComplete}
                        >
                          {item === 'transport'
                            ? cardInfo[item].transport_end_time_2
                            : item === 'lunch_food_count'
                            ? cardInfo[item].non_veg
                            : item === 'food_count'
                            ? cardInfo[item].lunch
                            : cardInfo[item].completed}
                        </Typography>
                        <Typography
                          variant="h10"
                          color="textSecondary"
                          gutterBottom
                        >
                          {item === 'transport'
                            ? '6:00 PM'
                            : item === 'lunch_food_count'
                            ? 'Non-Veg'
                            : item === 'food_count'
                            ? 'Lunch'
                            : 'Completed'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  )
}

export default TIIOverview
