import React from 'react'
import { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useEnv } from '@praxis/component-runtime-env'
import TextField from '@mui/material/TextField'
import { useAuth } from '@praxis/component-auth'
import Grid from '@mui/material/Grid'
import {
  Typography,
  FormLabel,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'
import { RequestFormStyle } from '../../styles/india/requestFormStyle'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import RequestDetails from './requestDetails'
import { CardActions } from '@mui/material'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { dialingCode } from '../../components/common/constants'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import SuccessDialogWorkOrder from '../../components/common/serviceRequestSuccess'
import UnavailablePage from '../../components/common/UnavailablePage'

let initialStateCRESRF = {
  requestDetails: {
    selected_building_value: null,
    selected_building: '',
    isInteriorOrExterior: 'interior',
    selected_floor: '',
    selected_sublocation: '',
    selected_type_issue: '',
    selected_issue: '',
    asset_number: '',
    short_description: '',
    additional_details: '',
    problem_code: '',
    selected_space: '',
    location_id: '',
    created_for_phone_no: '',
    is_equipment_problem: false,
  },
  toggle: false,
  selectedSpaceData: [],
  userSearch: null,
  workOrderNumber: '',
}

export default function RequestForm() {
  const [requestorRadio, setRequestorRadio] = React.useState('No')

  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const disablePage = env.constants.disablePage
  const classes = RequestFormStyle()

  const currentUserProfile = useSelector((state) => state.common.userProfile)
  const [toggle, setToggle] = useState(initialStateCRESRF.toggle)
  const [spaceId, setSpaceId] = useState('')
  const [requestorProfile, setRequestorProfile] = useState(currentUserProfile)

  const location = useSelector((state) => state.common.location)
  const [loading, setLoading] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [workOrderNumber, setWorkOrderNumber] = useState(
    initialStateCRESRF.workOrderNumber,
  )

  const [radioValue, setRadioValue] = useState('No')
  const [userSearch, setUserSearch] = useState(initialStateCRESRF.userSearch)

  const [options, setOptions] = useState([])
  const [submitloading, setSubmitLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [selectedSpaceData, setSelectedSpaceData] = useState(
    initialStateCRESRF.selectedSpaceData,
  )
  const [requestDetailsData, setRequestDetailsData] = useState(
    initialStateCRESRF.requestDetails,
  )

  useEffect(() => {
    if (!currentUserProfile) {
      const profileUrl =
        env.apiUrl[location].userProfile +
        session.userInfo.lanId +
        `&emp_id=${session.identity.employeeid}&location=${location}`

      axios
        .get(profileUrl)
        .then((res) => {
          setTimeout(() => {
            //setProfile(false)
          }, 2000)
          dispatch({ type: 'SAVE_USER_PROFILE', payload: res.data })
        })
        .catch((error) => {
          console.error(error)
          //setProfile(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfile])

  useEffect(() => {
    if (radioValue === 'Yes') {
      if (userSearch && userSearch !== '') {
        if (requestorProfile?.user_details?.building_id) {
          setSpaceId(
            requestorProfile?.user_details?.building_id +
              '/' +
              requestorProfile?.user_details?.floor_id +
              '/' +
              requestorProfile?.user_details?.desk_id,
          )
        } else {
          setSpaceId('')
        }
      } else {
        setSpaceId('')
      }
    } else {
      setRequestorProfile(currentUserProfile)
      if (currentUserProfile?.user_details?.building_id) {
        setSpaceId(
          currentUserProfile?.user_details?.building_id +
            '/' +
            currentUserProfile?.user_details?.floor_id +
            '/' +
            currentUserProfile?.user_details?.desk_id,
        )
      } else {
        setSpaceId('')
      }
    }
  }, [currentUserProfile, requestorProfile, radioValue, userSearch])

  const handleRadioButtons = (event, value) => {
    setRequestorRadio(value)
    setRadioValue(value)
    if (value === 'No') {
      dispatch({ type: 'CLEAR_SRF_FOR_USER_PROFILE' })
      setToggle(false)
      setUserName('')
      setUserSearch('')
    }
  }

  const handleReservedForTyping = (event, newValue) => {
    if (newValue.length >= 3) {
      const payloadOptions = {
        method: 'GET',
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      setLoading(true)
      ;(async () => {
        try {
          let url = env.apiUrl[location].userNameSearch + newValue
          const response = await fetch(url, payloadOptions)
          const data = await response.json()
          setLoading(false)

          let filterData = data.map((item) => {
            return {
              reserved_for_mail: item.email_id,
              reserved_for_id: item.lan_id,
              reserved_for: item.employee_name,
              reserved_for_employee_id: item.employee_id,
            }
          })

          setOptions(filterData)
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }

  const handleBookingForChange = (event, newValue) => {
    setUserSearch(newValue)
    setUserName(newValue?.reserved_for)

    const profileUrl =
      env.apiUrl[location].userProfile +
      newValue?.reserved_for_id +
      `&emp_id=${newValue?.reserved_for_employee_id}&location=${location}`

    let config = {
      headers: { Authorization: localStorage.access_token },
    }
    axios.get(profileUrl, config).then((userData) => {
      setRequestorProfile(userData?.data)
      setToggle(true)
    })
  }

  const dispatch = useDispatch()

  const handlePhoneChange = (e) => {
    let maxNum = 10
    const regex = /^[0-9\b]+$/
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum)
    }
    if (e.target.value === '' || regex.test(e.target.value)) {
      setRequestDetailsData({
        ...requestDetailsData,
        created_for_phone_no: e.target.value,
      })
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setSubmitLoading(true)
    let copyRequestDetailsData = { ...requestDetailsData }
    let buildingArray = copyRequestDetailsData.selected_building.split(':')
    let floorArray = copyRequestDetailsData.selected_floor.split(':')
    let sublocationArray =
      copyRequestDetailsData.selected_sublocation.split(':')
    copyRequestDetailsData['selected_building'] = buildingArray[0]
    copyRequestDetailsData['location_id'] =
      buildingArray[2].length > 3
        ? 'T' + buildingArray[2]
        : 'T0' + buildingArray[2]

    copyRequestDetailsData['is_equipment_problem'] =
      copyRequestDetailsData.selected_type_issue === 'equipment'

    if (copyRequestDetailsData.is_equipment_problem) {
      copyRequestDetailsData['selected_sublocation'] = sublocationArray[0]
      copyRequestDetailsData['problem_code'] = 'SERVICE_REQUEST'
    } else {
      copyRequestDetailsData['selected_sublocation'] = floorArray[1]
    }

    copyRequestDetailsData['created_by_lan_id'] = session.userInfo.lanId
    copyRequestDetailsData['created_for_lan_id'] =
      userSearch && userSearch !== ''
        ? userSearch.reserved_for_id
        : session.userInfo.lanId
    copyRequestDetailsData['created_for_email'] =
      userSearch && userSearch !== ''
        ? userSearch.reserved_for_mail
        : session.userInfo.email

    if (selectedSpaceData.length > 0) {
      let spaces = selectedSpaceData.map((item) => item.space_no)
      copyRequestDetailsData['selected_space'] = spaces.join(',')
    }

    copyRequestDetailsData.created_for_phone_no =
      requestDetailsData.created_for_phone_no !== ''
        ? dialingCode[location] + requestDetailsData.created_for_phone_no
        : ''

    delete copyRequestDetailsData['isInteriorOrExterior']
    delete copyRequestDetailsData['selected_floor']
    delete copyRequestDetailsData['selected_issue']
    delete copyRequestDetailsData['selected_type_issue']
    delete copyRequestDetailsData['selected_building_value']
    let url = env.apiUrl[location].submitWorkorder

    axios
      .post(url, copyRequestDetailsData)
      .then((response) => {
        if (response.status === 200) {
          setSubmitLoading(false)
          setWorkOrderNumber(response.data.work_order_number)
          setSuccessDialogOpen(true)
        } else {
          throw new Error('Error!There was some issue in creating workorder')
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setSubmitLoading(false)
          console.log(err)
          toast.error(
            'There was an issue submitting to Maximo. Please contact CRE.BuildingOperations@target.com with the message ' +
              '"' +
              err.response.data.message +
              '"',
            toastConfigure,
          )
        } else {
          setSubmitLoading(false)
          console.log(err)
          toast.error(
            'Error!There was some problem in creating workorder. Please contact WSR team ' +
              '"' +
              err.response.data.message +
              '"',
            toastConfigure,
          )
        }
      })
  }

  const clearState = () => {
    setRequestDetailsData(initialStateCRESRF.requestDetails)
    setUserSearch(initialStateCRESRF.userSearch)
    dispatch({ type: 'CLEAR_SRF_FOR_USER_PROFILE' })
    setRadioValue('No')
    setRequestorRadio('No')
    setToggle(initialStateCRESRF.toggle)
    setSelectedSpaceData(initialStateCRESRF.selectedSpaceData)
  }

  if (disablePage) {
    return <UnavailablePage message={env.constants.unavailableMsg} />
  }

  return (
    <div className={classes.root}>
      <Tooltip title="Home" enterTouchDelay="0" arrow>
        <IconButton
          size="small"
          color="primary"
          component={Link}
          to="/tm/home"
          className={classes.iconHome}
        >
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <div className={classes.cardParent}>
        <Card
          elevation={2}
          sx={{ width: { lg: '70%', xl: '70%' }, padding: '1%' }}
        >
          <form onSubmit={handleFormSubmit}>
            <CardContent>
              <Grid container xs={12} justifyContent="center">
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.title}
                  >
                    SERVICE REQUEST FORM - CRE BUILDING OPERATIONS
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtitle}>
                    <b>For US</b>: Service requests received after regular
                    business hours (7:00 am - 5:00 pm Central Time) will be
                    responded to on the next business day except for emergency
                    work orders which will be responded to within one hour.
                  </Typography>
                  <br />
                  <Typography className={classes.subtitle}>
                    <b>For TII</b>: Service requests received after regular
                    business hour (9:00 am - 6:00 pm Indian Standard Time) will
                    be responded to on the next business day.
                  </Typography>
                </Grid>
              </Grid>

              <br></br>
              <Grid container xs={12}>
                <Grid item>
                  <Typography
                    component="body1"
                    variant="h6"
                    className={classes.requestTitle}
                  >
                    Requestor Information
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <br></br>
              <Grid
                container
                // alignContent="center"
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.labelStyle}
                >
                  {/* <Typography className={classes.leftMargin}>
                  <FormLabel classes={{ focused: classes.radioLabel }}>
                    Are you requesting on behalf of someone else?
                  </FormLabel>
                </Typography> */}
                  <FormLabel
                    component="legend"
                    className={classes.formTextColor}
                  >
                    Are you requesting on behalf of someone else?
                  </FormLabel>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <RadioGroup
                    onChange={handleRadioButtons}
                    value={radioValue}
                    aria-label="Are you requesting on behalf of someone else?"
                    name="srf"
                    row
                    required
                    defaultValue={'No'}
                    className={classes.radioBtnForm}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="default" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="default" />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>

              {requestorRadio === 'Yes' && (
                <Grid container alignItems="center">
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className={classes.labelStyle}
                  >
                    <Typography>Search for TM</Typography>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Autocomplete
                      id="reserve-others"
                      disableClearable={true}
                      className={classes.formControl}
                      options={options}
                      loading={loading}
                      value={userSearch}
                      size="small"
                      onInputChange={handleReservedForTyping}
                      onChange={handleBookingForChange}
                      getOptionLabel={(option) => option.reserved_for}
                      clearOnBlur
                      fullWidth={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <br />

              <Grid
                container
                alignContent="center"
                className={classes.pad}
                // spacing={2}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.labelStyle}
                >
                  <Typography className={classes.formTextColor}>
                    Lan id:{' '}
                    {!toggle && requestorRadio !== 'Yes'
                      ? session.userInfo.lanId
                      : userSearch?.reserved_for_id?.toUpperCase()}
                  </Typography>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  // justify="space-evenly"
                  alignItems="center"
                  className={classes.labelStyle}
                >
                  <Typography className={classes.formTextColor}>
                    Full Name:{' '}
                    {!toggle && requestorRadio !== 'Yes'
                      ? session.userInfo.fullName
                      : userName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignContent="center"
                spacing={0}
                // className={classes.labelProps}
              >
                <Grid item xs={12} sm={6} className={classes.labelStyle}>
                  <Typography className={classes.formTextColor}>
                    Space:
                    {spaceId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container direction="row">
                    <Grid item lg={4} md={4} className={classes.labelStyle}>
                      <Typography className={classes.formTextColor}>
                        Contact No:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        //type="number"
                        inputProps={{ maxLength: 10 }}
                        value={requestDetailsData.created_for_phone_no}
                        onChange={handlePhoneChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <RequestDetails
                requestDetailsData={requestDetailsData}
                setRequestDetailsData={setRequestDetailsData}
                selectedSpaceData={selectedSpaceData}
                setSelectedSpaceData={setSelectedSpaceData}
              />
            </CardContent>
            <CardActions className={classes.actionButtons}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitloading}
              >
                {submitloading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  'Submit'
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={clearState}
                className={classes.btnBorderColor}
              >
                Reset
              </Button>
            </CardActions>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.centralise}>
                  <b>Note:</b> For US emergency issues, please call 📞
                  <b>612-761-7777</b>.
                </Typography>
                <Typography className={classes.centralise}>
                  For India emergency issues, please call{' '}
                  <b>TII-C2 📞080-46734300</b> and <b>TII-NXT 📞080-46734060</b>{' '}
                  .
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Card>
      </div>
      <SuccessDialogWorkOrder
        open={successDialogOpen}
        setOpen={setSuccessDialogOpen}
        workOrderNumber={workOrderNumber}
        clearState={clearState}
      />
    </div>
  )
}
