import makeStyles from '@mui/styles/makeStyles'

const LoginStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  loginRoot: {
    // backgroundColor: theme.palette.background.paper,
    // minWidth: '100vw',
    // minHeight: '100vh',
    paddingTop: 8,
    textAlign: 'center',
  },
  loginButton: {
    minWidth: 300,
    width: '100%',
    maxWidth: 500,
  },
  buttonPosition: {
    marginTop: 16,
  },
  panelDescription: {
    textAlign: 'center',
    // backgroundColor:'rgba(0, 0, 0, 0.09)',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    // color:'#AC0000'
    color: 'rgba(0, 0, 0, 0.8)',
  },
  fitImg: {
    margin: 'auto',
  },
  prevArrow: {
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    display: 'block',
    width: '20px',
    height: '20px',
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    color: 'gray',
    border: 'none',
    outline: 'none',
    left: '-25px',
    '&:focus': {
      outline: '#888 dashed 1px',
      outlineOffset: '2px',
      textDecoration: 'underline',
    },
  },
  nextArrow: {
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    display: 'block',
    width: '20px',
    height: '20px',
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    color: 'gray',
    border: 'none',
    outline: 'none',
    right: '-25px',
    '&:focus': {
      outline: '#888 dashed 1px',
      outlineOffset: '2px',
      textDecoration: 'underline',
    },
  },
  //for enabling pause/play button only for Screen readers
  // srOnly: {
  //   position: 'absolute',
  //   width: '1px',
  //   height: '1px',
  //   padding: 0,
  //   margin: '-1px',
  //   overflow: 'hidden',
  //   clip: 'rect(0, 0, 0, 0)',
  //   whiteSpace: 'nowrap' /* added line */,
  //   border: 0,
  // },
  loginBg: {
    background: 'linear-gradient(to bottom right,#eb3349,#AC0000, #6f0000 )',

    height: '100vh',
    width: 'auto',
  },
  changeLocation: {
    height: '100vh',
    width: 'auto',
  },
  changeLocationMsg: {
    textAlign: 'center',
    color: 'black',
  },
  welcomeMsg: {
    textAlign: 'center',
    color: 'white',
  },
  unavailableMsg: {
    textAlign: 'center',
  },
  text: {
    fontSize: '1.5rem',
    fontWeight: 'bolder',
    padding: 4,
  },
  logoStyle: {
    paddingTop: '15%',
    textAlign: 'center',
  },
}))
export default LoginStyle
