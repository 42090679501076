// import logo from '../../images/wsrLogo.svg'
import logoUrl from '../../images/wsrLogo.svg?url'

const LoadingIndicator = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '30px',
      }}
    >
      <img src={logoUrl} width="70px" height="70px" alt="WSR logo" />
    </div>
  )
}
export default LoadingIndicator
