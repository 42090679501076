import { useState, useEffect } from 'react'
import MaterialTable from '@material-table/core'
import { tableIcons } from '../../components/common/commonFunctionalities'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  USA,
  PERMANENT_RESERVATION,
  RESERVABLE_SPACE_RESERVATION,
} from '../../components/common/constants'
import { Grid, Button } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import AddFloorDialog from './addFloor'
import { PurposeStyle } from '../../styles/india/purposeOfVisitStyle'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { useNavigate, useLocation } from 'react-router-dom'

const FloorHierarchy = ({ location }) => {
  const { state } = useLocation()
  const { building_id } = state
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const history = useNavigate()
  const classes = PurposeStyle()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('Add New')
  const [openMailSection, setOpenMailSection] = useState(false)
  const [options, setOptions] = useState([])
  const [toMail, setToMail] = useState()
  const [showCancelReservationMessage, setShowCancelReservationMessage] =
    useState(false)
  const initialFloorData = {
    building_id: '',
    floor_id: '',
    floor_status: '',
    sub_floor: '',
    created_by: session.userInfo.email.split('@')[0],
    modified_by: '',
    old_floor_status: '',
    maximo_floor_code: '',
    to: '',
    additional_notes: '',
    floor_name: '',
    old_maximo_floor_code: '',
    is_serviceable: '',
    old_is_serviceable: '',
  }

  const [newFloor, setNewFloor] = useState(initialFloorData)

  const tableColumns = [
    {
      field: 'building_id',
      title: 'BuildingId',
    },
    {
      field: 'floor_name',
      title: 'FloorName',
      defaultSort: 'asc',
    },

    {
      field: 'floor_status',
      title: 'FloorStatus',
    },
    {
      field: 'is_serviceable',
      title: 'Visible to CRESRF Form',
      type: 'boolean',
      render: (rowData) =>
        rowData.is_serviceable ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <CancelIcon style={{ color: 'red' }} />
        ),
    },
  ]

  const getFloorList = () => {
    let url = env.apiUrl[USA].getFloorData + building_id
    axios
      .get(url, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        //hiding CRESRF newly created floors
        let filterData = response.data.filter(
          (item) => item.created_by !== 'SRF_UPDATE',
        )
        setTableData(filterData)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getFloorList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (refresh) {
      getFloorList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (
      (newFloor.old_floor_status === 'Reservable' &&
        (newFloor.floor_status === 'Permanent' ||
          newFloor.floor_status === 'Homebase')) ||
      (newFloor.old_floor_status === 'Homebase' &&
        newFloor.floor_status === 'Permanent')
    ) {
      setShowCancelReservationMessage(true)
    } else {
      setShowCancelReservationMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFloor.floor_status])

  const handleClose = () => {
    setOpen(false)
    setNewFloor(initialFloorData)
    setDialogTitle('Add New')
    setOpenMailSection(false)
    setOptions([])
  }

  const handleEditFloor = (evt, rowData) => {
    let payload = {
      building_id: rowData.building_id,
      floor_id: rowData.floor_id,
      floor_status: rowData.floor_status,
      maximo_floor_code: rowData.maximo_floor_code,
      is_serviceable: rowData.is_serviceable,
      old_is_serviceable: rowData.is_serviceable,
      created_by: rowData.created_by,
      modified_by: session.userInfo.email.split('@')[0],
      old_floor_status: rowData.floor_status,
      old_maximo_floor_code: rowData.maximo_floor_code,
    }

    setNewFloor(payload)
    setDialogTitle('Edit')
    setOpen(true)
  }

  const addNewFloor = (e) => {
    e.preventDefault()
    setLoading(true)
    setRefresh(false)

    let payload = {
      ...newFloor,
      building_id: building_id,
    }

    let url = env.apiUrl[USA].addNewFlooor
    axios
      .post(url, payload, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        toast.success('Floor Added Successfully', toastConfigure)
        handleClose()
        setLoading(false)
        setRefresh(true)
      })
      .catch((err) => {
        console.log(err)
        toast.error('Error creating the floor!!!', toastConfigure)
        setLoading(false)
        setRefresh(false)
      })
  }

  const saveFloorDetails = (payload) => {
    let url = env.apiUrl[USA].modifyFloor
    axios
      .put(url, payload, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        toast.success('Floor Modified Successfully', toastConfigure)
        handleClose()
        setLoading(false)
        setRefresh(true)
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 501) {
          setOpenMailSection(true)
          setShowCancelReservationMessage(false)
        } else if (err.response.status === 404) {
          toast.error(err.response.data, toastConfigure)
        }
        setLoading(false)
        setRefresh(false)
      })
  }

  const sendMail = (e) => {
    e.preventDefault()
    setLoading(true)
    setRefresh(false)

    let url = env.apiUrl[USA].sendEmail
    let payload = {
      ...newFloor,
      sub_floor: '',
    }

    axios
      .put(url, payload, {
        headers: { Authorization: localStorage.access_token },
      })
      .then((response) => {
        toast.success('Mail Sent Successfully', toastConfigure)
        handleClose()
        setLoading(false)
        setRefresh(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const editFloor = (e) => {
    e.preventDefault()
    setLoading(true)
    setRefresh(false)

    let payload = {
      ...newFloor,
      sub_floor: '',
    }

    if (
      (newFloor.old_floor_status === RESERVABLE_SPACE_RESERVATION &&
        (newFloor.floor_status === PERMANENT_RESERVATION ||
          newFloor.floor_status === 'Homebase')) ||
      (newFloor.old_floor_status === 'Homebase' &&
        newFloor.floor_status === PERMANENT_RESERVATION)
    ) {
      let url = env.apiUrl[USA].checkReservationforStatusChange
      const config = {
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      const getMeetingAndResId = []
      axios.post(url, payload, config).then((response) => {
        let responses = response.data

        // eslint-disable-next-line array-callback-return
        responses.map((item) => {
          let id = {
            meetingId: item.meeting_id,
            reservationId: item.reservation_id,
          }
          getMeetingAndResId.push(id)
        })
        if (getMeetingAndResId.length !== 0) {
          const config = {
            headers: {
              Authorization: localStorage.access_token,
            },
            data: getMeetingAndResId,
          }
          let url =
            env.apiUrl[USA].cancelMultipleReservations + `?cre_admin=true`
          axios.delete(url, config).then((res) => {
            if (res.status === 200) {
              saveFloorDetails(payload)
            }
          })
        } else {
          saveFloorDetails(payload)
        }
      })
    } else {
      saveFloorDetails(payload)
    }
  }

  return (
    <div>
      <br />
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history('/cre/spacemaster')}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add Floor
          </Button>
        </Grid>
      </Grid>
      <br />
      <MaterialTable
        title={''}
        options={{
          thirdSortClick: false,
          showTitle: true,
          search: true,
          toolbar: true,
          pageSizeOptions: [10, 25, 50, 100],
          loadingType: 'overlay',
          pageSize: 100,
          headerStyle: {
            position: 'sticky',
            top: 0,
            height: '50px',
            fontWeight: 'bold',
            // backgroundColor: '#aba7a8',
          },
          maxBodyHeight: '53vh',
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: tableIcons.Edit,
            tooltip: 'Edit Floor',
            onClick: (event, rowData) => handleEditFloor(event, rowData),
          },
        ]}
        columns={tableColumns}
        data={tableData}
        icons={tableIcons}
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: '',
          },
        }}
      />
      <AddFloorDialog
        newFloor={newFloor}
        setNewFloor={setNewFloor}
        handleClose={handleClose}
        open={open}
        classes={classes}
        addNewFloor={addNewFloor}
        dialogTitle={dialogTitle}
        editFloor={editFloor}
        buildingId={building_id}
        openMailSection={openMailSection}
        setOpenMailSection={setOpenMailSection}
        options={options}
        setOptions={setOptions}
        sendMail={sendMail}
        toMail={toMail}
        setToMail={setToMail}
        showCancelReservationMessage={showCancelReservationMessage}
        setShowCancelReservationMessage={setShowCancelReservationMessage}
      />
    </div>
  )
}

export default FloorHierarchy
