import { useRef, useEffect } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Typography, IconButton } from '@mui/material'
import LoginStyle from '../../styles/india/loginStyles'
// import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import PauseIcon from '@mui/icons-material/Pause'

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  // initialSlide: 0,
  // autoplay: true,
  // autoplaySpeed: 3000,
  speed: 500,
  cssEase: 'linear',
  accessibilty: true,
  swipeToSlide: true,
  ariaLabel: 'image slider',
  pauseOnHover: true,
  // appendDots:dots =>
  // {console.log(dots)
  // return <ul>{dots}</ul>
  // },
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

function NextArrow(props) {
  const classes = LoginStyle()

  const { onClick } = props
  return (
    <div onClick={onClick} className={classes.nextArrow}>
      <IconButton aria-label="Next" size="large">
        <ArrowForwardIosIcon color="blue" />
      </IconButton>
    </div>
  )
}

// function getRandomInt(max) {
//   return Math.floor(Math.random() * max)
// }

function PrevArrow(props) {
  const classes = LoginStyle()

  const { onClick } = props
  return (
    <div onClick={onClick} className={classes.prevArrow}>
      <IconButton aria-label="Previous" size="large">
        {' '}
        <ArrowBackIosIcon />
      </IconButton>
    </div>
  )
}

const Carousel = () => {
  const classes = LoginStyle()
  const carouselRef = useRef(null)

  // const play = () => {
  //   console.log(carouselRef.current)
  //   if (carouselRef.current) carouselRef.current.slickPlay()
  // }
  // const pause = () => {
  //   if (carouselRef.current) carouselRef.current.slickPause()
  // }

  useEffect(() => {
    let initialSlideIndex = 1
    if (carouselRef.current) carouselRef.current.slickGoTo(initialSlideIndex)
  }, [])

  return (
    <div>
      {/* <div className={classes.srOnly}>
        <IconButton aria-label="Click to play animation" onClick={play}>
          <PlayArrowIcon />
        </IconButton>
        <IconButton aria-label="Click to pause animation" onClick={pause}>
          <PauseIcon />
        </IconButton>
      </div> */}
      <Slider ref={carouselRef} {...settings}>
        <div className={classes.root}>
          <div>
            <img
              alt="WSR app can be used on any device"
              // width="80%"
              width="400px"
              height="450px"
              src="/WSR_1.png"
              className={classes.fitImg}
              title={'Image 1 of 3'}
            />
          </div>
          <Typography
            className={classes.panelDescription}
            // variant="body1"
          >
            Book from Anywhere
          </Typography>
        </div>
        <div>
          <img
            alt="Manage your bookings and submit Service Requets in WSR app"
            // width="100%"
            height="450px"
            src="/WSR_2.png"
            className={classes.fitImg}
            title={'Image 2 of 3'}
          />
          <Typography className={classes.panelDescription}>
            Manage Booking & Submit Service Requests
          </Typography>
        </div>
        <div>
          <img
            alt="Space will be sanitized after every use"
            // width="100%"
            height="450px"
            src="/WSR_3.png"
            className={classes.fitImg}
            title={'Image 3 of 3'}
          />
          <Typography className={classes.panelDescription}>
            Safe & Sanitized Space
          </Typography>
        </div>
      </Slider>
    </div>
  )
}

export default Carousel
