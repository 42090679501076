import moment from 'moment'

let initialState = {
  buildingId: '',
  selectedFloor: '',
  selectedBuilding: '',
  allFloors: null,
  allBuildings: null,
  cleaningDate: moment(),
}

export function HousekeepingReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_HOUSEKEEPING_FLOOR':
      return Object.assign({}, state, {
        selectedFloor: payload,
      })

    case 'SAVE_HOUSEKEEPING_BUILDING':
      return Object.assign({}, state, {
        selectedBuilding: payload,
      })

    case 'SAVE_HOUSEKEEPING_BUILDINGID':
      return Object.assign({}, state, {
        buildingId: payload,
      })

    case 'SAVE_HOUSEKEEPING_ALL_FLOORS':
      return Object.assign({}, state, {
        allFloors: payload,
      })

    case 'SAVE_HOUSEKEEPING_ALL_BUILDINGS':
      return Object.assign({}, state, {
        allBuildings: payload,
      })
    case 'SAVE_HOUSEKEEPING_CLEANING_DATE':
      return Object.assign({}, state, {
        cleaningDate: payload,
      })
    case 'SAVE_HOUSEKEEPING_FLOOR_NAME':
      return Object.assign({}, state, {
        selectedFloorName: payload,
      })
    default:
      return state
  }
}
