// import Autocomplete from '@mui/lab/Autocomplete'
import { useState, useEffect } from 'react'
// import { createFilterOptions } from '@mui/lab/Autocomplete'
// import { ModifiedTextfield } from '../styles/reservationFormStyle'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
// import moment from 'moment'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import CloseIcon from '@mui/icons-material/Close'
// import { TextField } from '@mui/material'

const CustomTimePicker = ({
  id,
  value,
  setValue,
  options,
  interval,
  label,
  disabled,
  classes,
}) => {
  let [createdOptions, setCreatedOptions] = useState([])

  /* Function to create list of options for TimePicker dropdown, 
  it takes start time and end time as integer/string,and interval in minutes
  Possible input- 18(integer),18:00(as string)
  */
  const makeOptions = () => {
    // 0, 1, 2, 3
    // 15 / 60
    let start = isNaN(options[0]) ? options[0].split(':')[0].trim() : options[0]
    let end = isNaN(options[1]) ? options[1].split(':')[0].trim() : options[1]
    let start_residue = isNaN(options[0])
      ? options[0].split(':')[1].trim()
      : null

    let finalOptions = []

    function generateTimeOption(j, i) {
      let minutes = i * interval
      minutes = minutes.toString().length === 1 ? '0' + minutes : minutes
      if (j < 12) {
        finalOptions.push(`${j}:${minutes} AM`)
        // eslint-disable-next-line eqeqeq
      } else if (j == 12) {
        finalOptions.push(`${j}:${minutes} PM`)
      } else {
        finalOptions.push(`${j - 12}:${minutes} PM`)
      }
    }

    for (let j = start; j < end; j++) {
      let i = 0
      if (start_residue && (start_residue !== '00') & (j === start)) {
        i = Number(start_residue) / interval
      }
      for (i; i < 60 / interval; i++) {
        generateTimeOption(j, i)
      }
    }
    generateTimeOption(end, 0) //to include last option e.g., input-[6,18],then '6:00 PM' should also be pushed
    setCreatedOptions(finalOptions)
  }

  useEffect(() => {
    if (options.length !== 0) makeOptions()
    else setCreatedOptions([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <>
      {!disabled && (
        <FormControl
          className={classes.formControl}
          variant="outlined"
          required={!disabled}
          hidden={disabled}
        >
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select
            id={id}
            componentName={id}
            label={label}
            className={classes.selected}
            classes={{ icon: classes.icon, select: classes.selected }}
            value={value}
            onChange={(event) => {
              setValue(event.target.value)
            }}
            hidden={disabled}
          >
            {createdOptions && createdOptions.length > 0 ? (
              createdOptions.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>
              })
            ) : (
              <MenuItem value="" disabled>
                No Options
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    </>
  )
}

export default CustomTimePicker
