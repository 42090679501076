export const India = 'India'
export const INDIA = 'INDIA'
export const USA = 'USA'
export const US = 'US'
export const cabin = 'Cabin'
export const full = 'Full'
export const partial = 'partial'
export const employee = 'EMPLOYEE'
export const contractor = 'CONTRACTOR'
export const HITECH_RESERVATION = 'Hi-Tech'
export const PERMANENT_RESERVATION = 'Permanent'
export const RESERVABLE_SPACE_RESERVATION = 'Reservable'
export const COLLAB = 'collab'
export const eaganBuildingId = 'T8269'
export const workstation = 'workstation'
export const ConferenceRoom = 'Conference Room'
export const Enclave = 'Enclave'
export const Desk = 'Desk'
export const office = 'office'
export const Office = 'Office'
export const LT = 'LT'
export const timezoneIndia = 'Asia/Kolkata'
export const timezoneUS = 'America/Chicago'
export const desk = 'desk'
export const weekend = 'weekend'
export const work = 'work'
export const permanent = 'permanent'
export const common = 'common'
export const _full = 'full'
export const interior = 'interior'
export const exterior = 'exterior'
export const TMSpaces = 'Team Member Spaces'
export const TMSpace = 'Team Member Space'
export const dialingCode = {
  USA: '+1 ',
  India: '+91 ',
}
export const TII_SHIFT = '9:30 AM-6:15 PM'

export const TII_END_TIME = '6:15 PM'
