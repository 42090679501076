import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import UpcomingReservations from '../teamMember/upcomingReservations'
import CompletedReservations from '../teamMember/completedReservation'
import CancelledReservations from '../teamMember/cancelledReservations'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}))

const ViewReservation = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            style={{ fontWeight: 'bold' }}
            label="Upcoming"
            {...a11yProps(0)}
          />
          <Tab
            style={{ fontWeight: 'bold' }}
            label="Completed"
            {...a11yProps(1)}
          />
          <Tab
            style={{ fontWeight: 'bold' }}
            label="Cancelled"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <UpcomingReservations />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <CompletedReservations />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <CancelledReservations />
      </TabPanel>
    </div>
  )
}

export default ViewReservation
