import makeStyles from '@mui/styles/makeStyles'

export const DeskStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '5%',
    height: '100vh',
    // backgroundColor: theme.palette.secondary.main,
    padding: '1%',
  },
  formStyle: {
    padding: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(2),
    width: 150,
    paddingLeft: 0,
    // maxWidth:'100%',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '98%',
    },
    [theme.breakpoints.only('md')]: {
      width: '90%',
    },
  },
  textFieldRead: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 150,
    [theme.breakpoints.down('lg')]: {
      minWidth: '90%',
    },
  },
  paper: {
    minWidth: 275,
    // border: '2px solid #AC0000',
    borderRadius: '15px',
    padding: '2%',
  },
  dialogRoot: {
    padding: '0px !important',
    textAlign: 'center',
  },
  contentDialog: {
    // paddingLeft: '10%',

    fontWeight: '540',
    color: 'black',
  },
  successBanner: {
    height: '5rem',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '3%',
  },
  fullRes: {
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: '20%',
    },
  },
  floorPlan: {
    marginTop: 16,
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
    cursor: 'pointer',
    border: '1px solid lightgray',
    padding: 8,
    borderRadius: 4,
    maxWidth: 300,
    [theme.breakpoints.up('lg')]: {
      width: 190,
    },
  },
  mapBtn: {
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
  },
  deskPanel: {
    // paddingTop: '5%',
    [theme.breakpoints.between('xs', 'lg')]: {
      marginTop: '20vh',
    },
    paddingTop: '2%',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    alignItems: 'baseline',
  },
  appBar: {
    position: 'relative',
  },

  sx: {
    '&.MuiAccordionDetails-root': {
      display: 'contents',
    },

    '&.MuiAccordionSummary-root': {
      display: 'flex',
      padding: '0px',

      transition:
        'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',

      width: '100%',
    },
  },
}))
