import React from 'react'
import { IFPPanelStyle } from '../../styles/ifpPanelStyle'

// const isClickedInside = (e, element) => {
//   let node = e.target
//   while (node) {
//     if (node === element) {
//       return true
//     }
//     node = node.parentNode
//   }
//   return false
// }

const Popup = ({ position, spaceId, onClose }) => {
  const containerRef = React.useRef(null)
  const classes = IFPPanelStyle()

  //   React.useEffect(() => {
  //     const onClick = e => {
  //       if (!isClickedInside(e, containerRef.current)) {
  //         onClose();
  //       }
  //     };
  //     window.addEventListener("click", onClick);
  //     return () => {
  //       window.removeEventListener("click", onClick);
  //     };
  //   }, []);
  return (
    <div
      ref={containerRef}
      style={{
        top: position.y + 20 + 'px',
        left: position.x + 20 + 'px',
      }}
      className={classes.popupStyle}
    >
      <div>
        {' '}
        <b>{spaceId}</b>
      </div>
    </div>
  )
}

export default Popup
