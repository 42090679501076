import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import MaterialTable, { MTableToolbar } from '@material-table/core'
// import { ExportCsv, ExportPdf } from '@material-table/exporters'
import {
  tableIcons,
  getCompensation,
  toastConfigure,
} from '../../components/common/commonFunctionalities'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'date-fns'
import moment from 'moment'
// import EventIcon from '@mui/icons-material/Event'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  TabPanel,
  a11yProps,
  addDays,
} from '../../components/common/commonFunctionalities'
import Overview from './overview'
import {
  convertAMPMFromHours,
  getDashboardTableData,
} from '../../components/common/commonFunctionalities'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { DashboardStyle } from '../../styles/dashboardStyle'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'
import { CsvBuilder } from 'filefy'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  INDIA,
  timezoneIndia,
  US,
  USA,
} from '../../components/common/constants'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '@praxis/component-analytics'
import { useAuth } from '@praxis/component-auth'
import Edit from '@mui/icons-material/Edit'

export default function Dashboard() {
  const env = useEnv()
  const constants = env.constants
  let holidays = null
  const [workingDays, setWorkingDays] = useState(0)
  const dispatch = useDispatch()
  const history = useNavigate()
  const classes = DashboardStyle()
  const { trackCustomEvent } = useAnalytics()
  const theme = useTheme()
  const auth = useAuth()
  const { session } = auth
  const userAccess = useSelector((state) => state.common.userAccess)
  const [buildingFloor, setBuildingFloor] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState('')
  const [selectedFloor, setSelectedFloor] = useState([])
  const [selectedFloorType, setSelectedFloorType] = useState(null)
  const [buildingId, setBuildingId] = useState('')
  const [floor, setFloor] = useState([])
  const [floorType, setFloorType] = useState(undefined)
  const [location, setLocation] = useState(US)
  const [building, setBuilding] = useState([])
  const [allowedBuildingIds, setAllowedBuildingIds] = useState([])
  const [allowedFloorIds, setAllowedFloorIds] = useState([])
  // const [isRowSelected, setIsRowSelected] = useState(false)

  const [loadTable, setLoadTable] = useState(false)

  const [tabValue, setTabValue] = React.useState(0)
  const [overview, setOverview] = useState(null)
  const [details, setDetails] = useState([])
  // const [startDate, setStartDate] = useState(moment().format('MM/DD/YYYY'))
  const [startDate, setStartDate] = useState(moment())

  const [endDate, setEndDate] = useState(null)
  // const [chartData, setChartData] = useState(null)

  let columns = [
    {
      field: 'reservation_id',
      title: 'Modify',
      render: (rowData) => (
        <Tooltip title="Modify Reservation">
          <IconButton
            onClick={(e) => modifyReservation(e, rowData)}
            disabled={
              rowData.status !== 'Active' ||
              rowData.tableData.checked === true ||
              !userAccess.has('CRE Admin')
            }
            size="large"
          >
            <Edit />
          </IconButton>
        </Tooltip>
      ),
      filtering: false,
      width: '10%',
    },

    {
      field: 'space_id',
      title: 'SpaceId',
    },
    {
      field: 'space_type',
      title: 'SpaceType',
    },
    {
      field: 'reserved_for',
      title: 'ReservedFor',
    },
    {
      field: 'reserved_by',
      title: 'ReservedBy',
    },
    {
      field: 'start_date',
      title: 'StartDate',
      hidden: true,
      emptyValue: startDate,
      export: false,
    },
    {
      field: 'end_date',
      title: 'EndDate',
      hidden: true,
      emptyValue: endDate,
      export: false,
    },
    {
      field: 'reservation_date',
      title: 'ReservationDate',
      render: (rowData) => (
        <div>{moment(rowData.reservation_date).format('MM-DD-YYYY')}</div>
      ),
    },
    {
      field: 'timing',
      title: 'Timing',
      render: (rowData) => (
        <div>
          {convertAMPMFromHours(rowData.start_time)}-
          {convertAMPMFromHours(rowData.end_time)}
        </div>
      ),
    },
    {
      field: 'type',
      title: 'Type',
      render: (rowData) => (
        <div>{rowData.start_time && rowData.end_time ? 'Partial' : 'Full'}</div>
      ),
    },

    {
      field: 'start_time',
      title: 'start_time',
      hidden: true,
      export: false,
    },
    {
      field: 'end_time',
      title: 'end_time',
      hidden: true,
      export: false,
    },
    {
      field: 'status',
      title: 'Status',
      render: (rowData) => {
        switch (rowData.status) {
          case 'Active':
            return (
              <Chip
                variant="outlined"
                style={{ color: theme.palette.success.main }}
                label={rowData.status}
              />
            )
          case 'Inactive':
            return (
              <Chip
                variant="outlined"
                style={{ color: theme.palette.error.main }}
                label={'Cancelled'}
              />
            )

          default:
            return <Chip variant="outlined" label={rowData.status} />
        }
      },
      filterComponent: (props) => (
        <FormControl>
          <Select
            id="search-options"
            className={classes.filter}
            placeholder="select"
            onChange={(event) => {
              props.onFilterChanged(
                props.columnDef.tableData.id,
                event.target.value,
              )
            }}
            autoWidth={true}
          >
            <MenuItem value="">
              <i>None</i>
            </MenuItem>

            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Cancelled</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        return value === rowData.status
      },
    },
    {
      field: 'building_name',
      title: 'BuildingName',
    },
    {
      field: 'floor_name',
      title: 'BuildingFloor',
    },
  ]

  useEffect(() => {
    const url = env.apiUrl['USA'].listHomebaseFlexFloors
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        const filteredData = res.data.filter(
          (item) => item.building_details.location === 'US',
        )

        // Set buildingFloor state
        const sortedData = _.groupBy(filteredData, 'building_details.location')
        setBuildingFloor(sortedData)
        let buildings = []
        buildings = sortedData['US'].map(
          (item) => item.building_details.building_name,
        )
        setBuilding(buildings)
        // Set buildingIds and floorIds states
        const { buildingIds, floorIds } = filteredData.reduce(
          (acc, curr) => {
            acc.buildingIds.push(curr.building_details.building_id)
            acc.floorIds.push(...curr.floor_details)
            return acc
          },
          { buildingIds: [], floorIds: [] },
        )
        setAllowedBuildingIds(buildingIds)
        let floorsAllowed = floorIds?.map((item) => item.floor_id)
        setAllowedFloorIds(floorsAllowed)
      })
      .catch((error) => {
        console.error(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value)
    // setLoadTable(true)
    setFloorType(undefined)
    getDashboardData(location, buildingId, event.target.value)
    setSelectedFloorType(null)
  }

  const handleCardClick = (event, floorCard = '', buildingCard = null) => {
    if (floorCard === '') {
      //user has clicked on building card

      changeBuilding(buildingCard)
    } else {
      //user has clicked on floor card
      setTabValue(1)

      let event = { target: {} }
      event.target['value'] = [floorCard]
      handleFloorChange(event)
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleFloorTypeChange = (event) => {
    setSelectedFloorType(event.target.value)
    getDashboardData(location, buildingId, selectedFloor, event.target.value)
  }

  const handleLocationChange = (event) => {
    let { value } = event.target
    setLocation(event.target.value)
    getDashboardData(event.target.value)
    let buildings = []
    if (event.target.value !== '') {
      buildings = buildingFloor[value].map(
        (item) => item.building_details.building_name,
      )
    }
    setBuilding(buildings)
    setFloor([])
    setSelectedFloor([])
    setSelectedBuilding('')
  }

  const makeDate = (startDate, endDate, loc) => {
    const start = new Date(startDate)
    const end = new Date(endDate)

    start.setHours(0, 0, 0, 0)
    end.setHours(0, 0, 0, 0)

    holidays = constants[loc].disabledDays

    if (start > end) {
      return 0
    }

    let temp = 0
    let current = new Date(start)

    while (current <= end) {
      current.setHours(0, 0, 0, 0)

      const isWeekend = current.getDay() === 0 || current.getDay() === 6

      const isHoliday = holidays.includes(
        current.getFullYear() +
          '-' +
          ('0' + (current.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + current.getDate()).slice(-2) +
          'T00:00',
      )
      if (!isWeekend && !isHoliday) {
        temp++
      }

      current.setDate(current.getDate() + 1)
    }

    return temp
  }

  const makeData = (buildingData, totalDays = workingDays) => {
    buildingData.sort((a, b) => (a.floor_name < b.floor_name ? -1 : 1))
    return buildingData.map((item) => {
      item.total_desks = item.total_desks * totalDays
      item.total_offices = item.total_offices * totalDays

      let officesData = []
      let deskData = []
      deskData.push({ name: 'Reserved Desk', value: item.reserved_desks })
      deskData.push({
        name: 'Available Desk',
        value: item.total_desks - item.reserved_desks,
      })

      officesData.push({
        name: 'Reserved Offices',
        value: item.reserved_offices,
      })
      officesData.push({
        name: 'Available Offices',
        value: item.total_offices - item.reserved_offices,
      })

      return { ...item, desk: deskData, office: officesData }
    })
  }

  const getDashboardData = (
    location,
    buildingId = '',
    selectedFloor = [],
    selectedFloorType = null,
    selectedStartDate = startDate,
    selectedEndDate = endDate,
  ) => {
    setLoadTable(true)

    if (buildingId === '') {
      let url =
        env.apiUrl['USA'].dashboardGetBuildings +
        `?start_date=${moment(selectedStartDate).format(
          'YYYY-MM-DD',
        )}&end_date=${moment(selectedEndDate).format(
          'YYYY-MM-DD',
        )}&location=${location}`

      axios
        .get(url)
        .then((response) => {
          setLoadTable(false)
          let buildingData = response.data

          //hardcoded,remove later
          buildingData = buildingData.filter((item) =>
            allowedBuildingIds.includes(item.building_id),
          )

          let finalWorkingDays = makeDate(
            selectedStartDate,
            selectedEndDate,
            location,
          )
          setWorkingDays(finalWorkingDays)
          setDetails([])
          setOverview(makeData(buildingData, finalWorkingDays))
        })
        .catch((err) => console.log(err))
    } else {
      let showValue = selectedFloor.map((item) => item.split(':')[0])
      let url =
        env.apiUrl['USA'].dashboardGetFloors +
        `?start_date=${moment(selectedStartDate).format(
          'YYYY-MM-DD',
        )}&end_date=${moment(selectedEndDate).format(
          'YYYY-MM-DD',
        )}&location=${location}&building_id=${buildingId}&building_floor=${showValue.join(
          ',',
        )}`

      axios
        .get(url)
        .then((response) => {
          setLoadTable(false)

          let buildingData = response.data.building_details
          let reservationData = response.data.reservation_details
          //hardcoded,remove later
          buildingData = buildingData.filter((item) =>
            allowedFloorIds.includes(item.floor_id),
          )

          const floorTypes = [
            ...new Set(
              buildingData.map((item) =>
                item.floor_status === '' ? 'Flex' : item.floor_status,
              ),
            ),
          ]
          setFloorType(floorTypes)

          if (selectedFloorType !== null) {
            let newBuildingData = buildingData.filter(
              (item) =>
                item.floor_status ===
                (selectedFloorType === 'Flex' ? '' : selectedFloorType),
            )
            let filteredFloor = newBuildingData.map((item) => item.floor_name)
            let newReservationData = reservationData.filter((item) =>
              filteredFloor.includes(item.floor_name),
            )

            let finalWorkingDays = makeDate(
              selectedStartDate,
              selectedEndDate,
              location,
            )
            setWorkingDays(finalWorkingDays)
            setOverview(makeData(newBuildingData, finalWorkingDays))
            setDetails(newReservationData)
          } else {
            let finalWorkingDays = makeDate(
              selectedStartDate,
              selectedEndDate,
              location,
            )
            setWorkingDays(finalWorkingDays)
            setOverview(makeData(buildingData, finalWorkingDays))
            setDetails(reservationData)
          }
        })
        .catch((err) => console.log(err))
    }
  }

  //This function will be removed once we get organization_name in dashboard api response

  const handleBuildingChange = (event) => {
    setSelectedBuilding(event.target.value)
    let selectedBuildingId = ''
    let floors = []
    if (event.target.value !== '') {
      selectedBuildingId = buildingFloor[location].find(
        (eachBuilding) =>
          eachBuilding.building_details.building_name === event.target.value,
      ).building_details.building_id
      floors = buildingFloor[location]
        .find(
          (bldng) =>
            bldng.building_details.building_name === event.target.value,
        )
        .floor_details.sort((a, b) => (a.floor_name < b.floor_name ? -1 : 1))
    }
    //hardcoded,remove later
    floors = floors.filter((item) => allowedFloorIds.includes(item.floor_id))

    setBuildingId(selectedBuildingId)
    setFloor(floors)
    setFloorType(undefined)
    getDashboardData(location, selectedBuildingId)

    setSelectedFloor([])
    setSelectedFloorType(null)
    setLoadTable(false)
  }

  const changeBuilding = (buildingObject) => {
    setSelectedBuilding(buildingObject.name)
    setBuildingId(buildingObject.id)

    let floors = buildingFloor[location]
      .find(
        (bldng) => bldng.building_details.building_name === buildingObject.name,
      )
      .floor_details.sort((a, b) => (a.floor_name < b.floor_name ? -1 : 1))

    //hardcoded,remove later
    floors = floors.filter((item) => allowedFloorIds.includes(item.floor_id))

    setFloor(floors)
    setSelectedFloor([])
    setLoadTable(false)
    getDashboardData(location, buildingObject.id)
  }

  const exportCustomCSV = (allColumns, allData) => {
    const [columns, data] = getDashboardTableData(allColumns, allData)

    let floorValue = selectedFloor.map((item) => item?.split(':')[1])
    let fileName = `${selectedBuilding} - ${floorValue.join(',')}`

    const builder = new CsvBuilder(fileName + '.csv')
    builder
      // .setDelimeter(this.props.exportDelimiter)
      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile()
  }

  const modifyReservation = (event, data) => {
    event.preventDefault()

    let payload = {
      building_id: data.building_id,
      reservation_date: [moment(data.reservation_date).format('MM/DD/YYYY')],
      start_time: data.start_time ? convertAMPMFromHours(data.start_time) : '',
      end_time: data.end_time ? convertAMPMFromHours(data.end_time) : '',
      workspace_type: data.space_type,
      fullDay: data.start_time ? false : true,
      reservation_id: data.reservation_id,
      reserved_for: data.reserved_for,
      meeting_id: data.meeting_id,
      location_timezone: data.location_timezone,
      lan_id: data.lan_id,
      organisationName: data.building_name,
      primary_location: data.location_timezone === timezoneIndia ? INDIA : US,
    }

    dispatch({
      type: 'SAVE_RESERVATION_DETAILS',
      payload: payload,
    })

    if (data.reserved_for !== data.booked_by) {
      dispatch({ type: 'RESERVING_FOR', payload: true })
      getCompensation(env, USA, data.lan_id)
        .then((data) => {
          if (data && 'level' in data) {
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: data['level'],
            })
          } else {
            //This else condition handles {compensation:null} object from worker dev api
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: null,
            })
          }
          // setModifyLoading(false)

          history('/tm/step1')
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error! Unable to fetch user profile', toastConfigure)
          dispatch({
            type: 'RESERVING_FOR_USER_COMPENSATION_US',
            payload: null,
          })
          // setModifyLoading(false)
        })
    } else {
      dispatch({ type: 'RESERVING_FOR', payload: false })
      history('/tm/step1')
      // setModifyLoading(false)
    }
  }

  // const cancelReservation = (rowData, resolve, reject) => {
  //   const config = {
  //     headers: {
  //       Authorization: localStorage.access_token,
  //     },
  //   }
  //   let resId = rowData.reservation_id
  //   let url =
  //     env.apiUrl[USA].cancelReservationUrl +
  //     `?reservation_id=${resId}&meeting_id=${rowData.meeting_id}`
  //   axios
  //     .delete(url, config)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success('Reservation cancelled successfully', toastConfigure)
  //         getDashboardData(location, buildingId, selectedFloor)
  //       }
  //       // setRefresh(true)
  //       trackCustomEvent(
  //         'Cancel Button',
  //         url,
  //         `Success. ReservationId: ${resId}, CancelledBy: ${
  //           session.userInfo.email.split('@')[0]
  //         }`,
  //         'Cancel'
  //       )
  //       resolve()
  //     })
  //     .catch((err) => {
  //       toast.error(`Error - ${err}`, toastConfigure)
  //       trackCustomEvent(
  //         'Cancel Button',
  //         url,
  //         `${err.statusCode} : ${err.statusMsg}`,
  //         'Cancel'
  //       )
  //       reject()
  //     })
  // }

  const handleMultipleCancel = (evt, data) => {
    let source = data
      .filter((item) => item.status === 'Active')
      .map((item) => {
        return {
          meetingId: item.meeting_id,
          reservationId: item.reservation_id,
        }
      })
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
      data: source,
    }
    let url = env.apiUrl[USA].cancelMultipleReservations + `?cre_admin=true`
    axios
      .delete(url, config)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Reservations cancelled successfully', toastConfigure)
          getDashboardData(location, buildingId, selectedFloor)
        }
        // setRefresh(true)
        trackCustomEvent(
          'Cancel Button',
          url,
          `Success. ReservationIds: ${source
            .map((item) => item.reservationId)
            .join(',')}, CancelledBy: ${session.userInfo.email.split('@')[0]}`,
          'Cancel',
        )
      })
      .catch((err) => {
        toast.error(`Error - ${err}`, toastConfigure)
      })
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Paper>
          <Grid
            container
            spacing={3}
            justifyContent="space-around"
            className={classes.searchBar}
          >
            <Grid item lg={4} md={6} xs={12}>
              {/* <Grid container justify="space-around"> */}

              <DatePicker
                // required
                // disableToolbar
                // variant="inline"
                // inputVariant="outlined"
                format="MM/DD/Y"
                // margin="normal"
                // id="date-picker-inline"
                label="Start Date"
                name="startDate"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  getDashboardData(
                    location,
                    buildingId,
                    selectedFloor,
                    selectedFloorType,
                    moment(date).format('MM/DD/YYYY'),
                    endDate,
                  )
                }}
                minDate={moment().subtract(constants.creMinMonth, 'months')}
                maxDate={addDays(constants.maxBookingDays - 1)}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
                // keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                // vari
                // autoOk={true}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <DatePicker
                required
                // disableToolbar
                // variant="inline"
                // inputVariant="outlined"
                format="MM/DD/Y"
                // margin="normal"
                // id="date-picker-inline"
                label="End Date"
                name="endDate"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date)
                  //setErrorText('')
                  getDashboardData(
                    location,
                    buildingId,
                    selectedFloor,
                    selectedFloorType,
                    startDate,
                    moment(date).format('MM/DD/YYYY'),
                  )
                }}
                minDate={startDate}
                maxDate={addDays(constants.maxBookingDays - 1)}
                // maxDate={addDays(
                //   constants.maxBookingDays - 1
                // ).toLocaleDateString('en-US', dateOption)}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
                // keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                // vari
                // autoOk={true}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="select-location-label">Location</InputLabel>
                <Select
                  labelId="select-location-label"
                  id="select-location"
                  value={location}
                  onChange={handleLocationChange}
                  label="Location"
                >
                  {buildingFloor &&
                    Object.keys(buildingFloor).map((item, key) => {
                      return (
                        <MenuItem value={item} key={key}>
                          {item}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-building-label">
                  Building Name
                </InputLabel>
                <Select
                  labelId="dashboard-building-label"
                  id="dashboard-building"
                  value={selectedBuilding}
                  onChange={handleBuildingChange}
                  label="Building Name"
                >
                  <MenuItem disabled>--Choose Building--</MenuItem>

                  {building.map((e, key) => {
                    return (
                      <MenuItem value={e} key={key}>
                        {e}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-floor-label">Floor Name</InputLabel>
                <Select
                  labelId="dashboard-floor-label"
                  id="dashboard-floor"
                  value={selectedFloor}
                  onChange={handleFloorChange}
                  label="Floor Name"
                  renderValue={(selected) => {
                    let showValue = selected.map((item) => item.split(':')[1])
                    const formattedValue = showValue.join(', ')
                    return (
                      <div
                        style={{
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {formattedValue}
                      </div>
                    )
                  }}
                  multiple
                >
                  <MenuItem disabled>--Choose Floor--</MenuItem>
                  {/* <MenuItem value={''} key="empty-floor">
                    &nbsp;&nbsp;
                  </MenuItem> */}
                  {floor.map((val, key) => {
                    return (
                      <MenuItem
                        value={`${val.floor_id}:${val.floor_name}`}
                        key={val.floor_id}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            selectedFloor.indexOf(
                              `${val.floor_id}:${val.floor_name}`,
                            ) > -1
                          }
                        />
                        <ListItemText primary={val.floor_name} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-floor-type-label">
                  Floor Type
                </InputLabel>
                <Select
                  labelId="dashboard-floor-type-label"
                  id="dashboard-floor-type"
                  value={selectedFloorType}
                  onChange={handleFloorTypeChange}
                  label="Floor Type"
                >
                  <MenuItem disabled>--Choose Floor Type--</MenuItem>
                  {Array.isArray(floorType)
                    ? floorType.map((e, key) => (
                        <MenuItem value={e} key={key}>
                          {e}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <br />

        <Grid container>
          <Grid item xs={12}>
            <Paper square>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab
                  label="Details"
                  {...a11yProps(1)}
                  disabled={buildingId === ''}
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Overview
                  cardInfo={overview}
                  // chartData={chartData}
                  handleCardClick={handleCardClick}
                  selectedFloor={selectedFloor}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <MaterialTable
                  title="Reservations"
                  isLoading={loadTable}
                  options={{
                    showTitle: true,
                    search: false,
                    toolbar: true,
                    selection: true,
                    selectionProps: (rowData) => ({
                      disabled:
                        rowData.status !== 'Active' ||
                        !userAccess.has('CRE Admin'),
                      color: 'primary',
                    }),
                    // tableLayout: 'fixed',
                    toolbarButtonAlignment: 'left',
                    pageSizeOptions: [10, 25, 50, 100],
                    loadingType: 'overlay',
                    pageSize: 100,
                    headerStyle: {
                      position: 'sticky',
                      top: 0,
                      height: '50px',
                      fontWeight: 'bold',
                      // minWidth: 54,
                      // width: '30px !important'
                      // backgroundColor: '#aba7a8',
                    },
                    maxBodyHeight: '53vh',
                    emptyRowsWhenPaging: false,
                    filtering: true,
                    actionsColumnIndex: 1,
                    showSelectAllCheckbox: false,
                  }}
                  columns={columns}
                  data={details}
                  icons={tableIcons}
                  // onSelectionChange={(rows) =>
                  //   rows.length > 0
                  //     ? setIsRowSelected(true)
                  //     : setIsRowSelected(false)
                  // }
                  components={{
                    Toolbar: (props) => (
                      <div className={classes.toolbarDashStyle}>
                        <div className={classes.toolbarTitle}>
                          <MTableToolbar {...props} />
                        </div>
                        <Tooltip title="Export to CSV">
                          <IconButton
                            onClick={() => {
                              exportCustomCSV(props.columns, props.originalData)
                            }}
                            size="large"
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ),
                  }}
                  // editable={{
                  //   isDeletable: (rowData) => rowData.status === 'Active' && !isRowSelected,
                  //   // &&
                  //   // rowData.tableData.checked === false
                  //   onRowDelete: (rowData) =>
                  //     new Promise((resolve, reject) => {
                  //       setTimeout(() => {
                  //         cancelReservation(rowData, resolve, reject)
                  //       }, 1000)
                  //     }),
                  //   deleteTooltip: (rowData) => 'Cancel reservation',
                  // }}
                  actions={[
                    {
                      icon: tableIcons.DeletePrimary,
                      iconProps: { color: 'primary' },
                      tooltip: 'Cancel selected Reservations',
                      // isFreeAction: true,
                      onClick: (event, rowData) =>
                        handleMultipleCancel(event, rowData),
                    },
                  ]}
                  localization={{
                    body: {
                      emptyDataSourceMessage: '',
                      editRow: {
                        deleteText:
                          'Are you sure you want to cancel this reservation?',
                        saveTooltip: 'Yes',
                        cancelTooltip: 'No',
                        icon: tableIcons.Remove,
                      },
                    },
                    toolbar: {
                      nRowsSelected: '{0} row(s) selected for cancellation',
                    },
                    header: {
                      actions: 'Cancel',
                    },
                  }}
                />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}></Box>
        {/* </Container> */}
      </main>
    </div>
  )
}
