let initialState = {
  reservation_type: 'full',
  location_timezone: '',

  building_id: '',
  reservation_date: [],
  start_time: '',
  end_time: '',
  workspace_type: 'desk',
  office_timings: '',
  floor: '',
  building_name: '',

  allBuildings: [],
  allFloors: [],
  availableDesks: [],
  space_id: '',
  reservation_id: null,
  skip_first: false,
  hideTransportPage: false,
  bookedDates: [],
  bookedStartTime: '',
  bookedEndTime: '',
  existingBuildingId: '',
}

export function ReservationIndiaReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_INDIA_RESERVATION_DETAILS':
      return Object.assign({}, state, payload)

    case 'CLEAR_RESERVATION_INDIA_DETAILS':
      return Object.assign({}, state, initialState)

    default:
      return state
  }
}
