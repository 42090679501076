import makeStyles from '@mui/styles/makeStyles'

export const InsightsPageStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 330,
    maxwidth: 330,
  },
  searchBar: {
    padding: theme.spacing(4),
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 120,
  },
  filter: {
    width: 90,
  },
  toolbarDashStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarTitle: {
    flexGrow: 5,
  },
  card: {
    textAlign: 'center',
    width: 200,
    cursor: 'pointer',
  },
  container: {
    padding: 16,
  },
  number: {
    color: theme.palette.primary.main,
  },
}))
