import makeStyles from '@mui/styles/makeStyles'

export const IFPPanelStyle = makeStyles(
  (theme, props = { color: 'white' }) => ({
    parent: {
      // display: 'flex',
      width: '100%',
    },
    panel: {
      border: '1px solid black',
      width: '100%',
    },
    stage: {
      border: '1px solid grey',
      backgroundColor: 'rgba(0, 0, 0, 0.23)',
    },
    pad: {
      padding: '1rem',
      height: '100%',
    },
    alignFields: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formControl: {
      width: 200,
    },
    spaceDetails: {
      padding: 16,
    },
    multiple: {
      float: 'left',
    },
    container: {
      maxHeight: 440,
      border: '1px solid grey',
      borderRadius: 4,
    },
    dot: {
      width: 20,
      height: 20,
      background: (props) => props.color,
      // border: '4px solid white',
      border: '1px solid black',

      borderRadius: '50%',
      // boxShadow: '2px 2px 2px black',
      //   margin:10
    },
    sameLine: {
      display: 'flex',
      flexDirection: 'row',
    },
    colorBox: {
      marginTop: '25%',
    },
    popupStyle: {
      padding: '10px',
      borderRadius: '3px',
      boxShadow: '0 0 5px grey',
      zIndex: 10,
      backgroundColor: 'gainsboro',
      position: 'absolute',
    },
    alignLabel: {
      marginTop: 12,
    },
    zoomBtn: {
      color: theme.palette.text.primary,
    },
    optionPanel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'whitesmoke',
      padding: theme.spacing(1),
    },
    selected: {
      color: 'white !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    btnBorder: {
      border: `1px solid rgba(172, 0, 0, 0.5) !important`,
    },
  }),
)
