import { useState, useEffect, useRef } from 'react'
import { Stage, Layer, Image, Circle, Text, Rect } from 'react-konva'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { IFPPanelStyle } from '../../styles/ifpPanelStyle'
import { CircleDot } from '../../components/common/mapCircle'
import ButtonGroup from '@mui/material/ButtonGroup'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import Tooltip from '@mui/material/Tooltip'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
//height and width on upload
const default_height = 800
const default_width = 1500

const zoomScale = 1.25
const maxZoomIn = 3
const maxZoomOut = 0.5
const mobileMaxZoomOut = 0.1

function useScreenOrientation() {
  const [orientation, setOrientation] = useState('portrait')

  useEffect(() => {
    const reportWindowSize = () => {
      if (window.innerHeight > window.innerWidth) setOrientation('portrait')
      else setOrientation('landscape')
    }
    window.addEventListener('resize', reportWindowSize)
    return () => window.removeEventListener('resize', reportWindowSize)
  }, [])

  return orientation
}

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  }
}

var lastCenter = null
var lastDist = 0

export const FloorMapCanvas = ({
  allPoints,
  pngImage,
  stageWidth = default_width,
  stageHeight = default_height,
  workstationId = 'desk_id',
  selectedDesk,
  setSelectedDesk,
  reservingForLength,
}) => {
  const classes = IFPPanelStyle()
  const orientation = useScreenOrientation()

  const [isZooming, setIsZooming] = useState(false)

  const [scalingWidth, setScalingWidth] = useState(1)
  const [scalingHeight, setScalingHeight] = useState(1)

  const [imageLoading, setImageLoading] = useState(false)
  const [selected, setSelected] = useState(new Set())

  const [image, setImage] = useState(null)

  const imageRef = useRef(null)
  const stageRef = useRef(null)

  useEffect(() => {
    let scaledHeight = stageHeight / default_height
    let scaledWidth = stageWidth / default_width

    setScalingHeight(scaledHeight)
    setScalingWidth(scaledWidth)
    if (stageRef.current) {
      fitStageIntoParentContainer()

      if (selectedDesk.length !== 0) {
        let desk = selectedDesk.map((desk) => desk.desk_id)
        setSelected(new Set([...desk]))
      }
    }
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(function () {
      resetScaleAndOrientation(orientation)
    }, 200)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation])

  function fitStageIntoParentContainer(deviceOrientation = orientation) {
    if (stageRef.current) {
      const stage = stageRef.current
      // now we need to fit stage into parent
      var containerWidth = window.innerWidth

      // now we need to fit stage into parent
      var containerHeight = window.innerHeight

      // to do this we need to scale the stage

      var scaleX = containerWidth / stageWidth

      // to do this we need to scale the stage
      var scaleY = containerHeight / stageHeight

      if (deviceOrientation.split('-')[0] === 'portrait') {
        scaleX = scaleY = Math.min(scaleX, scaleY) //  to enable "uniform stretch"
      }

      stage.width(stageWidth * scaleX)
      stage.height(stageHeight * scaleY)
      stage.scale({ x: scaleX, y: scaleY })
      stage.draw()
    }
  }

  useEffect(() => {
    loadImage()
    setImageLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pngImage])

  function handleLoad() {
    setImageLoading(false)

    setImage(imageRef.current)
  }

  function loadImage() {
    const img = new window.Image()
    img.src = pngImage
    img.crossOrigin = 'Anonymous'
    imageRef.current = img
    imageRef.current.addEventListener('load', handleLoad)
  }

  const handleClick = (e) => {
    let data = e.target.attrs

    if (selected.has(data.id)) {
      let tempSet = new Set([...selected])
      tempSet.delete(data.id)
      setSelected(tempSet)
      let newSelectedDesks = selectedDesk.filter(
        (desk) => desk.desk_id !== data.id,
      )
      setSelectedDesk(newSelectedDesks)
    } else {
      if (selected.size < reservingForLength) {
        setSelected(new Set([...selected]).add(data.id))
        setSelectedDesk([...selectedDesk, { desk_id: data.id }])
      } else {
        toast.error(
          'You can only select as many desks as people reserving.',
          toastConfigure,
        )
      }
    }
  }

  const resetScale = () => {
    if (stageRef.current !== null) {
      const stage = stageRef.current

      fitStageIntoParentContainer()
      const newPos = {
        x: 0,
        y: 0,
      }

      stage.position(newPos)
      stage.batchDraw()
    }
  }

  const resetScaleAndOrientation = (deviceOrientation = orientation) => {
    if (stageRef.current !== null) {
      const stage = stageRef.current

      fitStageIntoParentContainer(deviceOrientation)
      const newPos = {
        x: 0,
        y: 0,
      }

      stage.position(newPos)
      stage.batchDraw()
    }
  }

  const zoom = (magnify) => {
    if (stageRef.current !== null) {
      const stage = stageRef.current
      const oldScale = stage.scaleX()

      const midPoint = { x: stage.attrs.width / 2, y: stage.attrs.height / 2 }

      const mousePointTo = {
        x: (midPoint.x - stage.x()) / oldScale,
        y: (midPoint.y - stage.y()) / oldScale,
      }

      const newScale = magnify ? oldScale * zoomScale : oldScale / zoomScale

      if (stage.attrs.width <= 600) {
        //for mobile devices
        if (newScale > maxZoomIn || newScale < mobileMaxZoomOut) return
      } else {
        if (newScale > maxZoomIn || newScale < maxZoomOut) return
      }

      stage.scale({ x: newScale, y: newScale })
      const newPos = {
        x: midPoint.x - mousePointTo.x * newScale,
        y: midPoint.y - mousePointTo.y * newScale,
      }
      stage.position(newPos)
      stage.batchDraw()
    }
  }

  const handleMultiTouch = (e) => {
    e.evt.preventDefault()

    var touch1 = e.evt.touches[0]
    var touch2 = e.evt.touches[1]
    const stage = e.target.getStage()

    if (touch1 && touch2) {
      setIsZooming(true)

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      }
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      }

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2)
        return
      }
      var newCenter = getCenter(p1, p2)

      var dist = getDistance(p1, p2)

      if (!lastDist) {
        lastDist = dist
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      }

      var scale = stage.scaleX() * (dist / lastDist)

      if (stage.attrs.width <= 600) {
        //for mobile devices
        if (scale > maxZoomIn || scale < mobileMaxZoomOut) return
      } else {
        if (scale > maxZoomIn || scale < maxZoomOut) return
      }

      stage.scaleX(scale)
      stage.scaleY(scale)

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x
      var dy = newCenter.y - lastCenter.y

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      }

      stage.position(newPos)
      stage.batchDraw()

      lastDist = dist
      lastCenter = newCenter
    }
  }

  const handleTouchEnd = () => {
    lastDist = 0
    lastCenter = null
    setIsZooming(false)
  }
  const handleDragStart = (e) => {
    const stage = e.target.getStage()

    if (isZooming) {
      stage.stopDrag()
    }
  }

  return (
    <div>
      <div className={classes.optionPanel}>
        <ButtonGroup color="primary" aria-label="zoom buttons">
          <Tooltip title="Zoom In" arrow>
            <Button
              variant="outlined"
              // color="primary"
              onClick={() => zoom(true)}
            >
              <ZoomInIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Zoom Out" arrow>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => zoom(false)}
            >
              <ZoomOutIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Fit Layout" arrow>
            <Button
              variant="outlined"
              color="primary"
              onClick={resetScale}
              startIcon={<AspectRatioIcon />}
            >
              Fit
            </Button>
          </Tooltip>
        </ButtonGroup>
      </div>
      <Stage
        width={stageWidth}
        height={stageHeight}
        className={classes.stage}
        ref={stageRef}
        onTouchMove={handleMultiTouch}
        onTouchEnd={handleTouchEnd}
        draggable={true}
        onDragStart={handleDragStart}
      >
        <Layer>
          <Image
            image={image}
            width={stageWidth}
            height={stageHeight}
            ref={imageRef}
          />

          {allPoints &&
            allPoints.map((circlePoint) => (
              <Circle
                key={circlePoint[workstationId]}
                id={circlePoint[workstationId]}
                x={circlePoint.xcoordinate * scalingWidth}
                y={circlePoint.ycoordinate * scalingHeight}
                radius={8}
                fill={
                  selected.has(circlePoint[workstationId])
                    ? '#ff007f'
                    : 'limegreen'
                }
                status={circlePoint.space_enabled}
                opacity={1}
                onClick={handleClick}
                onTap={handleClick}
                stroke="black"
                strokeWidth={1}
              />
            ))}
        </Layer>

        <Layer listening={false}>
          {imageLoading && (
            <>
              <Rect
                x={stageWidth / 2}
                y={stageHeight / 2}
                fontSize={24}
                stroke="#555"
                strokeWidth={5}
                fill="#ddd"
                width={135}
                height={45}
                shadowColor="black"
                shadowBlur={10}
                shadowOffsetX={10}
                shadowOffsetY={10}
                shadowOpacity={0.2}
                cornerRadius={10}
              />
              <Text
                text="Loading..."
                x={stageWidth / 2}
                y={stageHeight / 2}
                fontSize={24}
                fill="#AC0000"
                fontStyle="bold"
                align="center"
                padding={10}
              />
            </>
          )}
        </Layer>
      </Stage>
      <br />

      <Grid container justifyContent="center">
        <Typography variant="body" className={classes.sameLine}>
          (Please click on &nbsp; <CircleDot color="limegreen" />
          &nbsp; to select workspace)
        </Typography>
      </Grid>
      <br />
      <Grid container justifyContent="center" spacing={3}>
        <Grid item direction="row" className={classes.sameLine}>
          <CircleDot color="limegreen" />
          &nbsp;&nbsp;
          <Typography>Available</Typography>
        </Grid>

        <Grid item className={classes.sameLine}>
          <CircleDot color="#ff007f" />
          &nbsp;&nbsp;
          <Typography>Selected</Typography>
        </Grid>
      </Grid>
    </div>
  )
}
