import { Grid, Typography } from '@mui/material'
import LoginStyle from '../../styles/india/loginStyles'

const WelcomeScreen = () => {
  const classes = LoginStyle()

  return (
    <Grid
      container
      direction="column"
      // spacing={2}
      className={classes.loginBg}
    >
      <Grid
        item
        style={{
          paddingTop: '15%',
          textAlign: 'center',
        }}
      >
        <img src="/wsrWhiteLogo.png" height="300px" alt="WSR logo" />
      </Grid>
      <Grid item className={classes.welcomeMsg}>
        <Typography className={classes.text}>
          Welcome to <em>Workspace Reservation</em>
        </Typography>
      </Grid>
    </Grid>
  )
}
export default WelcomeScreen
