import React, { useState, useEffect } from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Typography, Grid } from '@mui/material'
// import SettingsIcon from '@mui/icons-material/Settings'
// import logo from '../images/TargetLogoWhite.png'
import { useAuth } from '@praxis/component-auth'
import { SideDrawerStyle } from '../styles/sideDrawerStyle'
import { Link } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import axios from 'axios'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled'
// import Switch from '@mui/material/Switch'
// import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
// import Brightness4Icon from '@mui/icons-material/Brightness4'
import GetAppIcon from '@mui/icons-material/GetApp'
import PersonIcon from '@mui/icons-material/Person'
import DataUsageIcon from '@mui/icons-material/DataUsage'
// import ListIcon from '@mui/icons-material/List'
import { useNavigate } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useDispatch } from 'react-redux'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
// import DashboardIcon from '@mui/icons-material/Dashboard'
// import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import { useEnv } from '@praxis/component-runtime-env'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import DescriptionIcon from '@mui/icons-material/Description'
// import { contractor, USA } from './common/constants'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ApartmentIcon from '@mui/icons-material/Apartment'
// import Healing from '@mui/icons-material/Healing'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice'
import CreateIcon from '@mui/icons-material/Create'
import WorkSharp from '@mui/icons-material/WorkSharp'
import Business from '@mui/icons-material/Business'
import GridOnIcon from '@mui/icons-material/GridOn'
import HomeIcon from '@mui/icons-material/Home'

const SideDrawer = (props) => {
  const classes = SideDrawerStyle()
  const env = useEnv()
  const anchor = props.anchor
  const auth = useAuth()
  const history = useNavigate()
  const dispatch = useDispatch()
  const { session } = auth

  let [userAccess, setUserAccess] = useState(new Set())

  const [creOpen, setCreOpen] = useState(false)
  const [creDesignerOpen, setCreDesignerOpen] = useState(false)
  const [reportingOpen, setReportingOpen] = useState(false)
  const [homebaseOpen, setHomebaseOpen] = useState(false)

  // const location = useSelector((state) => state.common.location)
  // const userProfile = useSelector((state) => state.common.userProfile)
  // const [settingsOpen, setSettingsOpen] = useState(false)

  const byPassRoutes = () => {
    let currentWindow = window.location.href

    if (
      currentWindow.includes('/cre/contractorCertificate') ||
      currentWindow.includes('/tm/servicerequest')
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    let adGroups = session.userInfo.memberOf.join(',')
    axios
      .get(env.apiUrl['USA'].authUrl + adGroups)
      .then((response) => {
        let adgroupSet = new Set(response.data)
        setUserAccess(adgroupSet)
        dispatch({ type: 'SAVE_ACCESS_DETAILS', payload: adgroupSet })

        if (byPassRoutes()) {
          if (adgroupSet.size === 1 && adgroupSet.has('Cleaning Update')) {
            history('/housekeeping/landing')
          } else {
            history('/tm/home')
          }
        }
      })
      .catch((err) => console.log(err))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const installApp = (evt) => {
    document.querySelector('.install-button').click()
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    if (
      event.target.innerHTML === 'CRE Admin' ||
      event.target.innerHTML === 'CRE Designer' ||
      event.target.innerHTML === 'Settings' ||
      event.target.innerHTML === 'Reporting' ||
      event.target.innerHTML === 'Homebase'
    ) {
      return
    }
    props.handleMenu(open)
  }

  // const handleToggle = (event) => {
  //   console.log(event.target.checked)
  //   if (event.target.checked) {
  //     localStorage.setItem('dark-mode', true)
  //     props.setDarkMode(true)
  //   } else {
  //     localStorage.setItem('dark-mode', false)
  //     props.setDarkMode(false)
  //   }
  // }

  const handleCREClick = () => {
    setCreOpen(!creOpen)
  }

  const handleCREDesignerClick = () => {
    setCreDesignerOpen(!creDesignerOpen)
  }

  const handleReportingClick = () => {
    setReportingOpen(!reportingOpen)
  }

  const handleHomebaseClick = () => {
    setHomebaseOpen(!homebaseOpen)
  }

  // const handleHouseKeepingOpen = () => {
  //   setHouseKeepingOpen(!houseKeepingOpen)
  // }

  // const handleSettingsOpen = () => {
  //   setSettingsOpen(!settingsOpen)
  // }

  /* If you are adding expandable button in Drawer,do add the same in function toggleDrawer()
  to prevent auto-closing of drawer*/
  const list = (anchor) => (
    <div
      // className={clsx(classes.list, {
      //   [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      // })}
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item className={classes.outerGrid}>
            <img src="/target.svg" alt="logo" className={classes.imageLogo} />
          </Grid>
          <Grid item>
            <Grid container direction="column" className={classes.innerGrid}>
              <Grid item>
                <Typography>
                  {session == null
                    ? 'PlaceHolderName'
                    : session.userInfo.firstName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {session == null ? 'PlaceHolderName' : session.userInfo.lanId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </div>

      <List>
        <ListItem button key={'Team Member'} component={Link} to="/tm/home">
          <ListItemIcon>
            {' '}
            <PersonIcon />{' '}
          </ListItemIcon>
          <ListItemText primary={'Team Member'} />
        </ListItem>
        <ListItem
          button
          key={'CRE Service Request'}
          component={Link}
          to="/tm/servicerequest"
        >
          <ListItemIcon>
            {' '}
            <CreateIcon />{' '}
          </ListItemIcon>
          <ListItemText primary={'Submit a Building Issue?'} />
        </ListItem>
        {(userAccess.has('CRE Admin') || userAccess.has('Homebase Access')) && (
          <>
            <ListItem
              button
              key={'CRE Designer'}
              onClick={handleCREDesignerClick}
            >
              <ListItemIcon>
                {' '}
                <DesignServicesIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'CRE Designer'} />
              {creDesignerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={creDesignerOpen} unmountOnExit>
              <List component="div" className={classes.nested}>
                <ListItem button component={Link} to="/cre/cadProcess">
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="CAD File Processing (US)" />
                </ListItem>
                <ListItem button component={Link} to="/cre/upload">
                  <ListItemIcon>
                    <CloudUploadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload Floorplan (TII)" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button key={'CRE Admin'} onClick={handleCREClick}>
              <ListItemIcon>
                {' '}
                <SupervisedUserCircleIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'CRE Admin'} />
              {creOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={creOpen} unmountOnExit>
              <List component="div" className={classes.nested}>
                {/* <ListItem button component={Link} to="/cre/dashboard">
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem> */}
                {userAccess.has('CRE Admin') && (
                  <>
                    <ListItem button component={Link} to="/cre/spaceenablement">
                      <ListItemIcon>
                        <DesktopAccessDisabledIcon />
                      </ListItemIcon>
                      <ListItemText primary="Enable/Disable Workspace" />
                    </ListItem>
                    <ListItem button component={Link} to="/cre/spacemaster">
                      <ListItemIcon>
                        <WorkSharp />
                      </ListItemIcon>
                      <ListItemText primary="Space Hierarchy" />
                    </ListItem>
                  </>
                )}
                {(userAccess.has('CRE Admin') ||
                  userAccess.has('Homebase Access')) && (
                  <ListItem button component={Link} to="/cre/spaceallocation">
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Team Member Space Allocation (US)" />
                  </ListItem>
                )}
                {userAccess.has('CRE Admin') && (
                  <ListItem button component={Link} to="/cre/excelupload">
                    <ListItemIcon>
                      <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload Space Details (TII)" />
                  </ListItem>
                )}
                {userAccess.has('CRE Admin') && (
                  <ListItem button component={Link} to="/cre/capacity">
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Capacity Management (TII)" />
                  </ListItem>
                )}
                {userAccess.has('CRE Admin') && (
                  <ListItem button component={Link} to="/cre/feedback">
                    <ListItemIcon>
                      <FeedbackOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Feedback Responses" />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {(userAccess.has('TII Dashboard Access') ||
          userAccess.has('TII COVID Cert Access')) && (
          <>
            {' '}
            <ListItem button key={'Reporting'} onClick={handleReportingClick}>
              <ListItemIcon>
                {' '}
                <AssessmentIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'Reporting'} />

              {reportingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={reportingOpen} unmountOnExit>
              <List component="div" className={classes.nested}>
                {userAccess.has('TII Dashboard Access') && (
                  <ListItem button component={Link} to="/cre/dashboard">
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard (US)" />
                  </ListItem>
                )}
                {userAccess.has('TII Dashboard Access') && (
                  <ListItem button component={Link} to="/cre/tii/dashboard">
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard (TII)" />
                  </ListItem>
                )}
                <ListItem
                  button
                  key={'Insights'}
                  component={Link}
                  to="/insights"
                >
                  <ListItemIcon>
                    {' '}
                    <DataUsageIcon />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Insights'} />
                </ListItem>
                {/* {userAccess.has('TII COVID Cert Access')  && (
                  <ListItem
                    button
                    component={Link}
                    to="/cre/vaccinationDashboard"
                  >
                    <ListItemIcon>
                      <Healing />
                    </ListItemIcon>
                    <ListItemText primary="Contractor Vaccination Dashboard (TII)" />
                  </ListItem>
                )} */}
              </List>
            </Collapse>
          </>
        )}
        {(userAccess.has('CRE Admin') || userAccess.has('Homebase Access')) && (
          <>
            {' '}
            <ListItem button key={'Homebase'} onClick={handleHomebaseClick}>
              <ListItemIcon>
                {' '}
                <HomeIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'Homebase'} />

              {homebaseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={homebaseOpen} unmountOnExit>
              <List component="div" className={classes.nested}>
                <ListItem
                  button
                  key={'homebase'}
                  component={Link}
                  to="/cre/homebasemapping"
                >
                  <ListItemIcon>
                    {' '}
                    <Business />{' '}
                  </ListItemIcon>
                  <ListItemText primary={'Business Unit Mappings'} />
                </ListItem>
                <ListItem button component={Link} to="/cre/changespacestatus">
                  <ListItemIcon>
                    <GridOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Update Homebase Floor Space Status" />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {userAccess.has('Mailstop Update Access') && (
          <ListItem
            button
            key={'mailstop'}
            component={Link}
            to="/update/mailstop"
          >
            <ListItemIcon>
              {' '}
              <LocalPostOfficeIcon />{' '}
            </ListItemIcon>
            <ListItemText primary={'Update Mailstop (US)'} />
          </ListItem>
        )}
        {userAccess.has('Cleaning Update') && (
          <>
            {/* <ListItem
              button
              key={'HouseKeeping'}
              onClick={handleHouseKeepingOpen}
            >
              <ListItemIcon>
                {' '}
                <HomeWorkIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'HouseKeeping'} />
              {houseKeepingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={houseKeepingOpen} unmountOnExit>
              <List component="div" className={classes.nested}>
               <ListItem button component={Link} to="/housekeeping/landing">
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cleaning List" />
                </ListItem>
              </List>
            </Collapse> */}
            <ListItem
              button
              key={'HouseKeeping'}
              component={Link}
              to="/housekeeping/landing"
            >
              <ListItemIcon>
                {' '}
                <HomeWorkIcon />{' '}
              </ListItemIcon>
              <ListItemText primary={'HouseKeeping (US)'} />
            </ListItem>
          </>
        )}
      </List>
      <Divider />
      <>
        {/* <ListItem button key={'Settings'} onClick={handleSettingsOpen}>
          <ListItemIcon>
            {' '}
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'Settings'} />
          {settingsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem> */}

        {/* <Collapse in={settingsOpen} unmountOnExit>
          <List component="div" className={classes.nested}>
            <ListItem button key={'Use Dark Mode'}>
              <ListItemIcon>
                {' '}
                <Brightness4Icon />
              </ListItemIcon>
              <ListItemText primary={'Use Dark Mode'} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  color="primary"
                  onChange={handleToggle}
                  checked={
                    JSON.parse(localStorage.getItem('dark-mode')) || false
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse> */}
        <List>
          {/* <ListItem
            button
            key={'change location'}
            id="changeLocation"
            component={Link}
            to="/location"
          >
            <ListItemIcon>
              <RoomOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={'Change Location'} />
          </ListItem> */}
          {/* {userProfile &&
            userProfile.employee_details &&
            userProfile.employee_details.employee_type === contractor && (
              <ListItem
                button
                key={'upload cert'}
                id="uploadCert"
                component={Link}
                to="/contractor/vaccination"
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={'Vaccination Details'} />
              </ListItem>
            )} */}
          <ListItem
            button
            key={'install app'}
            className={classes.installBanner}
            id="installButton"
            onClick={installApp}
          >
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Install App (Android)'} />
          </ListItem>
        </List>
      </>
    </div>
  )

  return (
    <div>
      <React.Fragment key={anchor}>
        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
        <Drawer
          anchor={anchor}
          open={props.open}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  )
}

export default SideDrawer
