import { useEffect } from 'react'
import MaterialTable from '@material-table/core'
import { useState } from 'react'
import { tableIcons } from '../../components/common/commonFunctionalities'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { ContractorVaccinationStyle } from '../../styles/india/contractorVaccinationStyle'
import { India } from '../../components/common/constants'

const VaccinationDashboard = () => {
  const env = useEnv()
  const classes = ContractorVaccinationStyle()
  const [contractorVaccineList, setContractorVaccineList] = useState([])

  const getContractorVaccineList = () => {
    let modifiedData = []
    const url = env.apiUrl[India].contractorReport
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        res.data.forEach((element) => {
          modifiedData = res.data.filter(
            (item) =>
              (item.vaccination_file_url =
                window.location.origin +
                `/cre/contractorCertificate/${item.user_id}`),
          )
        })
        setContractorVaccineList(modifiedData)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getContractorVaccineList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Box className={classes.parentBox}>
        <Box>
          <MaterialTable
            title="Contractor Vaccination Report"
            columns={[
              { title: 'Contractor ID', field: 'user_id' },
              { title: 'Contractor Name', field: 'user_name' },

              {
                title: 'View Certificate',
                field: 'vaccination_file_url',
                render: (rowData) => (
                  <Link
                    className={classes.linkText}
                    to={{
                      pathname: `/cre/contractorCertificate/${rowData.user_id}`,
                    }}
                    target="_blank"
                  >
                    View
                  </Link>
                ),
              },
            ]}
            data={contractorVaccineList}
            icons={tableIcons}
            options={{
              exportButton: true,
              exportAllData: true,
              pageSize: 10,
              search: true,
              pageSizeOptions: [10, 25, 50],
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default VaccinationDashboard
