import DxfParser from 'dxf-parser'

// export const parsedObject = async (file) => {
//   var reader = new FileReader()
//   reader.readAsText(file)
//   reader.onload = async function (e) {
//     var fileText = e.target.result
//     var parser = new DxfParser()
//     var dxf = null
//     try {
//       dxf = parser.parseSync(fileText)
//     } catch (err) {
//       return console.error(err.stack)
//     }
//     console.log('Success!')

//     var obj = await JSON.stringify(dxf, null, 4)
//     console.log('Done writing output to ' + obj)
//     let extractedPoints = await extractDesks(JSON.parse(obj))
//     console.log(extractedPoints)
//     return new Promise((resolve) => {
//       console.log('ext point', extractedPoints)
//       return resolve(extractedPoints)
//     })
//     // return extractedPoints
//   }
// }

export const parsedObject = async (file) => {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader()
    reader.readAsText(file)
    reader.onload = async function (e) {
      var fileText = e.target.result
      var parser = new DxfParser()
      var dxf = null
      try {
        dxf = await parser.parseSync(fileText)
      } catch (err) {
        return console.error(err.stack)
      }

      var obj = {}
      obj = JSON.stringify(dxf, null, 4)
      resolve(obj)
    }
  })
}
