import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'date-fns'
import moment from 'moment'
import EventIcon from '@mui/icons-material/Event'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TIIOverview from './tiiOverview'
import {
  LoadedTabPanel as TabPanel,
  a11yProps,
  convertAMPMFromHours,
  getDashboardIndiaTableData,
  tableIcons,
} from '../../../components/common/commonFunctionalities'

import { DashboardIndiaStyle } from '../../../styles/india/dashboardStyle'
import { CsvBuilder } from 'filefy'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import _ from 'lodash'
import { INDIA, India } from '../../../components/common/constants'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'

const AdminDashboard = () => {
  const env = useEnv()
  //   const config = env.constants
  const theme = useTheme()
  const classes = DashboardIndiaStyle()
  const [buildingFloor, setBuildingFloor] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState('')
  const [selectedFloor, setSelectedFloor] = useState([])
  const [buildingId, setBuildingId] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [floor, setFloor] = useState([])
  const [location, setLocation] = useState(INDIA)
  const [building, setBuilding] = useState([])
  const [showForType, setShowForType] = useState('')
  //   const [dashboardDate, setDashboardDate] = useState(
  //     moment().format('MM/DD/YYYY')
  //   )
  const [loadTable, setLoadTable] = useState(false)

  const [tabValue, setTabValue] = React.useState(0)
  const [overview, setOverview] = useState(null)
  const [details, setDetails] = useState([])
  const tableRef = React.useRef(null)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [loading, setLoading] = useState(false)

  let [columns, setColumns] = useState([
    {
      field: 'reserved_for_id',
      title: 'LanId',
      width: 100,
    },
    {
      field: 'reservation_type',
      title: 'BookingType',
      width: 100,
      filterComponent: (props) => (
        <FormControl>
          <Select
            defaultValue={''}
            id="search-options"
            className={classes.filter}
            placeholder="select"
            onChange={(event) => changeReservationFilter(event, props)}
            autoWidth={true}
          >
            <MenuItem value="All">All</MenuItem>

            <MenuItem value="Permanent">Permanent</MenuItem>
            <MenuItem value="Reservable">Reservable</MenuItem>
            <MenuItem value="collab">Collab</MenuItem>
            <MenuItem value="Hi-Tech">Hi-Tech</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        if (value === 'All') return true
        else return value === rowData.reservation_type
      },
    },
    {
      field: 'workspace_type',
      title: 'WorkspaceType',
      width: 100,
    },
    {
      field: 'reserved_by',
      title: 'ReservedBy',
      width: 100,
    },
    {
      field: 'reserved_for',
      title: 'ReservedFor',
      width: 100,
    },
    {
      field: 'reserved_for_mail',
      title: 'Email',
      hidden: true,
      width: 100,
    },

    {
      field: 'booking_date',
      title: 'ReservationDate',
      width: 100,
    },
    {
      field: 'status',
      title: 'Status',
      width: 100,
      render: (rowData) => {
        switch (rowData.status) {
          case 'Active':
            return (
              <Chip
                variant="outlined"
                style={{ color: theme.palette.success.main }}
                label={rowData.status}
              />
            )
          case 'Cancelled':
            return (
              <Chip
                variant="outlined"
                style={{ color: theme.palette.error.main }}
                label={'Cancelled'}
              />
            )

          default:
            return <Chip variant="outlined" label={rowData.status} />
        }
      },
      filterComponent: (props) => (
        <FormControl>
          <Select
            defaultValue={''}
            id="search-options"
            className={classes.filter}
            placeholder="select"
            onChange={(event) => {
              props.onFilterChanged(
                props.columnDef.tableData.id,
                event.target.value,
              )
            }}
            autoWidth={true}
          >
            <MenuItem value="">
              <i>None</i>
            </MenuItem>

            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </Select>
        </FormControl>
      ),
      customFilterAndSearch: (value, rowData) => {
        return value === rowData.status
      },
    },
    {
      field: 'start_time',
      title: 'StartTime',
      width: 100,
      render: (rowData) => (
        <div>
          {rowData.start_time &&
            convertAMPMFromHours(rowData.start_time.split(' ')[1])}
        </div>
      ),
    },
    {
      field: 'end_time',
      title: 'EndTime',
      width: 100,
      render: (rowData) => (
        <div>
          {rowData.end_time &&
            convertAMPMFromHours(rowData.end_time.split(' ')[1])}
        </div>
      ),
    },

    {
      field: 'need_transport',
      title: 'Transport',
      width: 100,
    },
    {
      field: 'breakfast',
      title: 'Breakfast',
      width: 100,
    },
    {
      field: 'lunch',
      title: 'Lunch',
      width: 100,
    },
    {
      field: 'lunch_type',
      title: 'LunchType',
      width: 100,
    },
    {
      field: 'contact_number',
      title: 'PrimaryContact',
      width: 100,
    },
    {
      field: 'emergency_contact',
      title: 'EmergencyContact',
      width: 100,
    },
    {
      field: 'address',
      title: 'AddressForTransport',
      width: 100,
    },
    {
      field: 'space_id',
      title: 'SpaceId',
      width: 100,
    },
    {
      field: 'floor_id',
      title: 'FloorName',
      width: 100,
    },
    {
      field: 'building_name',
      title: 'BuildingName',
      width: 100,
      hidden: true,
    },
  ])

  useEffect(() => {
    const url = env.apiUrl[India].getBuildingFloorReservableUrl
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        let sortedData = _.groupBy(
          res.data.filter((item) => item.building_details.location === 'INDIA'),
          'building_details.location',
        )
        setBuildingFloor(sortedData)
        let buildings = []

        buildings = sortedData[location].map(
          (item) => item.building_details.building_name,
        )
        setBuilding(buildings)
      })
      .catch((error) => {
        console.error(error)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeReservationFilter = (event, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, event.target.value)
    setShowForType(event.target.value)
  }
  const handleFloorChange = (event) => {
    setSelectedFloor(event.target.value)

    getDashboardData(location, buildingId, event.target.value)
  }

  const handleCardClick = (event, floorCard = '', buildingCard = null) => {
    if (floorCard === '') {
      //user has clicked on building card

      changeBuilding(buildingCard)
    } else {
      //user has clicked on floor card
      setTabValue(1)

      let event = { target: {} }
      event.target['value'] = [floorCard]
      handleFloorChange(event)
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleLocationChange = (event) => {
    let { value } = event.target
    setLocation(event.target.value)
    getDashboardData(event.target.value)
    let buildings = []
    if (event.target.value !== '') {
      buildings = buildingFloor[value].map(
        (item) => item.building_details.building_name,
      )
    }
    setBuilding(buildings)
    setFloor([])
    setSelectedFloor([])
    setSelectedBuilding('')
  }

  const getDashboardData = (
    location,
    buildingId = '',
    selectedFloor = [],
    selectedStartDate = startDate,
    selectedEndDate = endDate,
  ) => {
    setLoadTable(true)
    let url =
      env.apiUrl[India].getTIIDashboardWeeksData +
      `?building_id=${buildingId}&start_date=${moment(selectedStartDate).format(
        'MM/DD/YYYY',
      )}&end_date=${moment(selectedEndDate).format('MM/DD/YYYY')}`
    axios
      .get(url)
      .then((response) => {
        setLoadTable(false)
        setLoading(false)
        let data = Object.assign({}, response.data)
        delete data['reservation_list']
        setOverview(data)
        setDetails(response.data.reservation_list)
      })
      .catch((err) => console.log(err))
  }

  //This function will be removed once we get organization_name in dashboard api response

  const handleBuildingChange = (event) => {
    setLoading(true)
    setSelectedBuilding(event.target.value)
    let selectedBuildingId = ''
    let floors = []
    if (event.target.value !== '') {
      selectedBuildingId = buildingFloor[location].find(
        (eachBuilding) =>
          eachBuilding.building_details.building_name === event.target.value,
      ).building_details.building_id
      floors = buildingFloor[location]
        .find(
          (bldng) =>
            bldng.building_details.building_name === event.target.value,
        )
        .floor_names.sort()
    }
    setBuildingId(selectedBuildingId)
    setFloor(floors)
    getDashboardData(location, selectedBuildingId)

    setSelectedFloor([])
    setLoadTable(false)
  }

  const changeBuilding = (buildingObject) => {
    setSelectedBuilding(buildingObject.name)
    setBuildingId(buildingObject.id)

    let floors = buildingFloor[location]
      .find(
        (bldng) => bldng.building_details.building_name === buildingObject.name,
      )
      .floor_names.sort()

    setFloor(floors)
    setSelectedFloor([])
    setLoadTable(false)
    getDashboardData(location, buildingObject.id)
  }

  const selectCard = (e, item) => {
    const omitItems = ['Total', 'Food count', 'Transport', 'Lunch']
    if (omitItems.includes(item)) {
      return
    }
    const cur = tableRef.current
    cur.onFilterChange(1, item)
    handleTabChange({}, 1)
    setColumns(columns)
    setShowForType(item)
  }

  const exportCustomCSV = (allColumns, allData) => {
    const [columns, data] = getDashboardIndiaTableData(allColumns, allData)

    let fileName = `${selectedBuilding} - ${selectedFloor}`

    const builder = new CsvBuilder(fileName + '.csv')
    builder

      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile()
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Paper>
          <Grid
            container
            spacing={3}
            justifyContent="space-around"
            className={classes.searchBar}
          >
            <Grid item lg={3} md={6} xs={12}>
              <DatePicker
                required
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/D/Y"
                margin="normal"
                id="date-picker-inline"
                maxDate={endDate}
                label=" Start Date"
                value={startDate}
                onChange={(date) => {
                  setLoading(true)
                  setStartDate(date)
                  getDashboardData(
                    location,
                    buildingId,
                    selectedFloor,
                    moment(date).format('MM/DD/YYYY'),
                    endDate,
                  )
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                vari
                autoOk={true}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <DatePicker
                required
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/D/Y"
                margin="normal"
                id="date-picker-inline"
                minDate={startDate}
                label="End Date"
                value={endDate}
                onChange={(date) => {
                  setLoading(true)
                  setEndDate(date)
                  getDashboardData(
                    location,
                    buildingId,
                    selectedFloor,
                    startDate,
                    moment(date).format('MM/DD/YYYY'),
                  )
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<EventIcon style={{ color: '#797979' }} />}
                className={classes.formControl}
                vari
                autoOk={true}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="select-location-label">Location</InputLabel>
                <Select
                  defaultValue={''}
                  labelId="select-location-label"
                  id="select-location"
                  value={location}
                  onChange={handleLocationChange}
                  label="Location"
                >
                  {buildingFloor &&
                    Object.keys(buildingFloor).map((item, key) => {
                      return (
                        <MenuItem value={item} key={key}>
                          {item}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <FormControl
                variant="outlined"
                classes={{ root: classes.formControl }}
              >
                <InputLabel id="dashboard-building-label">
                  Building Name
                </InputLabel>
                <Select
                  defaultValue={''}
                  labelId="dashboard-building-label"
                  id="dashboard-building"
                  value={selectedBuilding}
                  onChange={handleBuildingChange}
                  label="Building Name"
                >
                  <MenuItem disabled>--Choose Building--</MenuItem>

                  {building.map((e, key) => {
                    return (
                      <MenuItem value={e} key={key}>
                        {e}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={6} xs={12}></Grid>
          </Grid>
        </Paper>

        <br />

        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <Paper square>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Details" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <TIIOverview
                  isLoading={loading}
                  cardInfo={overview}
                  handleCardClick={handleCardClick}
                  selectedFloor={selectedFloor}
                  classes={classes}
                  selectCard={selectCard}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <MaterialTable
                  title={`${showForType} Reservations`}
                  isLoading={loadTable}
                  tableRef={tableRef}
                  options={{
                    showTitle: true,
                    search: false,
                    toolbar: true,

                    pageSizeOptions: [10, 25, 50, 100],
                    loadingType: 'overlay',
                    pageSize: 100,
                    headerStyle: {
                      position: 'sticky',
                      top: 0,
                      fontWeight: 'bold',
                    },
                    maxBodyHeight: '53vh',
                    emptyRowsWhenPaging: false,
                    filtering: true,
                  }}
                  columns={columns}
                  data={details}
                  icons={tableIcons}
                  components={{
                    Toolbar: (props) => (
                      <div className={classes.toolbarDashStyle}>
                        <div className={classes.toolbarTitle}>
                          <MTableToolbar {...props} />
                        </div>
                        <Tooltip title="Export to CSV">
                          <IconButton
                            onClick={() =>
                              exportCustomCSV(props.columns, props.originalData)
                            }
                            size="large"
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ),
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: '',
                    },
                  }}
                />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}></Box>
        {/* </Container> */}
      </main>
    </div>
  )
}

export default AdminDashboard
