import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { ViewReservationStyles } from '../../styles/viewReservationStyle'
import WarningIcon from '@mui/icons-material/Warning'

export const ConfirmationCard = ({
  open,
  handleClose,
  reservationId,
  cancelReservation,
}) => {
  const classes = ViewReservationStyles()
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent className={classes.dialogRoot}>
          <div className={classes.cancelBanner}>
            <WarningIcon style={{ fontSize: 56, color: '#ffbb33' }} />
          </div>

          <DialogContentText className={classes.contentDialog}>
            Are you sure you want to cancel the reservation?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: '0 auto', display: 'flex' }}>
          <Button variant="outlined" onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => cancelReservation(reservationId)}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
