import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import axios from 'axios'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { feedbackStyle } from '../../styles/feedbackStyle'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import makeStyles from '@mui/styles/makeStyles'
import DialogContentText from '@mui/material/DialogContentText'
import { useEffect } from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import { Grid } from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'
import { useSelector } from 'react-redux'

const helperTextStyles = makeStyles((theme) => ({
  root: {
    color: '#AC0000',
  },
}))

export default function FeedbackForm({ innerOpen, setInnerOpen }) {
  // feedback desc body
  const env = useEnv()
  const [body, setBody] = useState('')
  const [summary, setSummary] = useState('')
  const [errorText, setErrorText] = useState('')
  const [bodyErrorText, setBodyErrorText] = useState('')
  const helperTestClasses = helperTextStyles()
  const location = useSelector((state) => state.common.location)
  const auth = useAuth()
  const { session } = auth

  useEffect(() => {
    if (summary.length > 0) {
      setErrorText('')
    }
  }, [summary])

  useEffect(() => {
    if (body.length > 0) {
      setErrorText('')
    }
  }, [body])

  const handleFormClose = () => {
    setInnerOpen(false)
    setBody('')
    setSummary('')
    setErrorText('')
    setBodyErrorText('')
  }

  const handleFormSubmit = () => {
    if (summary.length === 0 && body.length === 0) {
      setErrorText('This field cannot be empty')
      setBodyErrorText('This field cannot be empty')
      return
    }
    if (summary.length === 0) {
      setErrorText('This field cannot be empty')
      return
    }
    if (body.length === 0) {
      setBodyErrorText('This field cannot be empty')
      return
    }
    setInnerOpen(false)
    let payload = {}
    payload['created_user_id'] = session.userInfo.email.split('@')[0]
    payload['feedback_type'] = summary
    payload['location'] = location
    payload['comments'] = body
    let feedbackUrl = env.apiUrl['USA'].feedback
    axios.post(feedbackUrl, payload).then((res) => {
      if (res.status === 201) {
        toast.success('Thank you for your feedback', toastConfigure)
      } else {
        toast.error('Error occurred while submitting feedback', toastConfigure)
      }
    })
    handleFormClose()
  }

  const handleSummaryChange = (event) => {
    setSummary(event.target.value)
  }

  const handleBodyChange = (event) => {
    setBody(event.target.value)
  }

  const classes = feedbackStyle()
  return (
    <>
      <div>
        <Dialog
          open={innerOpen}
          onClose={handleFormClose}
          aria-labelledby="feedback-form-dialog"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle className={classes.title} id="form-dialog-title">
            Feedback
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent="space-around"
              direction="column"
            >
              <br />
              <Grid item lg={12} xs={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  required
                >
                  <InputLabel id="building-select-label">
                    Feedback Type
                  </InputLabel>
                  <Select
                    labelId="building-select-label"
                    id="building-select"
                    onChange={handleSummaryChange}
                    value={summary}
                    label="Feedback Type"
                    // displayEmpty
                    className={classes.selected}
                    classes={{ icon: classes.icon, select: classes.selected }}
                  >
                    <MenuItem value="" disabled>
                      Feedback Type
                    </MenuItem>
                    <MenuItem value={'Issue'}>Issue</MenuItem>
                    <MenuItem value={'Suggestion'}>Suggestion</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                    <MenuItem value={'SRF'}>SRF</MenuItem>
                  </Select>
                  <FormHelperText className={helperTestClasses}>
                    {errorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* </Grid> */}
              &nbsp;
              <Grid item>
                <TextField
                  p={3}
                  required
                  FormHelperTextProps={{ classes: helperTestClasses }}
                  helperText={bodyErrorText}
                  id="standard-subject"
                  aria-labelledby="feedback-form-dialog-description"
                  label="Please elaborate"
                  fullWidth
                  defaultValue={body}
                  multiline
                  variant="outlined"
                  rows={4}
                  maxRows={10}
                  onChange={handleBodyChange}
                />
              </Grid>
            </Grid>
            &nbsp;
            <br />
            <DialogContentText>
              <Typography className={classes.typeItalic}>
                Feedback responses are monitored primarily for providing
                technical assistance. For other queries / complaints, please use
                slack channel <b>#wsr-help</b>{' '}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="row"
              spacing={3}
            >
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  onClick={handleFormClose}
                >
                  cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleFormSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
      <div></div>
    </>
  )
}
