import { useState, useEffect, useRef } from 'react'
import { Stage, Layer, Image, Circle, Text, Rect } from 'react-konva'
import { Typography, InputLabel, Select } from '@mui/material'
// import useImage from 'use-image'
import Popup from '../../components/common/ifpPopup'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { IFPPanelStyle } from '../../styles/ifpPanelStyle'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CircleDot } from '../../components/common/mapCircle'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ButtonGroup from '@mui/material/ButtonGroup'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import Tooltip from '@mui/material/Tooltip'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { workstation } from '../../components/common/constants'
import ToggleButton from '@mui/material/ToggleButton'
import MouseOutlinedIcon from '@mui/icons-material/MouseOutlined'

//height and width on upload
const default_height = 800
const default_width = 1500
const scaleBy = 1.01
const zoomScale = 1.25
const maxZoomIn = 3
const maxZoomOut = 0.5
export const DeskLayout = ({
  allPoints,
  pngImage,
  stageWidth = default_width,
  stageHeight = default_height,
  enableDisableSpace,
  clearMapSelection,
  allSpaceType,
}) => {
  const classes = IFPPanelStyle()
  const [scalingWidth, setScalingWidth] = useState(1)
  const [scalingHeight, setScalingHeight] = useState(1)
  // const [stageScale, setStageScale] = useState(1)

  const [imageLoading, setImageLoading] = useState(false)
  const [selected, setSelected] = useState(new Set())
  const [spaceArray, setSpaceArray] = useState([])
  const [spaceDetails, setSpaceDetails] = useState({
    spaceNumber: '-',
    status: '-',
    reserved: '',
    reservationDates: '',
  })
  const [selectedSpaceType, setSelectedSpaceType] = useState(workstation)
  const [isWheelingEnabled, setIsWheelingEnabled] = useState(false)

  const [operationValue, setOperationValue] = useState('')
  const columns = [
    {
      id: 'spaceNumber',
      label: 'Space Number',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'reserved',
      label: 'Reserved?',
    },
    {
      id: 'reservationDates',
      label: 'Reservation Dates',
    },
  ]

  const [isMultipleSelect, setIsMultipleSelect] = useState(false)

  const [popup, setPopup] = useState({
    spaceNumber: null,
  })
  // const [image] = useImage('/rev-grayscale.png')
  const [image, setImage] = useState(null)

  const imageRef = useRef(null)
  const stageRef = useRef(null)

  useEffect(() => {
    // loadImage()
    let scaledHeight = stageHeight / default_height
    let scaledWidth = stageWidth / default_width

    setScalingHeight(scaledHeight)
    setScalingWidth(scaledWidth)
    // if (stageRef.current) {
    //   const stage = stageRef.current
    //   // client rect will return bounding box of the stage
    //   // it means the size of area, required to draw all the children
    //   const clientRect = stage.getClientRect()
    //   console.log(clientRect)
    //   const scaleToFit = stageWidth / clientRect.width
    //   setStageScale(scaleToFit)
    // }
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let scaledHeight = stageHeight / default_height
    let scaledWidth = stageWidth / default_width

    setScalingHeight(scaledHeight)
    setScalingWidth(scaledWidth)
    if (stageRef.current) {
      fitStageIntoParentContainer()
    }
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleClear()
  }, [clearMapSelection])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPoints])
  useEffect(() => {
    loadImage()
    console.log('loading image')
    setImageLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pngImage])

  function handleLoad() {
    console.log('image loaded')
    setImageLoading(false)

    setImage(imageRef.current)
  }

  function loadImage() {
    const img = new window.Image()
    img.src = pngImage
    img.crossOrigin = 'Anonymous'
    imageRef.current = img
    imageRef.current.addEventListener('load', handleLoad)
  }

  function fitStageIntoParentContainer() {
    if (stageRef.current) {
      const stage = stageRef.current
      // now we need to fit stage into parent
      var containerWidth = window.innerWidth - 0.09 * window.innerWidth

      // now we need to fit stage into parent
      var containerHeight = window.innerHeight - 0.1 * window.innerHeight

      //make square shape
      // to do this we need to scale the stage

      var scaleX = containerWidth / stageWidth

      // to do this we need to scale the stage
      var scaleY = containerHeight / stageHeight

      // if (deviceOrientation.split('-')[0] === 'portrait') {
      //   scaleX = scaleY = Math.min(scaleX, scaleY) //  to enable "uniform stretch"
      // }

      stage.width(stageWidth * scaleX)
      stage.height(stageHeight * scaleY)
      stage.scale({ x: scaleX, y: scaleY })
      stage.draw()
    }
  }

  const handleSpaceTypeChange = (event) => {
    setSelectedSpaceType(event.target.value)
  }

  const handleClick = (e) => {
    let data = e.target.attrs
    let payload = {}
    let tempSet = new Set(selected)
    //for multiple selection
    if (selected.has(data.id)) {
      tempSet.delete(data.id)
      let newArray = spaceArray.filter((item) => item.spaceNumber !== data.id)
      setSpaceArray(newArray)
      payload.spaceNumber = '-'
      payload.status = '-'
      payload.reservationDates = ''
      payload.reserved = '-'
      setSpaceDetails(payload)
    } else {
      payload.spaceNumber = data.id
      payload.status = data.status ? 'Enabled' : 'Disabled'
      payload.reservationDates = data.reservationDate
      payload.reserved = data.reservationDate[0] !== null ? 'Yes' : 'No'
      setSpaceDetails(payload)

      if (isMultipleSelect) {
        tempSet.add(data.id)
        setSpaceArray([...spaceArray, payload])
      } else tempSet = new Set([data.id])
    }
    setSelected(tempSet)
  }

  const handleHover = (e) => {
    let data = e.target.attrs
    const stage = stageRef.current
    const pointer = stage.getPointerPosition()
    setPopup({
      spaceNumber: data.id,
      position: {
        x: pointer.x,
        y: pointer.y,
      },
    })
  }

  const handleClear = () => {
    let payload = {}
    payload.status = '-'
    payload.spaceNumber = '-'
    payload.reserved = '-'
    setSpaceDetails(payload)
    setSpaceArray([])
    setSelected(new Set())
    setOperationValue('')
  }

  const handleSwitch = (event) => {
    setIsMultipleSelect(event.target.checked)
    handleClear()
  }

  const handleMouseLeave = () => {
    setPopup({ spaceId: null })
  }

  const handleOperationChange = (event) => {
    setOperationValue(event.target.value)
  }

  const handleSingleDesk = () => {
    let status = spaceDetails.status === 'Enabled' ? false : true
    if (!status && spaceDetails.reservationDates[0] !== null) {
      toast.error(
        'Error! Cannot disable workspace with active reservations',
        toastConfigure,
      )
    } else {
      enableDisableSpace(spaceDetails.spaceNumber, status, selectedSpaceType)
    }
  }

  const handleMultipleDesks = () => {
    if (operationValue === 'false') {
      let isReserved = spaceArray.find((space) => {
        return typeof space.reservationDates[0] === 'string'
      })
      isReserved
        ? toast.error(
            `Error! Cannot disable workspace ${isReserved.spaceNumber} with active reservations`,
            toastConfigure,
          )
        : enableDisableSpace(selected, operationValue, selectedSpaceType)
      return
    }
    enableDisableSpace(selected, operationValue, selectedSpaceType)
  }

  const mouseWheeling = (event) => {
    event.evt.preventDefault()
    if (stageRef.current !== null) {
      if (!isWheelingEnabled) return

      const stage = stageRef.current

      const oldScale = stage.scaleX()
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      }
      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy

      if (newScale > maxZoomIn || newScale < maxZoomOut) return

      // setStageScale(newScale)
      stage.scale({ x: newScale, y: newScale })
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      }
      stage.position(newPos)
      stage.batchDraw()
    }
  }

  // const resetScale = () => {
  //   if (stageRef.current !== null) {
  //     const stage = stageRef.current
  //     const newScale = 1
  //     // setStageScale(newScale)

  //     stage.scale({ x: newScale, y: newScale })
  //     const newPos = {
  //       x: 0,
  //       y: 0,
  //     }
  //     // stage.scale({ x: 1, y: 1 })

  //     stage.position(newPos)
  //     stage.batchDraw()
  //   }
  // }

  const resetScale = () => {
    if (stageRef.current !== null) {
      const stage = stageRef.current

      fitStageIntoParentContainer()
      const newPos = {
        x: 0,
        y: 0,
      }

      stage.position(newPos)
      stage.batchDraw()
    }
  }

  const zoom = (magnify) => {
    if (stageRef.current !== null) {
      const stage = stageRef.current

      const oldScale = stage.scaleX()
      const { x: pointerX, y: pointerY } = stage.getPointerPosition()

      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      }
      const newScale = magnify ? oldScale * zoomScale : oldScale / zoomScale
      // setStageScale(newScale)

      if (newScale > maxZoomIn || newScale < maxZoomOut) return
      stage.scale({ x: newScale, y: newScale })
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      }
      stage.position(newPos)
      stage.batchDraw()
    }
  }

  return (
    <div className={classes.parent}>
      <Stage
        width={stageWidth}
        height={stageHeight}
        className={classes.stage}
        ref={stageRef}
        // scaleX={stageScale}
        // scaleY={stageScale}
        draggable
        onWheel={mouseWheeling}
      >
        <Layer listening={false}>
          <Image
            image={image}
            width={stageWidth}
            height={stageHeight}
            ref={imageRef}
          />

          {/* <img src={pngImage} width={stageWidth} height={stageHeight}/> */}
        </Layer>
        <Layer>
          {allPoints &&
            selectedSpaceType &&
            allPoints[selectedSpaceType] &&
            allPoints[selectedSpaceType].map((circlePoint) => (
              <Circle
                key={circlePoint.space_no}
                id={circlePoint.space_no}
                x={circlePoint.x_coordinate * scalingWidth}
                y={circlePoint.y_coordinate * scalingHeight}
                // perfectDrawEnabled={false}
                // numPoints={5}
                radius={7}
                //dodgerblue
                fill={
                  // spaceDetails.spaceNumber === circlePoint.space_no
                  selected.has(circlePoint.space_no)
                    ? 'gold'
                    : circlePoint.space_enabled
                    ? 'green'
                    : 'red'
                }
                status={circlePoint.space_enabled}
                reservationDate={circlePoint.reservation_dates}
                opacity={1}
                // shadowColor="black"
                // shadowBlur={10}
                // shadowOpacity={0.6}
                onClick={handleClick}
                onMouseOver={handleHover}
                onMouseLeave={handleMouseLeave}
                stroke="black"
                strokeWidth={1}
              />
            ))}
        </Layer>

        <Layer listening={false}>
          {imageLoading && (
            <>
              <Rect
                x={stageWidth / 2}
                y={stageHeight / 2}
                fontSize={24}
                stroke="#555"
                strokeWidth={5}
                fill="#ddd"
                width={135}
                height={45}
                shadowColor="black"
                shadowBlur={10}
                shadowOffsetX={10}
                shadowOffsetY={10}
                shadowOpacity={0.2}
                cornerRadius={10}
              />
              <Text
                text="Loading..."
                x={stageWidth / 2}
                y={stageHeight / 2}
                fontSize={24}
                fill="#AC0000"
                fontStyle="bold"
                align="center"
                padding={10}
              />
            </>
          )}
        </Layer>
      </Stage>
      &nbsp;
      <div className={classes.panel}>
        {/* <div style={{backgroundColor:'lightgray'}}> */}
        <div className={classes.optionPanel}>
          <ButtonGroup color="primary" aria-label="zoom buttons">
            <Tooltip title="Zoom In" arrow>
              <Button
                variant="outlined"
                // color="primary"
                onClick={() => zoom(true)}
              >
                <ZoomInIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Zoom Out" arrow>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => zoom(false)}
              >
                <ZoomOutIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Fit Layout" arrow>
              <Button variant="outlined" color="primary" onClick={resetScale}>
                <AspectRatioIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Mouse Zoom In/Out" arrow>
              <ToggleButton
                // variant="outlined"
                // color="primary"
                selected={isWheelingEnabled}
                value="wheel"
                onClick={() => setIsWheelingEnabled(!isWheelingEnabled)}
                classes={{
                  root: classes.btnBorder,
                  selected: classes.selected,
                }}
              >
                {isWheelingEnabled ? (
                  <MouseOutlinedIcon />
                ) : (
                  <MouseOutlinedIcon color="primary" />
                )}
              </ToggleButton>
            </Tooltip>
          </ButtonGroup>
        </div>
        {/* </div> */}
        <div className={classes.pad}>
          <div className={classes.alignFields}>
            <FormControlLabel
              control={
                <Switch
                  checked={isMultipleSelect}
                  onChange={handleSwitch}
                  name="multipleSelection"
                  color="primary"
                  inputProps={{ 'aria-label': 'Select multiple' }}
                />
              }
              label="Select Multiple"
              className={classes.multiple}
            />

            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel shrink htmlFor="workspace-type">
                Workspace Type
              </InputLabel>

              <Select
                native
                labelId="workspace-type"
                id="workspaceType"
                defaultValue={selectedSpaceType}
                value={selectedSpaceType}
                onChange={handleSpaceTypeChange}
                name="Workspace Type"
                label="Workspace Type"
                required
                inputProps={{
                  name: 'Workspace Type',
                  id: 'workspaceType',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {allSpaceType &&
                  allSpaceType.map((spaceType) => (
                    <option value={spaceType}>{spaceType.toUpperCase()}</option>
                  ))}
              </Select>
            </FormControl>
          </div>
          <br />
          <br />
          {/* <hr />  */}
          {isMultipleSelect ? (
            <>
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <FormControl
                    component="fieldset"
                    className={classes.sameLine}
                  >
                    <FormLabel className={classes.alignLabel}>
                      Choose Operation:&nbsp;&nbsp;
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="choose operation"
                      name="operation"
                      defaultValue="end"
                      value={operationValue}
                      onChange={handleOperationChange}
                    >
                      <FormControlLabel
                        value={'true'}
                        control={<Radio color="primary" />}
                        label="Enable"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio color="primary" />}
                        label="Disable"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item className={classes.sameLine}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={operationValue === '' || selected.size === 0}
                    onClick={handleMultipleDesks}
                  >
                    Submit
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClear}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
              <br />
              <Typography variant="h6">
                {' '}
                {spaceArray.length} space(s) selected
              </Typography>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spaceArray.map((row) => {
                      return (
                        <TableRow key={row.spaceNumber}>
                          <TableCell>{row.spaceNumber}</TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.reserved}</TableCell>
                          <TableCell>
                            {row.reservationDates.join(',')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
            </>
          ) : (
            <>
              <br />
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="space details">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={spaceDetails.spaceNumber}>
                      <TableCell>{spaceDetails.spaceNumber}</TableCell>
                      <TableCell>{spaceDetails.status}</TableCell>
                      <TableCell>{spaceDetails.reserved}</TableCell>
                      <TableCell>
                        {spaceDetails.reserved &&
                          spaceDetails.reserved === 'Yes' &&
                          spaceDetails['reservationDates'].toString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <br />
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={
                      spaceDetails.spaceNumber === '-' ||
                      spaceDetails.status === 'Enabled'
                    }
                    onClick={handleSingleDesk}
                  >
                    Enable
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={
                      spaceDetails.spaceNumber === '-' ||
                      spaceDetails.status === 'Disabled'
                    }
                    onClick={handleSingleDesk}
                  >
                    Disable
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={spaceDetails.spaceNumber === '-'}
                    onClick={handleClear}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            justifyContent="center"
            spacing={3}
            className={classes.colorBox}
          >
            <Grid item direction="row" className={classes.sameLine}>
              <CircleDot color="green" />
              &nbsp;&nbsp;
              <Typography>Enabled</Typography>
            </Grid>
            <Grid item className={classes.sameLine}>
              <CircleDot color="red" />
              &nbsp;&nbsp;
              <Typography>Disabled</Typography>
            </Grid>
            <Grid item className={classes.sameLine}>
              <CircleDot color="gold" />
              &nbsp;&nbsp;
              <Typography>Selected</Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      {popup.spaceNumber && (
        <Popup
          position={popup.position}
          spaceId={popup.spaceNumber}
          // onClose={() => {
          //   setPopup({ spaceId: null })
          // }}
        />
      )}
    </div>
  )
}
