import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import axios from 'axios'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../../components/common/commonFunctionalities'
import { Button, Chip } from '@mui/material'
// import fileDownload from 'js-file-download'
import CircularProgress from '@mui/material/CircularProgress'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HomeIcon from '@mui/icons-material/Home'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useEnv } from '@praxis/component-runtime-env'
import { useDropzone } from 'react-dropzone'
import { useAuth } from '@praxis/component-auth'
import { SiMicrosoftexcel } from 'react-icons/si'
import { UploadStyles } from '../../../styles/uploadStyle'
import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'
import ExportWithColumnProtection from '../india/ExportWithColumnProtection'
import WarningIcon from '@mui/icons-material/Warning'
import moment from 'moment'
import fileDownload from 'js-file-download'

const ExcelUpload = () => {
  const classes = UploadStyles()
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const dispatch = useDispatch()
  const excelUploadState = useSelector((state) => state.excelUpload)
  const allBuildings = useSelector((state) => state.excelUpload.allBuildings)
  const [building, setBuilding] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [fileObjects, setFileObjects] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    onDrop: (files) => handleFile(files),
  })

  useEffect(() => {
    getBuildingList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBuildingList = () => {
    const url = env.apiUrl['USA'].getBuildingFloorReservableUrl
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SAVE_EXCELUPLOAD_ALL_BUILDINGS',
          payload: res.data.filter(
            (item) => item.building_details.location === 'INDIA',
          ),
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const buildingChange = (event) => {
    setBuilding(event.target.value)
  }

  useEffect(() => {
    handleBuildingChange(building)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, allBuildings])

  const handleBuildingChange = (building) => {
    if (building !== '') {
      dispatch({
        type: 'SAVE_EXCELUPLOAD_BUILDING',
        payload: building,
      })
      dispatch({
        type: 'SAVE_EXCELUPLOAD_BUILDINGID',
        payload: allBuildings.find(
          (bldng) => bldng.building_details.building_name === building,
        ).building_details.building_id,
      })
      let floors = excelUploadState.allBuildings
        .find((bldng) => bldng.building_details.building_name === building)
        .floor_names.sort()
      dispatch({ type: 'SAVE_EXCELUPLOAD_ALL_FLOORS', payload: floors })
      dispatch({ type: 'SAVE_EXCELUPLOAD_FLOOR', payload: '' })
    }
  }

  const handleFloorChange = (event) => {
    dispatch({ type: 'SAVE_EXCELUPLOAD_FLOOR', payload: event.target.value })
  }

  const getJSONData = (buildingId, selectedFloor, selectedBuilding) => {
    setLoading(true)
    const url = env.apiUrl['India'].downloadExcelJSON
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    let payload = {}
    payload['building_id'] = buildingId
    payload['floor_id'] = selectedFloor
    axios.post(url, payload, config).then((res) => {
      if (res.data.length === 0) {
        setLoading(false)
        toast(
          'No data to download. Please enable desks before downloading it.',
          {
            icon: <WarningIcon style={{ color: 'yellow' }} />,
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          },
        )
      } else {
        const modifiedData = res.data.map(({ floor_name, ...rest }) => rest)
        ExportWithColumnProtection(
          modifiedData,
          `${selectedBuilding}-${selectedFloor}-${moment().format(
            'MMMM Do YYYY',
          )}`,
        )
        setLoading(false)
      }
    })
  }

  // const handleDeleteFiles = (fileObject) => {
  //   setFileObjects([])
  // }

  // const handlePreviewIcon = (fileObject, classes) => {
  //   const iconProps = {
  //     className: classes.image,
  //   }

  //   return <SiMicrosoftexcel color="#008000" {...iconProps} />
  // }

  const handleFile = (files) => {
    if (
      files[0].name.indexOf(
        `${excelUploadState.selectedBuilding}-${excelUploadState.selectedFloor}`,
      ) !== -1
    ) {
      setFileObjects(files)
    } else {
      toast.error(
        'Filename format is wrong. It should be of the format "BuildingName-FloorName***.xlsx"',
        toastConfigure,
      )
    }
  }

  const handleUpload = () => {
    setLoadingSubmit(true)
    let formData = new FormData()
    formData.append('file', fileObjects[0])
    formData.append('created_user_id', session.userInfo.lanId)
    formData.append('created_user_name', session.userInfo.email.split('@')[0])
    formData.append('building_id', excelUploadState.buildingId)
    formData.append('floor_id', excelUploadState.selectedFloor)
    axios
      .put(env.apiUrl['India'].uploadExcelSheet, formData)
      .then((response) => {
        toast.success(
          'Excel upload process started Successfully',
          toastConfigure,
        )
        setLoadingSubmit(false)
        setFileObjects([])
      })

      .catch((err) => {
        console.log(err)
        setLoadingSubmit(false)
        toast.error('Error in uploading file', toastConfigure)
      })
  }

  const handleMasterDownload = () => {
    const url = env.apiUrl['India'].downloadMasterExcelSheet
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
      responseType: 'arraybuffer',
    }
    let payload = {}
    axios.post(url, payload, config).then((res) => {
      fileDownload(
        res.data,
        `MasterSheet-${moment().format('MMMM Do YYYY')}.xlsx`,
      )
    })
  }

  return (
    <div style={{ padding: '1%', paddingTop: '1%' }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item></Grid>
        <Grid item>
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Building Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={excelUploadState.selectedBuilding}
              onChange={buildingChange}
              label="Building Name"
            >
              <MenuItem disabled>--Choose Building--</MenuItem>
              {excelUploadState.allBuildings &&
                excelUploadState.allBuildings.map((building, key) => {
                  return (
                    <MenuItem
                      value={building.building_details.building_name}
                      key={key}
                    >
                      {building.building_details.building_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          &nbsp;&nbsp;
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Floor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={excelUploadState.selectedFloor}
              onChange={handleFloorChange}
              label="Floor Name"
            >
              <MenuItem disabled>--Choose Floor--</MenuItem>
              {excelUploadState.allFloors &&
                excelUploadState.allFloors.map((e, key) => {
                  return (
                    <MenuItem value={e} key={key}>
                      {e}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/cre/dashboard"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <Button variant="text" color="primary" onClick={handleMasterDownload}>
            Download Master Sheet
          </Button>
        </Grid>
      </Grid>
      <br />
      {excelUploadState.selectedFloor !== '' && (
        <div className={classes.uploadSection}>
          <Paper elevation={0}>
            <div style={{ marginRight: '33%' }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                  <IconButton
                    aria-label="Download Template"
                    onClick={() => {
                      getJSONData(
                        excelUploadState.buildingId,
                        excelUploadState.selectedFloor,
                        excelUploadState.selectedBuilding,
                      )
                    }}
                    size="small"
                    color="primary"
                    classes={{ label: classes.iconButtonLabel }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <SiMicrosoftexcel color="#008000" fontSize="1.2rem" />
                    )}
                    <Typography style={{ color: 'black', fontSize: '12px' }}>
                      Download
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={4}>
                {/* <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}> */}
                {/* <DropzoneAreaBase
                      acceptedFiles={['.xlsx']}
                      maxFileSize={20000000}
                      filesLimit={1}
                      onAdd={handleFile}
                      showFileNames={true}
                      showPreviewsInDropzone={true}
                      getPreviewIcon={handlePreviewIcon}
                      fileObjects={fileObjects}
                      onDelete={handleDeleteFiles}
                      showAlerts={['error', 'info']}
                      // useChipsForPreview={true}
                    /> */}
                {/* </ThemeProvider>
                </StyledEngineProvider> */}
                <section className="container">
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    className={classes.dropzone}
                  >
                    <input {...getInputProps()} />
                    <p>Drag & drop file here or click</p>
                    {fileObjects.map((file) => (
                      <Chip
                        key={file.path}
                        variant="outlined"
                        label={file.path}
                      />
                    ))}
                  </div>
                </section>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
          </Paper>
        </div>
      )}
      <br />
      <Grid container xs={12} justifyContent="center">
        {fileObjects && fileObjects.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            disabled={fileObjects.length === 0 || loadingSubmit}
            onClick={handleUpload}
          >
            {loadingSubmit ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        )}
      </Grid>

      <br />
    </div>
  )
}

export default ExcelUpload
