import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { useAuth } from '@praxis/component-auth'
import { ConfirmationCard } from './confirmation'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import EventIcon from '@mui/icons-material/Event'
import { useDispatch } from 'react-redux'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import { useAnalytics } from '@praxis/component-analytics'
import moment from 'moment-timezone'
import toast from 'react-hot-toast'
import {
  toastConfigure,
  getCompensation,
} from '../../components/common/commonFunctionalities'
import {
  workstation,
  Desk,
  Office,
  USA,
  eaganBuildingId,
  timezoneIndia,
  INDIA,
  US,
  India,
  TII_END_TIME,
} from '../../components/common/constants'
import { CircularProgress, Divider } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    // border: '2px solid #AC0000',
    borderRadius: '7px',
    backgroundColor: '#f7f8fa',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    width: '22ch',
  },
  media: {
    height: 170,
  },
  formControl: {
    minWidth: '157px !important',
  },
  dialogRoot: {
    padding: '0px !important',
    textAlign: 'center',
  },
  contentDialog: {
    padding: 4,
    paddingTop: 8,
    fontWeight: 'bolder',
  },
  successBanner: {
    height: '3rem',
    backgroundColor: '#AC0000',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: '1%',
  },
  iconHome: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 220,
    height: 35,
  },
  typographyHeading: {
    fontSize: '13px',
  },
  typographyValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
}))

export default function UpcomingReservations() {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const classes = useStyles()
  const { trackCustomEvent } = useAnalytics()
  const [reservationList, setReservationList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  const [selectedExtTime, setSelectedExtTime] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [extendOpen, setExtendOpen] = React.useState(false)
  const [resId, setResId] = React.useState('')
  // const [modifyResId, setModifyResId] = React.useState('')
  // const [modifyOpen, setModifyOpen] = React.useState(false)
  const [reservationId, setReservationId] = React.useState('')
  const [deskId, setDeskId] = React.useState('')
  const [floorId, setFloorId] = React.useState('')
  const [floorName, setFloorName] = React.useState('')
  const [buildingId, setBuildingId] = React.useState('')
  const [organisation, setOrganisation] = React.useState('')
  const [bookingDate, setBookingDate] = React.useState('')
  const [startTime, setStartTime] = React.useState('')
  const [endTime, setEndTime] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [reservedFor, setReservedFor] = React.useState('')
  const [bookedBy, setBookedBy] = React.useState('')
  const [meetingId, setMeetingId] = React.useState('')
  const [extendMeetingId, setExtendMeetingId] = React.useState('')
  const [workspaceType, setWorkspaceType] = React.useState('')
  const [searchField, setSearchField] = React.useState('')
  const [timeArray, setTimeArray] = React.useState([])
  const [modifyLoading, setModifyLoading] = React.useState(false)
  const [location, setLocation] = React.useState(USA)
  const history = useNavigate()
  const dispatch = useDispatch()

  const getReservationList = () => {
    const url =
      env.apiUrl['USA'].viewReservationsUrl +
      session.userInfo.email.split('@')[0]
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        setReservationList(res.data.filter((item) => item.status === 'Active'))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getReservationList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (refresh === true) {
      const url =
        env.apiUrl['USA'].viewReservationsUrl +
        session.userInfo.email.split('@')[0]
      const config = {
        headers: {
          Authorization: localStorage.access_token,
        },
      }
      axios
        .get(url, config)
        .then((res) => {
          setReservationList(
            res.data.filter((item) => item.status === 'Active'),
          )
          setRefresh(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const cancelReservation = (resId) => {
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    let url =
      env.apiUrl['USA'].cancelReservationUrl +
      `?reservation_id=${resId}&location=${location}&meeting_id=${meetingId}`
    axios
      .delete(url, config)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Reservation cancelled successfully', toastConfigure)
        }
        setRefresh(true)
        setOpen(false)
        trackCustomEvent(
          'Cancel Button',
          url,
          `Success. ReservationId: ${resId}, CancelledBy: ${
            session.userInfo.email.split('@')[0]
          }`,
          'Cancel',
        )
      })
      .catch((err) => {
        toast.error(`Error - ${err}`, toastConfigure)
        trackCustomEvent(
          'Cancel Button',
          url,
          `${err.statusCode} : ${err.statusMsg}`,
          'Cancel',
        )
      })
  }

  const getExtendReservationTimes = (reserveId, endTime) => {
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    let url =
      env.apiUrl['USA'].extendReservationTimeUrl +
      `?reservation_id=${reserveId}`
    axios
      .get(url, config)
      .then((res) => {
        // getExtensionTimeArray(endTime, res.data.split('T')[1])

        let data = res.data.split('T')[1]
        // getExtensionTimeArray(endTime, data)
        timeIntervals(endTime, data)
        setExtendOpen(true)
      })
      .catch((error) => {
        toast.error(`Reservation cannot be extended further`, toastConfigure)
      })
  }

  const timeIntervals = (startString, endString) => {
    var start = moment(startString, 'HH:mm')
    var end = moment(endString, 'HH:mm')

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15)

    var result = []

    var current = moment(start)

    while (current <= end) {
      result.push(current.format('HH:mm'))
      current.add(30, 'minutes')
    }

    setTimeArray(result.slice(1))
  }

  const extensionSubmit = () => {
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    let url =
      env.apiUrl['USA'].extensionUrl + bookingDate + ' ' + selectedExtTime

    let payload = {}
    payload['reservation_id'] = reservationId
    payload['desk_id'] = deskId
    payload['floor_id'] = floorId
    payload['building_id'] = buildingId
    payload['status'] = status
    payload['organisation'] = organisation
    payload['reserved_for'] = reservedFor
    payload['booked_by'] = bookedBy
    payload['booking_date'] = bookingDate
    payload['start_time'] = startTime
    payload['end_time'] = endTime
    payload['meeting_id'] = extendMeetingId
    payload['workspace_type'] = workspaceType
    payload['floor_name'] = floorName
    axios
      .put(url, payload, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Reservation extended successfully', toastConfigure)
        }
        setRefresh(true)
        setExtendOpen(false)
        trackCustomEvent(
          'Extend Button',
          url,
          `Success. Building: ${organisation}, FloorNo: ${floorId}, DeskNumber: ${deskId}, BookingDate: ${bookingDate}, ExtendedBy:${
            session.userInfo.email.split('@')[0]
          }`,
          'Extend',
        )
      })
      .catch((err) => {
        toast.error('Error - Space not available for extending', toastConfigure)
        trackCustomEvent(
          'Extend Button',
          url,
          `${err.statusCode} : ${err.statusMsg}`,
          'Extend',
        )
      })
  }

  const convertAMPMFromHours = (time) => {
    //time= "13:00"
    let colonSplit = time.split(':') //['13','00']
    let timeInNumber = Number(colonSplit[0])
    let finalValue =
      timeInNumber > 12
        ? timeInNumber - 12 + `:${colonSplit[1]} PM`
        : timeInNumber === 12
        ? timeInNumber + `:${colonSplit[1]} PM`
        : timeInNumber + `:${colonSplit[1]} AM`
    return finalValue
  }
  const modifyReservation = (event, data) => {
    event.preventDefault()
    setReservationId(data.reservation_id)
    setModifyLoading(true)
    let payload = {
      building_id: data.building_id,
      reservation_date: [data.booking_date],
      start_time: data.start_time ? convertAMPMFromHours(data.start_time) : '',
      end_time: data.end_time ? convertAMPMFromHours(data.end_time) : '',
      workspace_type: data.workspace_type,
      fullDay: data.start_time ? false : true,
      reservation_id: data.reservation_id,
      reserved_for: data.reserved_for,
      meeting_id: data.meeting_id,
      location_timezone: data.location_timezone,
      lan_id: data.lan_id,
      organisationName: data.organisation,
      primary_location: data.location_timezone === timezoneIndia ? INDIA : US,
      desk_id: data.desk_id,
      transaction_id: data.transaction_id || '',
    }
    if (payload.end_time === TII_END_TIME) {
      payload.start_time = ''
      payload.end_time = ''
      payload.fullDay = true
    }
    dispatch({
      type: 'SAVE_RESERVATION_DETAILS',
      payload: payload,
    })

    if (data.reserved_for !== data.booked_by) {
      dispatch({ type: 'RESERVING_FOR', payload: true })
      getCompensation(env, USA, data.lan_id)
        .then((data) => {
          if (data && 'level' in data) {
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: data['level'],
            })
          } else {
            //This else condition handles {compensation:null} object from worker dev api
            dispatch({
              type: 'RESERVING_FOR_USER_COMPENSATION_US',
              payload: null,
            })
          }
          setModifyLoading(false)

          history('/tm/step1')
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error! Unable to fetch user profile', toastConfigure)
          dispatch({
            type: 'RESERVING_FOR_USER_COMPENSATION_US',
            payload: null,
          })
          setModifyLoading(false)
        })
    } else {
      dispatch({ type: 'RESERVING_FOR', payload: false })
      history('/tm/step1')
      setModifyLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExtendClose = () => {
    setExtendOpen(false)
  }

  const handleChange = (e) => {
    setSearchField(e.target.value)
  }

  const filteredResults = reservationList.filter((res) => {
    return res.booking_date.toLowerCase().includes(searchField.toLowerCase())
    // res.organisation.toLowerCase().includes(searchField.toLowerCase()) ||
    // res.floor_id.toLowerCase().includes(searchField.toLowerCase()) ||
    // res.desk_id.toLowerCase().includes(searchField.toLowerCase()) ||
    // res.reserved_for.toLowerCase().includes(searchField.toLowerCase())
  })

  return (
    <div>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item>
          <Typography>
            <b>
              <i> Total - {filteredResults.length} </i>
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Paper component="form" className={classes.search}>
            <IconButton
              disabled
              className={classes.iconButton}
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search by Date"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              disabled
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/tm/home"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <br />
      {filteredResults.map((e) => {
        return (
          <div key={e.reservation_id}>
            <Card className={classes.root} elevation={4}>
              {/* <CardMedia
                className={classes.media}
                image={Workspace}
                title="workspace"
              > */}
              <CardContent className={classes.content}>
                {e.start_time !== null ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                          {e.booking_date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <EventIcon />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Building:{' '}
                        <b>
                          {e.organisation === 'Target India-GWS Center'
                            ? 'TI-GWS'
                            : e.organisation}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Floor:{' '}
                        <b>
                          {e.floor_name !== null ? e.floor_name : e.floor_id}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space ID: <b>{e.desk_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space Type:{' '}
                        <b>
                          {' '}
                          {e.workspace_type === workstation
                            ? Desk
                            : Office}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Type:{' '}
                        {e.end_time === '18:15' ? (
                          <b> Full </b>
                        ) : (
                          <b> Partial </b>
                        )}
                      </Grid>
                      {e.end_time !== '18:15' ? (
                        <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                          Start Time:{' '}
                          <b>{convertAMPMFromHours(e.start_time)}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.end_time !== '18:15' ? (
                        <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                          End Time: <b>{convertAMPMFromHours(e.end_time)}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.reserved_for !==
                      session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved For: <b>{e.reserved_for}</b>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.booked_by !== session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved By: <b>{e.booked_by}</b>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                          {e.booking_date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <EventIcon />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Building:{' '}
                        <b>
                          {e.organisation === 'Target India-GWS Center'
                            ? 'TI-GWS'
                            : e.organisation}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Floor:{' '}
                        <b>
                          {e.floor_name !== null ? e.floor_name : e.floor_id}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space ID: <b>{e.desk_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space Type:{' '}
                        <b>
                          {' '}
                          {e.workspace_type === workstation
                            ? Desk
                            : Office}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Type: <b>Full</b>
                      </Grid>

                      {e.reserved_for !==
                      session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved For: <b>{e.reserved_for}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.booked_by !== session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved By: <b>{e.booked_by}</b>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </div>
                )}
              </CardContent>
              {/* </CardMedia> */}
              {e.start_time !== null &&
              e.location_timezone !== timezoneIndia ? (
                <CardActions>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setReservationId(e.reservation_id)
                      setDeskId(e.desk_id)
                      setFloorId(e.floor_id)
                      setFloorName(e.floor_name)
                      setBuildingId(e.building_id)
                      setOrganisation(e.organisation)
                      setBookingDate(e.booking_date)
                      setStartTime(e.start_time)
                      setEndTime(e.end_time)
                      setStatus(e.status)
                      setReservedFor(e.reserved_for)
                      setBookedBy(e.booked_by)
                      getExtendReservationTimes(e.reservation_id, e.end_time)
                      setExtendMeetingId(e.meeting_id)
                      setWorkspaceType(e.workspace_type)
                    }}
                  >
                    Extend
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={(event) =>
                      // setModifyResId(e.reservation_id)
                      // setModifyOpen(true)
                      modifyReservation(event, e)
                    }
                    disabled={
                      (e.end_time
                        ? moment(
                            e.booking_date + '' + e.end_time,
                            'MM/DD/YYYY HH:mm',
                          ).format('YYYY/MM/DD HH:mm') <
                          moment()
                            .tz(e.location_timezone)
                            .format('YYYY/MM/DD HH:mm')
                        : false) ||
                      (modifyLoading && e.reservation_id === reservationId)
                    }
                  >
                    {modifyLoading && e.reservation_id === reservationId ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Modify'
                    )}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setOpen(true)
                      setResId(e.reservation_id)
                      setMeetingId(e.meeting_id)
                      e.location_timezone === timezoneIndia
                        ? setLocation(India)
                        : setLocation(USA)
                    }}
                    disabled={
                      e.end_time
                        ? moment(
                            e.booking_date + '' + e.end_time,
                            'MM/DD/YYYY HH:mm',
                          ).format('YYYY/MM/DD HH:mm') <
                          moment()
                            .tz(e.location_timezone)
                            .format('YYYY/MM/DD HH:mm')
                        : false
                    }
                  >
                    Cancel
                  </Button>{' '}
                </CardActions>
              ) : (
                <CardActions>
                  <Button
                    color="primary"
                    // variant="contained"
                    variant="outlined"
                    size="small"
                    onClick={(event) => modifyReservation(event, e)}
                    disabled={
                      (e.end_time
                        ? moment(
                            e.booking_date + '' + e.end_time,
                            'MM/DD/YYYY HH:mm',
                          ).format('YYYY/MM/DD HH:mm') <
                          moment()
                            .tz(e.location_timezone)
                            .format('YYYY/MM/DD HH:mm')
                        : false) ||
                      (modifyLoading && e.reservation_id === reservationId)
                    }
                  >
                    {modifyLoading && e.reservation_id === reservationId ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Modify'
                    )}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setOpen(true)
                      setResId(e.reservation_id)
                      setMeetingId(e.meeting_id)
                      e.location_timezone === timezoneIndia
                        ? setLocation(India)
                        : setLocation(USA)
                    }}
                    disabled={
                      e.end_time
                        ? moment(
                            e.booking_date + '' + e.end_time,
                            'MM/DD/YYYY HH:mm',
                          ).format('YYYY/MM/DD HH:mm') <
                          moment()
                            .tz(e.location_timezone)
                            .format('YYYY/MM/DD HH:mm')
                        : false
                    }
                  >
                    Cancel
                  </Button>{' '}
                </CardActions>
              )}
              <Divider />
              {e.building_id === eaganBuildingId && (
                <CardContent>
                  <u>
                    <strong> Note</strong>
                  </u>
                  : There is no onsite security at this location. In an
                  emergency, call 911 and reference the{' '}
                  <a
                    rel="noreferrer"
                    href={env.apiUrl[USA].emergencyFlipChart}
                    target="_blank"
                  >
                    Emergency Procedures Flipchart
                  </a>
                  . It is important to contact Target Corporate Security
                  following any emergency incident by calling{' '}
                  <strong>612-761-1111</strong> – option #5.
                </CardContent>
              )}
            </Card>
            <br />
            <ConfirmationCard
              open={open}
              handleClose={handleClose}
              reservationId={resId}
              cancelReservation={cancelReservation}
            />
            <Dialog
              open={extendOpen}
              aria-labelledby="responsive-dialog-title"
              maxWidth="xs"
              fullWidth={true}
              handleClose={handleExtendClose}
            >
              <DialogContent className={classes.dialogRoot}>
                <div className={classes.successBanner}>
                  <Typography style={{ fontSize: 25, color: 'white' }}>
                    Extend Reservation
                  </Typography>
                </div>
                <DialogContentText className={classes.contentDialog}>
                  <FormControl className={classes.formControl} required>
                    <InputLabel id="building-select-label">
                      Extend Until
                    </InputLabel>
                    <Select
                      labelId="building-select-label"
                      id="building-select"
                      onChange={(event) =>
                        setSelectedExtTime(event.target.value)
                      }
                      label="Select Building"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Extend Until
                      </MenuItem>
                      {timeArray.map((time) => {
                        return (
                          <MenuItem value={time}>
                            {convertAMPMFromHours(time)}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ margin: '0 auto', display: 'flex' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleExtendClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={extensionSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {/* <Dialog
              open={modifyOpen}
              aria-labelledby="responsive-dialog-title"
              maxWidth="sm"
              fullWidth={true}
              handleClose={handleModifyClose}
            >
              <DialogContent className={classes.dialogRoot}>
                <div className={classes.successBanner}>
                  <Typography style={{ fontSize: 25, color: 'white' }}>
                    Modify Reservation
                  </Typography>
                </div>
                <DialogContentText className={classes.contentDialog}>
                  <Typography>
                    Your existing reservation will be auto-cancelled if you wish
                    to modify your current reservation. Do you want to continue?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ margin: '0 auto', display: 'flex' }}>
                <Button color="primary" onClick={modifyReservation}>
                  Yes
                </Button>
                <Button color="primary" onClick={handleModifyClose}>
                  No
                </Button>
              </DialogActions>
            </Dialog> */}
          </div>
        )
      })}
    </div>
  )
}
