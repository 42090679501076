import { combineReducers } from 'redux'
import { TeamMemberReservationReducer } from './teamMemberReservationReducer'
import { HousekeepingReducer } from './housekeepingReducer'
import { InteractiveFloorReducer } from './interactiveFloorReducer'
import { CommonReducer } from './commonReducer'
import { ExcelUploadReducer } from './india/excelUploadReducer'
import { ReservationIndiaReducer } from './india/reservationReducer'
import { CadProcessingReducer } from './cadProcessingReducer'
import { SpaceHierarchyReducer } from './spaceHierarchyReducer'

const rootReducer = combineReducers({
  teamMember: TeamMemberReservationReducer,
  housekeeping: HousekeepingReducer,
  interactive: InteractiveFloorReducer,
  cadProcess: CadProcessingReducer,
  excelUpload: ExcelUploadReducer,
  spaceHierarchy: SpaceHierarchyReducer,
  common: CommonReducer,
  //specific to India:-
  reservationIndia: ReservationIndiaReducer,
})

export default rootReducer
