import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  Button,
  TextField,
  NativeSelect,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import { tableIcons } from '../../components/common/commonFunctionalities'
import Grid from '@mui/material/Grid'
import { UploadStyles } from '../../styles/uploadStyle'
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { USA } from '../../components/common/constants'
import { useLocation } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import _ from 'lodash'
import InputLabel from '@mui/material/InputLabel'
import AddManualSpaces from './addManualSpace'

const ModifySingleSpace = () => {
  const classes = UploadStyles()
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const dispatch = useDispatch()
  const location = useLocation()
  const [spaceData, setSpaceData] = useState([])
  const [reload, setReload] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('Lan Id')
  const [loading, setLoading] = useState(false)
  const allBuildings = useSelector((state) => state.cadProcess.allBuildings)
  const [buildingDetails, setBuildingDetails] = useState('')
  const [floors, setFloors] = useState('')
  const [floorId, setFloorId] = useState('')
  const [enableFloor, setEnableFloor] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [enableCheckBox, setEnableCheckBox] = useState(false)
  const [loadTable, setLoadTable] = useState(false)
  const [costCenters, setCostCenters] = useState('')
  const [columns, setColumns] = useState([
    {
      field: 'lan_id',
      title: 'Lan ID',
      editable: 'never',
    },
    {
      field: 'first_name',
      title: 'First Name',
      editable: 'never',
    },
    {
      field: 'last_name',
      title: 'Last Name',
      editable: 'never',
    },
    {
      field: 'building_name',
      title: 'Building Name',
      editable: 'never',
    },
    {
      field: 'floor_name',
      title: 'Floor Name',
      editable: 'never',
    },
    {
      field: 'space_id',
      title: 'Space ID',
      editable: 'never',
    },
    {
      field: 'mailstop',
      title: 'Mailstop',
    },
    {
      field: 'cost_center_id',
      title: 'Cost Center ID',
      editable: 'never',
    },
  ])

  useEffect(() => {
    getAllBuildings()
    getCostCentres()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname === '/cre/spaceallocation') {
      let buildingNameIndex = columns.findIndex(
        (val) => val.field === 'building_name',
      )
      let floorNameIndex = columns.findIndex(
        (val) => val.field === 'floor_name',
      )
      let spaceIdIndex = columns.findIndex((val) => val.field === 'space_id')

      const copyOfColumns = [...columns]
      copyOfColumns[buildingNameIndex] = {
        field: 'building_name',
        title: 'Building Name',
        editComponent: (props) => (
          <>
            <Autocomplete
              id="selected_building"
              name="selected_building"
              options={allBuildings}
              value={allBuildings.find(
                (bldng) => bldng?.building_details?.building_id === props.value,
              )}
              getOptionLabel={(option) => option?.building_details?.building_id}
              onChange={(e, value) => {
                //setSelectedBuilding(value)

                let copyOfRowdata = { ...props.rowData }
                copyOfRowdata['floor_name'] = ''
                copyOfRowdata['building_name'] =
                  value?.building_details?.building_id
                props.onRowDataChange(copyOfRowdata)

                if (props?.value?.building_name !== '') {
                  let floors = allBuildings
                    .find(
                      (bldng) =>
                        bldng?.building_details?.building_id ===
                        value?.building_details?.building_id,
                    )
                    ?.floor_details.sort()
                  dispatch({ type: 'SAVE_CAD_ALL_FLOORS', payload: floors })
                }
              }}
              fullWidth={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {/* <NativeSelect
              value={props.value}
              onChange={(e) => {
                let copyOfRowdata = props.rowData
                copyOfRowdata['floor_name'] = ''
                copyOfRowdata['building_name'] = e.target.value
                props.onRowDataChange(copyOfRowdata)
                handleBuildingChange(e.target.value)
                // props.onChange(e.target.value)
              }}
            >
              <option value="">{''}</option>
              {allBuildings &&
                allBuildings.map((value, key) => {
                  return (
                    <option
                      value={value.building_details.building_id}
                      key={key}
                    >
                      {value.building_details.building_id}
                    </option>
                  )
                })}
            </NativeSelect> */}
          </>
        ),
      }

      copyOfColumns[floorNameIndex] = {
        field: 'floor_name',
        title: 'Floor Name',
        editComponent: (props) => (
          <>
            <NativeSelect
              value={`${props.rowData?.floor_id}:${props.rowData.floor_name}`}
              onChange={(e) => {
                let copyOfRowdata = { ...props.rowData }
                const { value } = e.target
                let values = value.split(':')
                copyOfRowdata['floor_name'] = values[1]
                copyOfRowdata['floor_id'] = values[0]

                props.onRowDataChange(copyOfRowdata)
                // props.onChange(e.target.value)
              }}
            >
              <option value={''} key="empty"></option>
              {allBuildings &&
              props.rowData['building_name'] &&
              props.rowData['building_name'] !== null &&
              props.rowData['building_name'] !== ''
                ? allBuildings
                    .find(
                      (bldng) =>
                        bldng.building_details.building_id ===
                        props.rowData['building_name'],
                    )
                    .floor_details.sort()
                    .map((floor, key) => {
                      return (
                        <option
                          value={`${floor.floor_id}:${floor.floor_name}`}
                          key={floor.floor_id}
                        >
                          {floor.floor_name}
                        </option>
                      )
                    })
                : ''}
            </NativeSelect>
          </>
        ),
      }
      copyOfColumns[spaceIdIndex] = {
        field: 'space_id',
        title: 'Space ID',
      }
      setColumns([...copyOfColumns])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBuildings, location])

  useEffect(() => {
    if (reload) {
      getSpaceDetails()
      setReload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  const getAllBuildings = () => {
    const url = env.apiUrl[USA].getAllBuildingsUrl
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SAVE_CAD_ALL_BUILDINGS',
          payload: res.data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // const handleBuildingChange = (building) => {
  //   if (building !== '') {
  //     let floors = allBuildings
  //       .find((bldng) => bldng.building_details.building_id === building)
  //       .floor_names.sort()
  //     dispatch({ type: 'SAVE_CAD_ALL_FLOORS', payload: floors })
  //     // dispatch({ type: 'SAVE_CAD_FLOOR', payload: '' })
  //   }
  // }

  const getCostCentres = () => {
    let url = env.apiUrl['USA'].getTMACCostCentres
    axios.get(url).then((response) => {
      setCostCenters(
        response.data.map((value) => {
          return `${value.costCenterId + '-' + value.costCenterName}`
        }),
      )
    })
  }

  const getSpaceDetails = () => {
    setLoading(true)
    let buildingFloorDetails = ''
    if (searchBy === 'Building Floor') {
      buildingFloorDetails = searchValue.split(',')
    }
    let costCenter = ''
    if (searchBy === 'Cost Center') {
      costCenter = searchValue.split('-')
    }

    let param = {
      'Lan Id': `lan_id=${searchValue.toUpperCase()}&first_name=&last_name=&building_id=&floor_id=&cost_center_id=`,
      'First Name': `lan_id=&first_name=${searchValue}&last_name=&building_id=&floor_id=&cost_center_id=`,
      'Last Name': `lan_id=&first_name=&last_name=${searchValue}&building_id=&floor_id=&cost_center_id=`,
      'Building Floor': `lan_id=&first_name=&last_name=&building_id=${buildingFloorDetails[0]}&floor_id=${buildingFloorDetails[1]}&cost_center_id=`,
      'Cost Center': `lan_id=&first_name=&last_name=&building_id=&floor_id=&cost_center_id=${costCenter[0]}`,
    }
    let url = env.apiUrl['USA'].searchSingleSpace + param[searchBy]
    axios
      .get(url)
      .then((response) => {
        setLoading(false)
        if (response.data.length === 0) {
          toast.error(
            'No data to display matching the search term',
            toastConfigure,
          )
        }
        setSpaceData(response.data)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const unallocateUser = (data, resolve, reject) => {
    setLoadTable(true)
    let payload = {}
    let url
    if (data.constructor === Array) {
      url =
        env.apiUrl[USA].unallocatemutlipleSpace +
        `${session.userInfo.email.split('@')[0]}`
      let spaces = data.map((value) => {
        return {
          lanId: value.lan_id,
          first_name: value.first_name,
          last_name: value.last_name,
          to_building: value.building_name,
          to_floor: value.floor_id,
          to_space: value.space_id,
          mailstop: value.mailstop,
        }
      })
      payload = spaces
    } else {
      url =
        env.apiUrl[USA].unallocateSpace +
        `${session.userInfo.email.split('@')[0]}`
      payload['lanId'] = data.lan_id
      payload['first_name'] = data.first_name
      payload['last_name'] = data.last_name
      payload['to_building'] = data.building_name
      payload['to_floor'] = data.floor_id
      payload['to_space'] = data.space_id
      payload['mailstop'] = data.mailstop
    }

    axios
      .put(url, payload)
      .then((response) => {
        if (response.status === 200) {
          setLoadTable(false)
          setReload(true)
          data.constructor === Array
            ? toast.success(
                `Unallocated Spaces successfully for the selected TM(s)`,
                toastConfigure,
              )
            : toast.success(
                `Unallocated Space successfully for the TM - ${data.first_name}`,
                toastConfigure,
              )
          resolve()
        } else throw new Error('Unable to update record')
      })
      .catch((err) => {
        setLoadTable(false)
        setReload(true)
        err.response.status === 500
          ? toast.error(
              `Failed to unallocate record for the TM ${payload.first_name}`,
              toastConfigure,
            )
          : toast.error(err.response.data, toastConfigure)
        reject()
      })
  }

  const updateRecord = (newData, oldData, resolve, reject) => {
    let url =
      env.apiUrl['USA'].modifySingleSpace +
      `${session.userInfo.email.split('@')[0]}`
    let payload = {}
    payload['lanId'] = oldData.lan_id
    payload['first_name'] = oldData.first_name
    payload['last_name'] = oldData.last_name
    payload['to_building'] = newData.building_name
    payload['to_floor'] = newData.floor_id
    payload['to_space'] = newData.space_id
    payload['mailstop'] = newData.mailstop
    axios
      .put(url, payload)
      .then((response) => {
        if (response.status === 200) {
          setReload(true)
          toast.success(
            `Details updated successfully for the TM - ${oldData.first_name}`,
            toastConfigure,
          )
          resolve()
        } else throw new Error('Unable to update record')
      })
      .catch((err) => {
        toast.error(err.response.data, toastConfigure)
        reject()
      })
  }

  const updateMailstop = (newData, oldData, resolve, reject) => {
    let url =
      env.apiUrl['USA'].updateMailStop +
      `${session.userInfo.email.split('@')[0]}`
    let payload = {}
    payload['lanId'] = oldData.lan_id
    payload['mailstop'] = newData.mailstop
    axios
      .put(url, payload)
      .then((response) => {
        if (response.status === 200) {
          setReload(true)
          toast.success(
            `Mailstop updated successfully for the TM - ${oldData.first_name}`,
            toastConfigure,
          )
          resolve()
        } else throw new Error('Unable to update record')
      })
      .catch((err) => {
        toast.error(err.response.data, toastConfigure)
        reject()
      })
  }
  const handleSearchByChange = (event) => {
    setEnableFloor(false)
    setFloors('')
    setEnableCheckBox(false)
    if (event.target.value === 'Building Floor') {
      setEnableFloor(true)
      setBuildingDetails('')
      setFloors('')
      setSpaceData([])
      setFloorId()
      setEnableCheckBox(true)
    }
    setSearchBy(event.target.value)
    setSearchValue('')
    setSpaceData([])
  }
  const handlefloorchange = (event) => {
    const { value } = event.target
    const values = value.split(':')

    setFloorId(value)
    setSearchValue(buildingDetails[0] + ',' + values[0])
  }

  return (
    <div>
      <Button
        style={{ float: 'right' }}
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add Manual Space
      </Button>
      {openDialog && (
        <AddManualSpaces
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
      <br />
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item>
          <FormControl variant="outlined">
            <Select
              id="search-by-tm"
              // className={classes.filter}
              // placeholder="select"
              onChange={handleSearchByChange}
              autoWidth={true}
              // defaultValue="Lan Id"
              value={searchBy}
            >
              <MenuItem value="Lan Id">LAN ID</MenuItem>
              <MenuItem value="First Name">First Name</MenuItem>
              <MenuItem value="Last Name">Last Name</MenuItem>
              <MenuItem value="Building Floor">Building&Floor</MenuItem>
              <MenuItem value="Cost Center">Cost Center</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!enableFloor &&
          (searchBy !== 'Cost Center' ? (
            <Grid item>
              <TextField
                required
                id="search-text"
                placeholder={`Search by ${searchBy}`}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') getSpaceDetails()
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <Autocomplete
                id="costCenter"
                name="costCenter"
                variant="outlined"
                options={costCenters || []}
                value={searchValue}
                getOptionLabel={(option) => option}
                onChange={(e, value) => {
                  if (value) {
                    setSearchValue(value)
                  } else {
                    setSearchValue('')
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') getSpaceDetails()
                }}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    required
                    variant="outlined"
                    label="Cost Center"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ))}
        {enableFloor && (
          <>
            <Grid item xs={3}>
              <Autocomplete
                id="building"
                name="building"
                variant="outlined"
                options={allBuildings}
                value={buildingDetails[0]}
                getOptionLabel={(option) => option.building_details.building_id}
                onChange={(e, value) => {
                  if (value) {
                    let filteredData = allBuildings
                      .filter(
                        (item) =>
                          item.building_details.building_id ===
                          value.building_details.building_id,
                      )
                      .map((value) => value.building_details.building_id)

                    setBuildingDetails(filteredData)
                    setFloors(value.floor_details)
                  } else {
                    setBuildingDetails('')
                    setFloors('')
                    setSearchValue('')
                  }
                }}
                fullWidth={true}
                renderInput={(params) => (
                  <TextField
                    required
                    variant="outlined"
                    label="Building"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                required
              >
                <InputLabel htmlFor="floor-label">Floor</InputLabel>
                <Select
                  native
                  labelId="floor-label"
                  id="floor"
                  onChange={handlefloorchange}
                  value={floorId}
                  label="Floor"
                >
                  <option value=""></option>
                  {floors.length !== 0 &&
                    floors.map((floor) => (
                      <option
                        value={`${floor.floor_id}:${floor.floor_name}`}
                        key={floor.floor_id}
                      >
                        {floor.floor_name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={getSpaceDetails}
            disabled={searchValue.trim() === ''}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Search'
            )}
          </Button>
        </Grid>
      </Grid>
      <br />
      {spaceData.length > 0 && (
        <MaterialTable
          title={'Space Allocation Details'}
          isLoading={loadTable}
          options={{
            showTitle: true,
            paging: true,
            loadingType: 'overlay',
            pageSizeOptions: [10, 25, 50, 100],
            pageSize: 25,
            headerStyle: {
              position: 'sticky',
              top: 0,
              backgroundColor: 'rgb(231, 231, 231)',
              height: '50px',
            },
            maxBodyHeight: '55vh',
            emptyRowsWhenPaging: false,
            search: false,
            toolbarButtonAlignment: 'left',
            selection: enableCheckBox,
          }}
          actions={[
            enableCheckBox && {
              icon: tableIcons.DeletePrimary,

              tooltip: 'Unallocate Selected Spaces',
              onClick: (evt, data) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    unallocateUser(data, resolve, reject)
                  }, 1000)
                }),
            },
          ]}
          columns={columns}
          data={spaceData}
          icons={tableIcons}
          editable={{
            isEditable: (rowData) => !rowData.tableData.checked,
            isDeletable: (rowData) =>
              rowData.building_name !== '' &&
              rowData.floor_name !== '' &&
              rowData.space_id !== '',

            isDeleteHidden: (rowData) =>
              location.pathname !== '/cre/spaceallocation' ||
              rowData.tableData.checked,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  let copyOldData = { ...oldData }
                  delete copyOldData['tableData']

                  if (_.isEqual(copyOldData, newData)) {
                    toast.error('No change detected', toastConfigure)
                    reject()
                  } else if (
                    newData.building_name === '' &&
                    newData.floor_name === '' &&
                    newData.space_id === '' &&
                    oldData.building_name === '' &&
                    oldData.floor_name === '' &&
                    oldData.space_id === ''
                  ) {
                    //handle empty building details but mailstop change
                    if (newData.mailstop !== oldData.mailstop) {
                      updateMailstop(newData, oldData, resolve, reject)
                    }
                  } else if (
                    location.pathname === '/cre/spaceallocation' &&
                    (newData.building_name === '' ||
                      newData.floor_name === '' ||
                      newData.space_id === '')
                  ) {
                    toast.error(
                      'Error in processing your request. Empty value found in the data(Building or Floor or Space ID)',
                      toastConfigure,
                    )
                    reject()
                  } else {
                    if (newData.mailstop !== oldData.mailstop) {
                      updateMailstop(newData, oldData, resolve, reject)
                    }
                    if (
                      newData.building_name !== oldData.building_name ||
                      newData.floor_name !== oldData.floor_name ||
                      newData.space_id !== oldData.space_id
                    ) {
                      updateRecord(newData, oldData, resolve, reject)
                    }
                  }
                }, 1000)
              }),

            onRowDelete: (rowData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  unallocateUser(rowData, resolve, reject)
                }, 1000)
              }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: '',
              deleteTooltip: 'Unallocate',
              editRow: {
                deleteText: 'Are you sure you want to unallocate this space?',
                saveTooltip: 'Confirm',
              },
            },
            toolbar: {
              nRowsSelected: '{0} row(s) selected for unallocation',
            },
          }}
        />
      )}
    </div>
  )
}
export default ModifySingleSpace
