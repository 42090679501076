import { useState, useEffect } from 'react'
import MaterialTable from '@material-table/core'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { USA } from '../../components/common/constants'
import {
  exportCustomCSV,
  tableIcons,
} from '../../components/common/commonFunctionalities'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import { useAuth } from '@praxis/component-auth'

const BusinessUnitMappings = () => {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const [data, setData] = useState([])
  const [isInsertDialogOpen, setIsInsertDialogOpen] = useState(false)
  const [newMapping, setNewMapping] = useState({
    businessUnit: '',
    portfolio: '',
    buildingId: '',
    floorId: '',
    costCenter: '',
    floorName: '',
  })
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [editingRowData, setEditingRowData] = useState(null)
  const [buildingFloorData, setBuildingFloorData] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [businessUnits, setBusinessUnits] = useState([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [deletingRowData, setDeletingRowData] = useState(null)

  const fetchData = () => {
    const url = env.apiUrl[USA].getAllBusinessUnitMappings
    axios
      .get(url)
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }

  const fetchBuildingAndFloorData = () => {
    const url = env.apiUrl[USA].getBuildingsandFloorsForHB
    axios
      .get(url)
      .then((response) => {
        setBuildingFloorData(response.data)
      })
      .catch((error) => {
        console.error('Error fetching building and floor data:', error)
      })
  }

  const fetchBusinessUnits = () => {
    const url = env.apiUrl[USA].getAllBusinessUnits
    axios
      .get(url)
      .then((response) => {
        const finalBusinessUnitsList = response.data.map((val) => {
          return `${val.costCenter + '-' + val.businessUnit}`
        })
        setBusinessUnits(finalBusinessUnitsList)
      })

      .catch((error) => {
        console.error('Error fetching business units:', error)
      })
  }

  useEffect(() => {
    fetchData()
    fetchBuildingAndFloorData()
    fetchBusinessUnits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInsertNewMapping = () => {
    setIsInsertDialogOpen(true)
  }

  const handleCloseInsertDialog = () => {
    setIsInsertDialogOpen(false)
    setNewMapping({
      businessUnit: '',
      portfolio: '',
      buildingId: '',
      floorId: '',
      costCenter: '',
      floorName: '',
    })
  }

  const handleNewMappingInputChange = (event) => {
    let { value, name } = event.target
    if (name === 'floorId') {
      let values = value.split(':')
      setNewMapping({
        ...newMapping,
        [name]: values[0],
        floorName: values[1],
      })
    } else {
      setNewMapping({
        ...newMapping,
        [name]: value,
      })
    }
  }

  const handleInsertNewMappingSubmit = () => {
    if (
      !newMapping.businessUnit.trim() ||
      !newMapping.buildingId.trim() ||
      !newMapping.floorId.trim()
    ) {
      toast.error(
        'Please fill all the fields before submitting',
        toastConfigure,
      )
      return
    }

    let parts = newMapping.businessUnit.split('-')
    let newCostCenter = parts[0].trim()
    let newBusinessUnit = parts.slice(1).join('-').trim()

    newMapping.businessUnit = newBusinessUnit
    newMapping.costCenter = newCostCenter

    const url =
      env.apiUrl[USA].createBusinessMapping +
      `?created_by=${session.userInfo.email.split('@')[0]}`
    axios
      .post(url, newMapping)
      .then(() => {
        toast.success('Business Unit Added Successfully', toastConfigure)
        fetchData() // Refresh the table data after adding a record
        setIsInsertDialogOpen(false)
        setNewMapping({
          businessUnit: '',
          portfolio: '',
          buildingId: '',
          floorId: '',
          costCenter: '',
          floorName: '',
        })
      })
      .catch((error) => {
        toast.error('Error inserting new mapping', toastConfigure)
        setNewMapping({
          businessUnit: '',
          portfolio: '',
          buildingId: '',
          floorId: '',
          costCenter: '',
          floorName: '',
        })
        console.error('Error inserting new mapping:', error)
      })
  }

  const handleDeleteMapping = () => {
    const url = `${env.apiUrl[USA].deleteBusinessMapping}/${deletingRowData.id}`
    axios
      .delete(url)
      .then(() => {
        toast.success('Business Unit Deleted Successfully', toastConfigure)
        fetchData() // Refresh the table data after deleting a record
        setIsDeleteDialogOpen(false)
        setDeletingRowData(null)
      })
      .catch((error) => {
        console.error('Error deleting mapping:', error)
      })
  }

  const handleOpenDeleteDialog = (rowData) => {
    setIsDeleteDialogOpen(true)
    setDeletingRowData(rowData)
  }

  const handleOpenEditDialog = (rowData) => {
    setIsEditDialogOpen(true)
    setEditingRowData(rowData)
    setSelectedBuilding(rowData.buildingId)
    setNewMapping({
      businessUnit: rowData.businessUnit,
      portfolio: rowData.portfolio,
      buildingId: rowData.buildingId,
      floorId: rowData.floorId,
      costCenter: rowData.costCenter,
      floorName: rowData.floorName,
    })
  }

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false)
    setEditingRowData(null)
    setNewMapping({
      businessUnit: '',
      portfolio: '',
      buildingId: '',
      floorId: '',
      costCenter: '',
      floorName: '',
    })
  }

  const handleEditMappingSubmit = () => {
    if (
      !newMapping.businessUnit.trim() ||
      !newMapping.buildingId.trim() ||
      !newMapping.floorId.trim()
    ) {
      toast.error(
        'Please fill all the fields before submitting',
        toastConfigure,
      )
      return
    }
    const url = `${env.apiUrl[USA].updateBusinessMapping}/${
      editingRowData.id
    }?modified_by=${session.userInfo.email.split('@')[0]}`
    axios
      .put(url, {
        ...editingRowData,
        buildingId: newMapping.buildingId,
        floorId: newMapping.floorId,
      })
      .then(() => {
        toast.success('Business Unit Updated Successfully', toastConfigure)
        fetchData() // Refresh the table data after updating a record
        setIsEditDialogOpen(false)
        setNewMapping({
          businessUnit: '',
          portfolio: '',
          buildingId: '',
          floorId: '',
          costCenter: '',
          floorName: '',
        })
      })
      .catch((error) => {
        console.error('Error updating mapping:', error)
      })
  }

  return (
    <>
      <div style={{ marginTop: '16px' }}>
        <Grid container direction="row">
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInsertNewMapping}
            >
              Insert New Mapping
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '16px' }}>
        {' '}
        {/* Add a space */}
        <MaterialTable
          title="Business Unit Mappings"
          columns={[
            { title: 'Business Unit', field: 'businessUnit' },
            { title: 'Building ID', field: 'buildingId' },
            { title: 'Floor ID', field: 'floorId', hidden: true },
            { title: 'Floor Name', field: 'floorName' },
            { title: 'Cost Center Number', field: 'costCenter' },
            { title: 'Created By', field: 'createdBy' },
            { title: 'Modified By', field: 'modifiedBy' },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            exportButton: true,
            exportAllData: true,
            search: true,
            pageSizeOptions: [10, 25, 50, 100],
            loadingType: 'overlay',
            pageSize: 100,
            headerStyle: {
              position: 'sticky',
              top: 0,
              // backgroundColor: 'rgb(231, 231, 231)',
              backgroundColor: 'lightgrey',
              height: '50px',
            },
            maxBodyHeight: '60vh',
            emptyRowsWhenPaging: false,
            exportMenu: [
              {
                label: 'Export CSV',
                exportFunc: (cols, datas) =>
                  exportCustomCSV(cols, datas, 'Business_Unit_Mappings'),
              },
            ],
          }}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: 'Edit Mapping',
              onClick: (event, rowData) => handleOpenEditDialog(rowData),
            },
            {
              icon: tableIcons.Delete,
              tooltip: 'Delete Mapping',
              onClick: (event, rowData) => handleOpenDeleteDialog(rowData),
            },
          ]}
        />
      </div>
      <Dialog
        open={isInsertDialogOpen || isEditDialogOpen}
        onClose={
          isInsertDialogOpen ? handleCloseInsertDialog : handleCloseEditDialog
        }
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          {isInsertDialogOpen ? 'Add New Mapping' : 'Edit Mapping'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isInsertDialogOpen
              ? 'Enter the details for the new mapping below:'
              : 'Edit the details for the mapping below:'}
          </DialogContentText>
          <Autocomplete
            id="business-unit-autocomplete"
            options={businessUnits}
            getOptionLabel={(option) => option}
            value={newMapping.businessUnit}
            onChange={(event, value) =>
              setNewMapping({ ...newMapping, businessUnit: value || '' })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="dense"
                label="Business Unit"
              />
            )}
            disabled={isEditDialogOpen}
          />

          <TextField
            select
            variant="outlined"
            margin="dense"
            label="Building ID"
            name="buildingId"
            value={newMapping.buildingId}
            onChange={(event) => {
              handleNewMappingInputChange(event)
              setSelectedBuilding(event.target.value)
            }}
            fullWidth
          >
            {buildingFloorData.map((item) => (
              <MenuItem
                key={item.building_details.building_id}
                value={item.building_details.building_id}
              >
                {item.building_details.building_id}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            margin="dense"
            label="Floor Name"
            name="floorId"
            value={`${newMapping.floorId}:${newMapping.floorName}`}
            onChange={handleNewMappingInputChange}
            fullWidth
          >
            {selectedBuilding &&
              buildingFloorData
                .find(
                  (item) =>
                    item.building_details.building_id === selectedBuilding,
                )
                .floor_details.map((floor) => (
                  <MenuItem
                    key={floor.floor_id}
                    value={`${floor.floor_id}:${floor.floor_name}`}
                  >
                    {floor.floor_name}
                  </MenuItem>
                ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              isInsertDialogOpen
                ? handleCloseInsertDialog
                : handleCloseEditDialog
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={
              isInsertDialogOpen
                ? handleInsertNewMappingSubmit
                : handleEditMappingSubmit
            }
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Delete Mapping</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this mapping?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteMapping} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BusinessUnitMappings
