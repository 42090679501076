import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

export const UploadInteractiveFloorStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: `${theme.spacing(0)}px !important`,
      minWidth: '157px !important',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    avatarStyle: {
      backgroundColor: theme.palette.primary.main,
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    iconHome: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cardRoot: {
      borderRadius: 16,
      padding: '3%',
      [theme.breakpoints.between('lg', 'xl')]: {
        paddingLeft: '5%',
      },
      maxWidth: '50%',
      transform: 'translate(50%,10%)',
      height: '70vh',
    },
    list: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '450px',
    },
    dropzone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      height: 180,
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
  }),
)
