import { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { UploadInteractiveFloorStyles } from '../../styles/uploadInteractiveFloorStyles'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import { Button, Chip } from '@mui/material'
// import { DropzoneAreaBase } from 'material-ui-dropzone'
import { useAuth } from '@praxis/component-auth'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress'

const SaveDxfFile = () => {
  const env = useEnv()
  const classes = UploadInteractiveFloorStyles()
  const auth = useAuth()
  const { session } = auth
  const [fileObjectsDXF, setFileObjectsDXF] = useState([])
  const [fileObjectsPNG, setFileObjectsPNG] = useState([])

  const [loading, setLoading] = useState(false)
  const history = useNavigate()
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/x-dxf': ['.dxf'],
    },
    onDrop: (files) => setFileObjectsDXF(files),
  })
  const dropzoneImage = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDrop: (files) => setFileObjectsPNG(files),
  })

  const selectedFloor = useSelector((state) => state.cadProcess.selectedFloor)
  const buildingId = useSelector((state) => state.cadProcess.buildingId)
  const buildingName = useSelector((state) => state.cadProcess.selectedBuilding)
  const [usFloorNames, setUsFloorNames] = useState([])

  useEffect(() => {
    fetchUSFloors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchUSFloors = () => {
    const url = env.apiUrl['USA'].listHomebaseFlexFloors
    axios
      .get(url)
      .then((response) => {
        const data = response.data

        const filteredFloorNames = data
          .filter((building) => building.building_details.location === 'US')
          .reduce((acc, building) => {
            acc.push(...building.floor_details)
            return acc
          }, [])
          .map((item) => item.floor_id)
        setUsFloorNames(filteredFloorNames)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }
  async function handleDXFUpload() {
    const floorData = selectedFloor.split(':')
    let formData = new FormData()
    formData.append('file', fileObjectsDXF[0])
    formData.append('created_user_id', session.userInfo.lanId)
    formData.append('created_user_name', session.userInfo.fullName)
    formData.append('building_id', buildingId)
    formData.append('floor_id', floorData[0])
    formData.append('file_type', 'DXF')

    return axios
      .put(env.apiUrl['USA'].cadFloorUpload, formData)
      .then((response) => {
        setFileObjectsDXF([])
        // toast.success('DXF file successfully uploaded', toastConfigure)
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        setLoading(false)

        toast.error('Error! AutoCAD file could not be uploaded', toastConfigure)
      })
  }

  async function handlePNGUpload() {
    if (fileObjectsPNG.length === 0) {
      return Promise.resolve(true)
    } else {
      const floorData = selectedFloor.split(':')
      let formData = new FormData()
      formData.append('file', fileObjectsPNG[0])
      formData.append('created_user_id', session.userInfo.lanId)
      formData.append('created_user_name', session.userInfo.fullName)
      formData.append('building_id', buildingId)
      formData.append('floor_id', floorData[0])
      formData.append('file_type', 'PNG')

      return axios
        .put(env.apiUrl['USA'].interactiveFloorUpload, formData)
        .then((response) => {
          setFileObjectsPNG([])
          return Promise.resolve(response.data)
          // toast.success('PNG file successfully uploaded', toastConfigure)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)

          toast.error('Error! Image file could not be uploaded', toastConfigure)
        })
    }
  }

  // const handleDXFDeleteFiles = (fileObject) => {
  //   setFileObjectsDXF([])
  // }

  // const handleDXFFile = (files) => {
  //   setFileObjectsDXF(files)
  // }

  // const handlePNGFile = (files) => {
  //   setFileObjectsPNG(files)
  // }

  // const handlePNGDeleteFiles = (fileObject) => {
  //   setFileObjectsPNG([])
  // }

  const submitFiles = (e) => {
    e.preventDefault()
    setLoading(true)
    handlePNGUpload().then((pngUploaded) => {
      if (pngUploaded) {
        handleDXFUpload()
          .then((dxfData) => {
            if (dxfData) {
              let payload = {}
              const floorData = selectedFloor.split(':')
              payload['building_id'] = buildingId
              payload['floor_id'] = floorData[0]
              payload['uploaded_by'] = session.userInfo.email.split('@')[0]
              toast.success('File(s) successfully uploaded', toastConfigure)
              setLoading(false)
              setTimeout(history('/cre/cadProcess'), 6000)
            }
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            toast.error('Error! Desk data could not be saved', toastConfigure)
          })
      }
    })
  }

  return (
    <div style={{ padding: '1%', paddingTop: '2%' }}>
      <Card className={classes.cardRoot} elevation={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Typography variant={'h4'}>
              Building: {buildingName} &nbsp;&nbsp;Floor:{' '}
              {selectedFloor?.split(':')[1]}
            </Typography>
          </Grid>
        </Grid>
        {/* <hr/> */}
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}></Grid>
        </Grid>
        <br />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={5}>
            {/* <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}> */}
            {/* <DropzoneAreaBase
                  dropzoneText={'Drag and drop a DXF file here or click'}
                  onAdd={handleDXFFile}
                  // onDrop={onDropDxf}
                  acceptedFiles={['.dxf']}
                  filesLimit={1}
                  maxFileSize={100000000} //100MB
                  showFileNames={true}
                  useChipsForPreview={true}
                  fileObjects={fileObjectsDXF}
                  onDelete={handleDXFDeleteFiles}
                /> */}
            {/* </ThemeProvider>
            </StyledEngineProvider> */}
            <section className="container">
              <div
                {...getRootProps({ className: 'dropzone' })}
                className={classes.dropzone}
              >
                <input {...getInputProps()} />
                <p>Drag & drop DXF file here or click</p>
                {fileObjectsDXF.map((file) => (
                  <Chip key={file.path} variant="outlined" label={file.path} />
                ))}
              </div>
            </section>
          </Grid>
          {usFloorNames.includes(selectedFloor?.split(':')[0]) && (
            <Grid item xs={12} lg={5}>
              {/* <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}> */}
              {/* <DropzoneAreaBase
                    dropzoneText={'Drag and drop a PNG file here or click'}
                    onAdd={handlePNGFile}
                    // onDrop={onDropPng}
                    acceptedFiles={['image/png']}
                    maxFileSize={20000000} //20MB
                    filesLimit={1}
                    showFileNames={true}
                    useChipsForPreview={true}
                    fileObjects={fileObjectsPNG}
                    onDelete={handlePNGDeleteFiles}
                  /> */}
              {/* </ThemeProvider>
              </StyledEngineProvider> */}

              <section className="container">
                <div
                  {...dropzoneImage.getRootProps({ className: 'dropzone' })}
                  className={classes.dropzone}
                >
                  <input {...dropzoneImage.getInputProps()} />
                  <p>Drag & drop Image file here or click</p>
                  {fileObjectsPNG.map((file) => (
                    <Chip
                      key={file.path}
                      variant="outlined"
                      label={file.path}
                    />
                  ))}
                </div>
              </section>
            </Grid>
          )}
        </Grid>
        <br />
        <br />
        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/cre/cadProcess"
              className={classes.iconHome}
            >
              {' '}
              Cancel{' '}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={fileObjectsDXF.length === 0 || loading}
              onClick={submitFiles}
            >
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default SaveDxfFile
