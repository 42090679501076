import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Button, adaptV4Theme, Chip } from '@mui/material'
// import { DropzoneAreaBase } from 'material-ui-dropzone'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles'
// import { SiMicrosoftexcel } from 'react-icons/si'
import { UploadStyles } from '../../styles/uploadStyle'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import toast from 'react-hot-toast'
import { toastConfigure } from '../../components/common/commonFunctionalities'
import CircularProgress from '@mui/material/CircularProgress'
import AddManualSpaces from './addManualSpace'
import { useDropzone } from 'react-dropzone'

const theme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          minHeight: '140px',
          backgroundColor: '#F5F5F5',
        },
        text: {
          fontFamily: 'calibri',
          fontSize: '16px',
        },
        icon: {
          color: '#AC0000',
        },
      },
      MuiDropzonePreviewList: {
        root: {
          justifyContent: 'center',
        },
        image: {
          height: '25px',
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: 'calibri',
          fontSize: '14px',
        },
      },
    },
  }),
)

const TeammemberSpaceAllocation = () => {
  const classes = UploadStyles()
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const [fileObjects, setFileObjects] = useState([])
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    onDrop: (files) => setFileObjects(files),
  })

  // const handleDeleteFiles = (fileObject) => {
  //   setFileObjects([])
  // }

  // const handlePreviewIcon = (fileObject, classes) => {
  //   const iconProps = {
  //     className: classes.image,
  //   }

  //   return <SiMicrosoftexcel color="#008000" {...iconProps} />
  // }

  // const handleFile = (files) => {
  //   setFileObjects(files)
  // }

  const handleUpload = () => {
    setLoadingSubmit(true)
    let formData = new FormData()
    formData.append('file', fileObjects[0])
    formData.append('created_user_id', session.userInfo.lanId)
    formData.append('created_user_name', session.userInfo.email.split('@')[0])
    axios
      .put(env.apiUrl['USA'].teammemberSpaceAllocation, formData)
      .then((response) => {
        toast.success(
          'Excel upload process started Successfully',
          toastConfigure,
        )
        setLoadingSubmit(false)
        setFileObjects([])
      })

      .catch((err) => {
        console.log(err)
        setLoadingSubmit(false)
        toast.error('Error in uploading file', toastConfigure)
      })
  }

  return (
    <div>
      <Button
        style={{ float: 'right' }}
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        Add Manual Space
      </Button>
      {openDialog && (
        <AddManualSpaces
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
      <div className={classes.uploadSection}>
        <Paper elevation={0}>
          <br />
          <br />
          <br />
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={4}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  {/* <DropzoneAreaBase
                    acceptedFiles={['.xlsx']}
                    maxFileSize={20000000}
                    filesLimit={1}
                    onAdd={handleFile}
                    showFileNames={true}
                    showPreviewsInDropzone={true}
                    getPreviewIcon={handlePreviewIcon}
                    fileObjects={fileObjects}
                    onDelete={handleDeleteFiles}
                    showAlerts={['error', 'info']}
                  /> */}
                </ThemeProvider>
              </StyledEngineProvider>

              <section className="container">
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className={classes.dropzone}
                >
                  <input {...getInputProps()} />
                  <p>Drag & drop file here or click</p>
                  {fileObjects.map((file) => (
                    <Chip
                      key={file.path}
                      variant="outlined"
                      label={file.path}
                    />
                  ))}
                </div>
              </section>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Paper>
      </div>
      <br />
      <Grid container xs={12} justifyContent="center">
        {fileObjects && fileObjects.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            disabled={fileObjects.length === 0 || loadingSubmit}
            onClick={handleUpload}
          >
            {loadingSubmit ? <CircularProgress size={24} /> : 'Upload'}
          </Button>
        )}
      </Grid>
      <br />
    </div>
  )
}
export default TeammemberSpaceAllocation
