import TextField from '@mui/material/TextField'
import { DayPicker } from 'react-day-picker'
import Popper from '@mui/material/Popper'
import FocusTrap from 'focus-trap-react'
import { Button } from '@mui/material'

const DatePickerMultiple = ({
  id,
  formattedDays,
  classes,
  handleClick,
  open,
  anchorEl,
  closePopper,
  selectedDays,
  handleDayClick,
  finalDisabledDays,
  label = 'Select Date(s)*',
  mode = 'multiple',
}) => {
  const footer = (
    <div className={classes.closeButton}>
      <Button
        variant="outlined"
        color="primary"
        onClick={closePopper}
        aria-label="Close Date picker"
      >
        Close
      </Button>
    </div>
  )
  return (
    <>
      <TextField
        label={label}
        variant="outlined"
        name={id}
        aria-describedby={id}
        value={formattedDays}
        className={classes.formControl}
        readOnly={true}
        onClick={handleClick}
        placeholder="MM/DD/YYYY"
        InputLabelProps={{
          shrink: true,
        }}
        onKeyPress={handleClick}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 10000 }}
        className={classes.datePickerDialog}
        // transition
        // disablePortal
      >
        {/* <ClickAwayListener onClickAway={closePopper}> */}
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <DayPicker
            initialFocus={open}
            // defaultMonth={selected}
            // animation={false}
            selected={selectedDays}
            onDayClick={handleDayClick}
            mode={mode}
            modifiersStyles={{
              selected: {
                backgroundColor: '#AC0000',
                border: '2px solid #AC0000',
              },
              today: {
                color: '#AC0000',
              },
            }}
            disabled={finalDisabledDays}
            footer={footer}
          />
        </FocusTrap>
        {/* </ClickAwayListener> */}
      </Popper>
    </>
  )
}
export default DatePickerMultiple
