import { useState, useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { navBarStyle } from '../styles/navbarStyle'
import SideDrawer from './sideDrawer'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import FeedbackForm from '../pages/teamMember/feedbackForm'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import LoginPage from './common/loginPage'
import WelcomeScreen from './splash/welcomeScreen'
import { useEnv } from '@praxis/component-runtime-env'
import { Chip } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import routesNew from '../routes/routes'
import { ProtectedElement } from '@praxis/component-auth'
// import { LandingPage } from '../pages/teamMember/teamMemberLandingPage'

export const Main = (props) => {
  const { isAuthenticated, login, logout, isAuthorized } = useAuth() // assign variables of same name as properties off of `auth`
  const env = useEnv()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const classes = navBarStyle()
  const [innerOpen, setInnerOpen] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const handleMenu = (value) => {
    // setSideBarOpen(!sideBarOpen)
    setSideBarOpen(value)
  }

  const enableFeedbackForm = () => {
    setInnerOpen(true)
  }

  useEffect(() => {
    setTimeout(() => {
      setRedirect(true)
    }, 1500)
  }, [])

  if (!redirect && !isAuthenticated()) return <WelcomeScreen />

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title}>
            WorkSpace Reservation{' '}
            {env.environment !== 'prod' && (
              <Chip
                size="small"
                className={classes.envBadge}
                label={env.environment.toUpperCase()}
              />
            )}
          </Typography>
          {isAuthorized() && (
            <div className={classes.bar}>
              <IconButton
                aria-label="Feedback"
                size="small"
                color="inherit"
                onClick={enableFeedbackForm}
                className={classes.iconButtonLabel}
                // classes={{ label: classes.iconButtonLabel }}
              >
                <FeedbackOutlinedIcon fontSize="small" />
                Feedback
              </IconButton>
              <IconButton
                aria-label="Logout"
                onClick={logout}
                size="small"
                color="inherit"
                className={classes.iconButtonLabel}
              >
                <ExitToAppIcon fontSize="small" />
                Logout
              </IconButton>
            </div>
          )}
          {isAuthorized() ? (
            <SideDrawer
              anchor="left"
              open={sideBarOpen}
              handleMenu={handleMenu}
              {...props}
            />
          ) : null}
          <FeedbackForm innerOpen={innerOpen} setInnerOpen={setInnerOpen} />
        </Toolbar>
      </AppBar>

      <div className={classes.offset} />
      {!isAuthorized() ? (
        <LoginPage
          login={
            login
            // () => login({ redirect: window.location.href })
          }
        />
      ) : (
        // <ProtectedElement
        //     renderUnauthenticated={() => (
        //       <LoginPage login={() => login({ redirect: window.location.href })} />
        //       )}
        //   >
        //     {/* {firstName}
        //     <button onClick={() => logout()}>Logout</button> */}
        //   </ProtectedElement>
        <Routes>
          {/* <Route path="/" element={<WelcomeScreen />} /> */}

          {routesNew &&
            routesNew.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<ProtectedElement>{route.component}</ProtectedElement>}
              ></Route>
            ))}
        </Routes>
      )}
    </>
  )
}
