import makeStyles from '@mui/styles/makeStyles'

export const OverviewStyle = makeStyles({
  root: {
    // width: 275,
    margin: 16,
    // maxwidth:600
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dashBoardCard: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
})
