import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useSelector } from 'react-redux'
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { RequestFormStyle } from '../../styles/india/requestFormStyle'
import {
  ConferenceRoom,
  Desk,
  Enclave,
  interior,
  Office,
  TMSpace,
  TMSpaces,
  workstation,
} from '../../components/common/constants'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

const filter = createFilterOptions()

const RequestDetails = ({
  requestDetailsData,
  setRequestDetailsData,
  selectedSpaceData,
  setSelectedSpaceData,
}) => {
  const classes = RequestFormStyle()
  const [floors, setFloors] = React.useState([])
  //This stores floor data before filtering
  const [responseData, setResponseData] = React.useState([])
  const env = useEnv()
  const [buildings, setBuildings] = React.useState([])
  // const [selectedBuilding, setSelectedBuilding] = React.useState(null)
  // const [selectedFloor, setSelectedFloor] = React.useState('')
  // const [requestorRadio, setRequestorRadio] = React.useState('interior')
  const location = useSelector((state) => state.common.location)
  const [allAreas, setAllAreas] = useState([])
  const [allProblemCodes, setAllProblemCodes] = useState([])
  const [spaces, setSpaces] = useState([])
  const [allEquipments, setAllEquipments] = useState([])

  useEffect(() => {
    const url = env.apiUrl[location].getBuildingsForSRF
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        res.data.sort((a, b) =>
          a.organization_name > b.organization_name ? 1 : -1,
        )
        setBuildings(res.data)
      })
      .catch((err) => console.log(err))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (buildings != null && requestDetailsData?.selected_building !== '') {
      const url =
        env.apiUrl[location].getFloorsForSRF +
        requestDetailsData?.selected_building.split(':')[1]
      axios
        .get(url)
        .then((response) => {
          response.data.sort((a, b) => (a.floor_name > b.floor_name ? 1 : -1))
          requestDetailsData?.isInteriorOrExterior === interior
            ? setFloors(
                // eslint-disable-next-line prettier/prettier
                response.data.filter((data) => data.is_exterior === false),
              )
            : setFloors(
                response.data.filter((data) => data.is_exterior === true),
              )
          setResponseData(response.data)
        })

        .catch((err) => console.log(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDetailsData.selected_building])

  const getTMSpaces = () => {
    if (
      requestDetailsData.selected_building !== '' &&
      requestDetailsData.selected_floor !== ''
    ) {
      let buildingValue = requestDetailsData.selected_building.split(':')
      let floorValue = requestDetailsData.selected_floor.split(':')

      const url =
        env.apiUrl[location].getAllSpaceTypes +
        `?building_name=${buildingValue[0]}&building_id=${buildingValue[1]}&floor_name=${floorValue[2]}`

      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            if (response.data && response.data.space_details.length > 0) {
              let data = response.data.space_details
              let finalSpaceList = []
              for (let i = 0; i < data.length; i++) {
                let insideData = data[i].space_status
                if (
                  [workstation, Office, Enclave, ConferenceRoom].includes(
                    data[i].space_type,
                  )
                ) {
                  for (let j = 0; j < insideData.length; j++) {
                    finalSpaceList.push({
                      space_no: insideData[j].space_no,
                      space_type:
                        data[i].space_type === workstation
                          ? Desk
                          : data[i].space_type,
                    })
                  }
                }
              }
              finalSpaceList.sort((a, b) =>
                a.space_type > b.space_type ? 1 : -1,
              )
              setSpaces(finalSpaceList)
            } else {
              setSpaces([])
            }
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const handleBuildingChange = (event, newValue) => {
    // setSelectedBuilding(event.target.value)
    setRequestDetailsData({
      ...requestDetailsData,
      selected_building_value: newValue,
      selected_building: `${newValue?.organization_name}:${newValue?.building_id}:${newValue?.location_id}`,
      selected_floor: '',
      selected_sublocation: '',
      selected_type_issue: '',
      selected_issue: '',
      problem_code: '',
    })
    setSelectedSpaceData([])
  }

  const handleFloorChange = (event) => {
    const { value } = event.target
    if (value) {
      setRequestDetailsData({
        ...requestDetailsData,
        selected_floor: value,
        selected_sublocation: '',
        selected_type_issue: '',
      })
      setSelectedSpaceData([])
      let values = value.split(':')
      getArea(values[1])
    }
  }

  const handleRadioButtons = (event, value) => {
    // setRequestorRadio(value)
    setRequestDetailsData({
      ...requestDetailsData,
      isInteriorOrExterior: value,
      selected_floor: '',
      selected_sublocation: '',
      selected_type_issue: '',
      problem_code: '',
    })

    setSelectedSpaceData([])

    value === interior
      ? setFloors(
          // eslint-disable-next-line prettier/prettier
          responseData?.filter((data) => data.is_exterior === false),
        )
      : setFloors(responseData?.filter((data) => data.is_exterior === true))
  }

  const getArea = (floor) => {
    let url = `${env.apiUrl[location].getAreasCreSrf}${floor}${env.apiUrl[location].areaCresrfparams}`

    axios
      .get(url)
      .then((response) => {
        response.data.areas.sort((a, b) =>
          a.description > b.description ? 1 : -1,
        )
        setAllAreas(response.data.areas)
      })
      .catch((err) => console.log(err))
  }

  const fetchAllSublocationProblems = (code) => {
    let url =
      env.apiUrl[location].getProblemCodes +
      code +
      env.apiUrl[location].problemCodeKey
    axios
      .get(url)
      .then((response) => {
        let filteredData = response.data
        if (typeof response.data === 'object') {
          filteredData = response.data.filter(
            (item) => item.to_service_request === true,
          )
        } else {
          filteredData = []
        }
        filteredData?.sort((a, b) => (a.description > b.description ? 1 : -1))
        setAllProblemCodes(filteredData)
      })
      .catch((err) => console.log(err))
  }

  const fetchAllEquipments = (areaCode) => {
    let url =
      env.apiUrl[location].getEquipmentsForSrf +
      areaCode +
      ')?key=' +
      env.apiUrl[location].maximoApikey
    axios
      .get(url)
      .then((response) => {
        if (response?.data && response.data.assets.length > 0) {
          response.data.assets.sort((a, b) =>
            a.description > b.description ? 1 : -1,
          )
          setAllEquipments(response.data.assets)
        }
      })
      .catch(setAllEquipments(''))
  }

  const handleAreaChange = (event) => {
    const { value } = event.target

    if (value) {
      setRequestDetailsData({
        ...requestDetailsData,
        selected_sublocation: value,
        selected_type_issue: '',
        asset_number: '',
        problem_code: '',
      })
      setSelectedSpaceData([])
      let values = value.split(':')
      fetchAllSublocationProblems(values[2])
      if (values[0] === TMSpaces || values[0] === TMSpace) {
        getTMSpaces()
      }

      fetchAllEquipments(values[1])
    }
  }

  const handleFieldsChange = (event) => {
    const { name, value } = event.target
    setRequestDetailsData({ ...requestDetailsData, [name]: value })
  }

  const handleEquipmentChange = (event) => {
    setRequestDetailsData({
      ...requestDetailsData,
      asset_number: event.target.value,
    })
  }

  const handleSpaceSelection = (event, newValue) => {
    if (newValue.length > 3) {
      return
    } else {
      setSelectedSpaceData(newValue)
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component="body1"
            variant="h6"
            className={classes.requestTitle}
          >
            Request Details
            <Divider />
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.formRoot}>
        <br />
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              id="selected_building-label"
              required
              className={classes.formTextColor}
            >
              Select building
            </InputLabel>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              required
            >
              <Autocomplete
                id="selected_building"
                name="selected_building"
                options={buildings}
                value={requestDetailsData.selected_building_value}
                getOptionLabel={(option) => option.organization_name}
                onChange={handleBuildingChange}
                clearOnBlur
                fullWidth={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    placeholder="Select Building"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.placeholdertext },
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <FormLabel component="legend" className={classes.formTextColor}>
              Where is your issue?
            </FormLabel>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <RadioGroup
              onChange={handleRadioButtons}
              aria-label="Radio button for where is your issue"
              name="isInteriorOrExterior"
              row
              defaultValue={'interior'}
              className={classes.radioBtnForm}
            >
              <FormControlLabel
                value="interior"
                control={<Radio color="default" />}
                label="Interior"
              />
              <FormControlLabel
                value="exterior"
                control={<Radio color="default" />}
                label="Exterior"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              id="selected_floor-label"
              required
              className={classes.formTextColor}
            >
              {requestDetailsData.isInteriorOrExterior === interior
                ? 'Select Floor'
                : 'Select type of exterior'}
            </InputLabel>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              required
            >
              <Select
                labelId="selected_floor-label"
                id="floor_cre"
                name="selected_floor"
                value={requestDetailsData.selected_floor}
                onChange={handleFloorChange}
                autoWidth={true}
                fullWidth={true}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>
                    {requestDetailsData.isInteriorOrExterior === interior
                      ? 'Select Floor'
                      : 'Select type of exterior'}
                  </em>
                </MenuItem>

                {floors &&
                  floors.map((floor) => {
                    return (
                      <MenuItem
                        key={floor?.floor_id}
                        value={`${floor.floor_name}:${floor.maximo_floor_code}:${floor.floor_id}`}
                      >
                        {floor?.floor_name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <br />
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              required
              id="selected_area-label"
              className={classes.formTextColor}
            >
              Select sublocation
            </InputLabel>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              required
            >
              <Select
                labelId="selected_area-label"
                id="area"
                name="selected_sublocation"
                required={true}
                displayEmpty
                value={requestDetailsData.selected_sublocation}
                onChange={handleAreaChange}
                autoWidth={true}
                fullWidth={true}
              >
                <MenuItem value="" disabled>
                  <em>Select sublocation</em>
                </MenuItem>
                {allAreas.map((item) => (
                  <MenuItem
                    key={item?.area}
                    value={`${item.description}:${item?.area}:${item?.failure_class}`}
                  >
                    {item?.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />

        {requestDetailsData &&
          requestDetailsData.selected_sublocation !== '' &&
          requestDetailsData.selected_sublocation.split(':')[0] ===
            TMSpaces && (
            <>
              <Grid container>
                <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
                  <InputLabel
                    id="selected_space-label"
                    className={classes.formTextColor}
                  >
                    Select room/cube space(s)
                  </InputLabel>
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.autoCompleteStyle}
                  >
                    <Autocomplete
                      id="selected_space"
                      name="selected_space"
                      value={selectedSpaceData}
                      multiple
                      limitTags={1}
                      selectOnFocus={true}
                      onChange={handleSpaceSelection}
                      getOptionLabel={(option) =>
                        `${option.space_no} (${option.space_type})`
                      }
                      options={spaces}
                      getOptionDisabled={(option) => option.space_type === ''}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params)

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            space_no:
                              'If workspace is not present in list, please provide it in additional details',
                            space_type: '',
                          })
                        }

                        return filtered
                      }}
                      variant="outlined"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Available Spaces"
                          autoFocus={true}
                          variant="outlined"
                        />
                      )}
                      //   hidden={disabled}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
            </>
          )}

        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              required
              id="selected_type_issue-label"
              className={classes.formTextColor}
            >
              Select type of issue
            </InputLabel>
          </Grid>
          <Grid item lg={8} md={8} xs={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              required
            >
              <Select
                labelId="selected_type_issue-label"
                id="selected_type_issue"
                name="selected_type_issue"
                required={true}
                displayEmpty
                value={requestDetailsData.selected_type_issue}
                onChange={(event) => {
                  setRequestDetailsData({
                    ...requestDetailsData,
                    selected_type_issue: event.target.value,
                    problem_code: '',
                  })
                }}
                autoWidth={true}
                fullWidth={true}
              >
                <MenuItem value="" disabled>
                  <em>Select type of issue</em>
                </MenuItem>
                <MenuItem value="equipment">Equipment Issue</MenuItem>

                <MenuItem value="space">Space Issue</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        {requestDetailsData &&
          requestDetailsData.selected_type_issue === 'space' && (
            <>
              <Grid container>
                <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
                  <InputLabel
                    required
                    id="problem_code-label"
                    className={classes.formTextColor}
                  >
                    Select issue
                  </InputLabel>
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required
                  >
                    <Select
                      labelId="problem_code-label"
                      id="problem_code"
                      name="problem_code"
                      required={true}
                      displayEmpty
                      value={requestDetailsData.problem_code}
                      onChange={handleFieldsChange}
                      autoWidth={true}
                      fullWidth={true}
                    >
                      <MenuItem value="" disabled>
                        <em>Select issue</em>
                      </MenuItem>
                      {allProblemCodes && allProblemCodes.length > 0 ? (
                        allProblemCodes.map((item) => (
                          <MenuItem
                            key={item.failure_list_id}
                            value={item.failure_code}
                          >
                            {item.description}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={undefined} disabled>
                          No data
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <br />
              </Grid>
              <br />
            </>
          )}
        {requestDetailsData &&
          requestDetailsData.selected_type_issue === 'equipment' && (
            <>
              <Grid container>
                <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
                  <InputLabel
                    required
                    id="selected_equipment-label"
                    className={classes.formTextColor}
                  >
                    Select Equipment
                  </InputLabel>
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required
                  >
                    <Select
                      labelId="selected_equipment-label"
                      value={requestDetailsData.asset_number}
                      onChange={handleEquipmentChange}
                      id="selected_equipment"
                      name="asset_number"
                      required={true}
                      displayEmpty
                      autoWidth={true}
                      fullWidth={true}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Equipment</em>
                      </MenuItem>
                      {allEquipments && allEquipments.length > 0 ? (
                        allEquipments.map((item) => (
                          <MenuItem key={item?.asset} value={item?.asset}>
                            {item?.description}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={undefined} disabled>
                          No data
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <br />
              </Grid>
              <br />
            </>
          )}
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              id="short_description-label"
              required
              className={classes.formTextColor}
            >
              Short Description (Max 120 characters)
            </InputLabel>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <TextField
              id="short_description"
              labelId="short_description-label"
              name="short_description"
              value={requestDetailsData.short_description}
              onChange={handleFieldsChange}
              rows={4}
              defaultValue="Default Value"
              variant="outlined"
              className={classes.formControl}
              inputProps={{ maxLength: 120 }}
              InputProps={{
                classes: { input: classes.placeholdertext },
              }}
              placeholder="Explain problem in brief"
              required
            />
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item lg={4} md={4} xs={12} className={classes.labelStyle}>
            <InputLabel
              id="additional_details-label"
              className={classes.formTextColor}
            >
              Additional Details
            </InputLabel>
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <TextField
              id="additional_details"
              labelId="additional_details-label"
              name="additional_details"
              value={requestDetailsData.additional_details}
              onChange={handleFieldsChange}
              multiline
              rows={4}
              defaultValue="Default Value"
              variant="outlined"
              className={classes.formControl}
              InputProps={{
                classes: { input: classes.placeholdertext },
              }}
              placeholder="Provide additional details here. For e.g, workspace number, issue details, etc"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default RequestDetails
