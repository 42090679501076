import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Button from '@mui/material/Button'
import { SuccessDialogStyle } from '../../styles/successDialogStyle'
import { Typography } from '@mui/material'

const SuccessDialogWorkOrder = ({
  open,
  workOrderNumber,
  setOpen,
  clearState,
}) => {
  const classes = SuccessDialogStyle()

  const handleClick = () => {
    setOpen(false)
    clearState()
  }

  return (
    <Dialog open={open} aria-labelledby="success-dialog" fullWidth={true}>
      <DialogContent className={classes.dialogRoot}>
        <div className={classes.successBanner}>
          <CheckCircleIcon style={{ fontSize: 56, color: 'green' }} />
        </div>

        <DialogContentText className={classes.contentDialog}>
          <Typography>Your request has been successfully submitted.</Typography>
          <br />

          <Typography>
            Your workorder number is <b>{workOrderNumber}</b>.
          </Typography>
          <br />

          <Typography>
            Your request will be processed by CRE Building Operations. If this
            is a safety/security issue, please contact your building's security
            desk.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          className={classes.okBtn}
          onClick={handleClick}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuccessDialogWorkOrder
