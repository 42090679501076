import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import TextField from '@mui/material/TextField'

export const ModifiedTextfield = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#AC0000',
      },
    },
  },
})(TextField)

export const FormStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '4%',
    height: '100vh',
    // backgroundColor: theme.palette.secondary.main,
    padding: '1%',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 330,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
    // '& .MuiOutlinedInput-notchedOutline': {
    //   borderColor: '#AC0000',
    //   borderWidth: '1px',
    // },
    marginTop: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selected: {
    borderColor: '#AC0000',
    // '&:before': {
    //   borderColor: '#AC0000',
    // },
    // '&:after': {
    //   borderColor: '#AC0000',
    // },
  },
  // icon: {
  //   fill: '#AC0000',
  // },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 330,
    minWidth: 330,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw',
    },
  },
  paper: {
    minWidth: 275,
    // border: '2px solid #AC0000',
    borderRadius: '15px',
  },
  cardRoot: {
    borderRadius: 16,
    padding: '1%',
    overflow: 'visible',
    // border: '2px solid #AC0000',
    // transform:'translate(50%,0px)',
    [theme.breakpoints.up('lg')]: {
      // width:'100%',
      // margin: '75px 4px 16px 4px',
      paddingLeft: '30%',
    },
  },
  radiosStyle: {
    border: '1px solid #cfcfcf',
    width: 330,
    borderRadius: 4,
    margin: 8,
  },
  labelColor: { color: theme.palette.primary.main },
  formControlStyle: {
    padding: 16,
  },
  homeIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 6,
  },
  nicolletDatePicker: {
    zIndex: 2,
    minWidth: '330px !important',
    // borderRadius:8,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: `${theme.typography.fontSize}px !important`,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90vw !important',
    },
  },
  visitPurpose: {
    marginTop: '20%',
  },
  datePickerDialog: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    backgroundColor: 'white',
  },
  closeButton: {
    textAlign: 'center',
    marginBottom: 4,
  },
  errorColor: {
    color: 'red',
    marginLeft: 8,
  },
}))
