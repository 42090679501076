import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { SwipeableList, SwipeableListItem } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { CSVLink } from 'react-csv'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import { css } from '@emotion/react'
import BeatLoader from 'react-spinners/BeatLoader'
import RefreshIcon from '@mui/icons-material/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    marginTop: '10px',
    backgroundColor: '#f7f8fa',
    border: '1px solid #BFBFBF',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    width: '22ch',
  },
  media: {
    height: 170,
  },
  formControl: {
    minWidth: '157px !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#6b41cb !important',
      borderWidth: '2px',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 210,
    height: 35,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconHome: {
    border: `0.5px solid ${theme.palette.primary.main}`,
  },
  moveRight: {
    marginLeft: 'auto',
  },
  typographyHeading: {
    fontSize: '13px',
  },
  typographyValue: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
}))

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

export default function ToBeCleanedList() {
  const env = useEnv()
  const classes = useStyles()
  const [cleaningList, setCleaningList] = React.useState([])
  const [searchField, setSearchField] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const floorId = useSelector((state) => state.housekeeping.selectedFloor)
  const floorName = useSelector((state) => state.housekeeping.selectedFloorName)
  const buildingId = useSelector((state) => state.housekeeping.buildingId)
  const buildingName = useSelector(
    (state) => state.housekeeping.selectedBuilding,
  )
  const cleaningDate = moment(
    useSelector((state) => state.housekeeping.cleaningDate),
  ).format('MM/DD/YYYY')

  const getCleaningList = () => {
    const usa_url =
      env.apiUrl['USA'].getCleaningList +
      `date=${cleaningDate}&building_id=${buildingId}&floor_id=${floorId}`
    const india_url =
      env.apiUrl['India'].getCleaningList +
      `cleaning_date=${cleaningDate}&building_id=${buildingId}&floor_id=${floorId}`
    const final_url =
      localStorage.getItem('base_location') === 'India' ? india_url : usa_url
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(final_url, config)
      .then((res) => {
        setCleaningList(res.data.cleaning_space_details)
        setCleaningList(
          res.data.cleaning_space_details.filter(
            (item) => item.cleaning_status === 'Cleaned',
          ),
        )
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCleaningList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e) => {
    setSearchField(e.target.value)
  }

  const filteredResults = cleaningList.filter((res) => {
    return (
      res.desk_id.toLowerCase().includes(searchField.toLowerCase()) ||
      res.priority.toLowerCase().includes(searchField.toLowerCase()) ||
      res.start_time.toLowerCase().includes(searchField.toLowerCase()) ||
      res.end_time.toLowerCase().includes(searchField.toLowerCase()) ||
      res.cleaning_time.toLowerCase().includes(searchField.toLowerCase())
    )
  })

  const convertAMPMFromHours = (time) => {
    let colonSplit = time.split(':')
    let timeInNumber = Number(colonSplit[0])
    let finalValue =
      timeInNumber > 12
        ? timeInNumber - 12 + `:${colonSplit[1]} PM`
        : timeInNumber === 12
        ? timeInNumber + `:${colonSplit[1]} PM`
        : timeInNumber + `:${colonSplit[1]} AM`
    return finalValue
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item></Grid>
        <Grid item>
          <Tooltip title="Download" enterTouchDelay="0" arrow>
            <IconButton size="small">
              <CSVLink
                filename="NotCleanedData.csv"
                data={cleaningList}
                style={{ color: '#AC0000' }}
              >
                <CloudDownloadIcon />
              </CSVLink>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Paper component="form" className={classes.search}>
            <IconButton
              disabled
              className={classes.iconButton}
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              disabled
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item>
          <Tooltip title="Refresh" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              className={classes.iconHome}
              onClick={() => {
                setLoading(true)
                getCleaningList()
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/housekeeping/landing"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={3}>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Building:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>{buildingName}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Floor:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>{floorName}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Date:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {moment(cleaningDate).format('MMM Do')}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography style={{ fontSize: '15px' }}>Total Tasks:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {filteredResults.length}
          </Typography>
        </Grid>
      </Grid>
      <hr />
      {loading === true ? (
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '50vh' }}
        >
          <BeatLoader color="#AC0007" css={override} size={15} />
        </Grid>
      ) : (
        <>
          {filteredResults.length !== 0 ? (
            <SwipeableList>
              {filteredResults.map(
                ({
                  reservation_id,
                  desk_id,
                  start_time,
                  end_time,
                  cleaning_status,
                  priority,
                  cleaning_time,
                }) => (
                  <SwipeableListItem blockSwipe={true}>
                    <Card className={classes.root} elevation={6}>
                      <CardContent className={classes.content}>
                        <div>
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            style={{ fontWeight: '520' }}
                          >
                            <Grid item xs={6} sm={3}>
                              <Typography className={classes.typographyHeading}>
                                Desk:
                              </Typography>{' '}
                              <Typography className={classes.typographyValue}>
                                {desk_id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className={classes.typographyHeading}>
                                Priority:
                              </Typography>
                              <Typography className={classes.typographyValue}>
                                {' '}
                                {priority}{' '}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className={classes.typographyHeading}>
                                Cleaning Start Time:
                              </Typography>{' '}
                              <Typography className={classes.typographyValue}>
                                {convertAMPMFromHours(
                                  moment(start_time).format('HH:mm'),
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className={classes.typographyHeading}>
                                Cleaning Completed Time:
                              </Typography>{' '}
                              <Typography className={classes.typographyValue}>
                                {convertAMPMFromHours(
                                  moment(cleaning_time).format('HH:mm'),
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>{' '}
                    <br />{' '}
                  </SwipeableListItem>
                ),
              )}
            </SwipeableList>
          ) : (
            <Typography
              align="center"
              style={{
                marginTop: '50px',
              }}
            >
              No records to display
            </Typography>
          )}
        </>
      )}
    </>
  )
}
