import FileSaver from 'file-saver'
import Excel from 'exceljs'

export const ExportWithColumnProtection = (data, filename) => {
  const key = 'C8pvRJ9F'
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  let dateFormat = 'MM/DD/YYYY'
  let reqColumn = ['A1', 'B1', 'C1', 'D1']
  const fileName = filename
  const excelFile = createExcelWorkBook(data, key, reqColumn, dateFormat)
  writeExcelJSWorkBookToFile(excelFile, fileName, fileType)
}

const createExcelWorkBook = (data, value, reqColumn, dateFormat) => {
  const workbook = new Excel.Workbook()
  const sheet = workbook.addWorksheet('Space Details')

  sheet.columns = [
    { header: 'building_id', key: 'building_id', width: 30 },
    { header: 'floor_id', key: 'floor_id', width: 10 },
    { header: 'space_id', key: 'space_id', width: 10 },
    {
      header: 'space_name',
      key: 'space_name',
      width: 15,
    },
    {
      header: 'space_status',
      key: 'space_status',
      width: 15,
    },
    {
      header: 'allocated_name(FirstName.LastName)',
      key: 'allocated_name',
      width: 40,
    },
    { header: 'lan_id', key: 'employee_id', width: 15 },
    {
      header: 'manager_name(FirstName.LastName)',
      key: 'manager_name',
      width: 40,
    },
    { header: 'pyramid', key: 'pyramid', width: 25 },
    { header: 'floor_name', key: 'floor_name', width: 25, hidden: true },
  ]

  sheet.addRows(data)
  sheet.getRow(1).font = { bold: true }
  sheet.getColumn('A').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'b3b3b3' },
  }
  sheet.getColumn('B').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'b3b3b3' },
  }
  sheet.getColumn('C').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'b3b3b3' },
  }
  sheet.getColumn('D').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'b3b3b3' },
  }
  sheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('A1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('B1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('B1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('C1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('C1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('D1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('D1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('E1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('E1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('F1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('F1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('G1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('G1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('H1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('H1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }
  sheet.getCell('I1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'fcfc03' },
  }
  sheet.getCell('I1').border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }

  sheet.dataValidations.add('E2:E9999', {
    type: 'list',
    allowBlank: false,
    formulae: ['"Reservable,Permanent"'],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Invalid value',
    error: 'The value must be from the dropdown',
  })

  sheet.dataValidations.add('I2:I9999', {
    type: 'list',
    allowBlank: true,
    formulae: [
      '"Communications Ops & Strategic Planning,Digital Ops,Finance,HR,Legal Affairs, Marketing, Merchandising, Process Innovation & Automation,Stores,Supply Chain,Target Properties & CRE,Target Tech, None"',
    ],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Invalid value',
    error: 'The value must be from the dropdown',
  })

  reqColumn = reqColumn.map(
    (key) =>
      (sheet.getCell(key).font = {
        color: { argb: 'FF0000' },
        bold: true,
      }),
  )

  sheet.dataValidations.add('G2:G9999', {
    type: 'custom',
    formulae: ['=COUNTIF(G$2:G$9999,G2)<2'],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Only unique values',
    error: 'The employee id you entered already exists in the excel sheet',
  })

  sheet.dataValidations.add('F2:F9999', {
    type: 'custom',
    formulae: ['=COUNTIF(F$2:F$9999,F2)<2'],
    showErrorMessage: true,
    errorStyle: 'error',
    errorTitle: 'Only unique values',
    error: 'The name you entered already exists in the excel sheet',
  })

  sheet.autoFilter = {
    from: 'A1',
    to: 'I1',
  }

  //Get the column names and set lock false for all the columns except first three
  let columnNames = Object.keys(data[0]).slice(4)
  for (let i = 0; i < columnNames.length; i++) {
    let column = sheet.getColumn(columnNames[i])
    column.protection = { locked: false }
  }
  sheet.getCell('E1').protection = {
    locked: true,
  }
  sheet.getCell('F1').protection = {
    locked: true,
  }
  sheet.getCell('G1').protection = {
    locked: true,
  }
  sheet.getCell('H1').protection = {
    locked: true,
  }
  sheet.getCell('I1').protection = {
    locked: true,
  }
  sheet.protect(value, {
    autoFilter: true,
    sort: true,
  })
  return workbook
}

const writeExcelJSWorkBookToFile = (excelFile, fileName, fileType) => {
  excelFile.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: fileType,
    })
    FileSaver.saveAs(blob, fileName)
  })
}

export default ExportWithColumnProtection
