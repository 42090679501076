import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import Grid from '@mui/material/Grid'
import EventIcon from '@mui/icons-material/Event'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import { workstation, Desk, Office } from '../../components/common/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    // border: '2px solid #AC0000',
    borderRadius: '7px',
    backgroundColor: '#f7f8fa',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  button: {
    width: '22ch',
  },
  media: {
    height: 170,
  },
  formControl: {
    minWidth: '157px !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#6b41cb !important',
      borderWidth: '2px',
    },
  },
  iconHome: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: 220,
    height: 35,
  },
}))

export default function CancelledReservations() {
  const env = useEnv()
  const auth = useAuth()
  const { session } = auth
  const classes = useStyles()
  const [reservationList, setReservationList] = React.useState([])
  const [searchField, setSearchField] = React.useState('')

  const getReservationList = () => {
    const url =
      env.apiUrl['USA'].viewReservationsUrl +
      session.userInfo.email.split('@')[0]
    const config = {
      headers: {
        Authorization: localStorage.access_token,
      },
    }
    axios
      .get(url, config)
      .then((res) => {
        setReservationList(
          res.data.filter((item) => item.status === 'Inactive'),
        )
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    getReservationList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const convertAMPMFromHours = (time) => {
    //time= "13:00"
    let colonSplit = time.split(':') //['13','00']
    let timeInNumber = Number(colonSplit[0])
    let finalValue =
      timeInNumber > 12
        ? timeInNumber - 12 + `:${colonSplit[1]} PM`
        : timeInNumber === 12
        ? timeInNumber + `:${colonSplit[1]} PM`
        : timeInNumber + `:${colonSplit[1]} AM`
    return finalValue
  }

  const handleChange = (e) => {
    setSearchField(e.target.value)
  }

  const filteredResults = reservationList.filter((res) => {
    return res.booking_date.toLowerCase().includes(searchField.toLowerCase())
  })

  return (
    <div>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item>
          <Typography>
            <b>
              <i> Total - {filteredResults.length} </i>
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Paper component="form" className={classes.search}>
            <IconButton
              disabled
              className={classes.iconButton}
              aria-label="menu"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search by Date"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search by Date"
              disabled
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item>
          <Tooltip title="Home" enterTouchDelay="0" arrow>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to="/tm/home"
              className={classes.iconHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <br />
      {filteredResults.map((e) => {
        return (
          <div>
            <Card className={classes.root} elevation={4}>
              <CardContent className={classes.content}>
                {e.start_time !== null ? (
                  <div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                          {e.booking_date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <EventIcon />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Building:{' '}
                        <b>
                          {e.organisation === 'Target India-GWS Center'
                            ? 'TI-GWS'
                            : e.organisation}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Floor: <b>{e.floor_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space ID: <b>{e.desk_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space Type:{' '}
                        <b>
                          {' '}
                          {e.workspace_type === workstation
                            ? Desk
                            : Office}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Type:{' '}
                        {e.end_time === '18:15' ? (
                          <b> Full </b>
                        ) : (
                          <b> Partial </b>
                        )}
                      </Grid>
                      {e.end_time !== '18:15' ? (
                        <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                          Start Time:{' '}
                          <b>{convertAMPMFromHours(e.start_time)}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.end_time !== '18:15' ? (
                        <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                          End Time: <b>{convertAMPMFromHours(e.end_time)}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.reserved_for !==
                      session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved For: <b>{e.reserved_for}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.booked_by !== session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved By: <b>{e.booked_by}</b>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                          {e.booking_date}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <EventIcon />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Building:{' '}
                        <b>
                          {e.organisation === 'Target India-GWS Center'
                            ? 'TI-GWS'
                            : e.organisation}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Floor: <b>{e.floor_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space ID: <b>{e.desk_id}</b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Space Type:{' '}
                        <b>
                          {' '}
                          {e.workspace_type === workstation
                            ? Desk
                            : Office}{' '}
                        </b>
                      </Grid>
                      <Grid item xs={6} sm={3} style={{ fontSize: '0.9rem' }}>
                        Type: <b>Full</b>
                      </Grid>
                      {e.reserved_for !==
                      session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved For: <b>{e.reserved_for}</b>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {e.booked_by !== session.userInfo.email.split('@')[0] ? (
                        <Grid item style={{ fontSize: '0.9rem' }}>
                          Reserved By: <b>{e.booked_by}</b>{' '}
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </div>
                )}
              </CardContent>
            </Card>{' '}
            <br />{' '}
          </div>
        )
      })}
    </div>
  )
}
