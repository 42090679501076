let initialState = {
  reservationFields: {
    building_id: '',
    reservation_date: [],
    start_time: '',
    end_time: '',
    workspace_type: 'workstation',
    fullDay: false,
    reservation_id: null,
    declaration_form_data: '',
    reserved_for: '',
    lan_id: null,
    food_needed: '',
    type_of_food: '',
    transportation_needed: '',
    meeting_id: '',
    location_timezone: '',
    organisationName: '',
    primary_location: 'US',
  },
  declaration_form_data: '',
  availableDesks: {},
  selectedFloor: '',
  reservingFor: false,
}

export function TeamMemberReservationReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case 'SAVE_RESERVATION_DETAILS':
      return Object.assign({}, state, {
        reservationFields: payload,
      })

    case 'SAVE_DECLARATION_FORM':
      return Object.assign({}, state, {
        declaration_form_data: payload,
      })

    case 'CLEAR_RESERVATION_DETAILS':
      return Object.assign({}, state, {
        reservationFields: initialState.reservationFields,
      })

    case 'AVAILABLE_DESKS':
      return Object.assign({}, state, {
        availableDesks: payload,
      })

    case 'SELECTED_FLOOR':
      return Object.assign({}, state, {
        selectedFloor: payload,
      })
    case 'RESERVING_FOR':
      return Object.assign({}, state, {
        reservingFor: payload,
      })
    default:
      return state
  }
}
