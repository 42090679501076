import makeStyles from '@mui/styles/makeStyles'

export const DashboardStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 330,
    maxwidth: 330,
  },
  searchBar: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: '96%',
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    // overflow: 'auto',
  },

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 120,
  },
  filter: {
    width: 90,
  },
  toolbarDashStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarTitle: {
    flexGrow: 5,
  },
}))
