import makeStyles from '@mui/styles/makeStyles'

export const RequestFormStyle = makeStyles((theme) => ({
  root: {
    // marginTop: '1%',
    padding: '1%',
  },
  cardParent: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '20%',
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 310,
    maxWidth: 360,
  },
  cardRoot: {
    borderRadius: 16,
    padding: '1%',
    overflow: 'visible',

    [theme.breakpoints.between('lg', 'xl')]: {
      width: '70%',
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    textAlign: 'left',
  },
  alignment: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
  },
  radioLabel: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  labelProps: {
    fontSize: '15px',
    lineHeight: '50px',
  },
  leftMargin: {
    marginLeft: '30px',
  },
  requestTitle: {
    color: theme.palette.primary.main,
  },
  labelStyle: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 32,
    },
    paddingLeft: 8,
  },
  formRoot: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 32,
    },
    // paddingTop: 16,
  },
  radioBtnForm: {
    margin: 8,
  },
  actionButtons: {
    justifyContent: 'center',
  },
  centralise: {
    textAlign: 'center',
  },
  pad: {
    padding: '4px 0px 4px 0px',
  },
  autoCompleteStyle: {
    margin: theme.spacing(2),
    minWidth: 310,
    maxWidth: 310,
  },
  iconHome: {
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'absolute',
    top: 74,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  btnBorderColor: {
    border: '1px solid #636363',
  },
  formTextColor: {
    color: '#616161',
  },
  placeholdertext: {
    '&::placeholder': {
      color: '#000000AD',
      opacity: 1,
      fontStyle: 'italic',
    },
  },
}))
